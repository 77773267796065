import { OrderHistory } from "~/application/types/entities/OrderHistory.type";
import { toDate } from "~/application/utils/date-functions";
import { OrderHistoryDTO } from "../dtos/OrderHistoryDTO";

export function mapOrderHistoryDTO(dto: OrderHistoryDTO): OrderHistory {
  return {
    uuid: dto.uuid,
    responsible: {
      uuid: dto.responsible.uuid,
      name: dto.responsible.name,
      avatarUrl: dto.responsible.avatar_url,
      context: dto.responsible.context,
    },
    description: {
      departureDate: toDate(dto.description.departure_date),
      arrivalDate: toDate(dto.description.arrival_date),
      history: dto.description.history,
      providerIdentification: dto.description.provider_identification,
      tracker: dto.description.tracker,
      value: dto.description.value,
    },
    status: dto.status,
    hour: dto.hour,
  };
}
