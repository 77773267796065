import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { Actions, CustomerEmployeesGroup } from "~/application/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { IconButton } from "~/components/IconButton";
import { SvgChevronDown, SvgChevronUp, SvgEdit } from "~/components/Icon/icons";
import { Caption } from "~/components/Typography";
import { Icon } from "~/components/Icon";
import { SearchBar, Switch } from "~/components/Input";
import { GroupEmployee } from "../../../hooks/useCustomerEmployeesGroups";
import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { Flex } from "~/components/Flex";
import { Collapse } from "react-collapse";
import { Text } from "~/components/Text";
import { Col } from "~/components/Grid";
import { Card, CardSectionHeader } from "~/components/Card";
import { Spinner } from "~/components/Spinner";

export interface CustomerEmployeesGroupListItemProps extends DataListItemProps {
  data: CustomerEmployeesGroup;
  onEditClick: (item: CustomerEmployeesGroup) => void;
  onToggleState: (item: CustomerEmployeesGroup) => void;
  fetchEmployees: ({
    name,
    groupIds,
  }: {
    name: string;
    groupIds: string[];
  }) => UseQueryResult<GroupEmployee[], unknown>;
}

export function CustomerEmployeesGroupListItem({
  data,
  onEditClick,
  onToggleState,
  fetchEmployees,
  ...props
}: CustomerEmployeesGroupListItemProps) {
  const { contexts, profile } = useUser();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleOpen = () => {
    if (!isCollapsed) {
      refetch();
    }
    setIsCollapsed(!isCollapsed);
  };

  const [name, setValue] = useState("");
  const currentName = useDebounce(name, 700);

  const canUpdateCustomerEmployeesGroup = useVerifyActions({
    actions: [Actions.CreateUpdateCustomerEmployeesGroup],
    contexts,
    profile,
  });

  const {
    data: customerEmployees,
    isLoading: isLoadingCustomerEmployees,
    refetch,
  } = fetchEmployees({
    groupIds: [data.uuid],
    name: currentName,
  });

  useEffect(() => {
    if (currentName) {
      refetch();
    }
  }, [currentName]);

  return (
    <Flex direction={"column"}>
      <DataListItem
        onClick={toggleOpen}
        data-active={data.active}
        data-list={isCollapsed}
        {...props}
        css={{
          cursor: "pointer",
          borderBottomLeftRadius: isCollapsed ? "$none" : undefined,
          borderBottomRightRadius: isCollapsed ? "$none" : undefined,
          "&:hover::after": {
            boxShadow: isCollapsed ? "$none" : undefined,
          },
        }}
      >
        <DataColItem headerTitle="Grupo" data={data.description} />

        {canUpdateCustomerEmployeesGroup && (
          <>
            <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
              <IconButton size="md" onClick={() => onEditClick(data)}>
                <Icon as={SvgEdit} />
              </IconButton>
            </Tooltip>

            <Switch checked={data.active} onClick={() => onToggleState(data)}>
              <Caption>{data.active ? "Ativo" : "Inativo"}</Caption>
            </Switch>
          </>
        )}

        <IconButton size="md">
          <Icon as={isCollapsed ? SvgChevronUp : SvgChevronDown} />
        </IconButton>
      </DataListItem>
      <Collapse isOpened={isCollapsed}>
        <Flex
          direction={"column"}
          css={{
            backgroundColor: "$neutrals-white",
            borderWidth: "0 2px 2px 2px",
            borderStyle: "solid",
            borderColor: "$primary-base",
            borderBottomLeftRadius: "$md",
            borderBottomRightRadius: "$md",
            p: "$6",
          }}
          gap="2"
        >
          <SearchBar onSearchChange={setValue} placeholder="Buscar por nome" />
          {isLoadingCustomerEmployees ? (
            <Flex align={"center"} justify={"center"}>
              <Spinner />
            </Flex>
          ) : (
            <Card
              css={{ width: "100%", margin: "0 auto", display: "flex", flexDirection: "column" }}
            >
              <CardSectionHeader
                css={{
                  p: "$5",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DataColItem
                  data={
                    <Flex>
                      <Text>Nome completo</Text>
                    </Flex>
                  }
                />

                <DataColItem
                  css={{
                    flex: "1.5",
                  }}
                  data={
                    <Flex>
                      <Text>E-mail</Text>
                    </Flex>
                  }
                />
                <DataColItem
                  data={
                    <Flex>
                      <Text>Perfil</Text>
                    </Flex>
                  }
                />
                <DataColItem
                  data={
                    <Flex>
                      <Text>Aprova</Text>
                    </Flex>
                  }
                />
                <DataColItem
                  css={{ flex: "0.5" }}
                  data={
                    <Flex>
                      <Text>Status</Text>
                    </Flex>
                  }
                />
              </CardSectionHeader>

              {(customerEmployees?.length || 0) <= 0 && (
                <Text css={{ p: "$5" }}>Nenhuma empresa encontrada</Text>
              )}

              {customerEmployees?.map((customerEmployee) => (
                <Flex
                  key={customerEmployee.uuid}
                  justify="between"
                  css={{
                    p: "$5",
                    gap: "$4",
                    wordBreak: "break-word",
                    borderBottom: "1px solid $colors$neutrals-light",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <DataColItem
                    data={
                      <Col css={{ wordBreak: "break-all" }}>
                        <Text size="2">
                          {customerEmployee.name} {customerEmployee.lastName}
                        </Text>
                      </Col>
                    }
                  />
                  <DataColItem
                    css={{
                      flex: "1.5",
                    }}
                    data={
                      <Col css={{ wordBreak: "break-all" }}>
                        <Text size="2">{customerEmployee.email}</Text>
                      </Col>
                    }
                  />
                  <DataColItem
                    data={
                      <Col css={{ wordBreak: "break-all" }}>
                        <Text size="2">{customerEmployee?.profile?.name}</Text>
                      </Col>
                    }
                  />
                  <DataColItem
                    data={
                      <Col css={{ wordBreak: "normal" }}>
                        <Text
                          size="2"
                          variant={!customerEmployee?.profile?.isApprover ? "error-base" : "sucess"}
                        >
                          {customerEmployee?.profile?.isApprover ? "Sim" : "Não"}
                        </Text>
                      </Col>
                    }
                  />
                  <DataColItem
                    css={{ flex: "0.5" }}
                    data={
                      <Col css={{ wordBreak: "normal" }}>
                        <Text
                          size="2"
                          variant={!customerEmployee?.isActive ? "error-base" : "sucess"}
                        >
                          {customerEmployee?.isActive ? "Ativo" : "Inativo"}
                        </Text>
                      </Col>
                    }
                  />
                </Flex>
              ))}
            </Card>
          )}
        </Flex>
      </Collapse>
    </Flex>
  );
}
