import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { CustomerSettingParameter } from "~/application/types";
import {
  getAvailableParameterOptions,
  SettingParameterTypeValue,
} from "~/application/types/entities/SettingParameter.type";
import { customerSettingParameterService } from "~/application/usecases/CustomerSettingParameter";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { CustomerSettingParameterDialog } from "../components/SettingParameterDialog";
import { QueryKeys } from "~/constants/queryKeys";

const LOG_TAG = "Customer/CustomerPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar paramêtros de configuração de empresas",
  CREATE_SUCCESS_MESSAGE: "Parâmetro de configuração criado",
  CREATE_ERROR_MESSAGE: "Falha ao criar paramêtro de configuração",
  UPDATE_SUCCESS_MESSAGE: "Parâmetro de configuração atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar paramêtro de configuração",
} as const;

export interface UseSettingParametersResult {
  customerSettingParameters?: CustomerSettingParameter[];
  isLoadingCustomerSettingParameters: boolean;
  isUpdatingCustomerSettingParameter: boolean;
  onEditClick: (item: CustomerSettingParameter) => void;
}

export function useSettingParameters(): UseSettingParametersResult {
  const { user, contexts } = useUser();
  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;

  const { data: customerSettingParameters, isLoading: isLoadingCustomerSettingParameters } =
    useQuery(
      [QueryKeys.CUSTOMER_SETTING_PARAMETERS, customerId],
      () => customerSettingParameterService.find({ customerId }),
      {
        refetchOnWindowFocus: false,
        enabled: true,
      }
    );

  const {
    mutateAsync: updateCustomerSettingParameter,
    isLoading: isUpdatingCustomerSettingParameter,
  } = useMutation(
    async (data: CustomerSettingParameter) => {
      return await customerSettingParameterService.update({
        ...data,
        customerId,
      });
    },
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Customer(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated CustomerSettingParameter(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_SETTING_PARAMETERS, customerId]);
        window.location.reload();
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
    }
  );

  useEffect(() => {
    if (isUpdatingCustomerSettingParameter) {
      dialogService.popDialog();
    }
  }, [isUpdatingCustomerSettingParameter]);

  const handleOpenEditModal = useCallback((item: CustomerSettingParameter) => {
    if (item.typeValue === SettingParameterTypeValue.BOOLEAN) {
      updateCustomerSettingParameter({ ...item, value: !item.value });
      return;
    }

    const options =
      item.typeValue === SettingParameterTypeValue.SELECT
      ? getAvailableParameterOptions(item.slug)
      : undefined;

    dialogService.showDialog(
      <CustomerSettingParameterDialog
        onSubmit={updateCustomerSettingParameter}
        data={item}
        options={options}
      />
    );
  }, []);

  return {
    customerSettingParameters,
    isLoadingCustomerSettingParameters,
    onEditClick: handleOpenEditModal,
    isUpdatingCustomerSettingParameter,
  };
}
