import { FC } from "react";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { ApprovalModelListItemProps } from "./types";
import { useVerifyActions } from "../../hooks/useVerifyActions";
import { Actions } from "~/application/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { Flex } from "~/components/Flex";
import useMobile from "../../hooks/useMobile";

export const ApprovalModelListItem: FC<ApprovalModelListItemProps> = ({
  data,
  onEditClick,
  onToggleState,
  ...props
}) => {
  const { contexts, profile } = useUser();
  const isMobile = useMobile();

  const canCreateApprovalModel = useVerifyActions({
    actions: [Actions.CreateApprovalModel],
    contexts,
    profile,
  });
  return (
    <DataListItem data-active={data.isActive} {...props} css={{ "@mxlg": { flexDirection: "column" }}}>
      <DataColItem headerTitle="Nome" data={data.name} />

      <DataColItem
        headerTitle="Aplica-se para todas as filiais"
        data={data.isAllBranches ? "sim" : "não"}
      />

      {onEditClick && canCreateApprovalModel && !isMobile && (
        <Tooltip content={<TooltipLabel>Editar Filial</TooltipLabel>}>
          <IconButton size="md" onClick={() => onEditClick(data)}>
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>
      )}

      {onToggleState && canCreateApprovalModel && !isMobile && (
        <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
          <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
        </Switch>
      )}

      <Flex justify="between" css={{ "@lg": { display: "none" }, width: "100%" }}>
        {onEditClick && canCreateApprovalModel && (
          <Tooltip content={<TooltipLabel>Editar Filial</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
        )}

        {onToggleState && canCreateApprovalModel && (
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        )}
      </Flex>
    </DataListItem>
  );
};

ApprovalModelListItem.displayName = "ApprovalModelListItem";
