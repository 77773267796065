import { OrderRoadSegment, OrderStatus, RoadTraveler } from "~/application/types";
import { Alert } from "~/components/Alert";
import { CardProps } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Text } from "~/components/Text";
import { asCPF } from "~/utils/mask.utils";
import { RoadTravelerBpeInfo } from "./components/RoadTravelerBpeInfo";
import { DateUtils } from "~/application/utils";
import { AdicionalTravelerInfo } from "../AdicionalTravelerInfo";
import { dialogService } from "~/components/DialogStack";
import { useOrder } from "../../hooks/useOrder";
import { Button } from "~/components/Button";
import { SvgInfo } from "~/components/Icon/icons";
import { Icon } from "~/components/Icon";

export interface RoadTravelerListItemProps extends CardProps {
  item: Pick<OrderRoadSegment, "connection" | "departure" | "arrival">;
  traveler: RoadTraveler;
  orderStatus: OrderStatus;
}

export function RoadTravelerListItem({
  item,
  traveler,
  orderStatus,
  ...props
}: RoadTravelerListItemProps) {
  const doestNotHaveAnyBpe = traveler.seats.every(({ bpe }) => !bpe);
  const customerDateOfBirth = DateUtils.smallDateFormat(traveler.dateBirth);

  const { order } = useOrder();

  const onOpenMoreInfoDialog = () => {
    dialogService.showDialog(<AdicionalTravelerInfo travelerId={traveler.uuid} order={order} />);
  };
  const orderIsIssued = orderStatus === OrderStatus.ISSUED;
  const travelerComplete = order?.travelers.find((t) => traveler.uuid === t.uuid);
  return (
    <Flex direction="column" {...props}>
      <Flex
        css={{
          borderRadius: "$md",
          backgroundColor: "$neutrals-white",
          width: "100%",
          p: "$3 $6 $3 $6",
          "@mxmd": {
            p: 0,
          },
        }}
      >
        <Flex gap="4" direction="column" css={{ width: "100%" }}>
          <Flex
            justify="between"
            direction={{ "@mxmd": "column" }}
            gap="4"
            css={{
              p: "$6",
            }}
          >
            <Flex
              direction={{ "@initial": "column", "@mxmd": "row" }}
              align={{ "@mxmd": "center" }}
            >
              <Text
                size="2"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "500",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                    display: "none",
                  },
                }}
              >
                Nome
              </Text>

              <Text size={{ "@initial": "2", "@mxmd": "3" }} css={{ fw: "600" }}>
                {traveler.fullName}
              </Text>
            </Flex>
            <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
              <Text
                size="2"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "500",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                  },
                }}
              >
                CPF
              </Text>

              <Text
                size="2"
                css={{
                  fw: "600",
                  "@mxmd": {
                    color: "#727272",
                  },
                }}
              >
                {asCPF(traveler.cpf)}
              </Text>
            </Flex>
            <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
              <Text
                size="2"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "600",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                  },
                }}
              >
                RG
              </Text>

              <Text size="2" css={{ fw: "600", wordBreak: "break-all" }}>
                {traveler.rg}
              </Text>
            </Flex>

            <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
              <Text
                size="2"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "600",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                  },
                }}
              >
                Data de nascimento
              </Text>

              <Text size="2" css={{ fw: "600", wordBreak: "break-all" }}>
                {customerDateOfBirth}
              </Text>
            </Flex>
            {travelerComplete?.group?.description && (
              <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
                <Text
                  size="2"
                  variant="dark"
                  css={{
                    mb: "1rem",
                    fw: "600",
                    display: "block",
                    "@mxmd": {
                      mr: "$3",
                    },
                  }}
                >
                  Grupo
                </Text>

                <Text size="2" css={{ fw: "600", wordBreak: "break-all" }}>
                  {travelerComplete?.group?.description}
                </Text>
              </Flex>
            )}
            <Flex direction={{ "@initial": "column", "@mxmd": "row" }}>
              <Text
                size="2"
                variant="dark"
                css={{
                  mb: "1rem",
                  fw: "600",
                  display: "block",
                  "@mxmd": {
                    mr: "$3",
                  },
                }}
              >
                Função
              </Text>

              <Text size="2" css={{ fw: "600", wordBreak: "break-all" }}>
                {traveler.position}
              </Text>
            </Flex>
            <Flex justify="center">
              <Button variant="tertiary" onClick={onOpenMoreInfoDialog} size="sm">
                <Icon as={SvgInfo} />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {
        <Flex
          css={{
            boxSizing: "border-box",
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            transition: "$fast",
            borderRadius: "$md",
          }}
        >
          <Flex
            css={{
              backgroundColor: "$neutrals-white",
              p: "0 $2 $6 0",
              borderRadius: " $md 0 0 $md ",
            }}
          ></Flex>
          <Flex
            css={{
              backgroundColor: "$neutrals-white",
              border: "2px $neutrals-light",
              borderStyle: "dashed none none none",
              width: "100%",
              p: "$3 $6 $3 $6",
              gap: "$1",
            }}
            direction="column"
          >
            {traveler.seats.map(({ seatNumber, ticket, bpe, isConnection }, index) => {
              const travelName = isConnection
                ? `${item.connection?.name} -> ${item.arrival}`
                : `${item.departure} -> ${item.connection ? item.connection.name : item.arrival}`;

              const key = `${travelName}-${ticket}-${bpe}-${index}`;

              return (
                <RoadTravelerBpeInfo
                  key={key}
                  seatNumber={seatNumber}
                  ticket={ticket}
                  doestNotHaveAnyBpe={doestNotHaveAnyBpe}
                  orderIsIssued={orderIsIssued}
                  enabled={!!bpe}
                  bpe={bpe}
                  travelName={travelName}
                />
              );
            })}
          </Flex>
          <Flex
            css={{
              backgroundColor: "$neutrals-white",
              p: "0 $2 $6 0",
              borderRadius: " 0 $md $md 0 ",
            }}
          ></Flex>
        </Flex>
      }
    </Flex>
  );
}

RoadTravelerListItem.displayName = "RoadTravelerListItem";
