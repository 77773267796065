import { BudgetOption, SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { useVerifyParameter } from "~/presentation/shared/hooks/useVerifyParameter";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { IconButton } from "~/components/IconButton";
import { SvgEdit } from "~/components/Icon/icons";
import { Actions, Budget, Customer } from "~/application/types";
import { Icon } from "~/components/Icon";
import { asCurrency } from "~/utils/mask.utils";
import { getPeriodicity, Periodicity } from "../utils";

export interface BudgetListItemProps extends DataListItemProps {
  data: Budget;
  customer?: Customer;
  onEditBudget: (item: Budget, customer: Customer) => void;
}

export function BudgetListItem({ data, customer, onEditBudget }: BudgetListItemProps) {
  const { contexts, profile } = useUser();

  const isPhaseParameter = useVerifyParameter({
    customer: customer ?? contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.PHASE,
  });

  const isCostCenterParameter = useVerifyParameter({
    customer: customer ?? contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.COST_CENTER,
  });

  const isProjectParameter = useVerifyParameter({
    customer: customer ?? contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.PROJECT,
  });

  const canUpdateBudget = useVerifyActions({
    actions: [Actions.CreateUpdateBudget],
    contexts,
    profile,
  });

  const periodicity = getPeriodicity(data.periodicity as Periodicity);

  const budgetTotal = data.items.reduce((acc, item) => acc + item.value, 0);

  return (
    <DataListItem
      css={{
        "@mxlg": {
          flexDirection: "column",
        },
      }}
    >
      {isCostCenterParameter && (
        <DataColItem headerTitle="Centro de custo" data={data.costCenter?.name} />
      )}

      {(isProjectParameter || isPhaseParameter) && (
        <DataColItem headerTitle="Projeto" data={data.project?.name} />
      )}

      {isPhaseParameter && (
        <DataColItem headerTitle="Fase" data={data.phase?.name} />
      )}

      <DataColItem headerTitle="Valor total do orçamento" data={asCurrency(budgetTotal)} />
      <DataColItem headerTitle="Periodicidade" data={periodicity?.label} />
      <DataColItem headerTitle="Bloqueio de orçamento" data={data.blocking ? "Ativado" : "Inativo"} />
      
      {canUpdateBudget && (
        <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
          <IconButton size="md" onClick={() => onEditBudget(data, customer as Customer)}>
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>
      )}
    </DataListItem>
  );
}

BudgetListItem.displayName = "BudgetListItem";
