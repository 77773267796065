import { useMemo } from "react";
import { useQueryCombinedFlights } from "~/presentation/Booking/BookingAirway/hooks/useQueryCombinedFlights";
import { useQueryReturnFlights } from "~/presentation/Booking/BookingAirway/hooks/useQueryReturnFlights";
import { ReturnFlightType } from "../../types";
import { UseRoundTripFlightsOptions, UseRoundTripFlightsReturn } from "./types";
import { FlightOrderType } from "../../../../types";
import { flightDuration } from "~/presentation/shared/utils";

export function useRoundTripFlights({
  flightQueryLinks,
  goFlight,
  enabled,
  retryCount,
  orderType,
}: UseRoundTripFlightsOptions): UseRoundTripFlightsReturn {
  const { data: combinedFlights, isLoading: isCombinedLoading } = useQueryCombinedFlights({
    goFlight,
    enabled,
    flightQueryLinks,
    retryCount,
  });

  const {
    data: returnFlights,
    isAvailable: isReturnAvailable,
    isLoading: isReturnLoading,
  } = useQueryReturnFlights({
    goFlight,
    enabled,
    flightQueryLinks,
    retryCount,
  });

  const combinedGoFlights = useMemo(() => {
    return combinedFlights.filter((f) => f.flightOrigin === "departure");
  }, [combinedFlights]);

  const combinedReturnFlights = useMemo(() => {
    return combinedFlights.filter((f) => f.flightOrigin === "arrival");
  }, [combinedFlights]);

  const combinedGoFlightFound = useMemo(() => {
    if (!goFlight) return null;
    return combinedFlights.find((i) => i.hash === goFlight.flight.hash) ?? null;
  }, [goFlight, combinedGoFlights]);

  const combinedGoFlightSelected = useMemo(() => {
    if (!goFlight || !combinedGoFlightFound) return null;

    const combinedGoFlightOption = combinedGoFlightFound.options.find(
      (o) => o.code === goFlight.flightOption.code
    );

    if (!combinedGoFlightOption) return null;

    return {
      flight: combinedGoFlightFound,
      flightOption: combinedGoFlightOption,
    };
  }, [goFlight, combinedGoFlightFound]);

  const data = useMemo<ReturnFlightType[]>(() => {
    let flightsList: ReturnFlightType[] = returnFlights.map<ReturnFlightType>((oneWayFlight) => ({
      flight: oneWayFlight,
      oneWayFlight,
      combinedFlight: null,
      isCombined: false,
    }));

    if (combinedGoFlightSelected) {
      flightsList = flightsList.concat(
        combinedReturnFlights.reduce<ReturnFlightType[]>((p, combinedFlight) => {
          const oneWayFlight = returnFlights.find((i) => i.hash === combinedFlight.hash);

          if (!oneWayFlight || oneWayFlight.airline !== combinedGoFlightSelected.flight.airline) {
            return p;
          }

          return [
            ...p,
            {
              flight: combinedFlight,
              combinedFlight,
              oneWayFlight,
              isCombined: true,
            },
          ];
        }, [])
      );
    }
    if (orderType === FlightOrderType.PRICE) {
      return flightsList.sort(
        (a, b) => a.flight.minimumPrice.amount - b.flight.minimumPrice.amount
      );
    }

    return flightsList.sort((a, b) => {
      const durationA = flightDuration(a.flight);
      const durationB = flightDuration(b.flight);
      return durationA - durationB;
    });
  }, [combinedGoFlightSelected, combinedReturnFlights, returnFlights]);

  return {
    data,
    isLoading: isCombinedLoading || isReturnLoading,
    isAvailable: isReturnAvailable,
    combinedGoFlight: combinedGoFlightSelected,
  };
}
