import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { queryClient } from "~/services/queryClient";
import { customerEmployeeService, customerService, stateService } from "~/application/usecases";
import { log } from "~/utils/log";
import { logError } from "~/presentation/shared/utils/errors";
import { ProfileContainer } from "./ProfileContainer";
import { QueryKeys as UserQueryKeys, QueryTimes } from "~/application/constants";
import { EditableCustomerEmployee } from "~/presentation/shared/views/CustomerEmployeeDialog";

const LOG_TAG = "Customer/CreateCustomerPage";

export type ErrorType = { statusCode: number; message: string };

const SNACKBAR_MESSAGES = {
  UPDATE_SUCCESS_MESSAGE: "Informações de perfil atualizado com sucesso.",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar perfil.",
  UPDATE_AVATAR_MESSAGE: "Falha ao atualizar foto perfil.",
  UPDATE_AVATAR_SUCCESS_MESSAGE: "Foto do perfil atualizado com sucesso.",
} as const;

const DEFAULT_FORM_DATA = {
  name: "",
  lastName: "",
  cpf: "",
  phone: "",
  rg: "",
  birthDate: "",
  image: null
} as Partial<EditableCustomerEmployee>;

export function ProfilePage() {
  const { user } = useUser();
 
  const { mutate: updateCustomerProfile, isLoading: isUpdatingCustomer } = useMutation(
    (item: Partial<EditableCustomerEmployee>) => {
      return customerEmployeeService.updateById({ ...item, uuid: user.profiles.customer.uuid } as EditableCustomerEmployee);
    },
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Customer(${item.uuid})`);
      },
      onSuccess: async (data) => {
        log.i(LOG_TAG, `Successfully update Customer(${data.uuid})`);
        
        window.location.reload()
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error: ErrorType) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
    }
  );

  const { mutate: updateAvatar, isLoading: isLoadingUpdateAvatar } = useMutation(
    (item: 
      { 
        file: File | null | undefined,
        uuid?: string,
        phone?: string,
        cpf?: string,
        birthDate?: string, 
        lastName?: string, 
        name?: string, 
        rg?: string
      }) => {
      return customerEmployeeService.updateAvatar({ file: item.file, uuid: user.uuid });
    },
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Customer Avatar(${item.uuid})`);
      },
      onSuccess: async (data, item) => {
        log.i(LOG_TAG, `Successfully update Customer Avatar`);

        updateCustomerProfile({ ...item, avatarUrl: data.avatarUrl });
        
        queryClient.invalidateQueries([UserQueryKeys.USER_PROFILE])
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_AVATAR_SUCCESS_MESSAGE, "success");
      },
      onError: (error: ErrorType) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data, isLoading: isLoadingCustomer } = useQuery(
    [QueryKeys.CUSTOMER],
    () => customerEmployeeService.findCustomer({ uuid: user.profiles.customer.uuid }),
  );
 
  return (
    <ProfileContainer
      defaultData={data}
      isLoadingCustomer={isLoadingCustomer}
      updateAvatar={updateAvatar}
      updateCustomerProfile={updateCustomerProfile}
    />
  );
}
