import { Link, scroller } from "react-scroll";
import { styled } from "~/application/theme";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";

const sections = [
  { id: "informacoes-coletadas", title: "1. Informações que Coletamos" },
  { id: "uso-informacoes", title: "2. Como Usamos Suas Informações" },
  { id: "compartilhamento-dados", title: "3. Compartilhamento de Dados" },
  { id: "protecao-dados", title: "4. Proteção dos Dados" },
  { id: "seus-direitos", title: "5. Seus Direitos" },
  { id: "retencao-dados", title: "6. Retenção de Dados" },
  { id: "cookies", title: "7. Cookies e Tecnologias Similares" },
  { id: "transferencias-internacionais", title: "8. Transferências Internacionais" },
  { id: "alteracoes-politica", title: "9. Alterações nesta Política" },
  { id: "contato", title: "10. Contato" },
];

const Ul = styled("ul", {});
const Li = styled("li", {});

export function PrivacyPolicyPage() {
  return (
    <Flex
      css={{
        minHeight: "100vh",
        width: "100%",
        px: "$6",
        py: "$10",
      }}
      direction="column"
      align={"center"}
    >
      <Flex
        css={{
          maxWidth: "864px",
          width: "100%",
        }}
        direction="column"
        gap="8"
      >
        <Text fw="600" size="7">
          Política de Privacidade do Briztrip
        </Text>
        <Flex gap="4" direction="column">
          <Text>Última atualização: 20/11/2024</Text>
          <Text
            size="4"
            css={{
              lineHeight: "$5",
            }}
          >
            A Briztrip ("nós", "nosso" ou "nossa") está comprometida em proteger sua privacidade.
            Esta Política de Privacidade descreve como coletamos, usamos, armazenamos e protegemos
            suas informações ao acessar nosso aplicativo, que funciona como uma WebView da
            plataforma de gestão de viagens corporativas.
          </Text>
        </Flex>
        <Flex
          direction="column"
          css={{
            p: "$3",
            backgroundColor: "$neutrals-lightest",
            borderRadius: "$md",
          }}
          gap="6"
        >
          <Text fw="600" size="5">
            Índice
          </Text>
          <Flex direction="column" gap="5">
            {sections.map((section) => (
              <Text
                variant="primary"
                key={section.id}
                css={{
                  cursor: "pointer",
                }}
                onClick={() =>
                  scroller.scrollTo(section.id, {
                    duration: 800,
                    delay: 0,
                    top: 300,
                    smooth: "easeInOutQuart",
                  })
                }
              >
                {section.title}
              </Text>
            ))}
          </Flex>
        </Flex>
        <Flex direction="column" gap="4" id="informacoes-coletadas">
          <Text fw="600" size="5">
            {sections[0].title}
          </Text>
          <Flex direction="column" gap="4">
            <Text fw="600" size="4">
              1.1 Informações Pessoais
            </Text>
            <Text>
              Nome, e-mail, CPF, telefone e informações fornecidas durante a criação de conta ou
              utilização de serviços, como reservas.
            </Text>
          </Flex>
          <Flex direction="column" gap="4">
            <Text fw="600" size="4">
              1.2 Dados de Navegação
            </Text>
            <Text>
              Informações de uso na WebView, incluindo cliques, páginas visitadas e preferências
              selecionadas.
            </Text>
          </Flex>
          <Flex direction="column" gap="4">
            <Text fw="600" size="4">
              1.3 Dados Técnicos
            </Text>
            <Text>
              Endereço IP, tipo de dispositivo, sistema operacional, versão do navegador e
              localização aproximada, para garantir o bom funcionamento do aplicativo.
            </Text>
          </Flex>
          <Flex direction="column" gap="4">
            <Text fw="600" size="4">
              1.4 Informações Sensíveis
            </Text>
            <Text>
              Dados como necessidades de acessibilidade, preferências alimentares ou outras
              informações específicas para viagens são coletados com consentimento explícito.
            </Text>
          </Flex>
        </Flex>
        <Flex direction="column" gap="4" id={sections[1].id}>
          <Text fw="600" size="5">
            {sections[1].title}
          </Text>
          <Ul
            css={{
              margin: 0,
            }}
          >
            <Flex direction="column" gap="2">
              <Li>Processar e gerenciar reservas de voos, hospedagens e locações de veículos.</Li>
              <Li>Personalizar sua experiência de navegação no aplicativo.</Li>
              <Li>Enviar notificações sobre suas solicitações de viagem e atualizações.</Li>
              <Li>Manter o funcionamento do aplicativo e melhorar a plataforma.</Li>
              <Li>Cumprir obrigações legais ou regulatórias.</Li>
            </Flex>
          </Ul>
        </Flex>
        <Flex direction="column" gap="4" id={sections[2].id}>
          <Text fw="600" size="5">
            {sections[2].title}
          </Text>
          <Text>
            Compartilhamos informações com terceiros apenas quando necessário para a execução dos
            serviços contratados ou quando exigido por lei, como:
          </Text>
          <Ul
            css={{
              m: "0",
            }}
          >
            <Flex direction="column" gap="2">
              <Li>Provedores de serviços de viagens (companhias aéreas, hotéis, locadoras).</Li>
              <Li>Processadores de pagamento.</Li>
              <Li>Autoridades governamentais ou regulatórias, quando aplicável.</Li>
            </Flex>
          </Ul>
        </Flex>
        <Flex direction="column" gap="4" id={sections[3].id}>
          <Text fw="600" size="5">
            {sections[3].title}
          </Text>
          <Text>
            Adotamos medidas técnicas e organizacionais para proteger os dados dos usuários contra
            acessos não autorizados, perda, alteração ou destruição. Incluímos:
          </Text>
          <Ul
            css={{
              m: "0",
            }}
          >
            <Flex direction="column" gap="2">
              <Li>Criptografia de informações sensíveis.</Li>
              <Li>Monitoramento contínuo e atualizações de segurança.</Li>
              <Li>Controles de acesso restrito aos dados.</Li>
            </Flex>
          </Ul>
        </Flex>
        <Flex direction="column" gap="4" id={sections[4].id}>
          <Text fw="600" size="5">
            {sections[4].title}
          </Text>
          <Text>Você pode exercer os seguintes direitos relacionados aos seus dados:</Text>
          <Ul>
            <Flex direction="column" gap="2">
              <Li>Acesso: Solicitar uma cópia dos dados armazenados.</Li>
              <Li>Correção: Atualizar ou corrigir informações.</Li>
              <Li>
                Exclusão: Pedir a remoção de dados, salvo quando necessário por obrigações legais.
              </Li>
              <Li>
                Revogação de Consentimento: Retirar consentimento para o uso de dados específicos.
              </Li>
            </Flex>
          </Ul>
          <Text>Para exercer seus direitos, entre em contato pelo e-mail abaixo.</Text>
        </Flex>
        <Flex direction="column" gap="4" id={sections[5].id}>
          <Text fw="600" size="5">
            {sections[5].title}
          </Text>
          <Text>
            Armazenamos seus dados pelo tempo necessário para atender aos objetivos descritos nesta
            política ou conforme exigido por lei. Informações financeiras podem ser retidas para
            fins fiscais ou regulatórios.
          </Text>
        </Flex>
        <Flex direction="column" gap="4" id={sections[6].id}>
          <Text fw="600" size="5">
            {sections[6].title}
          </Text>
          <Text>
            O Briztrip, por meio da WebView, pode utilizar cookies e tecnologias similares para:
          </Text>
          <Ul
            css={{
              m: "0",
            }}
          >
            <Flex direction="column" gap="2">
              <Li>Lembrar suas preferências de usuário.</Li>
              <Li>Melhorar a navegação e funcionalidade da plataforma.</Li>
              <Li>Analisar o comportamento de uso para oferecer melhorias.</Li>
            </Flex>
          </Ul>
          <Text>Você pode gerenciar os cookies diretamente no navegador.</Text>
        </Flex>
        <Flex direction="column" gap="4" id={sections[7].id}>
          <Text fw="600" size="5">
            {sections[7].title}
          </Text>
          <Text>
            Seus dados podem ser armazenados ou processados em servidores localizados fora de seu
            país de residência, sempre seguindo a legislação aplicável.
          </Text>
        </Flex>
        <Flex direction="column" gap="4" id={sections[8].id}>
          <Text fw="600" size="5">
            {sections[8].title}
          </Text>
          <Text>
            Podemos atualizar esta política para refletir alterações nos serviços ou requisitos
            legais. Notificaremos os usuários sobre mudanças significativas por meio do aplicativo
            ou de outro canal apropriado.
          </Text>
        </Flex>
        <Flex direction="column" gap="4" id={sections[9].id}>
          <Text fw="600" size="5">
            {sections[9].title}
          </Text>
          <p>Para dúvidas ou solicitações relacionadas à privacidade, entre em contato conosco:</p>
          <address>
            <strong>Briztrip</strong>
            <br />
            E-mail: [ desenvolvimento@kennedyturismo.com ]
            <br />
            Telefone: [ (99) 98255-0039 ]
            <br />
            Endereço: [ R. Benedito Leite, 629 - Centro, Imperatriz - MA, 65903-290 ]
          </address>
        </Flex>
        <Text>
          Ao utilizar o aplicativo Briztrip, você concorda com os termos desta Política de
          Privacidade.
        </Text>
      </Flex>
    </Flex>
  );
}
