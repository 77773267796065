import { AccountabilityExpense } from "~/application/types";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { FormDialog } from "~/components/FormDialog";
import { Image } from "~/components/Image";
import useMobile from "~/presentation/shared/hooks/useMobile";

export type AccountabilityExpenseListItemProps = {
  data: AccountabilityExpense;
  onCloseClick?: () => void;
};

export function VoucherDialog({ data, onCloseClick }: AccountabilityExpenseListItemProps) {
  const isMobile = useMobile();
  const isFilePdf = data.voucherPath.endsWith(".pdf");
  const envUrl = import.meta.env.VITE_API_URL.replace("/api/v1", "");

  return (
    <Container
      fixed={isMobile}
      size="11"
      css={{
        "@lg": {
          width: "800px",
        },
      }}
    >
      <FormDialog title="Comprovante" onClickDismissButton={onCloseClick}>
        <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
          {isFilePdf ? (
            <object data={data.voucherPath} type="application/pdf" width="100%" height="600px">
              <p>
                Seu navegador não suporta PDF embutido. Você pode baixar o PDF{" "}
                <a target="_blank" href={data.voucherPath}>
                  aqui
                </a>
                .
              </p>
            </object>
          ) : (
            <Image src={data.voucherPath} />
          )}
        </DialogBody>
      </FormDialog>
    </Container>
  );
}

VoucherDialog.displayName = "VoucherDialog";
