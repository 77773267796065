import { Airport } from "~/application/types";
import { AirportDTO } from "../dtos";

export function mapAirportDTO(data: AirportDTO): Airport {
  return {
    uuid: data.uuid,
    countryCode: data.country_code,
    city: data.city,
    cityCode: data.city_code,
    isInternational: data.is_international,
    latitude: data.latitude,
    longitude: data.longitude,
    name: data.name,
    iata: data.iata,
  };
}
