import { PendingOrder } from "~/application/types";
import { IPendingOrdersFilters } from "../PendingOrdersContainer";

export enum PendingOrdersTab {
  PENDING = "pendentes",
  PROCESSED = "processados",
}

type OrderItems = 'hotel' | 'airway' | 'vehicle' | 'road';

export const PENDING_ORDERS_MENU_TABS = [
  { title: "Pendentes", id: PendingOrdersTab.PENDING },
  { title: "Processados", id: PendingOrdersTab.PROCESSED },
];


export const orderItems = [
  { label: "Todos", value: "all" },
  { label: "Hotel", value: "hotel" },
  { label: "Carro", value: "vehicle" },
  { label: "Ônibus", value: "road" },
  { label: "Aéreo", value: "airway" },
];

export const groupByCustomer = [
  { label: "Empresa", value: "confirm" },
  { label: "Data de expiração", value: "negative" },
];

export function filterOrders(orders?: PendingOrder[], filters?: IPendingOrdersFilters) {
  const filteredOrders = orders?.filter((order) => {
    const matchesConsultant =
      !filters?.consultants.length ||
      filters?.consultants.some(
        (filter) => filter.value === order?.consultant?.uuid
      );

    const matchesIssuer =
      !filters?.issuers.length ||
      filters?.issuers.some(
        (filter) => filter.value === order?.issuer?.uuid
      );

    const matchesCustomer =
      !filters?.customers.length ||
      filters?.customers.some(
        (filter) => filter.value === order.customer.uuid
      );

    const matchesStatus =
      !filters?.status.length ||
      filters?.status.some((filter) => filter.value === order.status.status);

    const matchesTraveler =
      !filters?.travelers.length ||
      order.travelers.some((traveler) =>
        filters?.travelers.some((filter) => filter.value === traveler.uuid)
      );

    const matchesOrderNumber =
      !filters?.orderNumber ||
      order.orderNumber.toString() === filters.orderNumber;

    const matchesOrderTracker =
      !filters?.tracker ||
      order.itemsIncluded.airway.tracker === filters.tracker;


      const matchesOrderItem =
      !filters?.orderItems.length ||
      Object.keys(order.itemsIncluded).some((includedItemKey) =>
        filters?.orderItems.some((filter) => filter.value === includedItemKey)
      );


    return (
      matchesConsultant &&
      matchesCustomer &&
      matchesStatus &&
      matchesTraveler &&
      matchesOrderNumber &&
      matchesOrderItem &&
      matchesOrderTracker &&
      matchesIssuer
    );
  });

  if (filters?.groupByCustomer?.value === 'negative') {
    return filteredOrders;
  }

  const groupedOrders: Record<string, PendingOrder[]> = {};

  filteredOrders?.forEach((order) => {
    const customerName = order.customer.tradingName;
    if (!groupedOrders[customerName]) {
      groupedOrders[customerName] = [];
    }
    groupedOrders[customerName].push(order);
  });

  return Object.values(groupedOrders).flat();
}
