import { Route, Routes } from "react-router-dom";
import { routes } from "~/application/theme/routes";
import { ProfilePage } from "~/core/modules/Customer/pages/ProfilePage/ProfilePage";

export const ProfileRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<ProfilePage />} />
    </Routes>
  );
};
ProfileRouter.displayName = "ProfileRouter";
