import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import {
  getAdditionalInfoIcon,
  getAdditionalInfoLabel,
  getDescriptionAdditionalIcon,
} from "../utils";
import {
  ClientPendingFirstPurchaseDialogContent,
  InactiveClientsDialogContent,
  NewClientsDialogContent,
  OperationInterventions,
  OrderNotIssuedWeek,
  UnaccessibleCustomers,
} from "./DialogContent";
import { DashboardAdditionalInfoDialogProps, PurchaseReport, Report } from "../types";
import { getServiceIcon } from "~/core/shared/utils/order.utils";
import { SvgCalendar } from "~/components/Icon/icons";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { FormControlContent } from "~/core/shared/components/FormControl";
import { SearchBar, TextInput } from "~/components/Input";
import { DialogBody, DialogFooter } from "~/components/Dialog";
import { Button } from "~/components/Button";
import { SingleCalendar } from "~/core/shared/components/SingleCalendar/SingleCalendar";
import dayjs from "dayjs";
import { dialogService } from "~/components/DialogStack";
import { Container } from "~/components/Container";
import { FormDialog } from "~/components/FormDialog";
import { Box } from "~/components/Box";
import { Grid, Row } from "~/components/Grid";
import { DashboardBiResponse } from "~/application/usecases/DashboardBI/IDashboardService";
import { Skeleton } from "~/components/Skeleton";
import { OrderItems } from "~/application/types";
import {
  LogoutCustomers,
  NewClientsPurchaseMonth,
  NotBuyingCustomers,
  OperationalInterventions,
  OrdersNotIssuedWeek,
  PendingFirstPurchase,
} from "~/infrastructure/api/mappers/mapDashboardBIDTO";
import { ModalPortal } from "~/core/modules/DeprecatedBooking/components/ModalPortal";
import { Pagination } from "~/components/Pagination";
import useMobile from "~/presentation/shared/hooks/useMobile";

type InterventionCount = { [key in OrderItems]: number };
type AdditionalInfoType = {
  key: string;
  value?:
    | LogoutCustomers
    | NewClientsPurchaseMonth
    | PendingFirstPurchase
    | OrdersNotIssuedWeek
    | NotBuyingCustomers
    | OperationalInterventions;
};

export const DashboardAdditionalInfo: React.FC<DashboardAdditionalInfoDialogProps> = ({
  data,
  endDate,
  isLoadingInterventions,
  startDate,
  interventions,
  notBuyingCustomersLastPage,
  logoutCustomersPage,
  operationalInterventions,
  isLoadingLogoutCustomers,
  isLoadingNewClientsPurchaseMonth,
  isLoadingNotBuyingCustomers,
  isLoadingOrdersNotIssuedWeek,
  isLoadingPendingFirstPurchase,
  newClientsPurchaseMonth,
  notBuyingCustomers,
  notBuyingCustomersPage,
  ordersNotIssuedWeek,
  pendingFirstPurchase,
  logoutCustomersLastPage,
  logoutCustomers,
  onChangeLogoutCustomersPage,
  onSearchLogoutCustomers,
  onSearchNotBuyingCustomersText,
  onChangeMaxDate,
  onChangeMinDate,
  onChangeNotBuyingCustomers,
}) => {
  const NOT_BUYING_CUSTOMERS = "notBuyingCustomers";
  const LOGOUT_CUSTOMERS = "logoutCustomers";

  const currentDate = dayjs();
  const maxDate = currentDate.endOf("day").toDate();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfoType>({ key: "", value: {} });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const isMobile = useMobile();

  const formatDate = (date: Date | null) => {
    if (!date) return "";
    return dayjs(date).format("DD/MM/YY");
  };

  const handleDateClick = useCallback(
    (date: Date) => {
      if (startDate && dayjs(date).isSame(startDate, "day")) {
        onChangeMaxDate(null);
        onChangeMinDate(null);
        setSelectedDate(null);
      } else {
        setSelectedDate(date);
        if (!startDate) {
          onChangeMaxDate(date);
        } else if (date < startDate) {
          onChangeMaxDate(date);
        } else if (date > startDate) {
          onChangeMinDate(date);
        }
      }
    },
    [startDate]
  );

  const renderDialogContent = useCallback(
    (
      key: keyof typeof dialogChildren,
      data?: PurchaseReport | LogoutCustomers | OperationalInterventions
    ) => {
      const DialogComponent = dialogChildren[key];
      return <DialogComponent report={data?.report as Report[]} />;
    },
    []
  );

  const dialogChildren = useMemo(
    () => ({
      newClientsPurchaseMonth: NewClientsDialogContent,
      pendingFirstPurchase: ClientPendingFirstPurchaseDialogContent,
      notBuyingCustomers: InactiveClientsDialogContent,
      logoutCustomers: UnaccessibleCustomers,
      ordersNotIssuedWeek: OrderNotIssuedWeek,
      operationalInterventions: OperationInterventions,
    }),
    []
  );

  const additionalInfoPage = {
    [NOT_BUYING_CUSTOMERS]: notBuyingCustomersPage,
    [LOGOUT_CUSTOMERS]: logoutCustomersPage,
  };

  const additionalInfoLastPage = {
    [NOT_BUYING_CUSTOMERS]: notBuyingCustomersLastPage,
    [LOGOUT_CUSTOMERS]: logoutCustomersLastPage,
  };

  const countInterventionsByServiceType = useCallback(
    (interventions?: OperationalInterventions) => {
      const result = interventions?.report.reduce((acc: InterventionCount, intervention) => {
        const { serviceType, interventionsAmount } = intervention;

        if (serviceType && !acc[serviceType]) {
          acc[serviceType] = 0;
        }

        if (serviceType && interventionsAmount) {
          acc[serviceType] += interventionsAmount;
        }

        return acc;
      }, {} as InterventionCount);

      return result;
    },
    [interventions]
  );

  useEffect(() => {
    setAdditionalInfo((prev) => ({
      ...prev,
      value:
        additionalInfo.key === NOT_BUYING_CUSTOMERS && hasAdditionalIndo
          ? notBuyingCustomers
          : logoutCustomers,
    }));
  }, [notBuyingCustomers, logoutCustomers]);

  const interventionsByServiceType = countInterventionsByServiceType(interventions);

  const interventionDialog = useCallback(
    (
      key: keyof typeof dialogChildren,
      data: PurchaseReport | OperationalInterventions | undefined
    ) =>
      dialogService.showDialog(
        <Container size="10" fixed css={{ "@mxlg": { width: "95vw" } }}>
          <FormDialog title={getAdditionalInfoLabel(key)}>
            <DialogBody>
              <Box css={{ p: "$6" }}>
                <Row gap="6">{renderDialogContent(key, data)}</Row>
              </Box>
            </DialogBody>
          </FormDialog>
        </Container>
      ),
    [renderDialogContent, getAdditionalInfoLabel]
  );

  const hasAdditionalIndo = notBuyingCustomers || logoutCustomers;
  return (
    <Container css={{ p: "0", width: "83vw" }}>
      <Grid gap="5" columns={isMobile ? 1 : 3} css={{ mt: "$5" }}>
        {!isLoadingNewClientsPurchaseMonth ? (
          <Card css={{ mb: "$4" }}>
            <ModalPortal isOpen={modalIsOpen}>
              <Container size="10" css={{ "@mxlg": { width: "95vw" } }}>
                <FormDialog
                  title={getAdditionalInfoLabel(additionalInfo?.key)}
                  onClickDismissButton={() => {
                    setModalIsOpen(false);
                    onSearchNotBuyingCustomersText("");

                    onSearchLogoutCustomers("");
                  }}
                >
                  <Flex direction="column" css={{ p: "$6" }} gap="4">
                    {(additionalInfo.key === NOT_BUYING_CUSTOMERS ||
                      additionalInfo.key === LOGOUT_CUSTOMERS) && (
                      <SearchBar
                        onSearchChange={(text) => {
                          if (additionalInfo.key === NOT_BUYING_CUSTOMERS) {
                            onSearchNotBuyingCustomersText(text);
                          } else if (additionalInfo.key === LOGOUT_CUSTOMERS) {
                            onSearchLogoutCustomers(text);
                          }
                        }}
                        placeholder="Buscar por nome do colaborador"
                      />
                    )}
                    <Flex
                      gap="6"
                      css={{
                        width: "100%",
                      }}
                    >
                      {isLoadingNotBuyingCustomers || isLoadingLogoutCustomers ? (
                        <Flex direction="column" gap="2">
                          <Flex direction="column" gap="2">
                            {Array.from({ length: 10 }).map((_, index) => (
                              <Skeleton
                                css={{
                                  width: "40vw",
                                  height: "100px",
                                  borderRadius: "$md",
                                  "@mxlg": { width: "84vw" },
                                }}
                              />
                            ))}
                          </Flex>
                        </Flex>
                      ) : (
                        <Flex css={{ maxHeight: "400px", overflow: "auto", width: "100%" }}>
                          {renderDialogContent(
                            additionalInfo?.key as keyof typeof dialogChildren,
                            additionalInfo?.value
                          )}
                        </Flex>
                      )}
                    </Flex>
                    <Flex>
                      {(additionalInfo.key === NOT_BUYING_CUSTOMERS ||
                        additionalInfo.key === LOGOUT_CUSTOMERS) && (
                        <Card css={{ p: "$5", width: "100%" }}>
                          <Pagination
                            setActivePage={(page) => {
                              if (additionalInfo.key === NOT_BUYING_CUSTOMERS) {
                                onChangeNotBuyingCustomers(page);
                              } else if (additionalInfo.key === LOGOUT_CUSTOMERS) {
                                onChangeLogoutCustomersPage(page);
                              }

                              setAdditionalInfo((prev) => ({
                                ...prev,
                                value:
                                  additionalInfo.key === NOT_BUYING_CUSTOMERS
                                    ? notBuyingCustomers
                                    : logoutCustomers,
                              }));
                            }}
                            activePage={additionalInfoPage[additionalInfo.key]}
                            pagesCount={additionalInfoLastPage[additionalInfo.key]}
                          />
                        </Card>
                      )}
                    </Flex>
                  </Flex>
                </FormDialog>
              </Container>
            </ModalPortal>

            <Flex direction="column" css={{ p: "$5" }}>
              <Flex align="center">
                <Tag
                  variant="success-light"
                  size="md"
                  css={{ border: 0, borderRadius: "100%", p: "$6" }}
                >
                  <Icon size="md" as={getAdditionalInfoIcon("newClientsPurchaseMonth")} />
                </Tag>
                <Text css={{ ml: "$3" }} fw="500">
                  {getAdditionalInfoLabel("newClientsPurchaseMonth")}
                </Text>
              </Flex>
              <Flex css={{ py: "$5" }}></Flex>
              <Flex direction="column" align="start">
                <H5 size="6" fw="500">
                  {newClientsPurchaseMonth?.value}
                </H5>
                <Flex css={{ mt: "$5" }}>
                  <Icon as={getDescriptionAdditionalIcon("newClientsPurchaseMonth")} />
                  <Text css={{ ml: "$3" }} fw="500">
                    {newClientsPurchaseMonth?.description}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              justify="end"
              css={{ borderTop: "1px solid $colors$neutrals-light" }}
              onClick={() => {
                setAdditionalInfo({
                  key: "newClientsPurchaseMonth",
                  value: newClientsPurchaseMonth,
                });
                setModalIsOpen(true);
              }}
            >
              <Flex justify="end" css={{ p: "$5" }}>
                <Text css={{ color: "$primary-base", cursor: "pointer" }}>
                  Visualizar relatório
                </Text>
              </Flex>
            </Flex>
          </Card>
        ) : (
          <Skeleton
            css={{
              width: "500px",
              height: "250px",
            }}
          />
        )}

        {!isLoadingPendingFirstPurchase ? (
          <Card css={{ mb: "$4" }}>
            <Flex direction="column" css={{ p: "$5" }}>
              <Flex align="center">
                <Tag
                  variant="success-light"
                  size="md"
                  css={{ border: 0, borderRadius: "100%", p: "$6" }}
                >
                  <Icon size="md" as={getAdditionalInfoIcon("pendingFirstPurchase")} />
                </Tag>
                <Text css={{ ml: "$3" }} fw="500">
                  {getAdditionalInfoLabel("pendingFirstPurchase")}
                </Text>
              </Flex>
              <Flex css={{ py: "$5" }}></Flex>
              <Flex direction="column" align="start">
                <H5 size="6">{pendingFirstPurchase?.value}</H5>
                <Flex css={{ mt: "$5" }}>
                  <Icon as={getDescriptionAdditionalIcon("pendingFirstPurchase")} />
                  <Text css={{ ml: "$3" }} fw="500">
                    {pendingFirstPurchase?.description}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              justify="end"
              css={{ borderTop: "1px solid $colors$neutrals-light" }}
              onClick={() => {
                setAdditionalInfo({ key: "pendingFirstPurchase", value: pendingFirstPurchase });
                setModalIsOpen(true);
              }}
            >
              <Flex justify="end" css={{ p: "$5" }}>
                <Text css={{ color: "$primary-base", cursor: "pointer" }}>
                  Visualizar relatório
                </Text>
              </Flex>
            </Flex>
          </Card>
        ) : (
          <Skeleton
            css={{
              width: "500px",
              height: "250px",
            }}
          />
        )}

        {!isLoadingPendingFirstPurchase ? (
          <>
            <Card css={{ mb: "$4" }}>
              <Flex direction="column" css={{ p: "$5" }}>
                <Flex align="center">
                  <Tag
                    variant="success-light"
                    size="md"
                    css={{ border: 0, borderRadius: "100%", p: "$6" }}
                  >
                    <Icon size="md" as={getAdditionalInfoIcon("notBuyingCustomers")} />
                  </Tag>
                  <Text css={{ ml: "$3" }} fw="500">
                    {getAdditionalInfoLabel("notBuyingCustomers")}
                  </Text>
                </Flex>
                <Flex css={{ py: "$5" }}></Flex>
                <Flex direction="column" align="start">
                  <H5 size="6">{notBuyingCustomers?.value}</H5>
                  <Flex css={{ mt: "$5" }}>
                    <Icon as={getDescriptionAdditionalIcon("notBuyingCustomers")} />
                    <Text css={{ ml: "$3" }} fw="500">
                      {notBuyingCustomers?.description}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Flex justify="end" css={{ borderTop: "1px solid $colors$neutrals-light" }}>
                <Flex
                  onClick={() => {
                    setAdditionalInfo({ key: "notBuyingCustomers", value: notBuyingCustomers });
                    setModalIsOpen(true);
                  }}
                  justify="end"
                  css={{ p: "$5" }}
                >
                  <Text css={{ color: "$primary-base", cursor: "pointer" }}>
                    Visualizar relatório
                  </Text>
                </Flex>
              </Flex>
            </Card>
          </>
        ) : (
          <Skeleton
            css={{
              width: "500px",
              height: "250px",
            }}
          />
        )}

        {!isLoadingOrdersNotIssuedWeek ? (
          <Card css={{ mb: "$4" }}>
            <Flex direction="column" css={{ p: "$5" }}>
              <Flex align="center">
                <Tag
                  variant="success-light"
                  size="md"
                  css={{ border: 0, borderRadius: "100%", p: "$6" }}
                >
                  <Icon size="md" as={getAdditionalInfoIcon("ordersNotIssuedWeek")} />
                </Tag>
                <Text css={{ ml: "$3" }} fw="500">
                  {getAdditionalInfoLabel("ordersNotIssuedWeek")}
                </Text>
              </Flex>
              <Flex css={{ py: "$5" }}></Flex>
              <Flex direction="column" align="start">
                <H5 size="6">{ordersNotIssuedWeek?.value}</H5>
                <Flex css={{ mt: "$5" }}>
                  <Icon as={getDescriptionAdditionalIcon("ordersNotIssuedWeek")} />
                  <Text css={{ ml: "$3" }} fw="500">
                    {ordersNotIssuedWeek?.description}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex justify="end" css={{ borderTop: "1px solid $colors$neutrals-light" }}>
              <Flex
                onClick={() => {
                  setAdditionalInfo({ key: "ordersNotIssuedWeek", value: ordersNotIssuedWeek });
                  setModalIsOpen(true);
                }}
                justify="end"
                css={{ p: "$5" }}
              >
                <Text css={{ color: "$primary-base", cursor: "pointer" }}>
                  Visualizar relatório
                </Text>
              </Flex>
            </Flex>
          </Card>
        ) : (
          <Skeleton
            css={{
              width: "500px",
              height: "250px",
            }}
          />
        )}

        {!isLoadingLogoutCustomers ? (
          <Card css={{ mb: "$4" }}>
            <Flex direction="column" css={{ p: "$5" }}>
              <Flex align="center">
                <Tag
                  variant="success-light"
                  size="md"
                  css={{ border: 0, borderRadius: "100%", p: "$6" }}
                >
                  <Icon size="md" as={getAdditionalInfoIcon("logoutCustomers")} />
                </Tag>
                <Text css={{ ml: "$3" }} fw="500">
                  {getAdditionalInfoLabel("logoutCustomers")}
                </Text>
              </Flex>
              <Flex css={{ py: "$5" }}></Flex>

              <Flex direction="column" align="start">
                <H5 size="6">{logoutCustomers?.value}</H5>
                <Flex css={{ mt: "$5" }}>
                  <Icon as={getDescriptionAdditionalIcon("logoutCustomers")} />
                  <Text css={{ ml: "$3" }} fw="500">
                    {logoutCustomers?.description}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex justify="end" css={{ borderTop: "1px solid $colors$neutrals-light" }}>
              <Flex
                onClick={() => {
                  setAdditionalInfo({ key: "logoutCustomers", value: logoutCustomers });
                  setModalIsOpen(true);
                }}
                justify="end"
                css={{ p: "$5" }}
              >
                <Text css={{ color: "$primary-base", cursor: "pointer" }}>
                  Visualizar relatório
                </Text>
              </Flex>
            </Flex>
          </Card>
        ) : (
          <Skeleton
            css={{
              width: "500px",
              height: "250px",
            }}
          />
        )}

        {!isLoadingInterventions ? (
          <Card css={{ mb: "$4" }}>
            <Flex direction="column" css={{ p: "$5" }}>
              <Flex align="center">
                <Tag
                  variant="success-light"
                  size="md"
                  css={{ border: 0, borderRadius: "100%", p: "$6" }}
                >
                  <Icon size="md" as={getAdditionalInfoIcon("operationalInterventions")} />
                </Tag>
                <Text css={{ ml: "$3" }} fw="500">
                  {getAdditionalInfoLabel("operationalInterventions")}
                </Text>
              </Flex>

              <Flex justify="between" align="center" css={{ width: "100%" }}>
                <Flex>
                  {isLoadingInterventions ? (
                    <Skeleton css={{ width: "10px", height: "15px" }} />
                  ) : (
                    <H5 size="6">{interventions?.value}</H5>
                  )}
                </Flex>
                <Flex gap="3" css={{ mt: "$3" }}>
                  {isLoadingInterventions && (
                    <Flex gap="3">
                      <Skeleton css={{ width: "$12", height: "30px", borderRadius: "$md" }} />
                      <Skeleton css={{ width: "$12", height: "30px", borderRadius: "$md" }} />
                    </Flex>
                  )}
                  {Object.entries(interventionsByServiceType || {}).map(
                    ([serviceType, count], idx) => (
                      <Flex key={idx}>
                        <Flex
                          align="center"
                          css={{
                            background: "$neutrals-lightest",
                            px: "$3",
                            py: "$2",
                            borderRadius: "$lg",
                            cursor: "pointer",
                            border: "1px solid $neutrals-darkest",
                          }}
                          onClick={() =>
                            interventionDialog("operationalInterventions", interventions)
                          }
                        >
                          <Icon as={getServiceIcon(serviceType)} />
                          <Text css={{ ml: "$2" }}>{count}</Text>
                        </Flex>
                      </Flex>
                    )
                  )}
                </Flex>
              </Flex>
              <Flex gap="2" css={{ pt: "$4" }}>
                <Icon as={getDescriptionAdditionalIcon("operationalInterventions")} />
                <Text css={{ ml: "$3" }} fw="500">
                  Intervenções ocorridas durante a semana
                </Text>
              </Flex>
            </Flex>
            <Flex justify="end" align="center" css={{ p: "$5" }}>
              <Flex align="center">
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControlContent>
                      <TextInput
                        size="sm"
                        placeholder="Selecione a data"
                        leftIcon={SvgCalendar}
                        value={
                          selectedDate
                            ? endDate
                              ? `${formatDate(startDate as Date)} - ${formatDate(endDate as Date)}`
                              : formatDate(startDate as Date)
                            : ""
                        }
                        readOnly
                      />
                    </FormControlContent>
                  </PopoverTrigger>
                  <PopoverContent>
                    <Card>
                      <SingleCalendar
                        date={new Date()}
                        onDateClick={handleDateClick}
                        maxDate={maxDate}
                        activeMaxDate={endDate || undefined}
                        activeMinDate={startDate || dayjs().subtract(3, "month").toDate()}
                      />
                      <DialogFooter>
                        <Flex gap="2" css={{ justifyContent: "flex-end" }}>
                          <PopoverClose asChild>
                            <Button
                              variant="tertiary"
                              onClick={() => {
                                setSelectedDate(null);
                                onChangeMaxDate(null);
                                onChangeMinDate(null);
                              }}
                            >
                              <Text>Excluir</Text>
                            </Button>
                          </PopoverClose>
                          <PopoverClose asChild>
                            <Button
                              variant="secondary"
                              onClick={() => setSelectedDate(selectedDate || new Date())}
                            >
                              <Text>Aplicar</Text>
                            </Button>
                          </PopoverClose>
                        </Flex>
                      </DialogFooter>
                    </Card>
                  </PopoverContent>
                </Popover>
              </Flex>
            </Flex>
          </Card>
        ) : (
          <Skeleton
            css={{
              width: "500px",
              height: "250px",
            }}
          />
        )}
      </Grid>
    </Container>
  );
};
