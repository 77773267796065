import queryString from "query-string";
import type { Markup, PaginatedResource, Provider } from "~/application/types";
import { api } from "~/infrastructure/api";
import { MarkupDTO, ProviderDTO } from "~/infrastructure/api/dtos";
import { mapMarkupDTO } from "~/infrastructure/api/mappers";
import type {
  IFindMarkupByCustomerParams,
  IFindMarkupParams,
  IMarkupService,
} from "./IMarkupService";
import { mapProviderDTO } from "~/infrastructure/api/mappers/mapProviderDTO";
import { MarkupPerSuplier } from "~/application/types/entities/MarkupPerSuplier.type";
import { MarkupPerSuplierDTO } from "~/infrastructure/api/dtos/MarkupPerSuplierDTO";
import { mapMarkupPerSuplier } from "~/infrastructure/api/mappers/mapMarkupPerSuplier";

export class MarkupService implements IMarkupService {
  async findById(id: string): Promise<Markup> {
    return await api
      .get<MarkupDTO>(`/agencies/markups/${id}`)
      .then(({ data }) => mapMarkupDTO(data));
  }

  async find({
    page = 1,
    name,
    agencyId,
  }: IFindMarkupParams): Promise<PaginatedResource<Markup[]>> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/markups`,
      query: { page, name },
    });

    return await api.get<PaginatedResource<MarkupDTO[]>>(url).then(({ data }) => ({
      links: data.links,
      meta: data.meta,
      data: data.data.map(mapMarkupDTO),
    }));
  }

  async getMarkupItemsPerSuplier(markupId: string): Promise<MarkupPerSuplier[]> {
    return await api
      .get<MarkupPerSuplierDTO[]>(`/agencies/markups/${markupId}/provider-services`)
      .then(({ data }) => mapMarkupPerSuplier(data));
  }

  async updateMarkupPerSuplier({
    markupId,
    providerId,
    percentValue,
  }: {
    markupId: string;
    providerId: string;
    percentValue: number;
  }): Promise<void> {
    await api.patch(`/agencies/markup-items/${markupId}/provider-service/${providerId}`, {
      percent_value: percentValue,
    });
  }

  async create(data: Omit<Markup, "uuid">): Promise<Markup> {
    return await api
      .post<MarkupDTO>(`/agencies/${data.agencyId}/markups`, {
        name: data.name,
        agency_uuid: data.agencyId,
        items: data.items.map((item) => ({
          service_type: item.serviceType,
          percent_value: item.value,
          offline_percent_value: item.offlineValue,
          international_percent_value: item.internationalValue,
        })),
      })
      .then(({ data }) => mapMarkupDTO(data));
  }

  async updateById({ uuid, name, items }: Markup): Promise<void> {
    await api.put(`/agencies/markups/${uuid}`, {
      name: name,
      items: items.map(
        ({ uuid, markupId, serviceType, value, offlineValue, internationalValue }) => ({
          uuid,
          markup_uuid: markupId,
          service_type: serviceType,
          percent_value: value,
          offline_percent_value: offlineValue,
          international_percent_value: internationalValue,
        })
      ),
    });
  }

  async allProviders(): Promise<Provider[]> {
    return await api.get<ProviderDTO[]>(`/providers`).then(({ data }) => mapProviderDTO(data));
  }

  async toggleActive(data: Markup): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/agencies/markups/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api.patch<void>(`/agencies/markups/${data.uuid}/active`).then(({ data }) => data);
  }

  async findByCustomer({
    agencyId,
    customerId,
  }: IFindMarkupByCustomerParams): Promise<Markup | null> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/markups`,
      query: { customer_uuid: customerId },
    });

    return await api.get<PaginatedResource<MarkupDTO[]>>(url).then(({ data }) => {
      const hasData = Boolean(data.data[0]);

      if (hasData) {
        return mapMarkupDTO(data.data[0]);
      }

      return null;
    });
  }
}
