import queryString from "query-string";
import type { CustomerEmployee, PaginatedResource, UnflownAirway } from "~/application/types";
import { DateUtils } from "~/application/utils";
import { api } from "~/infrastructure/api";
import { AuthDTO, CustomerEmployeeDTO, UnflownAirwayDTO } from "~/infrastructure/api/dtos";
import { mapCustomerEmployeeDTO, mapUserDTO } from "~/infrastructure/api/mappers";
import { EditableCustomerEmployee } from "~/presentation/shared/views/CustomerEmployeeDialog";
import { DateFormats, toDate } from "~/utils/date.utils";
import { convertKeysToSnakeCase } from "~/utils/object.utils";
import type {
  ChangeCustomerEmployeePasswordProps,
  ChangePasswordResult,
  GroupOptionsType,
  ICustomerEmployeeService,
  IFindCustomerEmployeeParams,
} from "./ICustomerEmployeeService";
import { mapUnflownAirway } from "~/infrastructure/api/mappers/mapUnflownAirway";
import { FormDataBuilder } from "~/application/utils/object-functions";
import { fileArrayToFileList } from "~/presentation/shared/utils/array-functions";

export type GroupsDTO = {
  description: string,
  uuid: string,
  customer_uuid: string,
  active: boolean
}

export class CustomerEmployeeService implements ICustomerEmployeeService {
  async findById(id: string): Promise<CustomerEmployee> {
    return await api
      .get<CustomerEmployeeDTO>(`/customers/customer-employees/${id}`)
      .then(({ data }) => mapCustomerEmployeeDTO(data));
  }

  async find({
    page = 1,
    name,
    isActive,
    approver = null,
    customerId,
    profile,
    onlyUser,
  }: IFindCustomerEmployeeParams): Promise<PaginatedResource<CustomerEmployee[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/customer-employees`,
      query: {
        page,
        name,
        profile,
        is_active: isActive,
        only_user: onlyUser,
        approver,
      },
    });

    return await api
      .get<PaginatedResource<CustomerEmployeeDTO[]>>(url)
      .then(({ data: { data, links, meta, filters } }) => ({
        links: links,
        meta: meta,
        filters: filters,
        data: data.map(mapCustomerEmployeeDTO),
      }));
  }

  async create(data: EditableCustomerEmployee): Promise<CustomerEmployee> {

    if (data.birthDate) {
      data.birthDate = toDate(data.birthDate, DateFormats.SMALL_DATE).toISOString();
    }

    if (data.passportExpiration) {
      data.passportExpiration = toDate(
        data.passportExpiration,
        DateFormats.SMALL_DATE
      ).toISOString();
    }

    return await api
      .post<CustomerEmployeeDTO>(`/customers/${data.customerId}/customer-employees`, {
        name: data.name,
        last_name: data.lastName,
        phone: data.phone,
        email: data.email,
        gender: data.gender,
        cpf: data.cpf,
        rg: data.rg,
        birthdate: data.birthDate,
        registration: data.registration,
        group_uuid: data.group.uuid,
        position: data.position,
        outsourced: data.outsourced,
        passport_number: data.passportNumber,
        passport_expiration: data.passportExpiration,
        vip: data.vip,
        loyalty_latam: data.loyaltyLatam,
        loyalty_azul: data.loyaltyAzul,
        loyalty_gol: data.loyaltyGol,
        preferred_seat: data.preferredSeat,
        road_location: data.roadLocation,
        type_air_seat: data.typeAirSeat,
        cost_center_uuid: data.costCenter?.uuid,
        customer_uuid: data.customerId,
        profile_uuid: data.profile?.uuid,
        policy_uuid: data.policyId,
        create_user: data.canCreateUser ?? false,
        admin: data.isAdmin ?? false,
        approver: data.isApprover ?? false,
      })
      .then(({ data }) => mapCustomerEmployeeDTO(data));
  }

  async findGroups({ customerId, description }: { customerId: string; description: string }): Promise<GroupOptionsType[]> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/groups`,
      query: {
        description,
      },
    });

    return await api
      .get(url)
      .then(({ data }) =>
        data.data.map((group: GroupsDTO) => ({
          description: group.description,
          uuid: group.uuid,
          customerId: group.customer_uuid,
          active: group.active
        }))
      );
  }
  

  async updateById(data: EditableCustomerEmployee): Promise<CustomerEmployee> {
    
    if (data.birthDate) {
      data.birthDate = toDate(data.birthDate, DateFormats.SMALL_DATE).toISOString();
    }

    if (data.passportExpiration) {
      data.passportExpiration = toDate(
        data.passportExpiration,
        DateFormats.SMALL_DATE
      ).toISOString();
    }

    return await api
      .put<CustomerEmployeeDTO>(`/customers/customer-employees/${data.uuid}`, {
        name: data.name,
        last_name: data.lastName,
        phone: data.phone,
        email: data.email,
        gender: data.gender,
        cpf: data.cpf,
        rg: data.rg,
        group_uuid: data.group?.uuid,
        birthdate: data.birthDate,
        registration: data.registration,
        position: data.position,
        outsourced: data.outsourced,
        passport_number: data.passportNumber,
        passport_expiration: data.passportExpiration,
        vip: data.vip,
        loyalty_latam: data.loyaltyLatam,
        loyalty_azul: data.loyaltyAzul,
        loyalty_gol: data.loyaltyGol,
        preferred_seat: data.preferredSeat,
        road_location: data.roadLocation,
        avatar: data.image,
        avatar_url: data.avatarUrl,
        type_air_seat: data.typeAirSeat,
        cost_center_uuid: data.costCenter?.uuid,
        customer_uuid: data.customerId,
        profile_uuid: data.profile?.uuid,
        policy_uuid: data.policyId,
        admin: Boolean(data.isAdmin ?? false),
        approver: Boolean(data.isApprover ?? false),
        create_user: Boolean(data.canCreateUser ?? false)
      })
      .then(({ data }) => mapCustomerEmployeeDTO(data));
  }

  async findAllUnflownsAirways({
    customerEmployeeId,
  }: {
    customerEmployeeId: string;
  }): Promise<UnflownAirway[]> {
    return await api
      .get<{ data: UnflownAirwayDTO[] }>(
        `customers/customer-employees/${customerEmployeeId}/unflown-airways`
      )
      .then(({ data }) => data.data.map(mapUnflownAirway));
  }

  async toggleActive(data: CustomerEmployee): Promise<void> {
    if (data.isActive) {
      return await api
        .patch<void>(`/customers/customer-employees/${data.uuid}/inactive`)
        .then(({ data }) => data);
    }

    return await api
      .patch<void>(`/customers/customer-employees/${data.uuid}/active`)
      .then(({ data }) => data);
  }

  async findCustomer(data: { uuid: string }): Promise<CustomerEmployee> {
    return await api
      .get(`/customers/customer-employees/${data.uuid}`)
      .then(({ data }) => mapCustomerEmployeeDTO(data));
  }

  async updateAvatar({ uuid, file }: { uuid: string; file: File | null | undefined }): Promise<{ avatarUrl: string }> {
    const formDataBuilder = new FormDataBuilder({
      avatar: file
    })

    const formData = formDataBuilder.getFormData()

    return await api
      .post(`/users/${uuid}/avatar`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(({ data }) => ({ avatarUrl: data.avatar_url || null }));
  }

  async changePassword({
    password,
    confirmedPassword,
    csrfToken,
    secretKey,
  }: ChangeCustomerEmployeePasswordProps): Promise<ChangePasswordResult> {
    const url = "/change-password";
    const data = {
      password,
      confirmedPassword,
      csrfToken,
      secret: secretKey,
    };

    return await api
      .post<{ data: AuthDTO }>(url, convertKeysToSnakeCase(data))
      .then(({ data: response }) => ({
        user: mapUserDTO(response.data.user),
        token: {
          value: response.data.token.value as string,
          createdAt: DateUtils.toDate(response.data.token.created_at),
        },
      }));
  }
}
