import { Branch } from "~/application/types";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import useMobile from "~/presentation/shared/hooks/useMobile";
import * as MaskUtils from "~/utils/mask.utils";

export interface BranchListItemProps extends DataListItemProps {
  data: Branch;
  onEditClick: (item: Branch) => void;
  onToggleState: (item: Branch) => void;
}

export function BranchListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: BranchListItemProps) {
  const isMobile = useMobile();

  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Nome" data={data.name} />
      <DataColItem headerTitle="CNPJ" data={MaskUtils.asCNPJ(data.cnpj)} />
      <DataColItem headerTitle="Telefone" data={MaskUtils.asPhoneNumber(data.phone)} />
      <DataColItem headerTitle="Código de integração" data={data.integrationId} />

      {isMobile ? (
        <Flex justify="between" css={{ width: "100%" }}>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </Flex>
      ) : (
        <>
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>

          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </>
      )}
    </DataListItem>
  );
}

BranchListItem.displayName = "BranchListItem";
