import { useForm } from "react-hook-form";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";

export interface ChangePasswordDialogProps {
  onSubmit: () => void;
  onCloseClick?: () => void;
}

export function ChangePasswordDialog({ onSubmit, onCloseClick }: ChangePasswordDialogProps) {
  const { formState } = useForm({
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  if (formState.isSubmitting) {
    return (
      <LoadingModal isOpen={formState.isSubmitting} message={"Solicitando alteração de senha"} />
    );
  }

  return (
    <Container size="8" fixed>
      <Form onSubmit={onSubmit}>
        <FormDialog
          title="Confirmar solicitação de alteração de senha"
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button type="submit">
              <Text>Confirmar</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Row gap="6">
              <Col sz="12">
                <Text>
                  Ao clicar em confirmar será enviado para o funcionário um link para alteração da
                  senha
                </Text>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
