import { Fragment, useCallback } from "react";
import { Actions, ApprovalModel } from "~/application/types";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar, Select } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { filterByStatusOptions } from "~/core/modules/Customer/pages/CustomerPage/constants";
import { useApprovalModels } from "~/core/modules/Customer/pages/CustomerPage/hooks/useApprovalModels";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { ApprovalModelListItem } from "~/presentation/shared/components/ApprovalModelListItem";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";

export function ApprovalModelsPage() {
  const { user } = useAuth();
  const { contexts, profile } = useUser();

  const customerId = user.customer.uuid;

  const {
    data,
    isLoading,
    currentPage,
    lastPage,
    searchText,
    onCreateApprovalModel,
    onEditApprovalModel,
    onGoToPage,
    onToggleState,
    setSearchText,
    selectedStatus,
    onChangeStatusFilter,
  } = useApprovalModels({ customerId, enabled: true });
  const isMobile = useMobile();
  
  const canCreateApprovalModel = useVerifyActions({
    actions: [Actions.CreateApprovalModel],
    contexts,
    profile,
  });

  const listRenderer = useCallback(
    (item: ApprovalModel) => (
      <ApprovalModelListItem
        data={item}
        onEditClick={onEditApprovalModel}
        onToggleState={onToggleState}
        key={item.uuid}
      />
    ),
    [onEditApprovalModel, onToggleState]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          {!isMobile && <AppBarHeading title="Modelos de aprovação" description="" />}

          <AppBarActions>
            <SearchBar search={searchText} onSearchChange={setSearchText} placeholder="Encontrar" />
            {!isMobile && (
                <Select
                options={filterByStatusOptions}
                onChange={onChangeStatusFilter}
                value={selectedStatus}
                placeholder="Status"
                portalled
              />
            )}

            {canCreateApprovalModel && (
              <Button
                title="Novo modelo de aprovação"
                color="primary"
                onClick={onCreateApprovalModel}
                css={{ "@mxlg": { background: "#fff" }}}
              >
                <Icon as={SvgPlus} css={{ fill: isMobile ? "#000" : undefined }} />
                {!isMobile && <Text>Novo modelo de aprovação</Text>}
              </Button>
            )}
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20", "@mxlg": { p: "$5" }}}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {searchText.length > 0 ? (
                <Text>Nenhum modelo de aprovação corresponde aos dados informados</Text>
              ) : (
                <Text>Você ainda não possui modelos de aprovação cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Fragment>
  );
}
