import { FC } from "react";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { SwitchButton } from "~/components/Input";
import { DataColItem, DataListItem } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import * as MaskUtils from "~/utils/mask.utils";
import { getExpensePolicyTypeName } from "../../utils";
import { ExpensePolicyListItemProps } from "./types";
import useMobile from "../../hooks/useMobile";
import { Flex } from "~/components/Flex";

export const ExpensePolicyListItem: FC<ExpensePolicyListItemProps> = ({
  data,
  onEditClick,
  onToggleState,
  ...props
}) => {
  const isMobile = useMobile();

  return (
    <DataListItem data-active={data.isActive} {...props} css={{ "@mxlg": { flexDirection: "column" }}}>
      <DataColItem headerTitle="Política" data={data.policy.name} />

      <DataColItem
        headerTitle="Tipo de regra"
        data={getExpensePolicyTypeName(data.typeValue)}
      />

      <DataColItem headerTitle="Tipo de despesa" data={data.expenseType.name} />

      <DataColItem
        headerTitle="Valor"
        data={
          data.policyParameterExpense.type === "price"
            ? MaskUtils.asCurrency(data.value)
            : data.value
        }
      />

      {onEditClick && isMobile && (
        <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
          <IconButton size="md" onClick={() => onEditClick(data)}>
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>
      )}

      {onToggleState && isMobile && (
        <SwitchButton
          data-checked={data.isActive}
          onClick={() => onToggleState(data)}
        />
      )}

      <Flex justify="between" css={{ "@lg": { display: "none" }}}>
        {onEditClick && isMobile && (
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
        )}

        {onToggleState && isMobile && (
          <SwitchButton
            data-checked={data.isActive}
            onClick={() => onToggleState(data)}
          />
        )}
      </Flex>
    </DataListItem>
  );
};

ExpensePolicyListItem.displayName = "ExpensePolicyListItem";
