import { FC, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FormDialog } from "~/components/FormDialog";
import { Text } from "~/components/Text";
import { VehicleIssuanceDialogProps } from "./types";
import { VehicleIssuanceForm } from "./VehicleIssuanceForm";

import { VehicleEmission } from "~/application/types";

export const VehicleIssuanceDialog: FC<VehicleIssuanceDialogProps> = ({
  orderVehicle,
  onCloseClick,
  isLoading,
  onSubmit: onSubmitProp,
}) => {
  const { control, formState, handleSubmit } = useForm<VehicleEmission>({
    defaultValues: orderVehicle,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const onSubmit = useCallback(
    async (formData: VehicleEmission) => {
      return await onSubmitProp(formData);
    },
    [onSubmitProp]
  );

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          css={{
            width: "90%",
            margin: "0 auto",
          }}
          title="Emissão de item de carro"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>Emitir</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ overflow: "auto", maxHeight: "500px" }}>
            <VehicleIssuanceForm control={control} />
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};
