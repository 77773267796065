import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  AccountabilityExpense,
  DeepPartial,
  ExpenseCategory,
  ExpensePolicy,
  ExpensePolicyTypeValue,
  ExpenseType,
  Traveler,
} from "~/application/types";
import { accountabilityExpenseService, expenseTypeService } from "~/application/usecases";
import { MaskUtils } from "~/application/utils";
import { Alert, AlertIcon } from "~/components/Alert";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Grid, Row } from "~/components/Grid";
import { MaskedInput, NumberInput, Select, TextAreaInput, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { QueryKeys } from "~/constants/queryKeys";
import { UploadDropzone } from "~/presentation/core/components/UploadDropzone";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { FileViewDialog } from "~/presentation/shared/components/FileViewDialog";
import { DateFormats, displayDate, isValidDate } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";

export interface AccountabilityExpenseDialogProps {
  customerEmployeeId: string;
  customerId: string;
  orderId: string;
  travelers?: Traveler[];
  isNew?: boolean;
  defaultData?: DeepPartial<AccountabilityExpense>;
  onCloseClick?: () => void;
  onSubmit: (data: AccountabilityExpense & { travelerId?: string }) => void;
  fetchExpenseCategories: () => Promise<ExpenseCategory[]>;
  fetchExpensePolicies: (expenseType: ExpenseType) => Promise<ExpensePolicy[]>;
}

type ExpenseForm = Omit<AccountabilityExpense, "expenseType"> & {
  expenseType?: {
    uuid: string;
    name: string;
  };
};

export function AccountabilityExpenseDialog({
  isNew,
  customerEmployeeId,
  customerId,
  orderId,
  onSubmit,
  defaultData,
  onCloseClick,
  fetchExpensePolicies,
  fetchExpenseCategories,
}: AccountabilityExpenseDialogProps) {
  const { control, formState, watch, setValue, setError, clearErrors, handleSubmit } =
    useForm<ExpenseForm>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultValues: defaultData,
      reValidateMode: "onBlur",
      mode: "onSubmit",
    });

  const { unsatisfiedPolicies, expenseCategory, expenseType, expenseDate, value, voucherImage } =
    watch();

  const { user } = useUser();

  const travelerId = customerEmployeeId !== "" ? customerEmployeeId : user.profiles.customer.uuid;

  const fetchExpenseTypes = useCallback(
    () =>
      expenseTypeService.findByCategory({
        customerId,
        expenseCategoryId: expenseCategory?.uuid,
      }),
    [expenseCategory]
  );

  const { data: expenseCategories, isFetching: isFetchingExpenseCategories } = useQuery(
    [QueryKeys.EXPENSE_CATEGORIES],
    fetchExpenseCategories,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  const { data: expenseTypes, isFetching: isFetchingExpenseTypes } = useQuery(
    [QueryKeys.CUSTOMER_EXPENSE_TYPES, expenseCategory],
    fetchExpenseTypes,
    {
      cacheTime: 0,
      retry: 2,
      enabled: !!expenseCategory,
    }
  );

  const { data: expensePolicies, isLoading: isLoadingExpensePolicies } = useQuery(
    [QueryKeys.CUSTOMER_EXPENSE_POLICIES, expenseType],
    () => fetchExpensePolicies(expenseType as ExpenseType),
    {
      cacheTime: 0,
      retry: 2,
      enabled: !!expenseType?.uuid && !!expenseType.name,
    }
  );

  const fetchExpensesByDate = useCallback(
    () =>
      accountabilityExpenseService.find({
        orderId,
        travelerId,
        expenseDate,
        expenseTypeId: expenseType?.uuid,
      }),
    [expenseDate, expenseType]
  );

  const onOpenFile = useCallback((url: string, file: File) => {
    dialogService.showDialog(<FileViewDialog url={url} file={file} />);
  }, []);

  const {
    data: expensesByDate,
    isFetching: isFetchingExpensesByDate,
    isFetched: isFetchedExpensesByDate,
    remove: removeExpensesByDate,
  } = useQuery([expenseType, expenseDate, isNew], fetchExpensesByDate, {
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    enabled: !!expenseType && !!expenseDate && isValidDate(expenseDate),
  });

  useEffect(() => {
    if (expensesByDate && isValidDate(expenseDate)) {
      removeExpensesByDate();
    }
  }, [expenseDate]);

  const getExpenseCategoryLabel = useCallback((item: ExpenseCategory) => item.name, []);

  const getExpenseCategoryValue = useCallback((item: ExpenseCategory) => item.uuid, []);

  const getExpenseTypeLabel = useCallback((item: ExpenseType) => item.name, []);
  const getExpenseTypeValue = useCallback((item: ExpenseType) => item.uuid, []);

  const { isJustificationRequired, isValueFixed, isVoucherRequired } = useMemo(() => {
    const formRules = {
      isJustificationRequired: false,
      isValueFixed: false,
      isVoucherRequired: false,
    };

    if (!expensePolicies) return formRules;

    if (expensePolicies.some((p) => p.typeValue === ExpensePolicyTypeValue.UNTOUCHABLE)) {
      formRules.isValueFixed = true;
    }

    const policy = expensePolicies.find(
      ({ typeValue }) =>
        typeValue === ExpensePolicyTypeValue.TOUCHABLE_MORE_LESS ||
        typeValue === ExpensePolicyTypeValue.TOUCHABLE_LESS
    );

    if (policy && value && policy.value !== value) {
      formRules.isJustificationRequired = true;
    }

    if (policy && value && policy.value === value) {
      formRules.isJustificationRequired = false;
    }

    if (isNew && expensePolicies.some((p) => p.voucherRequired)) {
      formRules.isVoucherRequired = true;
    }

    return formRules;
  }, [isNew, expensePolicies, value]);

  const pricePolicy = (() =>
    expensePolicies?.find(
      ({ policyParameterExpense }) => policyParameterExpense.type === "price"
    ))();

  const amountPolicy = (() =>
    expensePolicies?.find(
      ({ policyParameterExpense }) => policyParameterExpense.type === "amount"
    ))();

  if (isNew && !value && pricePolicy) {
    setValue("value", pricePolicy.value);
  }

  useEffect(() => {
    if (expenseCategory && expenseType?.uuid && isNew) {
      setValue("expenseType", { uuid: "", name: "" });
    }
  }, [expenseCategory]);

  useEffect(() => {
    if (
      pricePolicy?.typeValue === ExpensePolicyTypeValue.TOUCHABLE_LESS &&
      value > pricePolicy.value
    ) {
      setError("value", { type: "required" });
    } else {
      clearErrors();
    }
  }, [value]);

  const maxValue =
    pricePolicy?.typeValue === ExpensePolicyTypeValue.TOUCHABLE_LESS
      ? pricePolicy.value
      : undefined;

  const canShowPolicies =
    !isLoadingExpensePolicies && expenseType && expensePolicies && expensePolicies.length > 0;

  const breakingPricePolicy =
    pricePolicy &&
    pricePolicy.typeValue === ExpensePolicyTypeValue.TOUCHABLE_LESS &&
    value > pricePolicy.value;

  const breakingAmountPolicy = amountPolicy && expensesByDate?.data.length === amountPolicy.value;

  const breakingPolicy = expensePolicies && (breakingAmountPolicy || breakingPricePolicy);

  const canShowExpensesByDate =
    isFetchedExpensesByDate &&
    !isFetchingExpensesByDate &&
    isValidDate(expenseDate) &&
    expensesByDate &&
    expensesByDate?.data.length > 0;

  useEffect(() => {
    if (
      pricePolicy &&
      value &&
      pricePolicy.typeValue === ExpensePolicyTypeValue.TOUCHABLE_LESS &&
      value > pricePolicy.value
    ) {
      return setError("value", { type: "required" });
    }

    clearErrors();
  }, [value, pricePolicy]);

  const [canLoadPolicies, setCanLoadPolicies] = useState(false);

  useEffect(() => {
    if (expenseCategory && expenseType?.uuid && expenseType.name) {
      setCanLoadPolicies(true);
    }

    if (expensePolicies) {
      setTimeout(() => setCanLoadPolicies(false), 2000);
    }
  }, [expensePolicies]);
  return (
    <Container size="8" fixed>
      <Form
        onSubmit={handleSubmit(
          (data) => onSubmit({ ...(data as AccountabilityExpense), travelerId }),
          (error) => {
            // eslint-disable-next-line no-console
            console.log(error);
          }
        )}
      >
        <FormDialog
          title={isNew ? "Nova despesa" : "Editar despesa"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick} type="reset">
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button
              disabled={
                formState.isSubmitting ||
                (isNew && !!breakingPolicy) ||
                (!isNew && breakingPricePolicy)
              }
              type="submit"
            >
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Row gap="6">
              <Col sz="12">
                <FormControl name="expenseCategory" control={control} required>
                  <FieldLabel>Categoria da despesa</FieldLabel>
                  <Select
                    options={expenseCategories}
                    isLoading={isFetchingExpenseCategories}
                    placeholder="Selecione a categoria da despesa"
                    getOptionLabel={getExpenseCategoryLabel}
                    getOptionValue={getExpenseCategoryValue}
                  />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="expenseType" control={control} required>
                  <FieldLabel>Tipo da despesa</FieldLabel>
                  <Select
                    options={expenseTypes?.data}
                    isLoading={isFetchingExpenseTypes}
                    placeholder="Selecione o tipo da despesa"
                    getOptionLabel={getExpenseTypeLabel}
                    getOptionValue={getExpenseTypeValue}
                  />
                </FormControl>
              </Col>

              {canLoadPolicies && (
                <Col sz="12">
                  <Alert variant="info">
                    <AlertIcon />
                    <Text css={{ lineHeight: "1.25" }}>
                      Carregando políticas para o tipo de despesa selecionado
                    </Text>
                  </Alert>
                </Col>
              )}

              {!canLoadPolicies && expensePolicies && canShowPolicies && (
                <Col>
                  <FieldLabel>Políticas</FieldLabel>
                  <Grid columns={1}>
                    <Alert css={{ p: "$4" }} variant="info">
                      <Flex gap="2" direction="column" justify="center" align="start">
                        <Text css={{ lineHeight: "1.5" }}>
                          {expensePolicies.map(({ typeValue, value }) => (
                            <>
                              {typeValue === ExpensePolicyTypeValue.AMOUNT_MAXIMUM && (
                                <Flex css={{ py: "$1" }} align="start" justify="start">
                                  <AlertIcon css={{ mr: "$2" }} size="md" />
                                  Este tipo de despesa, possui uma política que define uma
                                  quantidade máxima de {value} {value === 1 ? "vez" : "vezes"} por
                                  dia.
                                </Flex>
                              )}

                              {typeValue === ExpensePolicyTypeValue.UNTOUCHABLE && (
                                <Flex css={{ py: "$1" }} align="start" justify="start">
                                  <AlertIcon css={{ mr: "$2" }} size="md" />
                                  Esse tipo de despesa possui uma política que não permite alterar o
                                  valor fixo de {asCurrency(value)}.
                                </Flex>
                              )}

                              {typeValue === ExpensePolicyTypeValue.TOUCHABLE_LESS && (
                                <Flex css={{ py: "$1" }} align="start" justify="start">
                                  <AlertIcon css={{ mr: "$2" }} size="md" />
                                  Este tipo de despesa, possui uma política que define um valor
                                  máximo de&nbsp;
                                  {asCurrency(value)}.
                                </Flex>
                              )}
                            </>
                          ))}
                        </Text>

                        {canShowExpensesByDate && (
                          <Flex css={{ py: "$1" }} align="start" justify="start">
                            <AlertIcon css={{ mr: "$2" }} size="md" />
                            Você possui {expensesByDate?.data?.length ?? 0}{" "}
                            {(expensesByDate?.data.length ?? 0) === 1 ? "despesa" : "despesas"}{" "}
                            deste tipo para {displayDate(expenseDate, DateFormats.SMALL_DATE)}.
                          </Flex>
                        )}

                        {isVoucherRequired && (
                          <Flex css={{ py: "$1" }} align="start" justify="start">
                            <AlertIcon css={{ mr: "$2" }} size="md" />
                            Comprovante {isVoucherRequired ? "é" : "não é"} obrigatório.
                          </Flex>
                        )}
                      </Flex>
                    </Alert>
                  </Grid>
                </Col>
              )}

              {value &&
                pricePolicy &&
                pricePolicy.typeValue !== ExpensePolicyTypeValue.UNTOUCHABLE &&
                pricePolicy.value !== value && (
                  <Col sz="12">
                    <Alert variant="warning">
                      <AlertIcon />
                      <Text css={{ lineHeight: "1.25" }}>
                        Este tipo de despesa possui uma política que define um valor de{" "}
                        {asCurrency(pricePolicy?.value)}. Como houve uma alteração, a justificativa
                        é obrigatória.
                      </Text>
                    </Alert>
                  </Col>
                )}

              {isNew && breakingAmountPolicy && (
                <Col sz="12">
                  <Alert variant="warning">
                    <AlertIcon />
                    <Text css={{ lineHeight: "1.25" }}>
                      Este item está fora da sua politica de despesa. A quantidade máxima é de{" "}
                      {amountPolicy.value} despesas deste tipo por dia. Não será possível lançar
                      essa despesa.
                    </Text>
                  </Alert>
                </Col>
              )}

              {unsatisfiedPolicies && unsatisfiedPolicies.length > 0 && (
                <Col sz="12">
                  <Alert variant="warning">
                    <AlertIcon />
                    <Text css={{ lineHeight: "1.25" }}>
                      Este item está fora da sua politica de despesa. Ao adicionar este item, esteja
                      ciente que o reembolso será analisado.
                    </Text>
                  </Alert>
                </Col>
              )}

              <Col sz="12">
                <FormControl name="expenseDate" control={control} required>
                  <FieldLabel>Data da despesa</FieldLabel>
                  <MaskedInput mask={MaskUtils.MASK_DATE} />
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxlg": "12" }}>
                <FormControl name="company" control={control} required>
                  <FieldLabel>Estabelecimento</FieldLabel>
                  <TextInput placeholder="Digite o nome do estabelecimento" />
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxlg": "12" }}>
                <FormControl name="value" control={control} required>
                  <FieldLabel>Valor</FieldLabel>
                  <NumberInput
                    value={isNew ? pricePolicy?.value : value}
                    max={maxValue}
                    disabled={isValueFixed}
                    placeholder="R$"
                    prefix="R$ "
                  />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="voucherImage" control={control} required={isVoucherRequired}>
                  <FieldLabel>Comprovante</FieldLabel>
                  <UploadDropzone
                    value={voucherImage}
                    onChange={(file) => setValue("voucherImage", file as any)}
                    label="Envie o comprovante da despesa"
                    description="Os formatos aceitos são: png, jpg e pdf."
                    acceptTypes={["image/png", "image/jpeg", "application/pdf"]}
                    onOpenFile={onOpenFile}
                  />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl
                  name="justification"
                  control={control}
                  required={isJustificationRequired}
                >
                  <FieldLabel>Justificativa</FieldLabel>
                  <TextAreaInput placeholder="Digite a justificativa para a despesa" />
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
