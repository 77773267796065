import { Fragment, useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Actions, City, Order, VehicleQuery } from "~/application/types";
import { cityService } from "~/application/usecases";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCalendar, SvgGps, SvgSearch } from "~/components/Icon/icons";
import { TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Chip, ChipText, createControlledChipInput } from "~/core/shared/components/ChipInput";
import { DoubleCalendar } from "~/core/shared/components/DoubleCalendar";
import {
  FormControl,
  FormControlContent,
  FormControlLabel,
} from "~/core/shared/components/FormControl";
import {
  Popover,
  PopoverAnchor,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { SelectButton } from "~/core/shared/components/SelectButton";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  SelectValue,
} from "~/core/shared/components/SelectButton/Select";
import { rangedArray } from "~/utils/array.utils";
import { displayDate } from "~/utils/date.utils";
import { formatNumber } from "~/utils/string.utils";
import { Card, CardBody, CardFooter, CardSectionTitle } from "../../../components/Card";
import { SimpleListItem } from "../../../components/SimpleListItem";
import { VehicleBudgetActionType, useVehicleBudgetResult } from "../hooks/useVehicleBudget/type";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useVerifyParameter } from "~/presentation/shared/hooks/useVerifyParameter";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { useReasonTrips } from "~/core/modules/Customer/pages/CustomerPage/hooks/useReasonTrips";
import { FieldLabel, FormControl as NewFormControl } from "~/components/FormControl";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { Form } from "~/components/Form";

export type VehicleQueryFormProps = {
  defaultData?: Partial<VehicleQuery>;
  order?: Order;
  onSubmit: (data: VehicleQuery) => void;
  vehicleBudget?: useVehicleBudgetResult;
};

const CityInputChip = createControlledChipInput<City>();

export const VehicleQueryForm = ({
  defaultData,
  onSubmit,
  vehicleBudget,
  order,
}: VehicleQueryFormProps) => {
  const {
    control,
    setValue,
    resetField,
    watch,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<VehicleQuery>({
    defaultValues: defaultData,
  });

  const data = watch();

  const { contexts, user, profile } = useUser();

  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;

  const showReasonTrip =
    useVerifyParameter({
      customer: contexts.customer,
      parameter: SettingParameterSlug.POLICY_BY_REASON_TRIP,
    }) &&
    useVerifyActions({ actions: [Actions.CreateOrder, Actions.ViewReasonTrip], contexts, profile });

  const canCreateOrder = useVerifyActions({
    actions: [Actions.CreateOrder, Actions.CreateVehicleOrder],
    contexts,
    profile,
  });

  const { data: reasonTrips, fetchReasonTrip } = useReasonTrips({
    customerId,
    enabled: showReasonTrip,
  });

  const currentReasonTrip = order?.reasonTrip;

  useEffect(() => {
    if (currentReasonTrip) {
      setValue("reasonTrip", currentReasonTrip);
    }
  }, [currentReasonTrip]);

  useEffect(() => {
    if (data.originSelected) {
      setValue("destinationSelected", data.originSelected);
    }
  }, [data.originSelected]);

  // Calendar form
  const excludeDates = useCallback(() => {
    setValue("dateGetSelected", null);
    setValue("dateReturnSelected", null);
  }, [resetField]);

  const handleDateClick = useCallback(
    (date: Date) => {
      const { dateGetSelected, dateReturnSelected } = data;

      if (!dateGetSelected) {
        setValue("dateGetSelected", date);
      } else if (!dateReturnSelected) {
        if (date.getTime() > dateGetSelected.getTime()) {
          setValue("dateReturnSelected", date);
        } else {
          setValue("dateGetSelected", date);
          setValue("dateReturnSelected", null);
        }
      } else if (dateGetSelected && dateReturnSelected) {
        setValue("dateGetSelected", date);
        setValue("dateReturnSelected", null);
      } else {
        setValue("dateGetSelected", date);
      }
    },
    [data, setValue, resetField]
  );

  const maxDate = useMemo(() => {
    const date = new Date();
    if (data?.dateGetSelected) {
      const time = data?.dateGetSelected?.getTime() + 1000 * 60 * 60 * 24 * 31;
      date.setTime(Number(time));
      return date;
    }

    return undefined;
  }, [data?.dateGetSelected]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Flex
        direction="column"
        gap={{ "@initial": "4", "@mxlg": "6" }}
        css={{
          p: "$6",
          "@mxlg": {
            width: "80%",
            margin: "$15 auto 0",
            px: "0",
          },
        }}
      >
        <Flex direction="column" gap={{ "@initial": "4", "@mxlg": "6" }}>
          {showReasonTrip && (
            <Flex direction={{ "@mxlg": "column" }}>
              <NewFormControl name="reasonTrip" control={control} required>
                <FieldLabel css={{ "@mxlg": { color: "White" } }}>Motivo de viagem</FieldLabel>
                <AsyncSelect
                  size="sm"
                  placeholder="Digite o motivo de viagem"
                  defaultOptions={reasonTrips?.filter((reason) => reason.isActive)}
                  defaultValue={data?.reasonTrip || currentReasonTrip}
                  disabled={!!currentReasonTrip?.uuid}
                  fetchOptions={fetchReasonTrip}
                  getOptionLabel={(reason) => reason.reason}
                  getOptionValue={(reason) => reason.uuid}
                />
              </NewFormControl>
            </Flex>
          )}

          <Flex
            align="center"
            direction={{ "@mxlg": "column" }}
            gap={{ "@initial": "2", "@mxlg": "6" }}
          >
            <FormControl aria-required="true">
              <FormControlLabel
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
              >
                Local de retirada
              </FormControlLabel>

              <Tooltip
                open={!!errors.originSelected}
                variant="error"
                content={<TooltipLabel>Obrigatório</TooltipLabel>}
                side="bottom"
              >
                <CityInputChip
                  {...register("originSelected", {
                    required: true,
                  })}
                  placeholder={data.originSelected ? " " : "Digite uma cidade ou estado"}
                  defaultValue={data.originSelected}
                  leftIcon={SvgGps}
                  typingDelayMs={100}
                  minChars={3}
                  queryFunc={(text) => cityService.find({ name: text })}
                  chipRenderer={(value) => (
                    <Chip
                      css={{
                        backgroundColor: "$primary-light",
                        width: "fit-content",
                      }}
                    >
                      <ChipText>{value.name}</ChipText>
                      <Text>{value.state}</Text>
                    </Chip>
                  )}
                  emptyText="Nenhuma cidade encontrada"
                  loadingText="Carregando cidades..."
                  itemsRenderer={({ items, setActiveItem }) => (
                    <Fragment>
                      <CardSectionTitle>Cidades</CardSectionTitle>
                      <Box css={{ maxHeight: 250, overflowY: "auto" }}>
                        {items.map((city) => (
                          <PopoverClose asChild key={city.uuid}>
                            <SimpleListItem
                              onClick={() => setActiveItem(city)}
                              title={city.name}
                              description={`${city.state} - ${city.country}`}
                              css={{ p: "$2 $4" }}
                            />
                          </PopoverClose>
                        ))}
                      </Box>
                    </Fragment>
                  )}
                />
              </Tooltip>
            </FormControl>

            <FormControl aria-required="true">
              <FormControlLabel
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
              >
                Local de devolução
              </FormControlLabel>

              <Tooltip
                open={!!errors.destinationSelected}
                variant="error"
                content={<TooltipLabel>Obrigatório</TooltipLabel>}
                side="bottom"
              >
                <CityInputChip
                  {...register("destinationSelected", {
                    required: true,
                  })}
                  placeholder={data.destinationSelected ? "" : "Digite uma cidade ou estado"}
                  defaultValue={data.destinationSelected}
                  value={data.destinationSelected}
                  leftIcon={SvgGps}
                  typingDelayMs={100}
                  minChars={3}
                  queryFunc={(text) => cityService.find({ name: text })}
                  chipRenderer={(value) => (
                    <Chip
                      css={{
                        width: "fit-content",
                        backgroundColor: "$primary-light",
                      }}
                    >
                      <ChipText>{value.name}</ChipText>
                      <Text>{value.state}</Text>
                    </Chip>
                  )}
                  emptyText="Nenhuma cidade encontrada"
                  loadingText="Carregando cidades..."
                  itemsRenderer={({ items, setActiveItem }) => (
                    <Fragment>
                      <CardSectionTitle>Cidades</CardSectionTitle>
                      <Box css={{ maxHeight: 250, overflowY: "auto" }}>
                        {items.map((city) => (
                          <PopoverClose asChild key={city.uuid}>
                            <SimpleListItem
                              onClick={() => setActiveItem(city)}
                              title={city.name}
                              description={`${city.state} - ${city.country}`}
                              css={{ p: "$2 $4" }}
                            />
                          </PopoverClose>
                        ))}
                      </Box>
                    </Fragment>
                  )}
                />
              </Tooltip>
            </FormControl>
          </Flex>
        </Flex>

        <Flex
          align="center"
          direction={{ "@mxlg": "column" }}
          gap={{ "@initial": "2", "@mxlg": "6" }}
        >
          <Popover>
            <FormControl aria-required="true">
              <FormControlLabel
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
              >
                Data da retirada
              </FormControlLabel>

              <PopoverTrigger asChild>
                <FormControlContent>
                  <Tooltip
                    open={!!errors.dateGetSelected}
                    variant="error"
                    content={<TooltipLabel>Obrigatório</TooltipLabel>}
                    side="bottom"
                  >
                    <TextInput
                      {...register("dateGetSelected", {
                        required: true,
                      })}
                      style={{ width: "100%" }}
                      onBlur={(e) => e.preventDefault()}
                      placeholder="Data de retirada"
                      leftIcon={SvgCalendar}
                      value={(data.dateGetSelected && displayDate(data.dateGetSelected)) || ""}
                      readOnly
                    />
                  </Tooltip>
                </FormControlContent>
              </PopoverTrigger>

              {/* Anchor */}
              <PopoverAnchor />
            </FormControl>

            <FormControl aria-required="true">
              <FormControlLabel
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
              >
                Data da devolução
              </FormControlLabel>

              <PopoverTrigger asChild>
                <FormControlContent>
                  <Tooltip
                    open={!!errors.dateReturnSelected}
                    variant="error"
                    content={<TooltipLabel>Obrigatório</TooltipLabel>}
                  >
                    <TextInput
                      {...register("dateReturnSelected", {
                        required: true,
                      })}
                      data-size="sm"
                      onBlur={(e) => e.preventDefault()}
                      style={{ width: "100%" }}
                      placeholder="Data da devolução"
                      leftIcon={SvgCalendar}
                      value={
                        (data.dateReturnSelected && displayDate(data.dateReturnSelected)) || ""
                      }
                      readOnly
                    />
                  </Tooltip>
                </FormControlContent>
              </PopoverTrigger>
            </FormControl>

            <PopoverContent>
              <Card
                elevated
                css={{
                  "@mxlg": {
                    top: "100%",
                    position: "absolute",
                    width: "70vw",
                  },
                }}
              >
                <DoubleCalendar
                  date={data.dateGetSelected || new Date()}
                  activeMinDate={data.dateGetSelected as Date}
                  activeMaxDate={data.dateReturnSelected}
                  minDate={new Date()}
                  maxDate={maxDate}
                  onDateClick={handleDateClick}
                />

                <CardFooter>
                  <CardBody
                    css={{
                      "@mxlg": {
                        p: "$2",
                      },
                    }}
                  >
                    <Flex
                      gap="4"
                      css={{
                        justifyContent: "flex-end",
                        "@mxlg": {
                          justifyContent: "space-around",
                        },
                      }}
                    >
                      <PopoverClose asChild>
                        <Button
                          css={{
                            "@mxlg": {
                              fontSize: "$sm",
                              height: "$5",
                            },
                          }}
                          variant="tertiary"
                          onClick={excludeDates}
                        >
                          Excluir
                        </Button>
                      </PopoverClose>

                      <PopoverClose asChild>
                        <Button
                          css={{
                            "@mxlg": {
                              fontSize: "$sm",
                              height: "$5",
                            },
                          }}
                          variant="secondary"
                        >
                          Aplicar
                        </Button>
                      </PopoverClose>
                    </Flex>
                  </CardBody>
                </CardFooter>
              </Card>
            </PopoverContent>
          </Popover>

          <FormControl aria-required="true">
            <FormControlLabel
              css={{
                "@mxlg": {
                  color: "White",
                },
              }}
            >
              Hora da retirada
            </FormControlLabel>

            <FormControlContent>
              <Select
                defaultValue={defaultData?.timeGetSelected}
                onValueChange={(value) => setValue("timeGetSelected", value)}
              >
                <SelectTrigger asChild>
                  <SelectButton css={{ width: "100%" }}>
                    <SelectValue />
                  </SelectButton>
                </SelectTrigger>

                <SelectContent>
                  <Grid columns="2">
                    <Flex direction="column">
                      <Flex justify="center" css={{ p: "$2" }}>
                        <Text size="2" variant="white" css={{ ta: "center" }}>
                          am
                        </Text>
                      </Flex>
                      {rangedArray(12).map((value) => {
                        const fv = formatNumber(value, 2) + ":00";

                        return (
                          <SelectItem value={fv} key={value}>
                            <SelectItemText>{fv}</SelectItemText>
                          </SelectItem>
                        );
                      })}
                    </Flex>

                    <Flex direction="column">
                      <Flex justify="center" css={{ p: "$2", backgroundColor: "$primary-light" }}>
                        <Text size="2" variant="primary-dark" css={{ ta: "center" }}>
                          pm
                        </Text>
                      </Flex>
                      {rangedArray(12).map((value) => {
                        value += 12;
                        const fv = formatNumber(value, 2) + ":00";

                        return (
                          <SelectItem value={fv} key={value}>
                            <SelectItemText>{fv}</SelectItemText>
                          </SelectItem>
                        );
                      })}
                    </Flex>
                  </Grid>
                </SelectContent>
              </Select>
            </FormControlContent>
          </FormControl>

          <FormControl aria-required="true">
            <FormControlLabel
              css={{
                "@mxlg": {
                  color: "White",
                },
              }}
            >
              Hora da devolução
            </FormControlLabel>

            <FormControlContent>
              <Select
                defaultValue={defaultData?.timeReturnSelected}
                onValueChange={(value) => setValue("timeReturnSelected", value)}
              >
                <SelectTrigger asChild>
                  <SelectButton css={{ width: "100%" }}>
                    <SelectValue />
                  </SelectButton>
                </SelectTrigger>

                <SelectContent>
                  <Grid columns="2">
                    <Flex direction="column">
                      <Flex justify="center" css={{ p: "$2", backgroundColor: "$primary-dark" }}>
                        <Text size="2" variant="white" css={{ ta: "center" }}>
                          am
                        </Text>
                      </Flex>
                      {rangedArray(12).map((value) => {
                        const fv = formatNumber(value, 2) + ":00";

                        return (
                          <SelectItem value={fv} key={value}>
                            <SelectItemText>{fv}</SelectItemText>
                          </SelectItem>
                        );
                      })}
                    </Flex>

                    <Flex direction="column">
                      <Flex justify="center" css={{ p: "$2", backgroundColor: "$primary-light" }}>
                        <Text size="2" variant="primary-dark" css={{ ta: "center" }}>
                          pm
                        </Text>
                      </Flex>
                      {rangedArray(12).map((value) => {
                        value += 12;
                        const fv = formatNumber(value, 2) + ":00";

                        return (
                          <SelectItem value={fv} key={value}>
                            <SelectItemText>{fv}</SelectItemText>
                          </SelectItem>
                        );
                      })}
                    </Flex>
                  </Grid>
                </SelectContent>
              </Select>
            </FormControlContent>
          </FormControl>

          {canCreateOrder && (
            <Button
              size="md"
              type="submit"
              css={{
                size: "$13",
                alignSelf: "flex-end",
                "@mxlg": {
                  margin: "$4 auto",
                  width: "$50",
                  height: "$11",
                  backgroundColor: "#F0F2F5",
                  color: "$primary-base",
                },
              }}
              onClick={() => {
                vehicleBudget?.dispatch?.({
                  type: VehicleBudgetActionType.CLEAR,
                });
              }}
            >
              <Icon as={SvgSearch} variant={{ "@mxlg": "primary" }} />
              <Text
                css={{
                  "@lg": {
                    display: "none",
                  },
                }}
              >
                Buscar
              </Text>
            </Button>
          )}
        </Flex>
      </Flex>
    </Form>
  );
};
