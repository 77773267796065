import { Actions, CustomerEmployeesGroup } from "~/application/types";
import { Button } from "~/components/Button";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { useCallback } from "react";
import { CustomerEmployeesGroupListItem } from "~/core/modules/Customer/pages/CustomerPage/views/CustomerEmployeesGroups/components/CustomerEmployeesGroupListItem";
import { Flex } from "~/components/Flex";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { SearchBar } from "~/components/Input";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { useUser } from "~/presentation/core/contexts/UserContext";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { UseQueryResult } from "@tanstack/react-query";
import { GroupEmployee } from "~/core/modules/Customer/pages/CustomerPage/hooks/useCustomerEmployeesGroups";

export interface CustomerEmployeesGroupsContainerProps {
  data?: CustomerEmployeesGroup[];
  search: string;
  currentPage: number;
  lastPage: number;
  isLoading: boolean;
  onCreateCustomerEmployeesGroup: () => void;
  onToggleCustomerEmployeesGroupState: (item: CustomerEmployeesGroup) => void;
  onUpdateCustomerEmployeesGroup: (item: CustomerEmployeesGroup) => void;
  onGoToPage: (value: number) => void;
  onSearch: (value: string) => void;
  fetchEmployees: ({
    name,
    groupIds,
  }: {
    name: string;
    groupIds: string[];
  }) => UseQueryResult<GroupEmployee[], unknown>;
}

export function CustomerEmployeesGroupsContainer({
  data,
  search,
  currentPage,
  lastPage,
  isLoading,
  onCreateCustomerEmployeesGroup,
  onGoToPage,
  onToggleCustomerEmployeesGroupState,
  onUpdateCustomerEmployeesGroup,
  onSearch,
  fetchEmployees,
}: CustomerEmployeesGroupsContainerProps) {
  const { contexts, profile } = useUser();
  const isMobile = useMobile();

  const listRenderer = useCallback(
    (item: CustomerEmployeesGroup) => (
      <CustomerEmployeesGroupListItem
        fetchEmployees={fetchEmployees}
        data={item}
        onEditClick={onUpdateCustomerEmployeesGroup}
        onToggleState={onToggleCustomerEmployeesGroupState}
        key={item.uuid}
      />
    ),
    [onUpdateCustomerEmployeesGroup, onToggleCustomerEmployeesGroupState]
  );

  const canCreateCustomerEmployeesGroup = useVerifyActions({
    actions: [Actions.CreateUpdateCustomerEmployeesGroup],
    contexts,
    profile,
  });

  return (
    <Flex direction="column" gap="4">
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Grupos de funcionários" css={{ "@mxlg": { display: "none" } }} />

          <AppBarActions>
            <Flex gap="4">
              <SearchBar search={search} onSearchChange={onSearch} placeholder="Encontrar" />

              {canCreateCustomerEmployeesGroup && (
                <Button
                  title="Novo grupo"
                  color="primary"
                  onClick={onCreateCustomerEmployeesGroup}
                  css={{ "@mxlg": { background: "#fff" } }}
                >
                  <Icon as={SvgPlus} css={{ fill: isMobile ? "#000" : undefined }} />
                  {!isMobile && <Text>Novo grupo</Text>}
                </Button>
              )}
            </Flex>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20", "@mxlg": { p: "$5" } }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {search.length > 0 ? (
                <Text>Nenhum grupo corresponde aos dados informados</Text>
              ) : (
                <Text>Você ainda não possui grupos cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Flex>
  );
}
