import { FC, Fragment, useEffect } from "react";
import { MaskUtils } from "~/application/utils";
import { Button } from "~/components/Button";
import { CardBody } from "~/components/Card";
import { Cart, CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp, SvgExpenses } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { Label } from "~/components/Typography";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { AirwayCartItem } from "./components/AirwayCartItem";
import { AirwayCartUIProps } from "./types";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { dialogService } from "~/components/DialogStack";
import { AirwayBudgetDialog } from "../../../hooks/useAirwayBudget/components/AirwayBudgetDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useBrokenPolicyJustification } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";

export const AirwayCartUI: FC<AirwayCartUIProps> = ({
  adultIndividualValue,
  childIndividualValue,
  feeItems,
  totalValue,
  passengersText,
  isProceedDisabled,
  goFlight,
  returnFlight,
  isCombined,
  isConfirmationEnabled,
  isShowingCart,
  isLastStep,
  isTravelerEnabled,
  setIsShowingCart,
  onNextStep,
  onOpenDetails,
  onCreateAirwayBooking,
  isGoEnabled,
  isReturnEnabled,
  stretch,
}) => {
  const isMobile = useMobile();
  const { airwayBudget, airwayParams, bookingStep, airwayReducer } = useFlightsPage();
  const { activeBudget, state } = airwayBudget;
  const { contexts, user } = useUser();
  const { brokenPolicyItems } = useBrokenPolicyJustification();

  const hasViolatedPolicies = [goFlight, returnFlight].some((flight) => {
    return flight?.violatedPolicies && flight?.violatedPolicies.length > 0;
  });

  const departureUnflownTicketValue =
    airwayReducer.bookingState.goFlightSelected?.unflownAirway?.reduce(
      (acc, unflown) => (acc += unflown?.unflown?.value || 0),
      0
    ) || 0;

  const arrivalUnflownTicketValue =
    airwayReducer.bookingState.returnFlightSelected?.unflownAirway?.reduce(
      (acc, unflown) => (acc += unflown?.unflown?.value || 0),
      0
    ) || 0;

  const totalUnflownTicketValue = departureUnflownTicketValue + arrivalUnflownTicketValue;

  const typeBudget = bookingStep.currentIndex ? "arrival" : "departure";
  
  useEffect(() => {
    if (!!goFlight?.id && isGoEnabled) {
      return setIsShowingCart(true);
    }
    if (!!returnFlight?.id && isReturnEnabled) {
      return setIsShowingCart(true);
    }else{
      return setIsShowingCart(false);
    }
  }, [ isGoEnabled, goFlight, returnFlight, isReturnEnabled]);


  const onNextBookingStep = () => {
    if (isMobile && !isTravelerEnabled) {
      setIsShowingCart((prev)=>!prev)
      onNextStep();
      return;
    }

    if(isTravelerEnabled && hasViolatedPolicies) {
      setIsShowingCart(false)
      onNextStep();
      return;
    }
    onNextStep();
  };

  return (
    <Cart
      css={{
        "@mxlg": {
          position: "fixed",
          display: totalValue <= 0 ? "none" : "flex",
          width: "100%",
          left: "0",
          height: isShowingCart ? "400px" : "$15",
          transition: "$slow",
          bottom: "0",
          backgroundColor: "#003161",
          borderRadius: "0",
        },
      }}
    >
      <CartHeader
        css={{
          "@mxlg": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <Icon
          as={SvgExpenses}
          size="sm"
          css={{
            "@mxlg": {
              display: "none",
            },
          }}
        />
        <Flex justify="center" onClick={() => setIsShowingCart((prev) => !prev)}>
          <Icon
            as={isShowingCart ? SvgChevronUp : SvgChevronDown}
            css={{
              "@lg": {
                display: "none",
              },
            }}
            size="md"
          />
        </Flex>
        <Text
          css={{
            "@mxlg": {
              display: "none",
            },
          }}
        >
          Sua reserva
        </Text>
      </CartHeader>

      <CardBody
        css={{
          "@mxlg": {
            width: "100%",
            margin: "0 auto",
          },
        }}
      >
        <Flex direction="column" gap="5">
          <Flex gap="4" align="center">
            <Col>
              <Label
                css={{
                  "@mxlg": {
                    color: "#FFF",
                  },
                }}
              >
                Valor Total
              </Label>
            </Col>
            <Text
              size="6"
              css={{
                fw: "600",
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {MaskUtils.asCurrency(totalValue)}
            </Text>
          </Flex>
          <Flex
            gap="4"
            align="center"
            css={{
              transition: "$slow",
            }}
          >
            <Col>
              <Label
                css={{
                  "@mxlg": {
                    color: "#FFF",
                  },
                }}
              >
                Passageiros
              </Label>
            </Col>
            <Text
              variant="darkest"
              size="2"
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {passengersText}
            </Text>
          </Flex>
          <Flex gap="4" align="center">
            <Col>
              <Label
                css={{
                  "@mxlg": {
                    color: "#FFF",
                  },
                }}
              >
                Preço por adulto
              </Label>
            </Col>
            <Text
              variant="darkest"
              size="2"
              css={{
                "@mxlg": {
                  color: "#FFF",
                },
              }}
            >
              {MaskUtils.asCurrency(adultIndividualValue)}
            </Text>
          </Flex>
          {childIndividualValue !== null && (
            <Flex gap="4" align="center">
              <Col>
                <Label
                  css={{
                    "@mxlg": {
                      color: "#FFF",
                    },
                  }}
                >
                  Preço por criança
                </Label>
              </Col>
              <Text
                variant="darkest"
                size="2"
                css={{
                  "@mxlg": {
                    color: "#FFF",
                  },
                }}
              >
                {MaskUtils.asCurrency(childIndividualValue)}
              </Text>
            </Flex>
          )}
          {feeItems.map((item) => (
            <Flex gap="4" align="center" key={item.code}>
              <Col>
                <Label
                  css={{
                    "@mxlg": {
                      color: "#FFF",
                    },
                  }}
                >
                  {item.label}
                </Label>
              </Col>
              <Text
                variant="darkest"
                size="2"
                css={{
                  "@mxlg": {
                    color: "#FFF",
                  },
                }}
              >
                {MaskUtils.asCurrency(item.value)}
              </Text>
            </Flex>
          ))}
          {!!totalUnflownTicketValue && (
            <Flex gap="4" align="center">
              <Col>
                <Label
                  css={{
                    "@mxlg": {
                      color: "#FFF",
                    },
                  }}
                >
                  Banco de bilhetes
                </Label>
              </Col>
              <Text
                variant="darkest"
                size="2"
                css={{
                  "@mxlg": {
                    color: "#FFF",
                  },
                }}
              >
                {MaskUtils.asCurrency(totalUnflownTicketValue)}
              </Text>
            </Flex>
          )}
        </Flex>
      </CardBody>

      <CardBody
        css={{
          "@mxlg": {
            width: "80%",
            margin: "$4 auto",
          },
        }}
      >
        {activeBudget ? (
          <Button
            disabled={!state[typeBudget]?.length}
            onClick={
              isLastStep
                ? () =>
                    dialogService.showDialog(
                      <AirwayBudgetDialog
                        data={state}
                        user={user}
                        customer={contexts?.customer}
                        airwayParams={airwayParams}
                      />
                    )
                : onNextBookingStep
            }
          >
            <Text>{isLastStep ? "Gerar PDF" : "Avançar"}</Text>
          </Button>
        ) : (
          <Button
            disabled={isProceedDisabled}
            onClick={isConfirmationEnabled ? onCreateAirwayBooking : onNextBookingStep}
          >
            <Text>{isConfirmationEnabled ? "Efetuar reserva" : "Avançar"}</Text>
          </Button>
        )}
      </CardBody>

      {!isConfirmationEnabled && (
        <Fragment>
          {goFlight && (
            <AirwayCartItem title="Ida" flight={goFlight} onOpenDetails={onOpenDetails} />
          )}
          {!!stretch.length &&
            stretch.map((str, index) => (
              <AirwayCartItem
                key={str.flight.id}
                title={`Trechos ${index + 1}`}
                flight={str.flight}
                isCombined={str.isCombined}
                onOpenDetails={onOpenDetails}
              />
            ))}

          {returnFlight && (
            <AirwayCartItem
              title="Volta"
              flight={returnFlight}
              isCombined={isCombined}
              onOpenDetails={onOpenDetails}
            />
          )}
        </Fragment>
      )}
    </Cart>
  );
};

AirwayCartUI.displayName = "AirwayCartUI";
