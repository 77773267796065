import { Fragment, useCallback, useEffect } from "react";
import { Control, UseFormRegister, useFieldArray, useForm } from "react-hook-form";
import { Actions, City, HotelQuery, Order } from "~/application/types";
import { cityService } from "~/application/usecases";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCalendar, SvgGps, SvgSearch } from "~/components/Icon/icons";
import { TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { displayGuests } from "~/core/modules/Booking/modules/BookingHotel/utils";
import {
  Card,
  CardBody,
  CardFooter,
  CardSectionTitle,
} from "~/core/modules/DeprecatedBooking/components/Card";
import { SimpleListItem } from "~/core/modules/DeprecatedBooking/components/SimpleListItem";
import { Chip, ChipText, createControlledChipInput } from "~/core/shared/components/ChipInput";
import { Counter } from "~/core/shared/components/Counter";
import { CounterListItem } from "~/core/shared/components/CounterListItem";
import { DoubleCalendar } from "~/core/shared/components/DoubleCalendar";
import {
  FormControl,
  FormControlContent,
  FormControlLabel,
} from "~/core/shared/components/FormControl";
import {
  Popover as DeprecatedPopover,
  PopoverAnchor,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { SelectButton } from "~/core/shared/components/SelectButton";
import {
  SelectContent,
  SelectItem,
  SelectItemText,
  Select as SelectRoot,
  SelectTrigger,
  SelectValue,
} from "~/core/shared/components/SelectButton/Select";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { rangedArray } from "~/utils/array.utils";
import { displayDate } from "~/utils/date.utils";
import { formatNumber } from "~/utils/string.utils";
import {
  HotelBudgetActionType,
  useHotelBudgetResult,
} from "../../pages/HotelDetailsPage/hooks/useHotelBudget/type";
import { useReasonTrips } from "~/core/modules/Customer/pages/CustomerPage/hooks/useReasonTrips";
import { useVerifyParameter } from "~/presentation/shared/hooks/useVerifyParameter";
import { SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { FieldLabel, FormControl as NewFormControl } from "~/components/FormControl";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { Form } from "~/components/Form";

export type HotelQueryFormProps = {
  defaultData?: Partial<HotelQuery>;
  onSubmit: (data: HotelQuery) => void;
  setQuantityRooms?: (value: number) => void;
  hotelBudget?: useHotelBudgetResult;
  order?: Order;
};

// Extra types, used for custom inputs
type HotelRoomSectionProps = {
  data: HotelQuery;
  control: Control<HotelQuery, any>;
  register: UseFormRegister<HotelQuery>;
  roomIndex: number;
  children: React.ReactNode;
};

type HotelGuestsSelectProps = {
  data: HotelQuery;
  control: Control<HotelQuery, any>;
  register: UseFormRegister<HotelQuery>;
  onChange?: (event: { target: any; type?: any }) => Promise<void | boolean>;
  name?: string;
  disabled?: boolean;
  setQuantityRooms?: (value: number) => void;
};

const HotelInputChip = createControlledChipInput<City>();

export const HotelQueryForm = ({
  defaultData,
  hotelBudget,
  onSubmit,
  setQuantityRooms,
  order,
}: HotelQueryFormProps) => {
  const {
    control,
    setValue,
    resetField,
    watch,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<HotelQuery>({
    defaultValues: defaultData,
  });

  const { contexts, user, profile } = useUser();
  const currentReasonTrip = order?.reasonTrip;

  useEffect(() => {
    if (currentReasonTrip) {
      setValue("reasonTrip", currentReasonTrip);
    }
  }, [currentReasonTrip]);

  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;

  const showReasonTrip =
    useVerifyParameter({
      customer: contexts.customer,
      parameter: SettingParameterSlug.POLICY_BY_REASON_TRIP,
    }) &&
    useVerifyActions({ actions: [Actions.ViewReasonTrip, Actions.CreateOrder], contexts, profile });

  const canCreateOrder = useVerifyActions({
    actions: [Actions.CreateOrder, Actions.CreateHotelOrder],
    contexts,
    profile,
  });

  const { data: reasonTrips, fetchReasonTrip } = useReasonTrips({
    customerId,
    enabled: showReasonTrip,
  });

  const data = watch();

  function onFormSubmit(data: HotelQuery) {
    if (onSubmit) {
      onSubmit(data);
    }
  }
  const isMobile = useMobile();
  // Calendar form
  const excludeDates = useCallback(() => {
    setValue("checkInDate", null);
    setValue("checkOutDate", null);
  }, [resetField]);

  const handleDateClick = useCallback(
    (date: Date) => {
      const { checkInDate, checkOutDate } = data;

      if (!checkInDate || (checkInDate && checkOutDate)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue("checkOutDate", null);
        setValue("checkInDate", date);
        return;
      }

      const [newStart, newEnd] = [checkInDate, checkOutDate, date].sort((a, b) => {
        if (a === null) {
          return 1;
        }

        if (b === null) {
          return -1;
        }

        if (a === b) {
          return 0;
        }

        return a < b ? -1 : 1;
      });

      setValue("checkInDate", newStart);
      setValue("checkOutDate", newEnd);
    },
    [data, setValue, resetField]
  );

  return (
    <Form onSubmit={handleSubmit(onFormSubmit)}>
      <Flex
        direction="column"
        gap={{ "@initial": "4", "@mxlg": "6" }}
        css={{
          p: "$6",
          "@mxlg": {
            width: "80%",
            margin: "$15 auto 0",
            px: "0",
          },
        }}
      >
        <Flex direction={{ "@mxlg": "column" }} gap={{ "@initial": "2", "@mxlg": "6" }}>
          {showReasonTrip && (
            <Flex direction={{ "@mxlg": "column" }}>
              <NewFormControl name="reasonTrip" control={control} required>
                <FieldLabel css={{ "@mxlg": { color: "White" } }}>Motivo de viagem</FieldLabel>
                <AsyncSelect
                  size="sm"
                  placeholder="Digite o motivo de viagem"
                  defaultValue={data?.reasonTrip || currentReasonTrip}
                  defaultOptions={reasonTrips?.filter((reason) => reason.isActive)}
                  disabled={!!currentReasonTrip?.uuid}
                  fetchOptions={fetchReasonTrip}
                  getOptionLabel={(reason) => reason.reason}
                  getOptionValue={(reason) => reason.uuid}
                />
              </NewFormControl>
            </Flex>
          )}

          {!isMobile && (
            <Flex>
              <FormControl>
                <FormControlLabel
                  css={{
                    "@mxlg": {
                      color: "white",
                    },
                  }}
                >
                  Hóspedes
                </FormControlLabel>
                <HotelGuestsSelect {...{ control, register, data, setQuantityRooms }} />
              </FormControl>
            </Flex>
          )}
        </Flex>

        <Flex
          align="center"
          direction={{ "@mxlg": "column" }}
          gap={{ "@initial": "2", "@mxlg": "6" }}
        >
          <FormControl aria-required="true">
            <FormControlLabel
              css={{
                "@mxlg": {
                  color: "white",
                },
              }}
            >
              Para qual lugar
            </FormControlLabel>

            <Tooltip
              open={!!errors.city}
              variant="error"
              content={<TooltipLabel>Obrigatório</TooltipLabel>}
              side="bottom"
            >
              <HotelInputChip
                {...register("city", {
                  required: true,
                })}
                placeholder="Digite uma cidade ou estado"
                defaultValue={data.city}
                leftIcon={SvgGps}
                typingDelayMs={500}
                minChars={3}
                queryFunc={(text: string) => cityService.find({ name: text })}
                chipRenderer={(value: City) => (
                  <Chip
                    css={{
                      backgroundColor: "$primary-light",
                    }}
                  >
                    <ChipText>{value.name}</ChipText>
                    <Text>{value.state}</Text>
                  </Chip>
                )}
                emptyText="Nenhuma cidade encontrada"
                loadingText="Carregando cidades..."
                itemsRenderer={({ items, setActiveItem }) => (
                  <Fragment>
                    <CardSectionTitle>Cidades</CardSectionTitle>
                    <Box
                      css={{
                        maxHeight: "200px",
                      }}
                    >
                      {items.map((city) => (
                        <PopoverClose asChild key={city.uuid}>
                          <SimpleListItem
                            onClick={() => setActiveItem(city)}
                            title={city.name}
                            description={`${city.state} - ${city.country}`}
                            css={{ p: "$2 $4" }}
                          />
                        </PopoverClose>
                      ))}
                    </Box>
                  </Fragment>
                )}
              />
            </Tooltip>
          </FormControl>

          <DeprecatedPopover>
            <FormControl aria-required="true">
              <FormControlLabel css={{ "@mxlg": { color: "White" } }}>Check in</FormControlLabel>

              <PopoverTrigger asChild>
                <FormControlContent>
                  <Tooltip
                    open={!!errors.checkInDate}
                    variant="error"
                    content={<TooltipLabel>Obrigatório</TooltipLabel>}
                    side="bottom"
                  >
                    <TextInput
                      {...register("checkInDate", {
                        required: true,
                      })}
                      onBlur={(e) => e.preventDefault()}
                      placeholder="Data de entrada"
                      leftIcon={SvgCalendar}
                      value={(data.checkInDate && displayDate(data.checkInDate)) || ""}
                      style={{ width: "100%" }}
                      readOnly
                    />
                  </Tooltip>
                </FormControlContent>
              </PopoverTrigger>

              {/* Anchor */}
              <PopoverAnchor />
            </FormControl>

            <FormControl aria-required="true">
              <FormControlLabel
                css={{
                  "@mxlg": {
                    color: "White",
                  },
                }}
              >
                Check out
              </FormControlLabel>

              <PopoverTrigger asChild>
                <FormControlContent>
                  <Tooltip
                    open={!!errors.checkOutDate}
                    variant="error"
                    content={<TooltipLabel>Obrigatório</TooltipLabel>}
                    side="bottom"
                  >
                    <TextInput
                      {...register("checkOutDate", {
                        required: true,
                      })}
                      onBlur={(e) => e.preventDefault()}
                      placeholder="Data de saída"
                      leftIcon={SvgCalendar}
                      value={(data.checkOutDate && displayDate(data.checkOutDate)) || ""}
                      style={{ width: "100%" }}
                      readOnly
                    />
                  </Tooltip>
                </FormControlContent>
              </PopoverTrigger>
            </FormControl>

            {isMobile && (
              <FormControl>
                <FormControlLabel
                  css={{
                    "@mxlg": {
                      color: "white",
                    },
                  }}
                >
                  Hóspedes
                </FormControlLabel>
                <HotelGuestsSelect {...{ control, register, data, setQuantityRooms }} />
              </FormControl>
            )}

            <PopoverContent>
              <Card
                elevated
                css={{
                  "@mxlg": {
                    top: -100,
                    position: "absolute",
                    width: "70vw",
                  },
                }}
              >
                <DoubleCalendar
                  date={data.checkInDate || new Date()}
                  activeMinDate={data.checkInDate as Date}
                  activeMaxDate={data.checkOutDate}
                  minDate={new Date()}
                  onDateClick={handleDateClick}
                />

                <CardFooter>
                  <CardBody
                    css={{
                      "@mxlg": {
                        p: "$2",
                      },
                    }}
                  >
                    <Flex
                      gap="4"
                      css={{
                        justifyContent: "flex-end",
                        "@mxlg": {
                          justifyContent: "space-around",
                        },
                      }}
                    >
                      <PopoverClose asChild>
                        <Button
                          css={{
                            "@mxlg": {
                              fontSize: "$sm",
                              height: "$5",
                            },
                          }}
                          variant="tertiary"
                          onClick={excludeDates}
                        >
                          Excluir
                        </Button>
                      </PopoverClose>

                      <PopoverClose asChild>
                        <Button
                          css={{
                            "@mxlg": {
                              fontSize: "$sm",
                              height: "$5",
                            },
                          }}
                          variant="secondary"
                        >
                          Aplicar
                        </Button>
                      </PopoverClose>
                    </Flex>
                  </CardBody>
                </CardFooter>
              </Card>
            </PopoverContent>
          </DeprecatedPopover>

          {canCreateOrder && (
            <Button
              size="md"
              type="submit"
              css={{
                size: "$13",
                alignSelf: "flex-end",
                "@mxlg": {
                  margin: "$4 auto",
                  width: "$50",
                  height: "$11",
                  backgroundColor: "#F0F2F5",
                  color: "$primary-base",
                },
              }}
              onClick={() => hotelBudget?.dispatch?.({ type: HotelBudgetActionType.CLEAR })}
            >
              <Icon as={SvgSearch} variant={{ "@mxlg": "primary" }} />
              <Text
                css={{
                  "@lg": {
                    display: "none",
                  },
                }}
              >
                Buscar
              </Text>
            </Button>
          )}
        </Flex>
      </Flex>
    </Form>
  );
};

HotelQueryForm.displayName = "HotelQueryForm";

const MAX_GUEST_QUANTITY = 3;

// Other components
const HotelRoomSection = ({
  data,
  control,
  register,
  roomIndex,
  children,
  ...props
}: HotelRoomSectionProps) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `accommodations.${roomIndex}.guestsChildren`,
  });

  const adultQuantity = data.accommodations[roomIndex].adultQuantity;
  const childrenQuantity = data.accommodations[roomIndex].guestsChildren.length;

  return (
    <Flex direction="column" {...props}>
      <CardSectionTitle>Quarto {formatNumber(1 + roomIndex, 2)}</CardSectionTitle>

      <CounterListItem title="Adultos" description="A partir de 18 anos de idade">
        <Counter
          {...register(`accommodations.${roomIndex}.adultQuantity`, {
            min: 1,
            max: 99,
          })}
          minValue={1}
          maxValue={MAX_GUEST_QUANTITY - childrenQuantity}
          defaultValue={data.accommodations[roomIndex].adultQuantity}
        />
      </CounterListItem>
      <CounterListItem title="Crianças" description="Até 17 anos de idade">
        <Counter
          minValue={0}
          maxValue={MAX_GUEST_QUANTITY - adultQuantity}
          onIncrease={() => append({ age: "1" })}
          onDecrease={() => remove(fields.length - 1)}
          defaultValue={data.accommodations[roomIndex].guestsChildren.length}
        />
      </CounterListItem>

      {fields.map((field, fieldIndex) => (
        <Flex gap="4" align="center" justify="between" css={{ p: "$2 $4" }} key={field.id}>
          <Text size="3" css={{ fw: "600" }}>
            Idade menor {1 + fieldIndex}
          </Text>

          <SelectRoot defaultValue={field.age} onValueChange={(age) => update(fieldIndex, { age })}>
            <SelectTrigger asChild>
              <SelectButton>
                <SelectValue />
              </SelectButton>
            </SelectTrigger>

            <SelectContent>
              {rangedArray(15).map((value) => (
                <SelectItem value={`${1 + value}`} key={value}>
                  <SelectItemText>
                    {value === 0 ? "Até 12 meses" : `Até ${1 + value} anos`}
                  </SelectItemText>
                </SelectItem>
              ))}
            </SelectContent>
          </SelectRoot>
        </Flex>
      ))}

      {children}
    </Flex>
  );
};

const HotelGuestsSelect = ({
  data,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name,
  control,
  register,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disabled,
  setQuantityRooms,
}: HotelGuestsSelectProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "accommodations",
  });

  const isMobile = useMobile();

  const handleAppendRoom = useCallback(() => {
    if (fields.length >= 4) return;
    append({ adultQuantity: 1, guestsChildren: [] });
    setQuantityRooms?.(fields.length + 1);
  }, [append, fields, setQuantityRooms]);

  const handleRemoveRoom = useCallback(
    (index: number) => {
      remove(index);
      setQuantityRooms?.(fields.length - 1);
    },
    [remove]
  );

  return (
    <DeprecatedPopover>
      <PopoverTrigger asChild>
        <SelectButton size="sm" css={{ "@mxlg": { width: "100%" } }}>
          {displayGuests(
            data.accommodations?.reduce(
              (prev, { adultQuantity, guestsChildren }) => ({
                roomQuantity: prev.roomQuantity + 1,
                guestQuantity: prev.guestQuantity + adultQuantity + guestsChildren.length,
              }),
              {
                roomQuantity: 0,
                guestQuantity: 0,
              }
            )
          )}
        </SelectButton>
      </PopoverTrigger>

      <PopoverContent align={isMobile ? "center" : "start"} sideOffset={8}>
        <Container
          css={{
            "@mxlg": {
              width: "90%",
              margin: "0 auto",
              maxHeight: 500,
              overflowY: "auto",
              borderRadius: "$md",
            },
          }}
          size="5"
        >
          <Card
            elevated
            css={{
              maxHeight: 600,
              overflowY: "auto",
            }}
          >
            {fields.map((room, roomIndex) => {
              return (
                <HotelRoomSection
                  data={data}
                  control={control}
                  register={register}
                  roomIndex={roomIndex}
                  key={room.id}
                >
                  {roomIndex !== 0 && (
                    <CardBody>
                      <Button
                        variant="tertiary"
                        type="button"
                        onClick={() => handleRemoveRoom(roomIndex)}
                      >
                        Remover quarto
                      </Button>
                    </CardBody>
                  )}
                </HotelRoomSection>
              );
            })}

            <CardBody>
              <Grid columns="2" gap="4">
                <Button
                  variant="tertiary"
                  type="button"
                  disabled={fields.length >= 4}
                  onClick={handleAppendRoom}
                >
                  Adicionar quarto
                </Button>

                <PopoverClose asChild>
                  <Button variant="secondary">Aplicar</Button>
                </PopoverClose>
              </Grid>
            </CardBody>
          </Card>
        </Container>
      </PopoverContent>
    </DeprecatedPopover>
  );
};
