import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Justification, OrderItems } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Select, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { createJustification, editJustification } from "../utils";
import { Spinner } from "~/components/Spinner";

const justificationSchema = yup.object().shape({
  name: yup.string().required("A justificativa é obrigatória"),
  serviceType: yup.object().shape({
    value: yup
      .string()
      .oneOf(Object.values(OrderItems), "Tipo de serviço inválido")
      .required("O tipo de serviço é obrigatório"),
    label: yup.string().required(),
  }),
});

export type JustificationSchema = yup.InferType<typeof justificationSchema>;

export interface JustificationDialogProps {
  isNew?: boolean;
  defaultData?: Justification;
  serviceTypeOptions: {
    value: OrderItems;
    label: string;
  }[];
  onCloseClick?: () => void;
  onSubmit: (data: Justification) => void;
}

export function JustificationDialog({
  isNew,
  defaultData,
  serviceTypeOptions,
  onCloseClick,
  onSubmit,
}: JustificationDialogProps) {
  const { control, formState, handleSubmit } = useForm<JustificationSchema>({
    defaultValues: defaultData && editJustification(defaultData),
    resolver: yupResolver(justificationSchema),
  });

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit((data) => onSubmit(createJustification(data)))}>
        <FormDialog
          title={isNew ? "Nova justificativa" : "Editar justificativa"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              {formState.isSubmitting && (
                <Spinner
                  css={{
                    borderLeftColor: "$neutrals-white",
                    width: "$4",
                    height: "$4",
                    borderWidth: "2px",
                  }}
                />
              )}
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Row gap="6">
              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Justificativa</FieldLabel>
                  <TextInput placeholder="Digite a justiticativa" />
                  {formState.errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="serviceType" control={control} required>
                  <FieldLabel>Tipo de serviço</FieldLabel>
                  <Select options={serviceTypeOptions} placeholder="Selecione o tipo de serviço" />
                  {formState.errors.serviceType?.value && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.serviceType.value.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
