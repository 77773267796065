import { CustomerSettingParameter } from "~/application/types";
import { api } from "~/infrastructure/api";
import { mapCustomerSettingParameterDTO } from "~/infrastructure/api/mappers/mapCustomerSettingParameterDTO";
import {
  FindCustomerSettingParametersOptions,
  ICustomerSettingParameterService,
} from "./ICustomerSettingParameterService";

export class CustomerSettingParameterService implements ICustomerSettingParameterService {
  async find({
    customerId,
  }: FindCustomerSettingParametersOptions): Promise<CustomerSettingParameter[]> {
    const url = `/customers/${customerId}/setting-parameters`;
    return api.get(url).then(({ data: response }) => {
      return response.data.map(mapCustomerSettingParameterDTO);
    });
  }

  async update(data: CustomerSettingParameter): Promise<CustomerSettingParameter> {
    const url = `/customers/${data.customerId}/setting-parameters/${data.uuid}`;
    return api
      .put(url, { value: data.value?.toString() })
      .then(({ data: { data } }) => mapCustomerSettingParameterDTO(data));
  }
}
