import { Fragment, useCallback } from "react";
import slugify from "slugify";
import { FlightSegment, SegmentType } from "~/application/types";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClock } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H4, H5 } from "~/components/Typography";
import { Track } from "~/presentation/shared/components/Track";
import { getAirlineUrl } from "~/presentation/shared/utils";
import { FlightSegmentListItem } from "~/presentation/shared/views/FlightDetailsDialog";
import { timeInterval } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";

export const OrderFlightDetails = (data: {
  segments: FlightSegment[];
  flightValue: number;
  tracker: string | null;
}) => {
  const renderedAirlines = new Set();
  const hasArrivalSegment = data.segments.some((ar) => ar.type === SegmentType.DEPARTURE);

  const renderFlightSegment = useCallback(
    (item: FlightSegment, index: number, array: FlightSegment[]) => {
      const nextSeg = array[1 + index];

      const lastDepartureIndex = array.findLastIndex((ar) => ar.type === SegmentType.DEPARTURE);

      const isCombined = lastDepartureIndex === index;

      return (
        <Fragment key={index}>
          <FlightSegmentListItem data={item} airline={item.airline} />

          {nextSeg &&
            (!isCombined ? (
              <>
                <Flex css={{ height: "$4", justifyContent: "center" }}>
                  <Track data-orientation="vertical" />
                </Flex>
                <Alert
                  variant="neutral"
                  size="sm"
                  css={{
                    borderStyle: "dashed",
                    justifyContent: "center",
                  }}
                >
                  <Icon as={SvgClock} />

                  <Text>
                    {"Espera de "}
                    <strong>
                      {timeInterval(
                        item.arrivalFlightInfo.dateTime,
                        nextSeg.departureFlightInfo.dateTime
                      )}
                    </strong>
                    {" em "}
                    {`${item.arrivalFlightInfo.airport}`}
                  </Text>
                </Alert>
                <Flex css={{ height: "$4", justifyContent: "center" }}>
                  <Track data-orientation="vertical" />
                </Flex>
              </>
            ) : (
              <Flex
                css={{
                  pt: "$6",
                  pb: "$2",
                }}
              >
                {" "}
                <Text fw="700">Volta</Text>
              </Flex>
            ))}
        </Fragment>
      );
    },
    []
  );

  function hasAirportChangeInConnections(segments: FlightSegment[]): boolean {
    return segments.some(
      (segment, index) =>
        index < segments.length - 1 &&
        segment.arrivalFlightInfo.airportIata !==
          segments[index + 1].departureFlightInfo.airportIata
    );
  }

  const showAirportChangeAlert =
    data.segments.length > 1 && hasAirportChangeInConnections(data.segments);

  return (
    <Flex direction="column" gap="6">
      <Box>
        <Flex justify="between" align="center">
          <H4 size={{ "@mxlg": "5" }} css={{ fw: 500, mb: "$8" }}>
            Companhia Aérea
          </H4>

          {data.flightValue && (
            <H4 size={{ "@mxlg": "5" }} css={{ fw: 500, mb: "$5" }}>
              {" "}
              {`Tarifa: ${asCurrency(data.flightValue)}`}{" "}
            </H4>
          )}
        </Flex>

        {data.segments.map((seg) => {
          if (renderedAirlines.has(seg.airline)) {
            return null;
          }

          renderedAirlines.add(seg.airline);

          return (
            <CompanyLogo key={seg.airline} src={getAirlineUrl(slugify(seg.airline))} size="lg" />
          );
        })}

        <br />
        <br />
      </Box>

      {/* TODO: Questionar sobre informações da bagagem */}

      <Flex justify="between">
        <H4 css={{ fw: 500 }}>Itinerário</H4>
        <H5>{data.tracker}</H5>
      </Flex>

      {showAirportChangeAlert && (
        <Alert variant="warning">
          <Flex direction="column" gap="3">
            <Text variant="warning-dark">Atenção: Troca de aeroporto necessária</Text>
            <Text css={{ lineHeight: "$6" }}>
              Você precisará se deslocar entre diferentes aeroportos durante sua conexão. Planeje
              tempo suficiente para o trajeto.
            </Text>
          </Flex>
        </Alert>
      )}
      {hasArrivalSegment && <Text fw="700">Ida</Text>}

      <Flex direction="column" css={{ flexGrow: 1 }}>
        {data.segments.map(renderFlightSegment)}
      </Flex>
    </Flex>
  );
};
