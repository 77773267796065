import { yupResolver } from "@hookform/resolvers/yup";
import { Link, To } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CustomerEmployee, OfflineHotel } from "~/application/types";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { MaskedInput, TextInput } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { convertToWebp } from "~/presentation/shared/utils/image-functions/functions";
import { UploadImageInput } from "~/core/modules/Agency/components/UploadImageInput";
import { Container } from "~/components/Container";
import { updateCustomerProfileSchema } from "../utils/form";
import {  ErrorType } from "../ProfilePage";
import { UseMutateFunction } from "@tanstack/react-query";
import { EditableCustomerEmployee } from "~/presentation/shared/views/CustomerEmployeeDialog";
import { MaskUtils } from "~/application/utils";
import { useEffect } from "react";
import { dialogService } from "~/components/DialogStack";
import { Card } from "~/components/Card";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { snackbarService } from "~/components/SnackbarStack";

export interface UpdateCustomerProfileProps {
  defaultData?: CustomerEmployee;
  isLoadingCustomer: boolean;
  updateAvatar: UseMutateFunction<{
    avatarUrl: string;
  }, ErrorType, {
    file: File | null | undefined;
    uuid?: string;
    phone?: string;
    cpf?: string;
    birthDate?: string;
    lastName?: string;
    name?: string;
    rg?: string;
  }, void>;
  updateCustomerProfile: UseMutateFunction<CustomerEmployee, ErrorType, Partial<EditableCustomerEmployee>, void>
}

export function UpdateProfileForm({
  defaultData,
  isLoadingCustomer,
  updateAvatar,
  updateCustomerProfile,
}: UpdateCustomerProfileProps) {
  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(updateCustomerProfileSchema),
  });
  
  const { phone, image, cpf, birthDate, lastName, name, rg } = watch();
  
  useEffect(() => {
    Object.entries(defaultData || {}).forEach(([key, value]) => {
      setValue(key as keyof typeof defaultData , value as never)
    })
  }, [defaultData])

  useEffect(() => {
    if(isLoadingCustomer) {
      dialogService.showDialog(<LoadingDialog message="Carregando informações" />)
    }

    if(!isLoadingCustomer) dialogService.popAll();
  }, [isLoadingCustomer])

  const onSubmit = async (data: Partial<EditableCustomerEmployee>) => {
    const imageToUpload = await convertToWebp(image as unknown as FileList);
  
    const payload = {
      phone: data.phone,
      name: data.name,    
      lastName: data.lastName,
      birthDate: data.birthDate,
      cpf: data.cpf,
      rg: data.rg,
      email: data.email || null,
      file: imageToUpload?.[0] || null,
    };
    
    if(!imageToUpload.length) {
      updateCustomerProfile(payload as Partial<EditableCustomerEmployee>)
    }else {
      updateAvatar(payload)
    }

  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Flex direction="column" gap="8" css={{ mt: "$10 " }}>

          <Row gap="6" css={{ justifyContent: "center" }}>
            <Col sz={{ "@initial": "6", "@mxlg": "2" }}>
              <UploadImageInput
                {...register("image")}
                label="Importe a foto de perfil"
                style={{ borderRadius: "150px" }}
                files={image as unknown as FileList}
                removeFile={() => setValue("image", null)}
                accept="image/*"
              />
              {errors.image && (
                <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                  {errors.image.message}
                </Text>
              )}
            </Col>
          </Row>

          <H5>Informações básicas</H5>

          <Row gap="6">
            <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
              <FormControl name="name" control={control} required>
                <FieldLabel>Nome</FieldLabel>
                <TextInput placeholder="Digite o Nome" />
                {errors.name && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {errors.name.message}
                  </Text>
                )}
              </FormControl>
            </Col>

            <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
              <FormControl required name="lastName" control={control}>
                <FieldLabel>Sobrenome</FieldLabel>
                <TextInput placeholder="Digite o Sobrenome" />
                {errors.lastName && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {errors.lastName.message}
                  </Text>
                )}
              </FormControl>
            </Col>

            <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
              <FormControl required name="birthDate" control={control}>
                <FieldLabel>Data de nascimento</FieldLabel>
                <MaskedInput mask={MaskUtils.MASK_DATE} />
                {errors.birthDate && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {errors.birthDate.message}
                  </Text>
                )}
              </FormControl>
            </Col>

            <Col sz="12">
              <FormControl required name="cpf" control={control}>
                <FieldLabel>CPF</FieldLabel>
                <TextInput placeholder="Digite o CPF" />
                {errors.cpf && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {errors.cpf.message}
                  </Text>
                )}
              </FormControl>
            </Col>

            <Col sz="12">
              <FormControl required name="phone" control={control}>
                <FieldLabel>Telefone</FieldLabel>
                <MaskedInput mask={MaskUtils.MASK_MOBILE_PHONE_NUMBER} placeholder="Digite o telefone" />
                {errors.phone && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {errors.phone.message}
                  </Text>
                )}
              </FormControl>
            </Col>

            <Col sz="12">
              <FormControl required name="rg" control={control}>
                <FieldLabel>RG</FieldLabel>
                <TextInput placeholder="Digite o RG" />
                {errors.rg && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {errors.rg.message}
                  </Text>
                )}
              </FormControl>
            </Col>
          </Row>
        </Flex>

        <Flex direction="column" gap="8" css={{ mt: "$10 " }}></Flex>

        <Flex gap="4" justify="end" css={{ mt: "$10" }}>
          <Link to={-1 as To}>
            <Button variant="tertiary">Cancelar</Button>
          </Link>

          <Button
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <Spinner
                css={{
                  borderLeftColor: "$neutrals-white",
                  width: "$4",
                  height: "$4",
                  borderWidth: "2px",
                }}
              />
            )}
            Atualizar
          </Button>
        </Flex>
      </Form>
    </Container>
  );
}
