import { Phase } from "~/application/types";
import { PhaseDTO } from "../dtos";
import { mapShortApprovalModelDTO } from "./mapApprovalModelDTO";

export const mapPhaseDTO = (data: PhaseDTO): Phase => {
  return {
    uuid: data.uuid,
    projectId: data.project_uuid,
    name: data.name,
    originalName: data.original_name,
    code: data.code,
    budget: Number(data.budget),
    alert: data.alert ? Number(data.alert) : null,
    startAt: new Date(data.start_at),
    endAt: data.end_at ? new Date(data.end_at) : null,
    approvalModels: data.approval_models?.map(mapShortApprovalModelDTO),
  };
};
