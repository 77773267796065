import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryKeys } from "~/application/constants";
import { branchService, costCenterService, customerEmployeeService } from "~/application/usecases";
import { log } from "~/application/utils/log";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { CustomerReportsContainer } from "./ReportsContainer";
import { useReportTab } from "./hooks/useReportsTab";
import { ShoppingReportData } from "~/application/usecases/Reports/types";
import { dialogService } from "~/components/DialogStack";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { reportService } from "~/application/usecases/Reports";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { DeepPartial } from "~/application/types";
import { snackbarService } from "~/components/SnackbarStack";

const LOG_TAG = "CustomerSettings/ReportPage";

export const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar relatórios da agência",
} as const;

export function CustomerReportsPage() {
  const tabValue = useReportTab();
  const { contexts } = useUser();

  const [search, setSearch] = useState<
    DeepPartial<{
      approver: string;
      issuer: string;
      passenger: string;
      cost_center: string;
    }>
  >({
    approver: "",
    issuer: "",
    passenger: "",
    cost_center: "",
  });

  const [searchName] = useDebounce(search, 700);

  const customerId = contexts.customer?.uuid;

  const { data: branches, isLoading: isLoadingBranches } = useQuery(
    [QueryKeys.CUSTOMERS, customerId],
    () => branchService.find({ customerId }),
    {
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { data: costCenters, isLoading: isLoadingCostCenters } = useQuery(
    [QueryKeys.CUSTOMER_COST_CENTERS, customerId, searchName.cost_center],
    () => costCenterService.find({ customerId, name: searchName.cost_center }),
    {
      enabled: true,
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { data: approvers, isLoading: isLoadingApprovers } = useQuery(
    [QueryKeys.CUSTOMER_EMPLOYEES, customerId, searchName.approver],
    async () =>
      await customerEmployeeService.find({
        customerId,
        approversOnly: "Ativo",
        name: searchName.approver,
      }),
    {
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { data: issuers, isLoading: isLoadingIssuers } = useQuery(
    [QueryKeys.CUSTOMER_EMPLOYEES, customerId, searchName.issuer],
    async () =>
      await customerEmployeeService.find({
        customerId,
        name: searchName.issuer,
      }),
    {
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { data: passengers, isLoading: isLoadingPassengers } = useQuery(
    [QueryKeys.CUSTOMER_EMPLOYEES, customerId, searchName.passenger],
    async () =>
      await customerEmployeeService.find({
        customerId,
        name: searchName.passenger,
      }),
    {
      onError: (error) => {
        log.e(LOG_TAG, error);
      },
    }
  );

  const { mutate: onExportShoppingReport } = useMutation(
    async (data: ShoppingReportData) => await reportService.findShopping(data),
    {
      onSuccess: () => {
        dialogService.popAll();
        snackbarService.showSnackbar("Relatório de compras será enviado por e-mail");
      },
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Baixando relatório de compras" />);
      },
    }
  );

  return (
    <CustomerReportsContainer
      onSubmit={onExportShoppingReport}
      onSearchName={setSearch}
      activeTab={tabValue}
      branches={branches?.data}
      isLoadingBranches={isLoadingBranches}
      costCenters={costCenters}
      isLoadingCostCenters={isLoadingCostCenters}
      approvers={approvers?.data}
      isLoadingApprovers={isLoadingApprovers}
      issuers={issuers?.data}
      isLoadingIssuers={isLoadingIssuers}
      passengers={passengers?.data}
      isLoadingPassengers={isLoadingPassengers}
    />
  );
}
