import { FC, Fragment, useCallback } from "react";
import { FlightSegment, SegmentType } from "~/application/types";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClock } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Track } from "~/presentation/shared/components/Track";
import { timeInterval } from "~/utils/date.utils";
import { getAirlineUrl } from "../../../utils";
import { FlightSegmentListItem } from "../FlightSegmentListItem";
import { FlightDetailsProps } from "./types";
import slugify from "slugify";

export const FlightDetails: FC<FlightDetailsProps> = ({ data }) => {
  const hasArrivalSegment = data.segments.some((ar) => ar.type === SegmentType.DEPARTURE);

  const renderFlightSegment = useCallback(
    (item: FlightSegment, index: number, array: FlightSegment[]) => {
      const nextSeg = array[1 + index];
      const lastDepartureIndex = array.findLastIndex((ar) => ar.type === SegmentType.DEPARTURE);

      const isCombined = lastDepartureIndex === index;

      return (
        <Fragment key={index}>
          <FlightSegmentListItem data={item} airline={item.airline} />

          {nextSeg &&
            (!isCombined ? (
              <>
                <Flex css={{ height: "$4", justifyContent: "center" }}>
                  <Track data-orientation="vertical" />
                </Flex>
                <Alert
                  variant="neutral"
                  size="sm"
                  css={{
                    borderStyle: "dashed",
                    justifyContent: "center",
                  }}
                >
                  <Icon as={SvgClock} />

                  <Text>
                    {"Espera de "}
                    <strong>
                      {timeInterval(
                        item.arrivalFlightInfo.dateTime,
                        nextSeg.departureFlightInfo.dateTime
                      )}
                    </strong>
                    {" em "}
                    {`${item.arrivalFlightInfo.airport}`}
                  </Text>
                </Alert>
                <Flex css={{ height: "$4", justifyContent: "center" }}>
                  <Track data-orientation="vertical" />
                </Flex>
              </>
            ) : (
              <Flex
                css={{
                  pt: "$6",
                  pb: "$2",
                }}
              >
                {" "}
                <Text fw="700">Volta</Text>
              </Flex>
            ))}
        </Fragment>
      );
    },
    []
  );

  return (
    <Flex direction="column" gap="6">
      <Box>
        <H4 css={{ fw: 500, mb: "$8" }}>Companhia Aérea</H4>

        <CompanyLogo src={getAirlineUrl(slugify(data.airline))} size="lg" />
      </Box>

      {/* TODO: Questionar sobre informações da bagagem */}
      {/* <Box>
        <H4 css={{ fw: 500, mb: "$8" }}>Informações sobre bagagem</H4>
      </Box> */}

      <H4 css={{ fw: 500 }}>Itinerário</H4>
      {hasArrivalSegment && <Text fw="700">Ida</Text>}

      <Flex direction="column" css={{ flexGrow: 1 }}>
        {data.segments.map(renderFlightSegment)}
      </Flex>
    </Flex>
  );
};

// TrackItem.displayName = "TrackItem";
FlightDetails.displayName = "FlightDetails";
