import { FC, Fragment, useCallback, useState } from "react";
import { Card, CardSectionHeader } from "~/components/Card";
import { CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgAirplane, SvgClose, SvgFilter } from "~/components/Icon/icons";
import { Skeleton } from "~/components/Skeleton";
import { Text } from "~/components/Text";
import { Filter, flightsDuration, millisecondsToHours } from "~/presentation/shared/utils";
import { FilterListItem } from "./FilterListItem";
import { FiltersSectionProps } from "./types";
import { Box } from "~/components/Box";
import { H5 } from "~/components/Typography";
import { SimpleSlider } from "~/components/Slider/SimpleSlider";
import { useFlightsPage } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/contexts/FlightsPageContext";
import { Select } from "~/components/Input";
import { Col } from "~/components/Grid";
import { FlightOrderType } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/ui/types";

const FLIGHT_ORDER_OPTIONS = [
  {
    value: FlightOrderType.DURATION,
    label: "Duração",
  },
  {
    value: FlightOrderType.PRICE,
    label: "Preço",
  },
];

export const FiltersSection: FC<FiltersSectionProps> = ({
  title,
  filters,
  flightsList,
  isLoading = false,
  FooterElement,
  HeaderElement,
  onFilterChange,
  onFilterDurationChange,
  setOrderType,
  orderType,
}) => {
  const { min, max } = flightsDuration(flightsList || []);
  const [sliderValue, setSliderValue] = useState([min, max]);
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const filterRenderer = useCallback(
    (item: Filter, index: number) => (
      <FilterListItem data={item} onFilterChange={onFilterChange} key={`${item.key}-${index}`} />
    ),
    [onFilterChange]
  );

  const { flightQuery, bookingStep } = useFlightsPage();

  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const maxHourDuration = millisecondsToHours(max);
  const minHourDuration = millisecondsToHours(min);

  const handleSliderChange = useCallback(
    (value: number[]) => {
      setSliderValue(value);
      onFilterDurationChange?.({ min: value[0], max: value[1] });

      if (!isTooltipVisible) setTooltipVisible(true);
    },
    [min, max, millisecondsToHours]
  );

  const currentSteep = bookingStep?.currentIndex;

  return (
    <Card>
      <CartHeader
        variant={{ "@mxlg": "lightest" }}
        onClick={() => setIsFilterOpen((prev) => !prev)}
        css={{
          "@mxlg": {
            color: "$primary-base",
            justifyContent: "center",
            textAlign: "center",
          },
        }}
      >
        <Icon as={SvgFilter} variant={{ "@mxlg": "primary" }} />
        <Text>{title}</Text>
      </CartHeader>
      <Box
        css={{
          width: "96%",
          height: "100vh",
          transition: "$slow",
          position: "fixed",
          display: isFilterOpen ? "inline" : "none",
          zIndex: "$overlay",
          top: "0",
          left: "0",
          backgroundColor: "White",
          "@lg": {
            display: "none",
          },
        }}
      >
        <Flex
          align="center"
          justify="between"
          css={{ mt: "$5", width: "86%", py: "$5", margin: "0 auto" }}
        >
          <H5 css={{ color: "#0064C5" }}>Filtrar resultados</H5>
          <Icon as={SvgClose} onClick={() => setIsFilterOpen(false)} />
        </Flex>
        <Box
          css={{
            p: "$5",
            borderRadius: "$md",
            width: "93%",
            boxShadow: "$sm",
            overflowY: "scroll",
            height: "90vh",
            margin: "0 auto",
          }}
        >
          {!isLoading ? (
            <Fragment>
              {HeaderElement}

              {filters.map(filterRenderer)}
              {FooterElement}
            </Fragment>
          ) : (
            <Flex direction="column" gap="4" css={{ p: "$4" }}>
              <Skeleton variant="text-4" />
              <Skeleton variant="text-4" />
              <Skeleton variant="text-4" />
              <Skeleton variant="text-4" />
            </Flex>
          )}
        </Box>
      </Box>
      <Box
        css={{
          display: "none",
          "@lg": {
            display: "block",
          },
        }}
      >
        {!isLoading ? (
          <Fragment>
            {HeaderElement}
            <Flex align="center" justify="center" direction={"column"}>
              <CardSectionHeader
                css={{
                  width: "100%",
                }}
              >
                <Text>Ordenar por: </Text>
              </CardSectionHeader>
              <Flex css={{ width: "100%", p: "$6" }}>
                <Select
                  options={FLIGHT_ORDER_OPTIONS}
                  placeholder="Ordenar voos"
                  value={FLIGHT_ORDER_OPTIONS.find((flight) => flight.value === orderType)}
                  onChange={(e) => setOrderType?.(e.value)}
                  style={{
                    width: "100%",
                  }}
                />
              </Flex>
            </Flex>
            {filters.map(filterRenderer)}
            {flightsList ? (
              <>
                <CardSectionHeader>
                  <Text>Filtro de duração</Text>
                </CardSectionHeader>
                <Flex justify="center" direction="column" css={{ p: "$5" }}>
                  <Flex align="center" css={{ mb: "$5" }}>
                    <Icon as={SvgAirplane} css={{ mr: "$2" }} />
                    <Text>
                      Voo para {flightQuery.data?.stretch?.at(currentSteep)?.destination?.city}
                    </Text>
                  </Flex>
                  <SimpleSlider
                    css={{ width: "100%", pb: "$4" }}
                    min={min}
                    max={max}
                    isTooltipVisible={isTooltipVisible}
                    ToolTipValue={millisecondsToHours(sliderValue[0])}
                    onValueCommit={() => setTooltipVisible(false)}
                    onValueChange={handleSliderChange}
                  />
                  <Flex justify="between">
                    <Text>{minHourDuration}</Text>
                    <Text>{maxHourDuration}</Text>
                  </Flex>
                </Flex>
              </>
            ) : null}
            {FooterElement}
          </Fragment>
        ) : (
          <Flex direction="column" gap="4" css={{ p: "$4" }}>
            <Skeleton variant="text-4" />
            <Skeleton variant="text-4" />
            <Skeleton variant="text-4" />
            <Skeleton variant="text-4" />
          </Flex>
        )}
      </Box>
    </Card>
  );
};

FiltersSection.displayName = "FiltersSection";
