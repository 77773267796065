import { useCallback } from "react";

import { BudgetListItem } from "../components/BudgetListItem";
import { Budget, Customer } from "~/application/types";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";

export type TabBudgetsProps = {
  budgets: Budget[] | undefined;
  currentPage: number;
  lastPage: number;
  search: string;
  onEditBudget: (data: Budget, customer: Customer) => void;
  onGoToPage: (page: number) => void;
};

export function TabBudgets({
  budgets,
  currentPage,
  lastPage,
  search,
  onEditBudget,
  onGoToPage,
}: TabBudgetsProps) {
  const listRenderer = useCallback(
    (item: Budget) => (
      <BudgetListItem
        key={item.uuid}
        data={item}
        onEditBudget={onEditBudget}
      />
    ),
    [onEditBudget]
  );

  return (
    <>
      <Container
        css={{
          pt: "$10",
          pb: "$20",
          "@mxlg": {
            p: "$5",
          },
        }}
      >
        <LazyList
          gap="6"
          items={budgets}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              {(search.length > 0)  ? (
                <Text>Nenhuma orçamento corresponde aos dados informados</Text>
              ) : (
                <Text>Você não possui orçamentos cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination
            activePage={currentPage}
            pagesCount={lastPage}
            setActivePage={onGoToPage}
          />
        </Container>
      </Footer>
    </>
  );
}
