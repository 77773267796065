import { FC, Fragment } from "react";
import {
  Order,
  OrderRoadItem as OrderItemRoad,
  OrderItems,
} from "~/application/types";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { ManualEmissionButtons } from "~/core/modules/Order/pages/ManageOrderPage/views/OrderItems/components/ManualEmissionButtons";
import { AgencyRoadValueDetails } from "~/presentation/shared/components/AgencyRoadValueDetails";
import { DefaultCompanyLogo } from "~/presentation/shared/components/DefaultCompanyLogo";
import { OrderItemStatusTag } from "~/presentation/shared/components/OrderItemStatusTag";
import { RoadDetails } from "~/presentation/shared/components/RoadDetails";
import { RoadTravelerListItem } from "~/presentation/shared/components/RoadTravelerListItem";
import { TrackDots } from "~/presentation/shared/components/Track";
import { getRoadConnectionSeats, getRoadSeats } from "~/presentation/shared/utils";
import { asCurrency } from "~/utils/mask.utils";

export type OrderRoadItemProps = {
  data: Order;
  isLoading: boolean;
  onIssueRoad: (road: OrderItemRoad) => void;
  onIssueOrder: () => void;
  onCancelItem: ({
    orderItemId,
    orderItemType,
  }: {
    orderItemId: string;
    orderItemType: OrderItems;
  }) => void;
};

export const OrderRoadItem: FC<OrderRoadItemProps> = ({
  data,
  onIssueRoad,
  onCancelItem,
}: OrderRoadItemProps) => {
  return (
    <Fragment>
      {data.items.road?.travels.map((item) => {
        const roadSeats = getRoadSeats(item);
        const roadSeatsConnection = getRoadConnectionSeats(item);
        const roadValue = item.value + item.fee + item.providerFees;

        return (
          <Flex direction="column" key={item.uuid}>
            <Flex direction="column">
              <Flex direction="column" gap="6">
                <H5 css={{ fw: "700" }}>Passagem</H5>
                <Flex direction="column">
                  <Card css={{ border: "none" }}>
                    <Flex gap="6" css={{ padding: "$2", border: "none" }}>
                      <Card css={{ p: "$5", flexGrow: 1, border: "none" }}>
                        <Flex align="center">
                          <Col>
                            {item.companyImage ? (
                              <CompanyLogo
                                size="3xl"
                                src={item.companyImage}
                                alt={item.companyName}
                              />
                            ) : (
                              <DefaultCompanyLogo companyName={item.companyName} />
                            )}
                          </Col>

                          <Flex justify="end" align="center" gap="4" css={{ textAlign: "end" }}>
                            {item.connection && (
                              <Flex direction="column" gap="2">
                                <TrackDots numberOfDots={1} />
                                <Text
                                  variant="primary"
                                  size="2"
                                  onClick={() =>
                                    dialogService.showDialog(
                                      <RoadDetails
                                        data={{
                                          ...item,
                                          from: item.departure,
                                          to: item.arrival,
                                          company: item.companyName,
                                        }}
                                        seats={roadSeats}
                                        seatsConnection={roadSeatsConnection}
                                      />
                                    )
                                  }
                                  css={{
                                    lineHeight: "1.6",
                                    textDecoration: "underline",
                                    fw: 600,
                                    cursor: "pointer",
                                  }}
                                >
                                  2 Trechos
                                </Text>
                              </Flex>
                            )}

                            <Tag
                              variant="info-light"
                              css={{
                                "@mxlg": {
                                  display: "none",
                                },
                              }}
                            >
                              <Text>{item.seatClass}</Text>
                            </Tag>

                            <Box css={{ width: 200, height: "auto" }}>
                              <Text as="p" size="2" css={{ mb: "$3", color: "$neutrals-dark" }}>
                                Valor da passagem
                              </Text>

                              <Text size={{ "@initial": "7", "@mxlg": "5" }} css={{ fw: "600" }}>
                                {asCurrency(roadValue)}
                              </Text>

                              <Text
                                as="p"
                                size="2"
                                css={{
                                  mt: "$3",
                                  color: "$neutrals-dark",
                                  fw: "600",
                                }}
                              >
                                {item.provider}
                              </Text>
                            </Box>
                          </Flex>
                        </Flex>
                        <Col
                          as={Flex}
                          css={{
                            textAlign: "start",
                            flexDirection: "column",
                            gap: "$2",
                          }}
                        >
                          <Text as="p" size="3" css={{ color: "$neutrals-dark" }}>
                            Status
                          </Text>
                          <Text size="4" css={{ fw: "600" }}>
                            <OrderItemStatusTag data={item.status} />
                          </Text>
                        </Col>

                        <Col
                          as={Flex}
                          css={{
                            textAlign: "start",
                            flexDirection: "column",
                            gap: "$2",
                            mt: "$5",
                          }}
                        >
                          <Text as="p" size="3" css={{ color: "$neutrals-dark" }}>
                            Código da reserva
                          </Text>
                          <Text size="4" css={{ fw: "600" }}>
                            {item.tracker}
                          </Text>
                        </Col>

                        <Row
                          justify="center"
                          css={{
                            mt: 40,
                            "@mxlg": {
                              display: "block",
                              width: "100%",
                            },
                          }}
                        >
                          <Col css={{ textAlign: "start" }}>
                            <Flex direction="column" gap="3" align="start">
                              <Text as="p" size="3" css={{ mb: "$2", color: "$neutrals-dark" }}>
                                Saída
                              </Text>

                              <Text size={{ "@initial": "6", "@mxlg": "5" }} css={{ fw: "600" }}>
                                {item?.departureCity}
                              </Text>
                              <Text size={{ "@initial": "2", "@mxlg": "2" }} css={{ fw: "600" }}>
                                {item.departure}
                              </Text>

                              <Text
                                size={{
                                  "@mxlg": "2",
                                }}
                                css={{
                                  mb: "$2",
                                  color: "$neutrals-dark",
                                }}
                              >
                                {DateUtils.format(item.departureDate, DateFormats.SMALL_DATE_TIME)}
                              </Text>
                            </Flex>
                          </Col>

                          <Col
                            css={{
                              textAlign: "start",
                              "@mxlg": {
                                display: "block",
                                width: "100%",
                                mt: "$5",
                              },
                            }}
                          >
                            <Flex direction="column" gap="3" align="start">
                              <Text as="p" size="3" css={{ mb: "$2", color: "$neutrals-dark" }}>
                                Destino
                              </Text>
                              <Text size={{ "@initial": "6", "@mxlg": "5" }} css={{ fw: "600" }}>
                                {item?.arrivalCity}
                              </Text>
                              <Text size={{ "@initial": "2", "@mxlg": "2" }} css={{ fw: "600" }}>
                                {item.arrival}
                              </Text>
                              <Text
                                size={{ "@mxlg": "2" }}
                                css={{
                                  mb: "$2",
                                  color: "$neutrals-dark",
                                }}
                              >
                                {DateUtils.format(item.arrivalDate, DateFormats.SMALL_DATE_TIME)}
                              </Text>
                            </Flex>
                          </Col>
                        </Row>

                        <AgencyRoadValueDetails item={item} />
                      </Card>
                    </Flex>
                  </Card>
                </Flex>

                <ManualEmissionButtons
                  data={data}
                  item={item}
                  onCancelItem={onCancelItem}
                  onIssueItem={onIssueRoad}
                  itemType={OrderItems.ROAD}
                />

                <H5 css={{ fw: "800" }}>Passageiros</H5>

                <CardBody css={{ padding: 0, overflowX: "hidden", mb: "$3" }}>
                  <LazyList
                    items={item.travelers}
                    render={(traveler) => (
                      <RoadTravelerListItem
                        item={item}
                        orderStatus={data.status}
                        traveler={traveler}
                        key={traveler.uuid}
                      />
                    )}
                    skeletonHeight={88}
                    skeletonQuantity={2}
                  />
                </CardBody>
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </Fragment>
  );
};

OrderRoadItem.displayName = "OrderRoadItem";
