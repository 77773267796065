import * as pako from "pako";

import { OrderHotelGuest } from "~/application/types";

export function compressString(input: string): string {
  const binaryString = new TextEncoder().encode(input);
  const compressed = pako.gzip(binaryString);
  return btoa(String.fromCharCode(...compressed));
}

export function decompressString(compressed: string): string {
  const binaryString = atob(compressed);
  const charData = binaryString.split("").map((char) => char.charCodeAt(0));
  const compressedData = new Uint8Array(charData);
  const decompressed = pako.ungzip(compressedData);
  return new TextDecoder().decode(decompressed);
}

export const getGuestAmounts = (guests: OrderHotelGuest[]) => {
  return guests.reduce(
    (amounts, guest) => {
      if (guest.passengerType === "ADT") {
        amounts.adults += 1;
      }
      if (guest.passengerType === "CHD") {
        amounts.children += 1;
      }
      return amounts;
    },
    { adults: 0, children: 0 }
  );
};