import { useQuery } from "@tanstack/react-query";

import { BudgetOption, SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { Actions, BudgetBalance, Order, OrderStatus, Profile, UserContexts } from "~/application/types";
import { useVerifyParameter } from "~/presentation/shared/hooks/useVerifyParameter";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { budgetService } from "~/application/usecases/Budget";
import { QueryKeys } from "~/application/constants";

export interface UseBudgetBalanceParams {
  order?: Order;
  profile?: Profile;
  contexts: UserContexts;
}

export interface UseBudgetBalanceResult {
  budgetBalance?: BudgetBalance;
  canShowBudgetBalance: boolean;
  budgetName: (name: string | null) => string;
  isLoading: boolean;
}

export const useBudgetBalance = ({ order, profile, contexts }: UseBudgetBalanceParams) => {
  const orderStatus = order?.status as OrderStatus;

  const hasActionToShowBudgetBalance = useVerifyActions({
    actions: [Actions.ViewBudgetBalance],
    contexts,
    profile,
  });

  const { data: budgetBalance, isLoading } = useQuery({
    queryKey: [QueryKeys.ORDER_BUDGET_BALANCE, order?.uuid],
    queryFn: () => budgetService.getBalance(order!.uuid),
    enabled: !!order && hasActionToShowBudgetBalance,
  });

  const canShowBudgetBalance = !!budgetBalance 
    && budgetBalance.value !== null 
    && [OrderStatus.ON_APPROVAL, OrderStatus.APPROVED, OrderStatus.REJECTED].includes(orderStatus);

  const isPhaseParameter = useVerifyParameter({
    customer: contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.PHASE,
  });

  const isCostCenterParameter = useVerifyParameter({
    customer: contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.COST_CENTER,
  });

  const isProjectParameter = useVerifyParameter({
    customer: contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.PROJECT,
  });

  const budgetName = () => {
    const name = budgetBalance?.name;
    const projectName = budgetBalance?.projectName;

    if (isCostCenterParameter) return `para o centro de custo ${name}`;
    if (isProjectParameter) return `para o projeto ${name}`;
    if (isPhaseParameter) return `para a fase ${name} do projeto ${projectName}`;

    return '';
  };

  return {
    budgetBalance,
    canShowBudgetBalance,
    budgetName,
    isLoading,
  };
};