import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { DeepPartial, Markup, OrderItems } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { NumberInput, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { H4, H5 } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import * as OrderUtils from "~/core/shared/utils/order.utils";

export interface MarkupDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<Markup>;
  onCloseClick?: () => void;
  onSubmit: (data: Markup) => void;
}

export function MarkupDialog({ isNew, defaultData, onCloseClick, onSubmit }: MarkupDialogProps) {
  const { control, formState, watch, handleSubmit } = useForm<Markup>({
    defaultValues: defaultData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { items } = watch();

  if (formState.isSubmitting) {
    return (
      <LoadingModal
        isOpen={formState.isSubmitting}
        message={isNew ? "Criando markup" : "Editando markup"}
      />
    );
  }

  return (
    <Container size="8" fixed>
      <Form
        onSubmit={handleSubmit(onSubmit, (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        })}
      >
        <FormDialog
          title={isNew ? "Novo markup" : "Editar markup"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "60vh" }}>
            <Box css={{ mb: "$6" }}>
              <H4>Informações básicas</H4>
            </Box>

            <FormControl name="name" control={control} required>
              <FieldLabel>Nome do Markup</FieldLabel>
              <TextInput placeholder="Digite um nome para o markup" />
            </FormControl>

            {items.map((item, index) => (
              <Fragment key={item.serviceType}>
                <Box css={{ mt: "$10", mb: "$6" }}>
                  <H5>{OrderUtils.getServiceLabel(item.serviceType)}</H5>
                </Box>

                <Row gap="6">
                  <Col sz="6">
                    <FormControl name={`items.${index}.value`} control={control} required>
                      <FieldLabel>Valor</FieldLabel>
                      <NumberInput
                        suffix="%"
                        placeholder="Digite o valor para este serviço"
                        fixedDecimalScale={false}
                        min={0}
                        max={100}
                      />
                    </FormControl>
                  </Col>
                  <Col sz="6">
                    <FormControl name={`items.${index}.offlineValue`} control={control} required>
                      <FieldLabel>Valor offline</FieldLabel>
                      <NumberInput
                        suffix="%"
                        placeholder="Digite o valor para este serviço offline"
                        fixedDecimalScale={false}
                        min={0}
                        max={100}
                      />
                    </FormControl>
                  </Col>
                  {item.serviceType === OrderItems.AIRWAY && (
                    <Col sz="6">
                      <FormControl
                        name={`items.${index}.internationalValue`}
                        control={control}
                        required
                      >
                        <FieldLabel>Valor internacional</FieldLabel>
                        <NumberInput
                          suffix="%"
                          placeholder="Digite o valor para este serviço internacional"
                          fixedDecimalScale={false}
                          min={0}
                          max={100}
                        />
                      </FormControl>
                    </Col>
                  )}
                </Row>
              </Fragment>
            ))}
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
