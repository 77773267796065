import {
  AccountabilityExpense,
  AdvanceOrderStatus,
  Approver,
  CostCenter,
  CustomerEmployeesGroup,
  CustomerOrder,
  Order,
  Phase,
  Project,
  ReasonTrip,
} from "~/application/types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { IOrderApprovalParams } from "~/services/resources/OrderApproval/IOrderApprovalService";
import { userIsIssuer } from "../../../utils";
import { BrokenPolicyJustification } from "../views/OrderItem/hooks/BrokenPolicyJustificationContext";

export interface CreateAssessmentData {
  costCenter: CostCenter | null;
  agencyObs: string;
  branch: any;
  approver?: Approver;
  reasonTrip?: ReasonTrip;
  project: Project;
  group?: CustomerEmployeesGroup;
  observation: string;
  phase?: Phase | null;
  canApproveInstantly?: boolean;
  brokenPolicyItems?: BrokenPolicyJustification[];
  rejectedItems?: IOrderApprovalParams["rejectedItems"];
}

export const advanceOrderStatus = {
  [AdvanceOrderStatus.OPEN]: "Em Aberto",
  [AdvanceOrderStatus.APPROVED]: "Aprovado",
  [AdvanceOrderStatus.APPROVING]: "Em Aprovação",
  [AdvanceOrderStatus.DISAPPROVED]: "Reprovado",
};

export enum OrderApprovalItem {
  AIRWAY_ORDERS = "airwayOrders",
  ROOM_HOTEL_ORDERS = "roomHotelOrders",
  VEHICLE_ORDERS = "vehicleOrders",
  ROAD_ORDERS = "roadOrders",
}

export function userIsOrderSelfApprover(order?: Order | CustomerOrder): boolean {
  if (!order) return false;

  const { user, profile, contexts } = useUser();

  if (!profile?.isApprover) {
    return false;
  }

  const isIssuer = userIsIssuer(user, order, contexts);

  return !!order.approvalModel?.approvers?.some(({ uuid, isSelfApprover }) => {
    return isIssuer && user.profiles.customer.uuid === uuid && isSelfApprover;
  });
}

export function userIsOrderApprover(order?: Order | CustomerOrder): boolean {
  if (!order) return false;

  const { user, profile, contexts } = useUser();

  if (!profile?.isApprover) {
    return false;
  }

  const isIssuer = userIsIssuer(user, order, contexts);

  return !!order.approvalModel?.approvers?.some(({ uuid, isSelfApprover }) => {
    if (user.profiles.customer.uuid !== uuid) {
      return false;
    }

    if (isIssuer && !isSelfApprover) {
      return false;
    }

    return true;
  });
}

export function getExpenseIdentifier(expense: AccountabilityExpense) {
  return expense.uuid
    .slice(0, 6)
    .replace(/a|A/g, "0")
    .replace(/b|B/g, "1")
    .replace(/c|C/g, "2")
    .replace(/d|D/g, "3")
    .replace(/e|E/g, "4")
    .replace(/f|F/g, "5");
}
