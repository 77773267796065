import React, { useCallback, useMemo, useState } from "react";
import { Box } from "~/components/Box";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { FormDialog } from "~/components/FormDialog";
import { SearchBar } from "~/components/Input";
import { DataColItem, DataListItem } from "~/components/List";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { Approvers, Issuers } from "~/infrastructure/api/dtos";

export interface CustomerInfoDialogProps {
  approvers?: Approvers[];
  issuers?: Issuers[];
}

export function CustomerInfoDialog({ approvers = [], issuers = [] }: CustomerInfoDialogProps) {
  const [searchCustomer, setSearchCustomer] = useState("");

  const filteredIssuers = useMemo(
    () =>
      issuers.filter((issuer) => {
        return (
          (issuer.name && issuer.name.toLowerCase().includes(searchCustomer)) ||
          (issuer.email && issuer.email.toLowerCase().includes(searchCustomer)) ||
          (issuer.phone && issuer.phone.toLowerCase().includes(searchCustomer))
        );
      }),
    [issuers, searchCustomer]
  );

  const filteredApprovers = useMemo(
    () =>
      approvers.filter((approver) => {
        return (
          (approver.name && approver.name.toLowerCase().includes(searchCustomer)) ||
          (approver.email && approver.email.toLowerCase().includes(searchCustomer)) ||
          (approver.phone && approver.phone.toLowerCase().includes(searchCustomer))
        );
      }),
    [approvers, searchCustomer]
  );

  return (
    <Container size="8" fixed>
      <FormDialog
        title="Dados sobre o cliente"
        onClickDismissButton={() => dialogService.popDialog()}
      >
        <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
          <Flex css={{ width: "100%", mb: "$6" }}>
            <SearchBar
              placeholder="Buscar cliente"
              value={searchCustomer}
              onSearchChange={(search) => setSearchCustomer(search)}
              style={{ width: "100%" }}
            />
          </Flex>
          <Box>
            <Flex>
              <H5>Solicitantes</H5>
            </Flex>
            {filteredIssuers.length > 0 ? (
              <Box css={{ mt: "$10" }}>
                {filteredIssuers.map((issuer, index) => (
                  <DataListItem
                    key={`issuer-${index}`}
                    css={{ mb: "$5", width: "100%" }}
                    isActive={true}
                  >
                    <DataColItem headerTitle="Nome" data={issuer.name} />
                    <DataColItem
                      headerTitle="Email"
                      data={issuer.email}
                      css={{ wordBreak: "break-all" }}
                    />
                    <DataColItem headerTitle="Telefone" data={issuer.phone} />
                  </DataListItem>
                ))}
              </Box>
            ) : (
              <Box css={{ py: "$10" }}>
                <Text>Não há solicitantes correspondentes</Text>
              </Box>
            )}
            <Flex>
              <H5>Aprovadores</H5>
            </Flex>
            {filteredApprovers.length > 0 ? (
              <Box css={{ mt: "$10" }}>
                {filteredApprovers.map((approver, index) => (
                  <DataListItem key={`approver-${index}`} css={{ mb: "$5" }} isActive={true}>
                    <DataColItem headerTitle="Nome" data={approver.name} />
                    <DataColItem
                      headerTitle="Email"
                      data={approver.email}
                      css={{ wordBreak: "break-all" }}
                    />
                    <DataColItem headerTitle="Telefone" data={approver.phone} />
                  </DataListItem>
                ))}
              </Box>
            ) : (
              <Box css={{ py: "$10" }}>
                <Text>Não há aprovadores correspondentes</Text>
              </Box>
            )}
          </Box>
        </DialogBody>
      </FormDialog>
    </Container>
  );
}

CustomerInfoDialog.displayName = "CustomerInfoDialog";
