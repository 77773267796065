import { Card, CardBody } from "~/components/Card";
import { Flex, FlexProps } from "~/components/Flex";
import { Text } from "~/components/Text";
import { Cart } from "~/components/Cart";
import { Divider } from "~/components/Divider";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col } from "~/components/Grid";
import { TextAreaInput } from "~/components/Input";
import { useForm } from "react-hook-form";
import { forwardRef, useContext, useEffect } from "react";
import {
  BrokenPolicyJustificationContext,
  useBrokenPolicyJustification,
} from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";
import { OrderAirwaySegment } from "~/application/types";
import { Label } from "~/components/Typography";
import { pulse } from "~/components/Pulse/Pulse";
import { Element } from "react-scroll";

export interface ItemBrokenPolicyJusticationProps extends FlexProps {
  itemId: string;
  itemIdCombined?: string;
  brokenPolicyJustification?: string;
  isCombined?: boolean;
}

export const ItemBrokenPolicyJustication = forwardRef<
  React.ElementRef<typeof Flex>,
  ItemBrokenPolicyJusticationProps
>(({ itemId, brokenPolicyJustification, itemIdCombined, ...props }, ref) => {
  const { brokenPolicyItems, setBrokenPolicyItems } = useBrokenPolicyJustification();

  const currentBrokenPolicy = brokenPolicyItems?.find(
    (brokenPolicyItem) => brokenPolicyItem.uuid === itemId
  );

  const { control, watch } = useForm<{ brokenPolicyJustification: string }>({
    defaultValues: { brokenPolicyJustification: currentBrokenPolicy?.justification },
  });

  const formData = watch();

  useEffect(() => {
    if (!formData.brokenPolicyJustification) {
      return setBrokenPolicyItems([]);
    }

    if (brokenPolicyItems.some((i) => i.uuid === itemId)) {
      if (itemIdCombined) {
        setBrokenPolicyItems((old) =>
          old.map((i) => {
            return i.uuid === itemIdCombined
              ? { ...i, justification: formData.brokenPolicyJustification }
              : i;
          })
        );
      }
      return setBrokenPolicyItems((old) =>
        old.map((i) => {
          return i.uuid === itemId
            ? { ...i, justification: formData.brokenPolicyJustification }
            : i;
        })
      );
    }
    if (itemIdCombined) {
      setBrokenPolicyItems((old) => [
        ...old,
        { uuid: itemIdCombined, justification: formData.brokenPolicyJustification },
      ]);
    }
    setBrokenPolicyItems((old) => [
      ...old,
      { uuid: itemId, justification: formData.brokenPolicyJustification },
    ]);
  }, [formData.brokenPolicyJustification]);

  return (
    <Flex direction="column" {...props} ref={ref} id={`scroll-${itemId}`}>
      {brokenPolicyJustification ? (
        <Card
          css={{
            border: "1px solid $warning-dark",
          }}
        >
          <CardBody>
            <Flex gap="3" direction="column">
              <Label>Justificativa da política violada</Label>
              <Text
                as="p"
                variant="warning-dark"
                fw="700"
                css={{ lineHeight: 1.5, wordBreak: "break-all" }}
              >
                {brokenPolicyJustification}
              </Text>
            </Flex>
          </CardBody>
        </Card>
      ) : (
        <>
          <Divider />
          <Cart
            tabIndex={-1}
            css={{
              width: "100%",
              boxShadow: "$sm",
              animation: !formData.brokenPolicyJustification ? `${pulse} 2s infinite` : "",
            }}
          >
            <CardBody>
              <Col>
                <FormControl name="brokenPolicyJustification" control={control} required>
                  <FieldLabel>Justificativa da política violada</FieldLabel>
                  <TextAreaInput
                    placeholder="Digite o motivo para violação da política do item"
                    id={`scroll-${itemId}-input`}
                  />
                </FormControl>
              </Col>
            </CardBody>
          </Cart>
        </>
      )}
    </Flex>
  );
});
