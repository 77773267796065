import type { Approver } from "./Approver.type";
import type { Branch } from "./Branch.type";
import type { CostCenter } from "./CostCenter.type";
import { CustomerEmployeesGroup } from "./CustomerEmployeesGroup.type";
import { Phase } from "./Phase.type";
import { Project } from "./Project.type";

export type ApprovalType = "sequential" | "single";

export type Level = {
  approvers: Approver[];
};

export enum ApprovalTypeEnum {
  COST_CENTER = "cost_center",
  PHASE = "phase",
  PROJECT = "project",
  GROUP = "customer_employee_group",
}

export type ApprovalModel = {
  uuid: string;
  name: string;
  approvalType?: ApprovalTypeEnum;
  customerEmployeeId?: string;
  agencyEmployeeId?: string;
  branch?: Branch;
  costCenters: CostCenter[];
  groupApprovers: CustomerEmployeesGroup[];
  projects: Project[];
  groups: CustomerEmployeesGroup[];
  phases: Phase[];
  isActive: boolean;
  isAllBranches: boolean;
  applyExpense: boolean;
  approvers: Approver[];
  outPolicyApprovalModel?: ApprovalModel;
  approverExpense?: {
    uuid?: string;
    name?: string;
  };
};

export type ShortApprovalModel = Pick<
  ApprovalModel,
  "uuid" | "name" | "isActive" | "approvalType" | "approvers"
>;
