import { Fragment } from "react";
import { UseFormReturn } from "react-hook-form";

import { City, Customer, State } from "~/application/types";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Card } from "~/components/Card";
import { Container } from "~/components/Container";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { Tab, TabBar, TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { CUSTOMER_MENU_TABS, CustomerTab } from "../../utils";
import {
  TabCustomerSettingParameterProps,
  TabCustomerSettingParameters,
} from "./views/CustomerSettingParameter/TabCustomerSettingParameters";
import { TabApprovalModelProps, TabApprovalModels } from "./views/ApprovalModel/TabApprovalModels";
import { TabBranches, TabBranchesProps } from "./views/Branch/TabBranches";
import { TabCostCenters, TabCostCentersProps } from "./views/CostCenter/TabCostCenters";
import { TabCustomerEmployees } from "./views/CustomerEmployee/TabCustomerEmployees";
import { TabCustomerEmployeesProps } from "./views/CustomerEmployee/types";
import {
  TabExpensePolicies,
  TabExpensePoliciesProps,
} from "./views/ExpensePolicy/TabExpensePolicies";
import {
  TabCustomerEmployeesGroups,
  TabCustomerEmployeesGroupsProps,
} from "./views/CustomerEmployeesGroups/TabCustomerEmployeesGroups";
import { TabExpenseTypes, TabExpenseTypesProps } from "./views/ExpenseType/TabExpenseTypes";
import { TabFees, TabFeesProps } from "./views/Fee/TabFees";
import { TabJustifications, TabJustificationsProps } from "./views/Justification/TabJustifications";
import { TabMarkdowns, TabMarkdownsProps } from "./views/Markdown/TabMarkdowns";
import { TabMarkups, TabMarkupsProps } from "./views/Markup/TabMarkups";
import { TabReasonTrips, TabReasonTripsProps } from "./views/ReasonTrip/TabReasonTrips";
import { TabCustomerInformation } from "./views/TabCustomerInformation";
import { TabProjects, TabProjectsProps } from "./views/Projects/TabProjects";
import { TabCredential, TabCredentialProps } from "./views/Credential/TabCredential";
import { TabPaymentMethods, TabPaymentMethodsProps } from "./views/Payments/TabPayments";
import { Select } from "~/components/Input";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Box } from "~/components/Box";
import { CustomerSchema } from "./CustomerPage";
import { TabCustomerCrmProps } from "./views/Crm/types";
import { TabCustomerCrm } from "./views/Crm/TabCustomerCrm";
import { TabProfiles } from "./views/Profiles/TabProfiles";
import { TabProfilesProps } from "./views/Profiles/type";
import { TabPhases, TabPhasesProps } from "./views/Phases/TabPhases";
import { TabBudgets, TabBudgetsProps } from "./views/Budgets/TabBudgets";
import {
  TabPurchasingPolicies,
  TabPurchasingPoliciesProps,
} from "./views/PurchasingPolicies/TabPurchasingPolicies";

export interface CustomerContainerProps {
  isLoading: boolean;
  isUpdatingCustomer: boolean;
  activeTab: CustomerTab;
  customer: Customer | undefined;
  justificationsState: TabJustificationsProps;
  reasonTripsState: TabReasonTripsProps;
  costCentersState: TabCostCentersProps;
  credentialState: TabCredentialProps;
  feesState: TabFeesProps;
  customerId: string;
  expenseTypesState: TabExpenseTypesProps;
  customerEmployeesState: TabCustomerEmployeesProps;
  customerEmployeesGroupsState: TabCustomerEmployeesGroupsProps;
  expensePoliciesState: TabExpensePoliciesProps;
  markupsState: TabMarkupsProps;
  markdownsState: TabMarkdownsProps;
  branchesState: TabBranchesProps;
  approvalModelsState: TabApprovalModelProps;
  customerSettingParametersState: TabCustomerSettingParameterProps;
  customerCrmState: TabCustomerCrmProps;
  projectsState: TabProjectsProps;
  phasesState: TabPhasesProps;
  paymentMethodsState: TabPaymentMethodsProps;
  profilesState: TabProfilesProps;
  budgetsState: TabBudgetsProps;
  purchasingPoliciesState: TabPurchasingPoliciesProps;
  customerHookForm: (defaultData?: Customer | undefined) => UseFormReturn<CustomerSchema, any>;
  onUpdateCustomer: (customer: Customer) => void;
  fetchCitiesByState: (state: State) => Promise<City[]>;
}

export function CustomerContainer({
  isLoading,
  isUpdatingCustomer,
  activeTab,
  customer,
  reasonTripsState,
  justificationsState,
  costCentersState,
  feesState,
  customerId,
  credentialState,
  expenseTypesState,
  customerEmployeesState,
  customerEmployeesGroupsState,
  expensePoliciesState,
  markupsState,
  customerCrmState,
  markdownsState,
  branchesState,
  approvalModelsState,
  customerSettingParametersState,
  projectsState,
  phasesState,
  paymentMethodsState,
  profilesState,
  budgetsState,
  purchasingPoliciesState,
  customerHookForm,
  onUpdateCustomer,
  fetchCitiesByState,
}: CustomerContainerProps) {
  const isMobile = useMobile();

  const menuTabDefaultValue = CUSTOMER_MENU_TABS.find((tab) => tab.id === CustomerTab.INFORMATION);
  const activeTabValue = CUSTOMER_MENU_TABS.find((tab) => tab.id === activeTab);

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <Tooltip content={<TooltipLabel>Voltar para empresas</TooltipLabel>}>
            <Link to="/configuracoes/empresas">
              <IconButton size="md">
                <Icon as={SvgChevronLeft} css={{ "@mxlg": { fill: "#fff", ta: "center" } }} />
              </IconButton>
            </Link>
          </Tooltip>

          <AppBarHeading
            title={customer?.companyName || <Skeleton variant="text-6" style={{ maxWidth: 360 }} />}
          />
        </AppBarContainer>
      </AppBar>

      <Container css={{ py: "$10", "@mxlg": { px: "$5" } }}>
        <Tabs value={activeTab} vertical>
          <Row gap="6">
            <Col sz="3" css={{ "@mxlg": { display: "none" } }}>
              <Card>
                <TabBar>
                  {CUSTOMER_MENU_TABS.map(({ title, id }) => {
                    return (
                      <Link to={`#${id}`} title={title} key={id}>
                        <Tab id={id} value={id}>
                          <Text>{title}</Text>
                        </Tab>
                      </Link>
                    );
                  })}
                </TabBar>
              </Card>
            </Col>

            {isMobile ? (
              <Box>
                <Select
                  options={CUSTOMER_MENU_TABS}
                  defaultValue={menuTabDefaultValue}
                  value={activeTabValue}
                  getOptionValue={(provider) => provider.id}
                  getOptionLabel={(provider) => provider.title}
                  onChange={(e) => (location.hash = e.id)}
                />
              </Box>
            ) : null}

            <Col sz={{ "@initial": "9", "@mxlg": "12" }}>
              <TabContent value={CustomerTab.INFORMATION}>
                <TabCustomerInformation
                  data={customer}
                  customerHookForm={customerHookForm}
                  isLoading={isLoading}
                  isUpdatingCustomer={isUpdatingCustomer}
                  onUpdateCustomer={onUpdateCustomer}
                  fetchCitiesByState={fetchCitiesByState}
                />
              </TabContent>

              <TabContent value={CustomerTab.PAYMENTS}>
                <TabPaymentMethods {...paymentMethodsState} />
              </TabContent>

              <TabContent value={CustomerTab.CREDENTIALS}>
                <TabCredential {...credentialState} />
              </TabContent>

              <TabContent value={CustomerTab.PROFILES}>
                <TabProfiles {...profilesState} />
              </TabContent>

              <TabContent value={CustomerTab.BRANCHES}>
                <TabBranches {...branchesState} />
              </TabContent>

              <TabContent value={CustomerTab.COSTS_CENTERS}>
                <TabCostCenters {...costCentersState} />
              </TabContent>

              <TabContent value={CustomerTab.EMPLOYEES}>
                <TabCustomerEmployees {...customerEmployeesState} />
              </TabContent>

              <TabContent value={CustomerTab.CUSTOMER_EMPLOYEES_GROUPS}>
                <TabCustomerEmployeesGroups {...customerEmployeesGroupsState} />
              </TabContent>

              <TabContent value={CustomerTab.POLICIES_EXPENSES}>
                <TabExpensePolicies {...expensePoliciesState} />
              </TabContent>

              <TabContent value={CustomerTab.PURCHASING_POLICIES}>
                <TabPurchasingPolicies {...purchasingPoliciesState} customerId={customerId} />
              </TabContent>

              <TabContent value={CustomerTab.REASON_TRIPS}>
                <TabReasonTrips {...reasonTripsState} />
              </TabContent>

              <TabContent value={CustomerTab.JUSTIFICATIONS}>
                <TabJustifications {...justificationsState} />
              </TabContent>

              <TabContent value={CustomerTab.EXPENSE_TYPES}>
                <TabExpenseTypes {...expenseTypesState} />
              </TabContent>

              <TabContent value={CustomerTab.FEES}>
                <TabFees {...feesState} />
              </TabContent>

              <TabContent value={CustomerTab.MARKUPS}>
                <TabMarkups {...markupsState} />
              </TabContent>

              <TabContent value={CustomerTab.MARKDOWNS}>
                <TabMarkdowns {...markdownsState} />
              </TabContent>

              <TabContent value={CustomerTab.APPROVAL_MODELS}>
                <TabApprovalModels {...approvalModelsState} />
              </TabContent>

              <TabContent value={CustomerTab.PROJECTS}>
                <TabProjects {...projectsState} />
              </TabContent>

              <TabContent value={CustomerTab.PHASES}>
                <TabPhases {...phasesState} />
              </TabContent>

              <TabContent value={CustomerTab.Parameters}>
                <TabCustomerSettingParameters {...customerSettingParametersState} />
              </TabContent>

              <TabContent value={CustomerTab.CRM}>
                <TabCustomerCrm {...customerCrmState} />
              </TabContent>

              <TabContent value={CustomerTab.BUDGETS}>
                <TabBudgets {...budgetsState} customer={customer} />
              </TabContent>

              <TabContent value={CustomerTab.VIEW_BUDGETS}>
                <TabBudgets {...budgetsState} customer={customer} />
              </TabContent>
            </Col>
          </Row>
        </Tabs>
      </Container>
    </Fragment>
  );
}
