import { CompanyLogo } from "~/components/CompanyLogo";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FormDialog } from "~/components/FormDialog";
import { H4 } from "~/components/Typography";
import { DefaultCompanyLogo } from "~/presentation/shared/components/DefaultCompanyLogo";
import { RoadSegmentListItem } from "../../../Booking/BookingRoad/pages/RoadsPage/ui/sections/ConfirmationSection/components/RoadSegmentListItem";
import { RoadDetailsProps } from "./types";

export function RoadDetails({ data, seats, seatsConnection }: RoadDetailsProps) {
  return (
    <Container
      size="10"
      fixed
      css={{
        overflow: "auto",
      }}
    >
      <Form
        css={{
          "@mxlg": {
            width: "88%",
            margin: "0 auto",
          },
        }}
      >
        <FormDialog title="Detalhes da passagem">
          <DialogBody css={{ p: "$6", overflow: "auto", maxHeight: "70vh" }}>
            <Flex direction="column" align="start" gap="6">
              <H4 size={{ "@mxmd": "5" }}>{data.company}</H4>

              {data.companyImage ? (
                <CompanyLogo src={data.companyImage} alt={data.company} size="lg" />
              ) : (
                <DefaultCompanyLogo companyName={data.company} />
              )}
            </Flex>

            <H4 size={{ "@mxmd": "5" }} fw="600" css={{ my: "$6" }}>
              Itinerário
            </H4>

            <RoadSegmentListItem data={data} seats={seats} seatsConnection={seatsConnection} />
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
