import { OrderItemStatus } from "~/application/types";

export const EMPTY_OPTION = {
  uuid: "",
  description: "",
  address: "",
  phone: "",
  email: "",
  roomType: { name: "" },
  regimen: { name: "" },
  pixKey: "",
  virtualCardNumber: "",
  replicateRoom: { uuid: "", name: "" },
  providerValue: 0,
  hotelMarkup: 0,
  hotelFee: 0,
  customerValue: 0,
  obsAgency: "",
  obsIssuer: "",
  amenities: [],
  whatsapp: "",
  paymentMethods: { uuid: "", name: ""},
  status: OrderItemStatus.QUOTING,
  note: "",
  checkOutHour: "12",
  checkInHour: "14",
  otherTaxes: 0,
};
