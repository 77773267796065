import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BiztripLogo } from "~/components/BiztripLogo";
import { Box } from "~/components/Box";
import { Container } from "~/components/Container";
import { NavMenu } from "../NavMenu";
import { ProfileMenu } from "../ProfileMenu";
import { Styled } from "./styled";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";
import { SvgMenu } from "~/components/Icon/icons";

import Close from "~/components/Icon/icons/Close";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Actions, AgencyEmployeeType, OrderItems, UserContext } from "~/application/types";
import { Col } from "~/components/Grid/Col";
import { Text } from "~/components/Text";
import { MenuItemList, MenuItems } from "../NavMenuMobile/NavMenuMobile";
import { routes } from "~/application/theme/routes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { CreditLimit } from "../CreditLimits/CreditLimit";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";

export const NavBar: FC = () => {
  const navigate = useNavigate();
  const { data, currentStep } = useRemakeSearch();
  const { contexts, user, profile } = useUser();
  const location = useLocation();

  const [searchIsOpen, setSearchIsOpen] = useState(true);
  const [requestsIsOpen, setRequestsIsOpen] = useState(false);
  const [expanseIsOpen, setExpanseIsOpen] = useState(false);
  const [configIsOpen, setConfigIsOpen] = useState(false);
  const [orderIsOpen, setOrdersIsOpen] = useState(false);

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isMobile = useMobile();

  const menuNavigate = (route: string) => {
    navigate(route);
    setMenuIsOpen(false);
  };

  const canShowCreditLimit = useVerifyActions({
    actions: [Actions.ViewCreditLimit],
    contexts: contexts,
    profile,
  });

  const isManager = user.type === AgencyEmployeeType.MANAGER;
  const isValidAgencyEmployee = isManager && user.context === UserContext.Agency;

  return (
    <Styled.Container css={{ position: "relative", p: "$5" }} as={Container}>
      <Box>{isMobile ? <SvgMenu onClick={() => setMenuIsOpen(true)} /> : null}</Box>

      {menuIsOpen ? (
        <Box
          css={{
            "@mxxl": {
              width: "80%",
              height: "100vh",
              position: "absolute",
              transition: "ease-in-out",
              zIndex: "$overlay",
              top: "0",
              left: "0",
              backgroundColor: "White",
            },
          }}
        >
          <Box
            css={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "$10",
              height: "$20",
            }}
          >
            <BiztripLogo size="lg" />
            <Close onClick={() => setMenuIsOpen(false)} />
          </Box>
          <Box>
            <MenuItemList
              css={{}}
              data-active={location.pathname.startsWith("/busca")}
              isOpen={searchIsOpen}
              onItemExpand={() => setSearchIsOpen((old) => !old)}
              item={{
                type: MenuItems.SEARCH,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => menuNavigate(routes.Booking.Airway.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Aéreo
                </Text>{" "}
                <Text
                  onClick={() => menuNavigate(routes.Booking.Hotel.root)}
                  as="p"
                  css={{ fw: "500" }}
                >
                  Hotéis
                </Text>{" "}
                <Text
                  onClick={() => menuNavigate(routes.Booking.Road.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Ônibus
                </Text>{" "}
                <Text
                  onClick={() => menuNavigate(routes.Booking.Vehicle.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Carros
                </Text>{" "}
              </Col>
            </MenuItemList>
          </Box>
          <Box>
            <MenuItemList
              isOpen={requestsIsOpen}
              data-active={location.pathname.startsWith("/pedidos")}
              onItemExpand={() => setRequestsIsOpen((old) => !old)}
              item={{
                type: MenuItems.ORDER,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => menuNavigate(routes.Orders.CustomerOrders.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Meus pedidos
                </Text>{" "}
                <Text
                  onClick={() => menuNavigate(routes.Orders.Advances.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Adiantamentos
                </Text>{" "}
              </Col>
            </MenuItemList>
          </Box>
          <Box>
            <MenuItemList
              css={{}}
              isOpen={expanseIsOpen}
              data-active={location.pathname.startsWith("/despesas")}
              onItemExpand={() => setExpanseIsOpen((old) => !old)}
              item={{
                type: MenuItems.EXPENSE,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => menuNavigate("/despesas")}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Despesas
                </Text>{" "}
              </Col>
            </MenuItemList>
          </Box>

          <Box>
            <MenuItemList
              css={{
                hover: {
                  outline: "none",
                },
              }}
              isOpen={configIsOpen}
              onItemExpand={() => setConfigIsOpen((old) => !old)}
              item={{
                type: MenuItems.CONFIG,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => menuNavigate(routes.Configuration.Parameters.Customers.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Parâmetros
                </Text>{" "}
                <Text
                  onClick={() => menuNavigate(routes.Configuration.CostCenters.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Centros de custo
                </Text>{" "}
                <Text
                  onClick={() => menuNavigate(routes.Configuration.CustomerEmployees.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Funcionários
                </Text>
              </Col>
            </MenuItemList>
          </Box>
        </Box>
      ) : null}

      <Box
        css={{
          flexBasis: "16%",
        }}
      >
        <BiztripLogo
          size="lg"
          onClick={() => {
            if (data.road && currentStep !== data.road.length) return;
            navigate("/");
          }}
        />
      </Box>

      {isMobile && contexts.agency && (
        <Box
          css={{
            "@mxxl": {
              width: "80%",
              height: "100vh",
              position: "absolute",
              transition: "ease-in-out",
              display: menuIsOpen ? "block" : "none",
              zIndex: "$overlay",
              top: "0",
              left: "0",
              backgroundColor: "White",
            },
          }}
        >
          <Box
            css={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "$10",
              height: "$20",
            }}
          >
            <BiztripLogo size="lg" />
            <Close onClick={() => setMenuIsOpen(false)} />
          </Box>
          {contexts.customer && (
            <Box>
              <MenuItemList
                css={{
                  hover: {
                    outline: "none",
                  },
                }}
                isOpen={orderIsOpen}
                onItemExpand={() => setOrdersIsOpen((old) => !old)}
                item={{
                  type: MenuItems.SEARCH,
                }}
                orderId={"orderId"}
                key={OrderItems.ROAD}
              >
                <Col css={{ ml: "$18" }}>
                  <Text
                    onClick={() => {
                      menuNavigate(routes.Booking.Airway.root);
                    }}
                    as="p"
                    css={{ fw: "500", py: "$3" }}
                  >
                    Aéreo
                  </Text>{" "}
                  <Text
                    onClick={() => menuNavigate(routes.Booking.Road.root)}
                    as="p"
                    css={{ fw: "500", py: "$3" }}
                  >
                    Ônibus
                  </Text>{" "}
                  <Text
                    onClick={() => navigate(routes.Booking.Hotel.root)}
                    as="p"
                    css={{ fw: "500", py: "$3" }}
                  >
                    Hotel
                  </Text>{" "}
                  <Text
                    onClick={() => menuNavigate(routes.Booking.Vehicle.root)}
                    as="p"
                    css={{ fw: "500", py: "$3" }}
                  >
                    Carro
                  </Text>{" "}
                </Col>
              </MenuItemList>
            </Box>
          )}
          <Box>
            <MenuItemList
              css={{
                hover: {
                  outline: "none",
                },
              }}
              isOpen={orderIsOpen}
              onItemExpand={() => setOrdersIsOpen((old) => !old)}
              item={{
                type: MenuItems.ORDER,
              }}
              orderId={"orderId"}
              key={OrderItems.ROAD}
            >
              <Col css={{ ml: "$18" }}>
                <Text
                  onClick={() => menuNavigate(routes.Agencies.Orders.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Ver pedidos
                </Text>{" "}
                <Text
                  onClick={() => menuNavigate(routes.Orders.PendingOrders.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Pedidos pendentes
                </Text>{" "}
                <Text
                  onClick={() => menuNavigate(routes.Configuration.Reports.root)}
                  as="p"
                  css={{ fw: "500", py: "$3" }}
                >
                  Relatórios
                </Text>{" "}
              </Col>
            </MenuItemList>
          </Box>

          {isValidAgencyEmployee && (
            <Box>
              <MenuItemList
                css={{
                  hover: {
                    outline: "none",
                  },
                }}
                isOpen={configIsOpen}
                onItemExpand={() => setConfigIsOpen((old) => !old)}
                item={{
                  type: MenuItems.CONFIG,
                }}
                orderId={"orderId"}
                key={OrderItems.ROAD}
              >
                <Col css={{ ml: "$18" }}>
                  <Text
                    onClick={() => menuNavigate(routes.Configuration.Customers.root)}
                    as="p"
                    css={{ fw: "500", py: "$3" }}
                  >
                    Empresas
                  </Text>{" "}
                  <Text
                    onClick={() => menuNavigate(routes.Configuration.Fees.root)}
                    as="p"
                    css={{ fw: "500", py: "$3" }}
                  >
                    Taxas
                  </Text>{" "}
                  <Text
                    onClick={() => menuNavigate(routes.Configuration.Markups.root)}
                    as="p"
                    css={{ fw: "500", py: "$3" }}
                  >
                    Markups
                  </Text>
                  <Text
                    onClick={() => menuNavigate(routes.Configuration.Credentials.root)}
                    as="p"
                    css={{ fw: "500" }}
                  >
                    Credenciais
                  </Text>
                  <br />
                  <Text
                    onClick={() => menuNavigate(routes.Configuration.CreditCards.root)}
                    as="p"
                    css={{ fw: "500" }}
                  >
                    Cartões de crédito
                  </Text>
                  <br />
                  <Text
                    onClick={() => menuNavigate(routes.Configuration.Markdowns.root)}
                    as="p"
                    css={{ fw: "500", py: "$3" }}
                  >
                    Markdowns
                  </Text>
                </Col>
              </MenuItemList>
            </Box>
          )}
        </Box>
      )}

      <Box
        css={{
          flex: 1,
          "@mxlg": {
            display: "none",
          },
        }}
      >
        <NavMenu />
      </Box>

      {user.customer && !isMobile && canShowCreditLimit && <CreditLimit />}

      <ProfileMenu />
    </Styled.Container>
  );
};

NavBar.displayName = "NavBar";
