import { Route, Routes } from "react-router-dom";
import { routes } from "~/application/theme/routes";
import { UserContext } from "~/application/types";
import { SettingParametersPage } from "~/core/modules/Customer/pages/SettingParametersPage";
import { ApprovalModelsPage } from "~/presentation/CustomerSettings/pages/ApprovalModelsPage/ApprovalModelsPage";
import { BranchesPage } from "~/presentation/CustomerSettings/pages/BranchesPage";
import { CostCentersPage } from "~/presentation/CustomerSettings/pages/CostCentersPage";
import { CustomerEmployeesPage } from "~/presentation/CustomerSettings/pages/CustomerEmployeesPage";
import { ExpensePoliciesPage } from "~/presentation/CustomerSettings/pages/ExpensePoliciesPage";
import { ExpenseTypesPage } from "~/presentation/CustomerSettings/pages/ExpenseTypesPage";
import { JustificationsPage } from "~/presentation/CustomerSettings/pages/JustificationsPage";
import { ReasonsTripPage } from "~/presentation/CustomerSettings/pages/ReasonsTripPage";
import { ReportsPage } from "~/presentation/Reports/pages/ReportsPage";
import { AgenciesPage } from "../core/modules/Agency/pages/AgenciesPage";
import { AgencyPage } from "../core/modules/Agency/pages/AgencyPage";
import { CreateAgencyPage } from "../core/modules/Agency/pages/CreateAgencyPage";
import { FeesPage } from "../core/modules/Agency/pages/FeesPage";
import { MarkdownsPage } from "../core/modules/Agency/pages/MarkdownsPage";
import { MarkupsPage } from "../core/modules/Agency/pages/MarkupsPage";
import { CreateCustomerPage } from "../core/modules/Customer/pages/CreateCustomerPage";
import { CustomerPage } from "../core/modules/Customer/pages/CustomerPage";
import { CustomersPage } from "../core/modules/Customer/pages/CustomersPage";
import { ExpenseCategoriesPage } from "../core/modules/ExpenseCategory/pages/ExpenseCategoriesPage";
import { RequireRole } from "./helpers/RequireRole";
import { ProjectsPage } from "~/core/modules/Agency/pages/ProjectsPage";
import { CredentialsPage } from "~/core/modules/Agency/pages/CredencialPage/CredentialsPage";
import { CardsPage } from "~/core/modules/Agency/pages/CreditCardsPage/CardsPage";
import { PurchasingPoliciesPage } from "~/presentation/CustomerSettings/pages/PurchasingPoliciesPage/PurchasingPoliciesPage";
import { DashboardBIContainer } from "~/core/modules/Agency/pages/DashboardBIPage/DashboardContainer";
import { DashboardBIPage } from "~/core/modules/Agency/pages/DashboardBIPage/DashboardBIPage";
import { CreateOfflineHotelPage } from "~/core/modules/Agency/pages/CreateOfflineHotelPage";
import { OfflineHotelsPage } from "~/core/modules/Agency/pages/OfflineHotelsPage";
import { EditOfflineHotelPage } from "~/core/modules/Agency/pages/EditOfflineHotelPage";
import { OfflineHotelRoomsPage } from "~/core/modules/Agency/pages/OfflineHotelRoomsPage";
import { PhasesPage } from "~/core/modules/Agency/pages/PhasesPage";
import { BudgetsPage } from "~/core/modules/Agency/pages/BudgetsPage";
import { CustomerEmployeesGroupsPage } from "~/presentation/CustomerSettings/pages/CustomerEmployeesGroupsPage";

export const ConfigurationRouter = () => {
  return (
    <Routes>
      <Route path="*">
        <Route
          path={routes.Configuration.Parameters.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route
            path={routes.Configuration.Parameters.Customers.path}
            element={<SettingParametersPage />}
          />
        </Route>

        <Route
          path={routes.Configuration.DashboardBI.path}
          element={<RequireRole role={UserContext.Agency} />}
        >
          <Route
            index
            element={<DashboardBIPage />}
          />
        </Route>

        <Route
          path={routes.Agencies.path}
          element={<RequireRole role={UserContext.Biztrip} />}
        >
          <Route index element={<AgenciesPage />} />
          <Route path="nova-agencia" element={<CreateAgencyPage />} />
          <Route path=":agencyId" element={<AgencyPage />} />
        </Route>

        <Route
          path="categorias-de-despesa"
          element={<RequireRole role={UserContext.Biztrip} />}
        >
          <Route index element={<ExpenseCategoriesPage />} />
        </Route>

        <Route
          path="empresas"
          element={<RequireRole role={UserContext.Agency} />}
        >
          <Route index element={<CustomersPage />} />
          <Route path="nova-empresa" element={<CreateCustomerPage />} />
          <Route path=":customerId" element={<CustomerPage />} />
          <Route path=":customerId/funcionários/" element={<CustomerPage />} />
        </Route>

        <Route
          path={routes.Configuration.Fees.path}
          element={<RequireRole role={UserContext.Agency} />}
        >
          <Route index element={<FeesPage />} />
        </Route>

        <Route
          path={routes.Configuration.Markups.path}
          element={<RequireRole role={UserContext.Agency} />}
        >
          <Route index element={<MarkupsPage />} />
        </Route>

        <Route path={routes.Configuration.Reports.path}>
          <Route index element={<ReportsPage />} />
        </Route>

        <Route
          path={routes.Configuration.Projects.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<ProjectsPage />} />

          <Route path={routes.Configuration.Projects.Project.path}>
            <Route path={routes.Configuration.Projects.Project.Phases.path} element={<PhasesPage />} />
          </Route>
        </Route>

        <Route
          path={routes.Configuration.Credentials.path}
          element={<RequireRole role={UserContext.Agency} />}
        >
          <Route index element={<CredentialsPage />} />
        </Route>

        <Route
          path={routes.Configuration.CreditCards.path}
          element={<RequireRole role={UserContext.Agency} />}
        >
          <Route index element={<CardsPage />} />
        </Route>

        <Route
          path={routes.Configuration.Markdowns.path}
          element={<RequireRole role={UserContext.Agency} />}
        >
          <Route index element={<MarkdownsPage />} />
        </Route>

        <Route
          path={routes.Configuration.Branches.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<BranchesPage />} />
        </Route>

        <Route
          path={routes.Configuration.CostCenters.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<CostCentersPage />} />
        </Route>

        <Route
          path={routes.Configuration.CustomerEmployees.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<CustomerEmployeesPage />} />
        </Route>

        <Route
          path={routes.Configuration.CustomerEmployeesGroups.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<CustomerEmployeesGroupsPage />} />
        </Route>

        <Route
          path={routes.Configuration.ApprovalModels.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<ApprovalModelsPage />} />
        </Route>

        <Route
          path={routes.Configuration.PoliciesExpenses.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<ExpensePoliciesPage />} />
        </Route>

        <Route
          path={routes.Configuration.ReasonTrips.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<ReasonsTripPage />} />
        </Route>

        <Route
          path={routes.Configuration.Justifications.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<JustificationsPage />} />
        </Route>

        <Route path={routes.Configuration.PurchasingPolicies.path}>
          <Route index element={<PurchasingPoliciesPage />} />
        </Route>

        <Route
          path={routes.Configuration.ExpenseTypes.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<ExpenseTypesPage />} />
        </Route>

        <Route
          path={routes.Configuration.OfflineHotels.path}
          element={<RequireRole role={UserContext.Agency} />}
        >
          <Route index element={<OfflineHotelsPage />} />
          <Route 
            path={routes.Configuration.OfflineHotels.RegisterOfflineHotel.path} 
            element={<CreateOfflineHotelPage />} 
          />
          <Route path={routes.Configuration.OfflineHotels.OfflineHotel.path}>
            <Route 
              path={routes.Configuration.OfflineHotels.OfflineHotel.EditOfflineHotel.path} 
              element={<EditOfflineHotelPage />} 
            />

            <Route path={routes.Configuration.OfflineHotels.OfflineHotel.OfflineHotelRoom.path}>
              <Route index element={<OfflineHotelRoomsPage />} />
            </Route>
          </Route>
        </Route>

        <Route
          path={routes.Configuration.Budgets.path}
          element={<RequireRole role={UserContext.Customer} />}
        >
          <Route index element={<BudgetsPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

ConfigurationRouter.displayName = "ConfigurationRouter";
