import { FC, useCallback, useMemo, useState } from "react";
import { Flight, FlightOption } from "~/application/types";
import { asCurrency } from "~/application/utils/mask-functions";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { BookingFlightListItem } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/ui/views/BookingFlightListItem";
import { FiltersSection } from "~/presentation/shared/components/FiltersSection";
import { ReturnFlightType, ReturnFlightsSectionUIProps } from "./types";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { Checkbox } from "~/components/Input";
import { Flex } from "~/components/Flex";
import { AirwayBudgetDispatchType } from "../../../hooks/useAirwayBudget/type";

export const ReturnFlightsSectionUI: FC<ReturnFlightsSectionUIProps> = ({
  data,
  filters,
  isAgency,
  isLoading,
  bookingState,
  flightsList,
  onFilterDurationChange,
  onSelectFlight,
  onFilterChange,
  calculateDiscount,
  setOrderType,
  orderType,
}) => {
  const minimumPrice = useMemo(() => (data[0] ? data[0].flight.minimumPrice.amount : null), [data]);
  const { airwayBudget } = useFlightsPage();
  const { state } = airwayBudget;

  const { activeBudget, dispatch } = airwayBudget;

  const allAirwayBudget = state.arrival.length === data.length;

  const maxBudgetNumber = 30;

  const allFlightsBudget = state?.arrival?.length === maxBudgetNumber;

  const selectAllFlights = useCallback(() => {
    if (allFlightsBudget) {
      return dispatch?.({ type: AirwayBudgetDispatchType.ARRIVAL, paylod: [] });
    }
    dispatch?.({
      type: AirwayBudgetDispatchType.ARRIVAL,
      paylod: flightsList.slice(0, maxBudgetNumber),
    });
  }, [flightsList, allFlightsBudget, maxBudgetNumber]);

  const sortedFlights = data.sort((a, b) => {
    if (a.isCombined && !b.isCombined) return -1;
    if (!a.isCombined && b.isCombined) return 1;
    return 0;
  });

  const listRenderer = useCallback(
    (item: ReturnFlightType) => {
      const tagCss = {
        "@mxlg": {
          fontSize: "9px",
          textAlign: "center",
          border: 0,
        },
      };

      const absoluteDiscount = calculateDiscount(item);
      const discount = `${(absoluteDiscount * 100).toFixed(2)}%`;

      const isSelected = bookingState.returnFlightSelected?.flight.id === item.flight.id;

      return (
        <BookingFlightListItem
          data={item.flight}
          flightOrigin={item.isCombined ? "combined" : "arrival"}
          selectedOption={bookingState.returnFlightSelected?.flightOption}
          isSelected={isSelected}
          onSelectFlight={(_item: Flight, option: FlightOption) => onSelectFlight(item, option)}
          TagsElement={
            <>
              <Tag variant="neutral-light" css={tagCss}>
                {item.isCombined ? "RT" : "OW"}
              </Tag>

              {item.flight.discount && (
                <Tag variant="error-light" css={tagCss}>
                  {isAgency && typeof item.flight.discount !== "boolean"
                    ? `- ${asCurrency(item.flight.discount.amount)}`
                    : "$"}
                </Tag>
              )}

              {minimumPrice === item.flight.minimumPrice.amount && (
                <Tag variant="success-light" css={tagCss}>
                  Melhor oferta
                </Tag>
              )}

              {discount && absoluteDiscount > 0 && (
                <Tag variant="warning-light" css={tagCss}>
                  Desconto de {discount}
                </Tag>
              )}
            </>
          }
          key={item.flight.hash}
        />
      );
    },
    [
      bookingState.returnFlightSelected,
      minimumPrice,
      onSelectFlight,
      calculateDiscount,
      allAirwayBudget,
    ]
  );

  return (
    <Row
      css={{
        "@mxlg": {
          flexDirection: "column",
          width: "100%",
          margin: "0 auto",
        },
      }}
    >
      <Col
        sz="4"
        css={{
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        <FiltersSection
          setOrderType={setOrderType}
          isLoading={isLoading}
          orderType={orderType}
          filters={filters}
          flightsList={flightsList}
          title="Filtrar resultados"
          onFilterDurationChange={onFilterDurationChange}
          onFilterChange={onFilterChange}
        />
      </Col>

      <Col
        sz="8"
        css={{
          "@mxlg": {
            width: "100%",
            flexWrap: "wrap",
            mt: "$5",
          },
        }}
      >
        <Flex direction="column" gap="3">
          {activeBudget && (
            <Flex align="center" gap="2">
              <Checkbox checked={allFlightsBudget} onChange={selectAllFlights} />
              <Text fw="500" size="3">
                Selecionar todos desta página
              </Text>
            </Flex>
          )}
          <LazyList
            gap="8"
            items={sortedFlights}
            isLoading={isLoading}
            render={listRenderer}
            skeletonQuantity={10}
            skeletonHeight={200}
            EmptyComponent={
              <EmptyState>
                <Text>Nenhuma passagem encontrada</Text>
              </EmptyState>
            }
          />
        </Flex>
      </Col>
    </Row>
  );
};

ReturnFlightsSectionUI.displayName = "ReturnFlightsSectionUI";
