import type { Flight, FlightOriginType } from "~/application/types";
import { api } from "~/infrastructure/api";
import type { FlightDTO } from "~/infrastructure/api/dtos";
import { mapFlightDTO } from "~/infrastructure/api/mappers";
import type { IFlightDetailsService } from "./IFlightDetailsService";

export class FlightDetailsService implements IFlightDetailsService {
  async find(flight: Flight, origin: FlightOriginType): Promise<Flight> {
    const url = `/flights/${flight.searchKey}/${origin}/${flight.id}`;

    return await api
      .get<{ flight: FlightDTO; search_key: string; flight_id: number | null }>(url)
      .then(({ data }) => mapFlightDTO(data.flight, data.search_key));
  }
}
