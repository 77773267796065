import { RemakeSearchData } from "~/application/types";
import { RemakeSearchDataDTO } from "../dtos";
import { toDate } from "~/utils/date.utils";

export const mapRemakeSearchDataDTO = (data: RemakeSearchDataDTO): RemakeSearchData => {
  return {
    road: data.road.map((item) => ({
      customerId: item.customer_uuid,
      departureDate: toDate(item.departure_date),
      destination: item.destination,
      origin: item.origin,
      roadOrderUuid: item.road_order_uuid,
    })),
    airway: data.airway.map((item) => ({
      customerId: item.customerId,
      departureDate: toDate(item.departure_date),
      arrivalDate: item.arrival_date ? toDate(item.arrival_date) : undefined,
      destination: {
        city: item.destination.city,
        cityCode: item.destination.city_code,
        countryCode: item.destination.country_code,
        iata: item.destination.iata,
        latitude: item.destination.latitude,
        longitude: item.destination.longitude,
        name: item.destination.name,
        uuid: item.destination.uuid,
        isInternational: item.destination.is_international,
      },
      origin: {
        city: item.origin.city,
        cityCode: item.origin.city_code,
        countryCode: item.origin.country_code,
        iata: item.origin.iata,
        latitude: item.origin.latitude,
        longitude: item.origin.longitude,
        name: item.origin.name,
        uuid: item.origin.uuid,
        isInternational: item.origin.is_international,
      },
      travelers: item.travelers.map((traveler) => ({
        code: traveler.code,
        quantity: traveler.quantity,
      })),
      airwayOrderUuids: item.airway_order_uuids,
    })),
  };
};
