import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import {
  AirwayEmission,
  HotelEmission,
  Order,
  OrderHotelItem,
  OrderItemStatus,
  OrderItems,
  OrderRoadItem,
  OrderStatus,
  OrderVehicleItem,
  Provider,
  VehicleEmission,
} from "~/application/types";
import { emissionService, orderAirwayService, orderService } from "~/application/usecases";
import { offlineHotelService } from "~/application/usecases/OfflineHotel";
import { NavigatorUtils } from "~/application/utils";
import { CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { Text } from "~/components/Text";
import { QueryKeys } from "~/constants/queryKeys";
import { SimpleDialog } from "~/core/shared/components/SimpleDialog";
import { api } from "~/infrastructure/api";
import { OrderItemsMenuItem } from "~/presentation/ManageOrder/pages/ManageOrderPage/types";
import { getOrderMenuItems } from "~/presentation/ManageOrder/pages/ManageOrderPage/utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { logError } from "~/presentation/shared/utils/errors";
import { AirwayIssuanceDialog } from "~/presentation/shared/views/AirwayIssuance";
import { HotelIssuanceDialog } from "~/presentation/shared/views/HotelIssuance/HotelIssuanceDialog";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { RoadEmissionSubmit, RoadIssuanceDialog } from "~/presentation/shared/views/RoadIssuance";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { ConfirmOrderCancel } from "../../OrderPage/components/ConfirmOrderCancel";
import { ConfirmIssueItemDialog } from "~/presentation/shared/views/ConfirmIssueItemDialog/ConfirmIssueItemDialog";
import { VehicleIssuanceDialog } from "~/presentation/shared/views/VehicleIssuance/VehicleIssuanceDialog";
import { ConfirmOrderDialog } from "~/presentation/shared/views/ConfirmOrderDialog/ConfirmOrderDialog";
import { UnflownData } from "~/presentation/ManageOrder/pages/ManageOrderPage/components/UnflownAirwayDialog/type";
import { QueryTimes } from "~/constants";
import { Agreement } from "~/application/types/entities/BindCredential.type";

export interface UseOrderItemsResult {
  order?: Order;
  isLoading: boolean;
  isIssuingOrder: boolean;
  orderId: string;
  canSelfAssign: boolean;
  orderItems?: OrderItemsMenuItem[];
  itemsAvailableToAdd?: OrderItems[] | null;
  onRejectCancelling: () => void;
  onCancelOrder: () => void;
  onIssueOrder: () => void;
  onManualCancelOrder: () => void;
  onCopyText: (text: string) => void;
  onAssignConsultant: () => void;
  onScrollIntoSection: (id: string) => void;
  onCheckUserMessage: (id: string) => boolean;
  onIssueAirway: () => void;
  onIssueRoad: (orderItem: OrderRoadItem) => void;
  onIssueVehicle: (orderData: OrderVehicleItem) => void;
  onIssueHotel: (orderData: OrderHotelItem) => void;
  onCancelItem: ({
    orderItemId,
    orderItemType,
  }: {
    orderItemId: string;
    orderItemType: OrderItems;
  }) => void;
  onDeleteOfflineQuote: (optionId: string) => void;
  onUpdateUnflownAirway: (data: UnflownData[]) => void;
}

export type ConfirmIssueItemProps = {
  orderItem: OrderItems;
  data: HotelEmission | RoadEmissionSubmit | AirwayEmission[] | VehicleEmission;
};

export interface UseOrderItemsOptions {
  order?: Order;
  orderId: string;
  enabled: boolean;
  isLoadingPaymentMethods?: boolean;
  isLoadingAgreements: boolean;
  isLoadingProviders: boolean;
  paymentMethods?: {
    description: string;
    uuid: string;
  }[]
  providers?: Provider[];
  agreements?: Agreement[];
}

const LOG_TAG = "Order/ManageOrderPage/useOrderItems";

const SNACKBAR_MESSAGES = {
  REJECT_ORDER_CANCEL_ERROR_MESSAGE: "Falha ao rejeitar cancelamento",
  REJECT_ORDER_CANCEL_SUCCESS_MESSAGE: "Cancelamento rejeitado com sucesso",
  ISSUE_SUCCESS_MESSAGE: "Item emitido com sucesso",
  ISSUE_ERROR_MESSAGE: "Falha ao emitir item",
  ISSUE_ROAD_ERROR_MESSAGE: "Falha ao emitir item rodoviário",
  ISSUE_AIRWAY_ERROR_MESSAGE: "Falha ao emitir item aéreo",
  ISSUE_VEHICLE_ERROR_MESSAGE: "Falha ao emitir item veículo",
  UPDATE_UNFLOWN_AIRWAY_ERROR_MESSAGE: "Falha ao atualizar o bilhete aéreo",
  UPDATE_UNFLOWN_AIRWAY_SUCCESS_MESSAGE: "Bilhete aéreo atualizado com sucesso",
  ISSUE_HOTEL_ERROR_MESSAGE: "Falha ao emitir item hotel",
  ASSIGN_TO_ORDER_MESSAGE: "Você foi vinculado ao pedido",
  ASSIGN_TO_ORDER_ERROR_MESSAGE: "Falha ao vincular você ao pedido",
  CANCEL_ORDER_SUCCESS_MESSAGE: "Pedido cancelado com sucesso",
  CANCEL_ORDER_ERROR_MESSAGE: "Falha ao cancelar pedido",
  ISSUE_ORDER_SUCCESS_MESSAGE: "Pedido emitido com sucesso",
  ISSUE_ORDER_ERROR_MESSAGE: "Falha ao emitir pedido",
  CANCEL_ITEM_SUCCESS_MESSAGE: "Item cancelado com sucesso",
  CANCEL_ITEM_ERROR_MESSAGE: "Falha ao cancelar item",
  REMOVE_QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE: "Cotação removida com sucesso",
  REMOVE_QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE: "Falha ao remover cotação",
} as const;

export function useOrderItems({
  order,
  orderId,
  providers,
  paymentMethods,
  agreements,
  isLoadingAgreements,
  isLoadingProviders,
  enabled, // eslint-disable-line @typescript-eslint/no-unused-vars
}: UseOrderItemsOptions): UseOrderItemsResult {
  const { user } = useUser();
  const customerId = user.customer?.uuid || (order?.customer.uuid as string);

  const itemsAvailableToAdd = useMemo<OrderItems[] | null>(() => {
    return !order || order.expenseOnly
      ? null
      : Object.values(OrderItems).filter((item) => !order.itemsIncluded.includes(item));
  }, [order]);

  const { mutate: mutateAssignSelfConsultant } = useMutation(
    () => api.post(`/orders/${orderId}/consultants`),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Vinculando-se ao pedido" />);
      },
      onSuccess: () => {
        log.i(LOG_TAG, `Successfully assigned User(${user.uuid})`);

        queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ASSIGN_TO_ORDER_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.ISSUE_AIRWAY_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { data: paymentMethod, isLoading: isLoadingPaymentMethod } = useQuery(
    [QueryKeys.HOTEL_OFF_PAYMENT_METHOD],
    () => orderService.paymentMethod(),
    {
      staleTime: QueryTimes.LONG,
      refetchOnWindowFocus: true,
    }
  );


  const { mutate: mutateCancelItem } = useMutation(
    ({ orderItemType, orderItemId }: { orderItemId: string; orderItemType: OrderItems }) =>
      emissionService.cancelItem({ orderItemId, orderItemType }),
    {
      onMutate: () => {
        dialogService.popDialog();
        log.i(LOG_TAG, "Cancelling Item");
        dialogService.showDialog(<LoadingDialog message="Cancelando item" />);
      },
      onSuccess: () => {
        log.i(LOG_TAG, `Successfully cancelled Item(orderId: ${orderId})`);

        dialogService.popAll();

        queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CANCEL_ITEM_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        dialogService.popDialog();
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CANCEL_ORDER_ERROR_MESSAGE,
        });

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CANCEL_ITEM_ERROR_MESSAGE, "error");
      },
    }
  );

  const handleAssignConsultant = useCallback(() => {
    dialogService.showDialog(
      <SimpleDialog
        title="Vincular-se a este pedido?"
        cancelTitle="Cancelar"
        confirmTitle="Vincular"
        onConfirm={() => mutateAssignSelfConsultant()}
      >
        <CardBody>
          <Text size={{ "@mxlg": "3" }} variant="darkest">
            Ao clicar em Vincular, você passa a ser o consultor responsável pelo processamento desse
            pedido.
          </Text>
        </CardBody>
      </SimpleDialog>
    );
  }, []);

  const { mutate: mutateUpdateUnflownAirway } = useMutation(
    async (data: UnflownData[]) => orderAirwayService.updateUnflownAirway(data),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Emitindo item" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ORDERS]);
        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_UNFLOWN_AIRWAY_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popAll();
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_UNFLOWN_AIRWAY_ERROR_MESSAGE,
        });
        dialogService.popDialog();
      },
    }
  );

  const handleScrollIntoSection = useCallback((id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleCopyText = useCallback(
    (value: string) => NavigatorUtils.copyToClipboard(value),
    [user]
  );

  const handleCheckUserMessage = useCallback(
    (id: string) => user.profiles.agency?.uuid === id,
    [user]
  );

  const canSelfAssign = useMemo<boolean>(
    () => order?.consultant?.uuid !== user.profiles.agency.uuid,
    [user, order]
  );

  const orderItems = useMemo(() => {
    return order && getOrderMenuItems(order);
  }, [order?.items]);

  const { mutateAsync: mutateIssueAirway } = useMutation(
    (data: AirwayEmission[]) => emissionService.airway(orderId, data),

    {
      onMutate: () => {
        log.i(LOG_TAG, "Issuing Airway");
        dialogService.popAll();
        dialogService.showDialog(<LoadingDialog message="Emitindo item" />);
      },
      onSuccess: () => {
        log.i(LOG_TAG, `Successfully issued Airway(orderId: ${orderId})`);

        queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ISSUE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.ISSUE_AIRWAY_ERROR_MESSAGE,
        });
        dialogService.popDialog();
      },
    }
  );

  const { mutateAsync: mutateIssueRoad } = useMutation(
    (data: RoadEmissionSubmit) => emissionService.road(orderId, data),
    {
      onMutate: () => {
        log.i(LOG_TAG, "Issuing Road");
        dialogService.popAll();
        dialogService.showDialog(<LoadingDialog message="Emitindo item" />);
      },
      onSuccess: () => {
        log.i(LOG_TAG, `Successfully issued Road(orderId: ${orderId})`);

        queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ISSUE_SUCCESS_MESSAGE, "success");

        dialogService.popAll();
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.ISSUE_ROAD_ERROR_MESSAGE,
        });
        dialogService.popDialog();
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutateAsync: mutateIssueHotel } = useMutation(
    (data: HotelEmission) => emissionService.hotel(orderId, data),
    {
      onMutate: () => {
        log.i(LOG_TAG, "Issuing Hotel");
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Emitindo item" />);
      },
      onSuccess: () => {
        log.i(LOG_TAG, `Successfully issued Road(orderId: ${orderId})`);

        queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ISSUE_SUCCESS_MESSAGE, "success");

        dialogService.popAll();
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.ISSUE_ROAD_ERROR_MESSAGE,
        });
        dialogService.popDialog();
      },
    }
  );

  const { mutateAsync: mutateIssueVehicle } = useMutation(
    (data: VehicleEmission) => emissionService.vehicle(orderId, data),
    {
      onMutate: () => {
        log.i(LOG_TAG, "Issuing Road");
        dialogService.showDialog(<LoadingDialog message="Emitindo item" />);
      },
      onSuccess: () => {
        log.i(LOG_TAG, `Successfully issued vehicle(orderId: ${orderId})`);

        queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ISSUE_SUCCESS_MESSAGE, "success");

        dialogService.popAll();
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.ISSUE_VEHICLE_ERROR_MESSAGE,
        });
        dialogService.popDialog();
      },
    }
  );

  const handleConfirmIssueItem = useCallback(
    ({ data, orderItem }: ConfirmIssueItemProps) => {
      dialogService.showDialog(
        <ConfirmIssueItemDialog
          onCancel={() => dialogService.popDialog()}
          onConfirm={() => {
            switch (orderItem) {
              case OrderItems.AIRWAY:
                return mutateIssueAirway(data as AirwayEmission[]);
              case OrderItems.ROAD:
                return mutateIssueRoad(data as RoadEmissionSubmit);
              case OrderItems.VEHICLE:
                return mutateIssueVehicle(data as VehicleEmission);
              case OrderItems.HOTEL:
                return mutateIssueHotel(data as HotelEmission);
            }
          }}
        />
      );
    },
    [mutateIssueAirway, mutateIssueHotel, mutateIssueRoad]
  );

  const handleIssueAirway = useCallback(() => {
    if (order?.items.airway) {
      dialogService.showDialog(
        <AirwayIssuanceDialog
          orderAirway={order.items.airway}
          onSubmit={(data) => handleConfirmIssueItem({ data, orderItem: OrderItems.AIRWAY })}
        />
      );
    }
  }, [order, handleConfirmIssueItem]);

  const handleIssueRoad = useCallback(
    (orderData: OrderRoadItem) => {
      if (order?.items.road) {
        dialogService.showDialog(
          <RoadIssuanceDialog
            agreements={agreements}
            orderRoad={orderData}
            paymentMethods={paymentMethods}
            isLoadingPaymentMethod={isLoadingPaymentMethod}
            isLoading={isLoadingPaymentMethod}
            onSubmit={(data) => handleConfirmIssueItem({ data, orderItem: OrderItems.ROAD })}
          />
        );
      }
    },
    [order, paymentMethods, providers, isLoadingPaymentMethod, handleConfirmIssueItem, agreements]
  );
  const handleIssueHotel = useCallback(
    (orderData: OrderHotelItem) => {
      if (order?.items.hotel) {
        dialogService.showDialog(
          <HotelIssuanceDialog
            order={order}
            agreements={agreements}
            isLoadingPaymentMethod={isLoadingPaymentMethod}
            paymentMethods={paymentMethods}
            orderHotel={orderData}
            isLoading={isLoadingAgreements}
            onSubmit={(data) => handleConfirmIssueItem({ data, orderItem: OrderItems.HOTEL })}
          />
        );
      }
    },
    [order, paymentMethod, isLoadingPaymentMethod, handleConfirmIssueItem, agreements]
  );

  const handleIssueVehicle = useCallback(
    (orderData: OrderVehicleItem) => {
      if (order?.items.vehicle) {
        dialogService.showDialog(
          <VehicleIssuanceDialog
            orderVehicle={orderData}
            isLoading={isLoadingProviders}
            onSubmit={(data) => handleConfirmIssueItem({ data, orderItem: OrderItems.VEHICLE })}
          />
        );
      }
    },
    [order, handleConfirmIssueItem]
  );

  const { mutateAsync: mutateManualOrderCancel } = useMutation(
    async () => await orderService.manualCancel(orderId),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Cancelando pedido manualmente" />);
      },
      onSuccess: () => {
        log.i(LOG_TAG, `Successfully cancelled Order(${orderId})`);

        queryClient.invalidateQueries([QueryKeys.ORDERS]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CANCEL_ORDER_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CANCEL_ORDER_ERROR_MESSAGE, "error");
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutateAsync: mutateOrderCancel } = useMutation(
    async () => await orderService.cancel(orderId),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Cancelando pedido" />);
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CANCEL_ORDER_ERROR_MESSAGE,
        });

        dialogService.popDialog();
      },
    }
  );

  const handleOrderCancel = useCallback(() => {
    dialogService.showDialog(<ConfirmOrderCancel onCancelOrder={mutateOrderCancel} />);
  }, [mutateOrderCancel]);

  const handleManualOrderCancel = useCallback(() => {
    dialogService.showDialog(
      <ConfirmOrderCancel
        title="Cancelamento manual do pedido"
        onCancelOrder={mutateManualOrderCancel}
      />
    );
  }, [mutateManualOrderCancel]);

  const { mutateAsync: mutateIssueOrder, isLoading: isIssuingOrder } = useMutation(
    async () => await orderService.issue(orderId),
    {
      onMutate: () => {
        log.i(LOG_TAG, "Issuing Order");
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Emitindo pedido" />);
      },
      onSuccess: () => {
        log.i(LOG_TAG, `Successfully issued Order(${orderId})`);

        queryClient.invalidateQueries([QueryKeys.ORDERS]);
        queryClient.invalidateQueries([QueryKeys.BUDGETS_VIEW, customerId]);
        queryClient.invalidateQueries([QueryKeys.ORDER_BUDGET_BALANCE, order?.uuid]);

        dialogService.popAll();

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ISSUE_ORDER_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        dialogService.popDialog();

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ISSUE_ORDER_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: onDeleteOfflineQuote } = useMutation(
    (optionId: string) => offlineHotelService.deleteOption(optionId),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Removendo cotação" />);
      },
      onError: (error) => {
        dialogService.popDialog();

        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.REMOVE_QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE,
        });
      },
      onSuccess: () => {
        dialogService.popDialog();
        queryClient.invalidateQueries([QueryKeys.ORDERS]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.REMOVE_QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE,
          "success",
          5000
        );
      },
    }
  );

  const { mutateAsync: mutateRejectCancelling } = useMutation(
    async () => await orderService.rejectCancellation(orderId),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Rejeitando cancelamento" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.ORDERS]);
        queryClient.invalidateQueries([QueryKeys.ORDER_HISTORY]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.REJECT_ORDER_CANCEL_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        logError({
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.REJECT_ORDER_CANCEL_ERROR_MESSAGE,
          logTag: LOG_TAG,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  useEffect(() => {
    const orderHotelStatus = order?.items.hotel?.rooms.map((room) => room.status) || [];
    const orderFlightStatus = order?.items.airway?.flights.map((flight) => flight.status) || [];
    const orderRoadStatus = order?.items.road?.travels.map((travel) => travel.status) || [];
    const orderVehicleStatus =
      order?.items.vehicle?.vehicles.map((vehicle) => vehicle.status) || [];

    const allItensOrderStatus = [
      ...orderHotelStatus,
      ...orderFlightStatus,
      ...orderRoadStatus,
      ...orderVehicleStatus,
    ];

    const allOrderItensIssued = allItensOrderStatus?.every(
      (status) => status === OrderItemStatus.DONE
    );

    if (
      allOrderItensIssued &&
      allItensOrderStatus.length &&
      order?.status === OrderStatus.PENDING_ISSUE
    ) {
      dialogService.showDialog(
        <ConfirmOrderDialog
          onCancel={() => {
            dialogService.popAll();
          }}
          onConfirm={mutateIssueOrder}
        />
      );
    }
  }, [order, mutateIssueOrder, dialogService]);

  return {
    order,
    orderId,
    isLoading: !order,
    itemsAvailableToAdd,
    orderItems,
    isIssuingOrder,
    canSelfAssign,
    onRejectCancelling: mutateRejectCancelling,
    onCancelItem: mutateCancelItem,
    onIssueOrder: mutateIssueOrder,
    onCancelOrder: handleOrderCancel,
    onCopyText: handleCopyText,
    onAssignConsultant: handleAssignConsultant,
    onScrollIntoSection: handleScrollIntoSection,
    onCheckUserMessage: handleCheckUserMessage,
    onIssueAirway: handleIssueAirway,
    onIssueRoad: handleIssueRoad,
    onIssueVehicle: handleIssueVehicle,
    onIssueHotel: handleIssueHotel,
    onManualCancelOrder: handleManualOrderCancel,
    onDeleteOfflineQuote,
    onUpdateUnflownAirway: mutateUpdateUnflownAirway,
  };
}
