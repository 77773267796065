import {
  Order,
  OrderHotelItem,
  OrderHotelItemType,
  OrderHotelOfflineOption,
  OrderItemStatus,
  OrderStatus,
  UserContext,
} from "~/application/types";
import { MaskUtils, NavigatorUtils } from "~/application/utils";
import { diffBetweenDates } from "~/application/utils/date-functions";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FormControl } from "~/components/FormControl";
import SvgBlueCheckbox from "~/components/Icon/icons/SvgBlueCheckbox";
import { Radio } from "~/components/Input";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { OptionListItemProps } from "./types";
import { useUser } from "~/presentation/core/contexts/UserContext";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { useCallback } from "react";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { IconButton } from "~/components/IconButton";
import { Icon } from "~/components/Icon";
import { SvgCopy } from "~/components/Icon/icons";

export function OptionListItem({
  order,
  control,
  options,
  adultNumber,
  roomTypes,
  item,
  formData,
  setValue,
  onItemExpand,
  onOpenOptionDetails,
  ...props
}: OptionListItemProps) {
  const { user } = useUser();
  const { orderReducer } = useOrder();
  const isMobile = useMobile();
  const isAgency = user?.context === UserContext.Agency;
  const isQuotingStatus = order?.status === OrderStatus.QUOTING;
  const isOpenStatus = order?.status === OrderStatus.QUOTED;
  const isAprovedStatus = [
    OrderStatus.APPROVED,
    OrderStatus.ISSUED,
    OrderStatus.PENDING_ISSUE,
  ].includes(order?.status as OrderStatus);

  const optionsFiltered = options?.filter((option) => {
    return isAprovedStatus ? option.status === OrderItemStatus.QUOTED : true;
  });

  const onCopyText = useCallback((value: string) => NavigatorUtils.copyToClipboard(value), []);

  const renderCopyTracker = useCallback(
    (tracker: string) => (
      <Tooltip content={<TooltipLabel>Copiar localizador</TooltipLabel>}>
        <IconButton onClick={() => onCopyText(tracker)} size="md">
          <Icon as={SvgCopy} />
        </IconButton>
      </Tooltip>
    ),
    [onCopyText]
  );

  const onSelectOption = (selectedOption: OrderHotelOfflineOption) => {
    if (!options) return;

    const index = options.findIndex(({ uuid }) => uuid === selectedOption.uuid);

    if (index < 0) return;

    options.forEach((option, i) => {
      if (i === index) {
        option.status = OrderItemStatus.QUOTED;
      } else {
        option.status = OrderItemStatus.QUOTING;
      }
    });

    const newRoom = { ...item, options } as OrderHotelItem;

    onItemExpand(newRoom);

    const rooms = order?.items.hotel?.rooms.map((room) => {
      return {
        ...room,
        options: room.options?.map((option) => {
          return selectedOption.uuid === option.uuid
            ? {
                ...option,
                status: OrderItemStatus.QUOTED,
              }
            : option;
        }),
      };
    }) as OrderHotelItem[];

    const hotelItem = { ...order?.items?.hotel, rooms };

    const items = {
      ...order?.items,
      hotel: hotelItem as OrderHotelItemType,
    };

    orderReducer?.dispatch({
      type: "update",
      payload: {
        ...order,
        items: items as Order["items"],
      } as Order,
    });
  };

  return (
    <>
      {optionsFiltered?.map((option, index) => (
        <Box
          css={{ mt: "$6" }}
          key={option.uuid || `option-${option.description}-${option.address}`}
        >
          <Card
            {...props}
            css={{
              cursor: "pointer",
              border: formData.optionId === option.uuid ? "3px solid $primary-base" : undefined,
              borderRadius: "$md",
            }}
            key={option.uuid}
          >
            <CardBody
              css={{
                p: "0",
              }}
            >
              <Form>
                <Flex
                  css={{ width: "100%", p: "0" }}
                  direction={{ "@mxlg": "column" }}
                  justify="between"
                >
                  <Flex css={{ width: "100%", p: "$6" }} direction="column" gap="6">
                    <Flex direction="column" gap="8">
                      <Flex gap="4" direction="column">
                        {isMobile ? (
                          <Flex align="center">
                            <Text as="h1" size={{ "@mxlg": "4" }}>
                              {option.description} Ver detalhes
                            </Text>
                            {isQuotingStatus && (
                              <Button
                                variant="tertiary"
                                css={{
                                  "@mxlg": {
                                    ml: "$12",
                                    height: "$3",
                                    p: "$3",
                                  },
                                }}
                                onClick={() => onOpenOptionDetails(item!, option)}
                              >
                                Ver detalhes
                              </Button>
                            )}
                          </Flex>
                        ) : (
                          <Flex justify="between">
                            <Text as="h2" size={{ "@mxlg": "5" }}>
                              {option.description}
                            </Text>

                            <Text variant="darkest">
                              <Flex align="center">
                                {item?.tracker && item.tracker}
                                {item?.tracker && renderCopyTracker(item.tracker as string)}
                              </Flex>
                            </Text>
                          </Flex>
                        )}

                        <Text as="h3" size={{ "@mxlg": "4" }}>{`Quarto ${roomTypes[0]}`}</Text>
                      </Flex>
                    </Flex>

                    <Flex direction="column" gap="8">
                      <Flex>
                        <Flex gap="2">
                          <Text variant="black" size="3">
                            {item?.cityName}
                            {option.address ? " -> " : ""}
                          </Text>
                          <Text variant="black" size="3">
                            {option.address}
                          </Text>
                        </Flex>
                      </Flex>
                      <Text
                        size={{ "@mxlg": "3" }}
                        variant="darkest"
                        css={{
                          flex: "1",
                        }}
                      >
                        {item?.roomRegimen}
                      </Text>

                      <Flex>
                        <Text size={{ "@mxlg": "3" }} css={{ flex: "0 0 50%" }}>
                          Contato(s): <Text variant="darkest">{options?.at(index)?.phone}</Text>
                        </Text>
                        <Text size={{ "@mxlg": "3" }}>
                          <Text
                            css={{
                              "@mxlg": {
                                display: "none",
                              },
                            }}
                          >
                            Email:{" "}
                          </Text>
                          <Text css={{ "@mxlg": { wordBreak: "break-all" } }} variant="darkest">
                            {option?.email}
                          </Text>
                        </Text>
                      </Flex>

                      <Flex>
                        <Text size={{ "@mxlg": "3" }}>
                          Observações:{" "}
                          <Text variant="darkest">
                            {isAgency ? option?.obsAgency : option.obsIssuer}
                          </Text>
                        </Text>
                      </Flex>
                      <Flex>
                        <Text
                          size={{ "@mxlg": "3" }}
                          css={{
                            flex: "1",
                          }}
                        >
                          Check-in:{" "}
                          <Text variant="darkest">
                            {displayDate(option?.checkIn as Date, DateFormats.LONG_DATE_TIME)}
                          </Text>
                        </Text>
                        <Text
                          size={{ "@mxlg": "3" }}
                          css={{
                            flex: "1",
                          }}
                        >
                          Check-out:{" "}
                          <Text variant="darkest">
                            {displayDate(option?.checkOut as Date, DateFormats.LONG_DATE_TIME)}
                          </Text>
                        </Text>
                      </Flex>

                      <Flex gap="2">
                        {option?.amenities?.map((amenity) => (
                          <Tag key={amenity.uuid} variant="info-light">
                            {amenity.name}
                          </Tag>
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex
                    align="center"
                    direction="column"
                    justify="between"
                    css={{
                      width: "35%",
                      border: "1px $neutrals-light",
                      borderStyle: "none none none dotted",
                      p: "$6",
                      "@mxlg": {
                        pt: "0",
                        width: "100%",
                      },
                    }}
                    gap="4"
                  >
                    <Flex
                      direction="column"
                      gap="4"
                      align="center"
                      css={{
                        "@mxlg": {
                          width: "100%",
                        },
                      }}
                    >
                      <Text
                        css={{
                          "@mxlg": {
                            ta: "start",
                            width: "100%",
                          },
                        }}
                      >
                        <Text variant="darkest">{`${
                          diffBetweenDates(option?.checkOut, option?.checkIn) + 1
                        } diárias, `}</Text>
                        {!!adultNumber && (
                          <Text variant="darkest">{`${adultNumber} Adulto${
                            adultNumber > 1 ? "s" : ""
                          }`}</Text>
                        )}
                      </Text>
                      <Text
                        fw="600"
                        size={{ "@mxlg": "5" }}
                        css={{
                          "@mxlg": {
                            ta: "start",
                            width: "100%",
                          },
                        }}
                      >
                        {MaskUtils.asCurrency(option.customerValue)}
                      </Text>
                    </Flex>

                    {isOpenStatus && (
                      <FormControl control={control} name="optionId">
                        <Flex
                          align="center"
                          css={{
                            border:
                              formData.optionId !== option.uuid
                                ? "3px solid $primary-base"
                                : undefined,
                            p: "$4",
                            borderRadius: "$md",
                          }}
                          onClick={() => {
                            onSelectOption?.(option);
                            setValue("optionId", option.uuid);
                          }}
                        >
                          {formData.optionId === option.uuid ? (
                            <SvgBlueCheckbox />
                          ) : (
                            <Radio
                              checked={formData.optionId === option.uuid}
                              value={option.uuid}
                              size="md"
                            />
                          )}

                          <Text
                            fw="600"
                            size="2"
                            css={{ ml: "$2", mb: "$0.95", cursor: "pointer" }}
                          >
                            {formData.optionId === option.uuid
                              ? "Cotação selecionada"
                              : "Selecionar cotação"}
                          </Text>
                        </Flex>
                      </FormControl>
                    )}
                  </Flex>
                </Flex>
              </Form>
            </CardBody>
          </Card>
        </Box>
      ))}
    </>
  );
}
