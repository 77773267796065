import { AdvanceOrderStatus, ApprovalTypeEnum, TravelerAdvance, User } from "~/application/types";
import { IFindExpenseByAdvanceOrderResult } from "~/application/usecases/AccountabilityExpense/IAccountabilityExpenseService";
import { ApproversListProps } from "../../../../../../../../presentation/shared/components/ApproversList";

export const POLICY_TYPE_VALUE = {
  touchable_less: "Alterável para menos",
  touchable_more_less: "Alterável para mais e menos",
  untouchable: "Inalterável",
  amount_maximum: "Quantidade Máxima",
};

export function userIsExpenseApprover(user: User, advanceOrder?: TravelerAdvance): boolean {
  return advanceOrder && advanceOrder.approvers
    ? advanceOrder.approvers.findIndex(({ id }) => id === user.profiles.customer.uuid) >= 0
    : false;
}

export function canShowExpenseApprovers(result?: IFindExpenseByAdvanceOrderResult): boolean {
  const advanceOrderStatusIsDisapprovedOrApproving = [
    AdvanceOrderStatus.DISAPPROVED,
    AdvanceOrderStatus.APPROVING,
  ].includes(result?.advanceOrder.status as AdvanceOrderStatus);

  return (advanceOrderStatusIsDisapprovedOrApproving &&
    (result?.approvers?.length || 0) > 0) as boolean;
}

export function getExpenseApprovers(
  result?: IFindExpenseByAdvanceOrderResult
): ApproversListProps["approvers"] {
  return (result?.approvers || []).map(({ id, ...props }) => ({
    uuid: id,
    ...props,
  })) as ApproversListProps["approvers"];
}
