import type { DeepPartial, OrderRoadItem } from "~/application/types";
import { RoadIssuanceFormData } from "./types";
import { Agreement } from "~/application/types/entities/BindCredential.type";

export type CreateFormDataProps = {
  data: OrderRoadItem;
  agreements?: Agreement[];
};

export function createFormData({
  data,
  agreements,
}: CreateFormDataProps): DeepPartial<RoadIssuanceFormData> {
  const { uuid, travelers, tracker, providerUnitFee, fare, provider, fee, markup, providerFees } = data;

  const agreementCode = agreements?.find(({ credential }) => credential.provider.name === provider)?.codeAgreement;

  return {
    issuance: {
      uuid,
      travelers: travelers.map((traveler) => ({
        fee,
        markup,
        uuid: traveler.uuid,
        tracker,
        providerFees: providerUnitFee,
        fare,
        provider: {
          name: agreementCode,
          uuid: agreements?.find(({ credential }) => credential.provider.name === provider)?.uuid,
        },
        seats: traveler.seats.map((seat) => ({
          ticket: seat.ticket,
          seatNumber: seat.seatNumber,
          bpe: seat.bpe,
          isConnection: seat.isConnection,
          seatType: seat.seatType,
        })),
      })),
    },
  };
}

export function parseFormData({ issuance }: RoadIssuanceFormData) {
  
  return {
    uuid: issuance.uuid,
    travelers: issuance.travelers.map((traveler) => ({
      paymentMethod: {
        uuid: traveler?.paymentMethod?.uuid,
        description: traveler?.paymentMethod?.description
      },
      uuid: traveler.uuid,
      tracker: traveler.tracker,
      value: traveler.value,
      providerId: traveler.provider.uuid,
      providerFees: traveler.providerFees,
      fare: traveler.fare,
      markup: traveler.markup,
      fee: traveler.fee,
      seats: traveler.seats.map((seat) => ({
        ticket: seat.ticket,
        seatNumber: seat.seatNumber,
        bpe: seat.bpe,
        isConnection: seat.isConnection,
        seatType: seat.seatType,
      })),
    })),
  };
}
