import { CustomerEmployeesGroup } from "~/application/types";
import { CustomerEmployeesGroupDTO } from "../dtos";
import { mapShortApprovalModelDTO } from "./mapApprovalModelDTO";

export const mapCustomerEmployeesGroupDTO = (
  data: CustomerEmployeesGroupDTO
): CustomerEmployeesGroup => {
  return {
    uuid: data.uuid,
    description: data.description,
    customerId: data.customer_uuid,
    active: data.active,
    approvalModels: data.approval_models?.map(mapShortApprovalModelDTO),
  };
};
