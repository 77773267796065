import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { OrderStatus, RemakeSearchData } from "~/application/types";
import { customerOrderService, orderMessageService, orderService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useRemakeSearch } from "~/core/shared/contexts/remakeSearchContext";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { ErrorRemakeSearchDialog } from "~/presentation/shared/components/ErrorRemakeSearchDialog";
import { getOrderStatus } from "~/presentation/shared/utils";
import { logError } from "~/presentation/shared/utils/errors";
import { log } from "~/utils/log";
import { AgenciesOrdersContainer } from "./components/AgenciesOrdersContainer";
import { useAgencyOrdersTab } from "./hooks/useAgencyOrderTab";
import { LOG_TAG, SNACKBAR_MESSAGES } from "./types";
import { groupOrderByDate } from "./utils";

export type OrderSearch = {
  readonly orderNumber: string;
  readonly employeeName: string;
  readonly issuerName: string;
  readonly approverName: string;
  readonly customerName: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly tracker: string;
};

export function AgencyOrdersPage() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searches, setSearches] = useState<OrderSearch | undefined>();
  const [search] = useDebounce<typeof searches>(searches, 700);
  const { user } = useAuth();
  const tabOrderStatus = useAgencyOrdersTab();
  const { setState } = useRemakeSearch();
  const navigate = useNavigate();

  const status =
    tabOrderStatus === OrderStatus.APPROVED
      ? [tabOrderStatus, OrderStatus.PENDING_ISSUE]
      : [tabOrderStatus];

  const { data, isLoading } = useQuery(
    [QueryKeys.CUSTOMER_ORDERS, user.agency.uuid, currentPage, tabOrderStatus, search],
    () =>
      customerOrderService.find({
        page: currentPage,
        status: search ? undefined : status,
        orderNumber: search?.orderNumber,
        customerName: search?.customerName,
        employeeName: search?.employeeName,
        issuerName: search?.issuerName,
        approverName: search?.approverName,
        startDate: search?.startDate,
        endDate: search?.endDate,
        tracker: search?.tracker,
      }),
    {
      staleTime: QueryTimes.SMALL,
      refetchOnMount: "always",
      refetchInterval: 1000 * 60 * 0.5, // 30 seconds
      refetchOnWindowFocus: true,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const onIssuerNotify = () =>
    useMutation(async (orderUuid: string) => await orderMessageService.notify(orderUuid), {
      onMutate: (item) => {
        log.i(LOG_TAG, `Notifying Issuer(${item})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully Notify Issuer(${item})`);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ISSUER_SUCCESS_NOTIFY, "success");
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.ISSUER_ERROR_NOTIFY, "error");
      },
    });

  const useRefetchRoadQuery = (orderUuid: string) => {
    const { refetch, isLoading } = useQuery<RemakeSearchData>(
      [QueryKeys.REORDER, orderUuid],
      async () => await orderService.remakeSearch(orderUuid),
      {
        enabled: false,
        onSuccess: (data) => {
          setState({
            data,
            finishStep: {
              airway: false,
              road: false,
            },
          });
        },
        onError: () => {
          navigate("/busca/passagens-onibus", {});
          dialogService.showDialog(<ErrorRemakeSearchDialog />);
        },
      }
    );

    return { refetch, isLoading };
  };

  const { mutate: cancelOrder } = useMutation(
    (orderUuid: string) => orderService.cancel(orderUuid),
    {
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar("Falha ao cancelar o pedido", "error");
      },
    }
  );

  const { data: orderSummaryStatus } = useQuery(
    [QueryKeys.ORDERS_SUMMARY],
    async () => await customerOrderService.summary({}),
    {}
  );

  const orderSummaryStatusMemo = useMemo(() => {
    return orderSummaryStatus?.byStatus.map((s) => {
      return {
        id: s.status,
        total: s.total,
        title: getOrderStatus(s.status),
      };
    });
  }, [orderSummaryStatus]);

  const ordersGroupsList = useMemo(
    () => data?.data && groupOrderByDate(data.data.orders),
    [data?.data.orders]
  );

  const handleOrderNumberChangeSearch = useCallback((text: string) => {
    if (/\D/.test(text)) {
      return;
    }

    setSearches((old) => ({ ...old, orderNumber: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleCustomerNameChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, customerName: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleEmployeeNameChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, employeeName: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleIssuerNameChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, issuerName: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleApproverNameChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, approverName: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleStartDateChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, startDate: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleEndDateChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, endDate: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleTrackerChangeSearch = useCallback((text: string) => {
    setSearches((old) => ({ ...old, tracker: text } as OrderSearch));
    setCurrentPage(1);
  }, []);

  const handleCleanFilters = useCallback(() => {
    setSearches(undefined);
    setCurrentPage(1);
  }, []);

  return (
    <AgenciesOrdersContainer
      searches={searches}
      setStartDate={handleStartDateChangeSearch}
      setEndDate={handleEndDateChangeSearch}
      setOrderNumber={handleOrderNumberChangeSearch}
      setCustomerName={handleCustomerNameChangeSearch}
      setEmployeeName={handleEmployeeNameChangeSearch}
      setIssuerName={handleIssuerNameChangeSearch}
      setApproverName={handleApproverNameChangeSearch}
      setTracker={handleTrackerChangeSearch}
      onClearFilters={handleCleanFilters}
      isLoading={isLoading}
      ordersGroupsList={ordersGroupsList}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      onGoToPage={setCurrentPage}
      activeStatusTab={tabOrderStatus}
      tabSummary={orderSummaryStatusMemo}
      onIssuerNotify={onIssuerNotify}
      onRefetchRoadQuery={useRefetchRoadQuery}
      onCancelOrder={cancelOrder}
    />
  );
}
