import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { customerService, orderMessageService, orderService } from "~/application/usecases";
import { orderHistoryService } from "~/application/usecases/OrderHistory";
import { OrderProviderService } from "~/application/usecases/Provider";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { useOrderItems as agencyUseOrderItem } from "~/core/modules/Agency/pages/AgencyOrderPage/hooks/useOrderItems";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { OrderProvider } from "~/presentation/shared/hooks/useOrder";
import { logError } from "~/presentation/shared/utils/errors";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { ManageOrderContainer } from "./ManageOrderContainer";
import { useOrderItems } from "./hooks/useOrderItems";
import { useOrderTab } from "./hooks/useOrderTab";
import { OrderTab } from "./utils";
import { useApprovalOrder } from "../OrderPage/views/OrderItem/hooks/useApprovalOrder";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { OrderStatus } from "~/application/types";
import { fa } from "@faker-js/faker";

const LOG_TAG = "Order/ManageOrderPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar pedido",
  QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE: "Falha ao cotar pedido",
  QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE: "Pedido cotado com sucesso",
  LOAD_ORDER_MESSAGES_ERROR_MESSAGE: "Falha ao carregar mensagens do pedido",
  LOAD_ORDER_HISTORY_ERROR_MESSAGE: "Falha ao carregar histórico do pedido",
} as const;

export function ManageOrderPage() {
  const { orderId } = useParams() as { orderId: string };
  const { user } = useUser();

  const tabValue = useOrderTab();

  const {
    data,
    isFetching,
    refetch: refetchOrder,
  } = useQuery([QueryKeys.ORDERS, orderId], () => orderService.findById(orderId), {
    staleTime: QueryTimes.LONG,
    enabled: !!orderId,
    refetchOnWindowFocus: true,
    onError: (error) => {
      log.e(LOG_TAG, error);

      snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
    },
  });

  const {
    data: orderHistory,
    isLoading: isLoadingOrderHistory,
    refetch: refetchOrderHistory,
  } = useQuery([QueryKeys.ORDER_HISTORY], async () => await orderHistoryService.get(orderId), {
    staleTime: QueryTimes.SMALLEST,
    refetchOnMount: "always",
    enabled: !!orderId,
    onError: (error) => {
      logError({
        logTag: LOG_TAG,
        error,
        defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ORDER_HISTORY_ERROR_MESSAGE,
      });
    },
  });

  const { data: providers, isLoading: isLoadingProviders } = useQuery(
    [QueryKeys.PROVIDERS, data],
    () => OrderProviderService.find(),
    {
      staleTime: QueryTimes.LONG,
      enabled: !!data?.customer.uuid,
      refetchOnWindowFocus: true,
    }
  );

  const { data: agreements, isLoading: isLoadingAgreements } = useQuery(
    [QueryKeys.AGREEMENTS, data],
    () => OrderProviderService.findAgreements({ agreementId: data?.customer.uuid }),
    {
      staleTime: QueryTimes.LONG,
      enabled: !!data?.customer.uuid,
      refetchOnWindowFocus: true,
    }
  );

  const { data: paymentMethod, isLoading: isLoadingPaymentMethod } = useQuery(
    [QueryKeys.HOTEL_OFF_PAYMENT_METHOD],
    () => orderService.paymentMethod(),
    {
      refetchOnWindowFocus: true,
    }
  );

  const { mutate: mutateSendOfflineQuote } = useMutation(
    (orderId: string) => orderService.quote(orderId),
    {
      onMutate: () => {
        dialogService.showDialog(<LoadingDialog message="Enviando cotação" />);
      },
      onError: (error) => {
        dialogService.popDialog();

        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.QUOTE_OFFLINE_HOTEL_ERROR_MESSAGE,
        });
      },
      onSuccess: () => {
        dialogService.popDialog();
        queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.QUOTE_OFFLINE_HOTEL_SUCCESS_MESSAGE,
          "success",
          5000
        );
      },
    }
  );

  const { data: additionalInfo, isLoading: isLoadingAdditionalInfo } = useQuery(
    [data?.customer.uuid],
    async () =>
      await customerService.findById(data?.customer.uuid ?? "", {
        withApprovers: true,
        withIssuers: true,
        withAdditionalInfo: true,
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ORDER_HISTORY_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: orderMessages, isLoading: isLoadingOrderMessages } = useQuery(
    [QueryKeys.ORDER_MESSAGES, orderId],
    () => orderMessageService.find(orderId),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnWindowFocus: true,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ORDER_MESSAGES_ERROR_MESSAGE, "error");
      },
    }
  );

  const orderItemsState = useOrderItems({
    orderId,
    order: data,
    providers,
    agreements,
    isLoadingPaymentMethods: isLoadingPaymentMethod,
    paymentMethods: paymentMethod,
    isLoadingProviders,
    isLoadingAgreements,
    enabled: tabValue === OrderTab.ITEMS,
  });

  const agencyOrderItemsState = agencyUseOrderItem({
    orderId,
    order: data,
    enabled: tabValue === OrderTab.ITEMS,
    refetchOrder,
    refetchOrderHistory,
  });

  const { onConsultOrderItemsPrice } = useApprovalOrder({
    refetchOrder,
    refetchOrderHistory,
    onOrderIssued: () => {
      refetchOrder();
      refetchOrderHistory();
    },
  });

  const {
    isAdvanceItemExpanded,
    isAirwayItemExpanded,
    isHotelItemExpanded,
    isVehicleItemExpanded,
    isRoadItemExpanded,
    toggleAdditionalInfoVisible,
    toggleAirwayItemVisible,
    toggleHotelItemVisible,
    toggleRoadItemVisible,
    toggleVehicleItemVisible,
    onQuoteOfflineHotel,
    onOpenHotelOptionDetails,
  } = agencyOrderItemsState;

  const orderItemsProps = {
    ...orderItemsState,
    isLoadingPaymentMethod,
    isAdvanceItemExpanded,
    isAirwayItemExpanded,
    isHotelItemExpanded,
    isFetching,
    isVehicleItemExpanded,
    isRoadItemExpanded,
    toggleAdditionalInfoVisible,
    toggleAirwayItemVisible,
    toggleHotelItemVisible,
    toggleRoadItemVisible,
    toggleVehicleItemVisible,
    onQuote: onQuoteOfflineHotel,
    onOpenHotelOptionDetails,
    onSendOfflineQuote: mutateSendOfflineQuote,
    onConsultOrderItemsPrice,
  };

  const canShowAssignConsultantDialog = [
    OrderStatus.ISSUED,
    OrderStatus.CANCELED,
    OrderStatus.QUOTED,
  ].includes(data?.status as OrderStatus);

  const shouldExecuteOnAssignConsultant =
    !canShowAssignConsultantDialog &&
    data &&
    (!data?.consultant || data?.consultant?.uuid !== user.profiles.agency.uuid);

  useEffect(() => {
    if (shouldExecuteOnAssignConsultant) {
      orderItemsState.onAssignConsultant();
    }
  }, [data, canShowAssignConsultantDialog]);

  return (
    <OrderProvider order={data}>
      <ManageOrderContainer
        isLoading={isFetching}
        isLoadingPaymentMethod={isLoadingPaymentMethod}
        activeTab={tabValue}
        onConsultOrderItemsPrice={onConsultOrderItemsPrice}
        order={data}
        paymentMethod={paymentMethod}
        isLoadingAdditionalInfo={isLoadingAdditionalInfo}
        additionalInfo={additionalInfo}
        orderItemsState={orderItemsProps}
        orderMessages={orderMessages || []}
        isLoadingOrderMessages={isLoadingOrderMessages}
        isLoadingOrderHistory={isLoadingOrderHistory}
        orderHistory={orderHistory}
      />
    </OrderProvider>
  );
}
