import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import {
  ApiError,
  ExpensePolicy,
  ExpenseType,
  PaginatedResource,
  Policy,
  PolicyParameterExpense,
} from "~/application/types";
import {
  expenseCategoryService,
  expensePolicyService,
  expenseTypeService,
  policyParameterExpenseService,
  policyService,
} from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { ExpensePolicyDialog } from "~/presentation/shared/views/ExpensePolicyDialog";
import { InactivateDialog } from "~/presentation/shared/views/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { ExpensePoliciesFilters } from "./types";

const LOG_TAG = "CustomerSettings/ExpensePolicies";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar regras",
  CREATE_SUCCESS_MESSAGE: "Regra criada",
  CREATE_ERROR_MESSAGE: "Falha ao criar regra",
  UPDATE_SUCCESS_MESSAGE: "Regra atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar regra",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar regra",
  ACTIVATE_SUCCESS_MESSAGE: "Regra ativada",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar regra",
  INACTIVATE_SUCCESS_MESSAGE: "Regra inativada",
} as const;

const EMPTY_EXPENSE_POLICIES_FILTERS: ExpensePoliciesFilters = {
  expenseTypes: [],
  policies: [],
  policyParameterExpenses: [],
};

export function useExpensePoliciesPage() {
  const { contexts } = useUser();

  const customerId = contexts.customer.uuid;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [filters, setFilters] = useState<ExpensePoliciesFilters>(
    EMPTY_EXPENSE_POLICIES_FILTERS
  );

  const { data, isFetching } = useQuery<
    PaginatedResource<ExpensePolicy[]>,
    ApiError
  >(
    [QueryKeys.CUSTOMER_EXPENSE_POLICIES, customerId, currentPage, filters],
    () =>
      expensePolicyService.find({
        page: currentPage,
        customerId,
        expenseTypes: filters.expenseTypes,
        policies: filters.policies,
        policyParameterExpenses: filters.policyParameterExpenses,
      }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: handleCreateExpensePolicy } = useMutation(
    (item: ExpensePolicy) => expensePolicyService.create({ ...item }),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating ExpensePolicy(${item.uuid})`);
      },
      onSuccess: (item) => {
        log.i(LOG_TAG, `Successfully created ExpensePolicy(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EXPENSE_POLICIES]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: handleUpdateExpensePolicy } = useMutation(
    (item: ExpensePolicy) => expensePolicyService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ExpensePolicy(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ExpensePolicy(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EXPENSE_POLICIES]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: ExpensePolicy) => expensePolicyService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ExpensePolicy(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ExpensePolicy(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EXPENSE_POLICIES]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const fetchExpenseCategories = useCallback(
    () =>
      expenseCategoryService
        .find({})
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );

  const fetchPolicyParameterExpenses = useCallback(
    () => policyParameterExpenseService.find(),
    []
  );

  const fetchPolicies = useCallback(
    () =>
      policyService
        .find({ customerId })
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    [customerId]
  );

  const fetchExpenseTypes = useCallback(
    () =>
      expenseTypeService
        .find({ customerId })
        .then((values) => values.data)
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    [customerId]
  );

  const handleToggleState = useCallback((item: ExpensePolicy) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog onConfirm={() => mutateToggleState(item)} />
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <ExpensePolicyDialog
        fetchExpenseCategories={fetchExpenseCategories}
        fetchExpenseTypes={fetchExpenseTypes}
        fetchPolicies={fetchPolicies}
        fetchPolicyParameterExpenses={fetchPolicyParameterExpenses}
        onSubmit={handleCreateExpensePolicy}
      />
    );
  }, [
    fetchExpenseCategories,
    fetchExpenseTypes,
    fetchPolicies,
    fetchPolicyParameterExpenses,
  ]);

  const handleOpenEditModal = useCallback(
    (item: ExpensePolicy) => {
      dialogService.showDialog(
        <ExpensePolicyDialog
          data={item}
          fetchExpenseCategories={fetchExpenseCategories}
          fetchExpenseTypes={fetchExpenseTypes}
          fetchPolicies={fetchPolicies}
          fetchPolicyParameterExpenses={fetchPolicyParameterExpenses}
          onSubmit={handleUpdateExpensePolicy}
        />
      );
    },
    [
      fetchExpenseCategories,
      fetchExpenseTypes,
      fetchPolicies,
      fetchPolicyParameterExpenses,
    ]
  );

  const handleChangeFilterPolicies = useCallback((data: Policy[]) => {
    setFilters((old) => ({ ...old, policies: data }));
    setCurrentPage(1);
  }, []);

  const handleChangeFilterPolicyParameterExpenses = useCallback(
    (data: PolicyParameterExpense[]) => {
      setFilters((old) => ({ ...old, policyParameterExpenses: data }));
      setCurrentPage(1);
    },
    []
  );

  const handleChangeFilterExpenseTypes = useCallback((data: ExpenseType[]) => {
    setFilters((old) => ({ ...old, expenseTypes: data }));
    setCurrentPage(1);
  }, []);

  const handleCleanFilters = useCallback(() => {
    setFilters(EMPTY_EXPENSE_POLICIES_FILTERS);
    setCurrentPage(1);
  }, []);

  return {
    data: data?.data,
    isLoading: isFetching,
    currentPage: currentPage,
    selectedFilters: filters,
    lastPage: data?.meta.last_page ?? 0,
    onGoToPage: setCurrentPage,
    onCreateExpensePolicy: handleOpenCreateModal,
    onEditExpensePolicy: handleOpenEditModal,
    onToggleExpensePolicyState: handleToggleState,
    fetchExpenseTypes,
    fetchPolicyParameterExpenses,
    fetchPolicies,
    onChangeFilterPolicies: handleChangeFilterPolicies,
    onChangeFilterPolicyParameterExpenses:
    handleChangeFilterPolicyParameterExpenses,
    onChangeFilterExpenseTypes: handleChangeFilterExpenseTypes,
    onCleanFilters: handleCleanFilters,
  };
}
