import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Order } from "~/application/types";
import { MaskUtils } from "~/application/utils";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col } from "~/components/Grid";
import { MaskedInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { dayjs, validateDate } from "~/utils/date.utils";

const editOrderExpirationDateSchema = yup.object().shape({
  expiresAt: yup
    .string()
    .required("A data de expiração é obrigatória")
    .test("is-valid-date", "Data inválida", (value) => validateDate(value)),
});

type EditOrderExpirationDateSchema = yup.InferType<typeof editOrderExpirationDateSchema>;

export type EditOrderExpirationDateDialogProps = {
  data: Order;
  onCloseClick?: () => void;
  onSubmit: (data: Order) => void;
};

export const EditOrderExpirationDateDialog = ({ data, onSubmit, onCloseClick }: EditOrderExpirationDateDialogProps) => {
  const { control, formState, handleSubmit } = useForm<EditOrderExpirationDateSchema>({
    resolver: yupResolver(editOrderExpirationDateSchema),
  });

  const handleEditOrderExpirationDate = ({ expiresAt }: EditOrderExpirationDateSchema) => {
    expiresAt = expiresAt.split("/").reverse().join("-");
    onSubmit({ ...data, expirationDate: dayjs(expiresAt).startOf("D").toDate() });
  };

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(handleEditOrderExpirationDate)}>
        <FormDialog
          title="Editar data de expiração"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>Aplicar</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Col sz="12">
              <FormControl name="expiresAt" control={control} required>
                <FieldLabel>Data de expiração</FieldLabel>
                <MaskedInput placeholder="DD/MM/AAAA" mask={MaskUtils.MASK_DATE} />
                {formState.errors.expiresAt && (
                  <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                    {formState.errors.expiresAt.message}
                  </Text>
                )}
              </FormControl>
            </Col>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};