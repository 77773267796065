import { Fragment, useCallback } from "react";
import { CustomerSettingParameter } from "~/application/types";
import { AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { AppBar } from "~/components/AppBar/AppBar";
import { Container } from "~/components/Container";
import { EmptyState } from "~/components/EmptyState";
import { LazyList } from "~/components/LazyList";
import { Skeleton } from "~/components/Skeleton";
import { Text } from "~/components/Text";
import { SettingParameterListItem } from "./components/SettingParameterListItem";
import { UseSettingParametersResult } from "./hooks/useSettingParameters";

export interface SettingParametersContainerProps {
  isLoading: boolean;
  data?: CustomerSettingParameter[];
  onEditClick: UseSettingParametersResult["onEditClick"];
  isUpdatingCustomerSettingParameter: boolean;
}

export function SettingParameterContainer({
  data,
  isLoading,
  onEditClick,
  isUpdatingCustomerSettingParameter,
}: SettingParametersContainerProps) {
  const listRenderer = useCallback(
    (item: CustomerSettingParameter) => {
      return (
        <SettingParameterListItem
          data={item}
          key={item.uuid}
          onEditClick={onEditClick}
          isUpdating={isUpdatingCustomerSettingParameter}
        />
      );
    },
    [isUpdatingCustomerSettingParameter, onEditClick]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading
            title={
              isLoading ? (
                <Skeleton variant="text-6" style={{ maxWidth: 360 }} />
              ) : (
                "Parâmetros de configurações"
              )
            }
            description="Parâmetros de configurações de empresas"
          />
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20", "@mxlg": { p: "40px" }}}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={10}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você não possui parâmetros de empresa cadastrados</Text>
            </EmptyState>
          }
        />
      </Container>
    </Fragment>
  );
}
