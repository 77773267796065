import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Branch, DeepPartial } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { SvgKey } from "~/components/Icon/icons";
import { MaskedInput, TextInput } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import * as MaskUtils from "~/utils/mask.utils";

const branchSchema = yup.object().shape({
  cnpj: yup
    .string()
    .matches(MaskUtils.PATTERN_CNPJ, "CNPJ inválido")
    .required("O CNPJ é obrigatório"),
  integrationId: yup.string().nullable(),
  name: yup.string().required("O nome da filial é obrigatório"),
  email: yup.string().email("E-mail inválido").required("O e-mail é obrigatório"),
  phone: yup.string().min(18, "Telefone inválido").required("O telefone é obrigatório"),
});

export type BranchSchema = yup.InferType<typeof branchSchema>;

export interface BranchDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<Branch>;
  onCloseClick?: () => void;
  onSubmit: (data: Branch) => void;
}

export function BranchDialog({ isNew, defaultData, onCloseClick, onSubmit }: BranchDialogProps) {
  const { control, watch, formState, handleSubmit } = useForm<BranchSchema>({
    defaultValues: {
      ...defaultData,
      cnpj: MaskUtils.asCNPJ(defaultData?.cnpj ?? ""),
      phone: MaskUtils.asPhoneNumber(defaultData?.phone ?? ""),
    },
    resolver: yupResolver(branchSchema),
  });

  const { phone } = watch();

  if (formState.isSubmitting) {
    return (
      <LoadingModal
        isOpen={formState.isSubmitting}
        message={isNew ? "Criando filial" : "Editando filial"}
      />
    );
  }

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit((data) => onSubmit(data as Branch))}>
        <FormDialog
          title={isNew ? "Nova filial" : "Editar filial"}
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              {formState.isSubmitting && (
                <Spinner
                  css={{
                    borderLeftColor: "$neutrals-white",
                    width: "$4",
                    height: "$4",
                    borderWidth: "2px",
                  }}
                />
              )}
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6">
              <Col sz="6">
                <FormControl name="cnpj" control={control} required>
                  <FieldLabel>CNPJ</FieldLabel>
                  <MaskedInput mask={MaskUtils.MASK_CNPJ} placeholder="Digite o CNPJ da filial" />
                  {formState.errors.cnpj && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.cnpj.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="6">
                <FormControl name="integrationId" control={control}>
                  <FieldLabel>ID da integração</FieldLabel>
                  <TextInput leftIcon={SvgKey} placeholder="Número do ID" />
                  {formState.errors.integrationId && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.integrationId.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome da filial</FieldLabel>
                  <TextInput placeholder="Digite o nome da filial" />
                  {formState.errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="7">
                <FormControl name="email" control={control} required>
                  <FieldLabel>E-mail</FieldLabel>
                  <TextInput placeholder="Digite o nome da filial" />
                  {formState.errors.email && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.email.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="5">
                <FormControl name="phone" control={control} required>
                  <FieldLabel>Telefone da empresa</FieldLabel>
                  <MaskedInput mask={MaskUtils.pickPhoneNumberMask(phone)} />
                  {formState.errors.phone && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.phone.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
