import { Fee } from "~/application/types";
import { FeeDTO } from "../dtos";

export function mapFeeDTO(data: FeeDTO): Fee {
  return {
    uuid: data.uuid,
    name: data.name,
    items: data.items.map((item) => ({
      uuid: item.uuid,
      type: item.type,
      serviceType: item.service_type,
      value: parseFloat(item.value),
      offlineValue: parseFloat(item.offline_value),
      internationalValue: parseFloat(item.international_value),
    })),
    agencyId: data.agency_uuid,
    isActive: Boolean(data.active),
  };
}
