import { ApprovalModel, ApprovalTypeEnum, Approver, Level } from "~/application/types";
import { groupBy } from "~/utils/object.utils";

export const EMPTY_LEVEL = {
  approvers: [] as Approver[],
} as Level;

export type EditableApprovalModel = {
  uuid?: string;
  name: string;
  isAllBranches: boolean;
  approvalType?: ApprovalTypeEnum;
  markAllCostCenters: boolean;
  markAllProjects: boolean;
  markAllPhases: boolean;
  markAllGroups: boolean;
  markAllGroupApprovers: boolean;
  branchName?: {
    uuid?: string;
    name?: string;
  };
  costCenters: {
    uuid: string;
    name: string;
    isActive?: boolean;
  }[];
  projects: {
    uuid: string;
    name: string;
    isActive?: boolean;
  }[];
  phases: {
    uuid: string;
    name: string;
    isActive?: boolean;
  }[];
  groups: {
    uuid: string;
    description: string;
    isActive?: boolean;
  }[];
  groupApprovers: {
    uuid: string;
    description: string;
    isActive?: boolean;
  }[];
  applyExpense: boolean;
  expenseApprover?: {
    uuid?: string;
    name?: string;
  };
  outPolicyApprovalModel?: { uuid: string; name: string };
  levels: Level[];
};

interface GetDefaultValuesProps {
  isNew?: boolean;
  defaultData: ApprovalModel;
}

export function getDefaultValues({
  isNew,
  defaultData,
}: GetDefaultValuesProps): EditableApprovalModel {
  const levels = Object.entries(groupBy(defaultData?.approvers ?? [], "level")).map(
    ([_, approvers]) => ({ approvers })
  ) as Level[];

  let defaultValues = {
    ...defaultData,
    markAllCostCenters: false,
    markAllProjects: false,
    levels,
    markAllPhases: false,
    markAllGroups: false,
    markAllGroupApprovers: false,
  };

  if (levels.length === 0) {
    defaultValues.levels = [EMPTY_LEVEL];
  }

  if (isNew) {
    defaultValues = {
      ...defaultValues,
      name: "",
      costCenters: [],
      approvalType: ApprovalTypeEnum.COST_CENTER,
      isAllBranches: true,
      applyExpense: true,
    };
  }

  if (defaultData?.branch?.name || defaultData?.branch?.uuid) {
    return {
      ...defaultValues,
      branchName: defaultData.branch,
    };
  }

  return {
    ...defaultValues,
    branchName: undefined,
  };
}
