import { useCallback, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import { QueryKeys } from "~/application/constants";
import { OrderAirwaySegment } from "~/application/types";
import { orderAirwayService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { OrderFlightDetailsDialog } from "~/core/modules/Order/pages/OrderPage/components/OrderFlightDetails";
import { logError } from "~/presentation/shared/utils/errors";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { queryClient } from "~/services/queryClient";
import { LOG_TAG } from "./useOrderItems";
import { AddTrackerDialog } from "../components/Airway/AddTrackerDialog";

const SNACKBAR_MESSAGES = {
  ADD_TRACKER_SUCCESS_MESSAGE: "Localizador adicionado",
  ADD_TRACKER_ERROR_MESSAGE: "Falha ao adicionar localizador",
} as const;

export interface UseAirwayItemProps {
  orderId: string;
}

export interface UseAirwayItemResult {
  isAirwayItemExpanded: boolean;
  onOpenFlightDetails: (data: OrderAirwaySegment | undefined) => void;
  toggleAirwayItemVisible: () => void;
  onOpenAddTrackerDialog: (data: OrderAirwaySegment) => void;
}

export function useAirwayItem({ orderId }: UseAirwayItemProps): UseAirwayItemResult {
  const [isAirwayItemExpanded, setAirwayItemExpanded] = useState(true);

  const onOpenFlightDetails = useCallback((data: OrderAirwaySegment | undefined) => {
    const { segments, value, tracker } = data as OrderAirwaySegment;
    dialogService.showDialog(
      <OrderFlightDetailsDialog
        flightValue={value}
        data={segments}
        tracker={tracker}
        onCloseClick={dialogService.popDialog}
      />
    );
  }, []);

  const toggleAirwayItemVisible = useCallback(() => {
    setAirwayItemExpanded((old) => !old);
  }, []);

  const { mutate: addTracker } = useMutation({
    mutationFn: orderAirwayService.addTracker,
    onMutate: () => {
      dialogService.popDialog();
      dialogService.showDialog(<LoadingDialog message="Adicionando localizador" />);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.ORDERS, orderId]);
      snackbarService.showSnackbar(SNACKBAR_MESSAGES.ADD_TRACKER_SUCCESS_MESSAGE, "success");
    },
    onError: (error) => {
      logError({
        error,
        logTag: LOG_TAG,
        defaultErrorMessage: SNACKBAR_MESSAGES.ADD_TRACKER_ERROR_MESSAGE,
      });
    },
    onSettled: () => dialogService.popAll(),
  });

  const onOpenAddTrackerDialog = useCallback((data: OrderAirwaySegment) => {
    dialogService.showDialog(
      <AddTrackerDialog data={data} onSubmit={addTracker} />
    );
  }, []);

  return {
    isAirwayItemExpanded,
    toggleAirwayItemVisible,
    onOpenFlightDetails,
    onOpenAddTrackerDialog,
  };
}
