import { useMemo } from "react";
import { OrderHotelItem } from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgEye, SvgInfo, SvgTrash } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H3, H4, Label } from "~/components/Typography";
import { StarRating } from "~/core/shared/components/StarsRating";
import { DateFormats, dayjs, displayDate } from "~/utils/date.utils";
import * as MaskUtils from "~/utils/mask.utils";
import { formatSentence } from "~/utils/string.utils";
import { AmenitiesModal } from "./components/AmenitiesModal";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { ViolatedPoliciesButton } from "../ViolatedPoliciesButton/ViolatedPoliciesButton";
import { Image } from "~/components/Image";
import { getGuestAmounts } from "../../utils/string-functions/functions";
import { 
  AccommodationRulesDialog
 } from "~/core/modules/Booking/modules/BookingHotel/pages/HotelDetailsPage/views/SelectRooms/components/AccommodationRulesDialog";
import { Button } from "~/components/Button";

export interface OrderHotelCardProps {
  data: OrderHotelItem;
  onOpenDetails?: () => void;
  onOpenPolicies?: () => void;
  onDeleteItem: ((itemId: string) => void) | undefined;
}

export function OrderHotelCard({
  data,
  onDeleteItem,
}: OrderHotelCardProps) {
  const { contexts } = useUser();
  const isAgency = contexts.agency;

  const guestsText = useMemo(() => {
    const { adults, children } = getGuestAmounts(data.guests);

    return formatSentence(
      [dayjs(data.checkOut).diff(dayjs(data.checkIn), "d") + 1, "diária", "diárias"],
      [adults, "adulto", "adultos"],
      [children, "criança", "crianças"]
    );
  }, [data]);

  const handleOpenMoreAmenities = () => {
    dialogService.showDialog(
      <AmenitiesModal amenities={data.amenities || []} onClose={() => dialogService.popDialog()} />
    );
  };

  const handleOpenAccommodationPolicies = () => {
    dialogService.showDialog(<AccommodationRulesDialog data={data.bookingRules!} />);
  };

  return (
    <Card
      as={Flex}
      css={{
        overflow: "visible",
        "@mxlg": {
          flexDirection: "column",
          border: "0",
          p: "$5",
        },
      }}
    >
      {!!data.imageLink && (
        <Flex gap="3">
          <Image
            css={{
              width: "$64",
              height: "100%",
              objectFit: "cover",
              borderTopLeftRadius: "$md",
              borderBottomLeftRadius: "$md",
              "@mxlg": {
                width: "100%",
                borderTopLeftRadius: "$none",
                borderBottomLeftRadius: "$none",
              },
            }}
            src={data.imageLink}
          />
        </Flex>
      )}

      <Flex
        direction="column"
        justify="between"
        as={CardBody}
        gap="8"
        css={{
          flex: "1 0 0%",
          lineHeight: "$11",
          borderRight: "1px solid $colors$neutrals-lightest",
          "@mxlg": {
            borderRight: 0,
            p: "0",
          },
        }}
      >
        <Flex
          gap="2"
          justify="between"
          align="center"
          css={{
            width: "100%",
          }}
        >
          <Flex
            gap="3"
            direction="column"
            css={{
              width: "100%",
            }}
          >
            <Flex direction={{ "@mxlg": "column" }} align="center" justify="between">
              <Flex
                gap="2"
                css={{ "@mxlg": { width: "100%", p: "$2" } }}
                wrap="wrap"
                align={{ "@initial": "center", "@mxlg": "start" }}
              >
                <H3 size={{ "@mxlg": "3" }}>{data.hotel}</H3>
                <StarRating stars={data.stars || 0} />
              </Flex>
              {!!data.violatedPolicies?.length && (
                <ViolatedPoliciesButton data={data.violatedPolicies} />
              )}
            </Flex>
            <Text size="4" fw="500">
              {data.accommodation.toUpperCase()}
            </Text>
            <Text size="4" fw="500">
              {data.roomRegimen}
            </Text>
          </Flex>
          {!isAgency && (
            <IconButton
              css={{
                "@lg": {
                  display: "none",
                },
              }}
              size="md"
              onClick={() => onDeleteItem?.(data.uuid)}
            >
              <Icon as={SvgTrash} />
            </IconButton>
          )}
        </Flex>

        <Flex gap="8" wrap="wrap" direction="column">
          <Flex gap="2">
            <Text size="2" variant="black" fw="500">
              {data?.cityName}
            </Text>
            <Text size="2" variant="darkest">
              {data.address}
            </Text>
          </Flex>

          <Flex gap="2">
            <Text
              size="2"
              variant="black"
              fw="500"
              css={{
                flex: "1",
              }}
            >
              Check-in:{" "}
              <Text size="2" variant="darkest">
                {displayDate(data.checkIn, DateFormats.LONG_DATE)}
              </Text>
            </Text>
            <Text
              size="2"
              variant="black"
              fw="500"
              css={{
                flex: "1",
              }}
            >
              Check-out:{" "}
              <Text size="2" variant="darkest">
                {displayDate(data.checkOut, DateFormats.LONG_DATE)}
              </Text>
            </Text>
          </Flex>

          <Flex 
            direction={{ "@initial": "column", "@md": "row" }} 
            align="center" 
            justify="between" 
            wrap="wrap"
            gap="4"
          >
            <Flex gap="2" wrap="wrap">
              {data.amenities?.slice(0, 2).map((amenity, index) => (
                <Tag key={index} size="sm" variant="info-light">
                  {amenity}
                </Tag>
              ))}
              {Number(data.amenities?.length) > 2 && (
                <Flex
                  align="center"
                  justify="center"
                  gap="2"
                  onClick={handleOpenMoreAmenities}
                  css={{
                    padding: "$2 $6",
                    border: "1px solid $primary-base",
                    borderRadius: "$pill",
                    cursor: "pointer",
                    transition: "all 0.1s",
                    "&:hover": {
                      backgroundColor: "$primary-base",
                      [`${Text}`]: {
                        color: "$neutrals-white",
                      },
                      [`${Icon}`]: {
                        fill: "$neutrals-white",
                      },
                    },
                  }}
                >
                  <Icon as={SvgEye} size="sm" css={{ fill: "$primary-base" }} />
                  <Text variant="primary" size="3">
                    Ver mais
                  </Text>
                </Flex>
              )}
            </Flex>

            {data.bookingRules && (
              <Button
                css={{ width: "fit-content", "@mxmd": { width: "100%" } }}
                variant="tertiary"
                onClick={handleOpenAccommodationPolicies}
              >
                <Icon as={SvgInfo} />
                Políticas
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>

      <Box
        css={{
          width: "20%",
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        <Flex
          css={{
            width: "100%",
          }}
        >
          <Flex
            direction="column"
            gap="4"
            as={CardBody}
            align="end"
            css={{
              width: "100%",
              "@mxlg": {
                p: "$1",
              },
            }}
          >
            <Label
              css={{
                "@mxlg": {
                  mt: "$5",
                },
              }}
            >
              {guestsText}
            </Label>
            <H4>{MaskUtils.asCurrency(data.value || 0)}</H4>
          </Flex>
        </Flex>
      </Box>
    </Card>
  );
}

OrderHotelCard.displayName = "OrderHotelCard";
