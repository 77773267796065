import { BudgetOption, SettingParameterSlug } from "~/application/types/entities/SettingParameter.type";
import { useVerifyParameter } from "~/presentation/shared/hooks/useVerifyParameter";
import { BudgetView, Customer } from "~/application/types";
import { asCurrency } from "~/utils/mask.utils";
import { Col, Row } from "~/components/Grid";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { useUser } from "~/presentation/core/contexts/UserContext";

export interface BudgetViewListItemProps {
  data: BudgetView;
  customer?: Customer;
}

export function BudgetViewListItem({ data, customer }: BudgetViewListItemProps) {
  const { contexts } = useUser();

  const isPhaseParameter = useVerifyParameter({
    customer: customer ?? contexts.customer,
    parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
    value: BudgetOption.PHASE,
  });

  return (
    <Row key={data.budgetId} gapX="1" gapY="1" css={{ minHeight: "8rem", borderTop: "1px solid $primary-base" }}>
      <Col sz="1" css={{ alignSelf: "stretch", p: "$4", borderRight: "1px solid $primary-base" }}>
        <Flex 
          direction="column" 
          gap="2"
          align="center" 
          justify="center" 
          css={{ flex: 1, height: "100%", textAlign: "center" }}
        >
          {isPhaseParameter && (
            <Text size="3" variant="dark" fw="900" css={{ lineHeight: "1.25" }}>{data.projectName}</Text>
          )}

          <Text size="3" variant="dark" fw="600" css={{ lineHeight: "1.25" }}>{data.name}</Text>
        </Flex>
      </Col>

      <Col sz="1" css={{ alignSelf: "stretch", p: "$4", borderRight: "1px solid $primary-base" }}>
        <Flex direction="column" justify="between" css={{ flex: 1, height: "100%" }}>
          <Text size="3" variant="dark" fw="600">Previsto</Text>
          <Text size="3" variant="dark" fw="600">Realizado</Text>
          <Text size="3" variant="dark" fw="600">Saldo</Text>
        </Flex>
      </Col>

      <Col sz="9" css={{ alignSelf: "stretch", py: "$4", borderRight: "1px solid $primary-base" }}>
        <Flex css={{ flex: 1, height: "100%" }}>
          {data.months.map((month, i) => (
            <Flex 
              key={month.name} 
              direction="column" 
              justify="between" 
              align="center" 
              css={{ flex: 1, height: "100%" }}
            >
              {month.forecast !== null ? (
                <Text size="2" variant="dark" fw="600">{asCurrency(month.forecast)}</Text>
              ) : (
                <Flex align="center" justify="center" css={{ height: "$3", width: "100%", pr: i === 11 ? "$3" : 0 }}>
                  <Flex css={{ width: "100%",  height: 1, backgroundColor: "$primary-base" }} />
                </Flex>
              )}
              <Text size="2" variant="dark" fw="600">{month.consumed !== 0 ? asCurrency(month.consumed) : "-"}</Text>
              <Text size="2" variant={month.balance >= 0 ? "sucess" : "error-base"} fw="600">
                {(month.balance === 0 && month.forecast === null) ? "-" : asCurrency(month.balance)}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Col>

      <Col sz="1" css={{ alignSelf: "stretch", py: "$4" }}>
        <Flex direction="column" justify="between" align="center" css={{ flex: 1, height: "100%" }}>
          <Text size="2" variant="dark" fw="600">{asCurrency(data.total.forecast)}</Text>
          <Text size="2" variant="dark" fw="600">{data.total.consumed !== 0 ? asCurrency(data.total.consumed) : "-"}</Text>
          <Text size="2" variant={data.total.balance >= 0 ? "sucess" : "error-base"} fw="600">
            {asCurrency(data.total.balance)}
          </Text>
        </Flex>
      </Col>
    </Row>
  );
}
