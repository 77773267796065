import { Fragment } from "react";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { UpdateProfileForm } from "./components/FormUpdateProfile";
import { ErrorType } from "./ProfilePage";
import { CustomerEmployee } from "~/application/types";
import { UseMutateFunction } from "@tanstack/react-query";
import { EditableCustomerEmployee } from "~/presentation/shared/views/CustomerEmployeeDialog";
import { Card } from "~/components/Card";

export interface CreateCustomerContainerProps {
  defaultData?: CustomerEmployee;
  isLoadingCustomer: boolean;
  updateAvatar: UseMutateFunction<{
    avatarUrl: string;
}, ErrorType, {
    file: File | null | undefined;
    uuid?: string;
    phone?: string;
    cpf?: string;
    birthDate?: string;
    lastName?: string;
    name?: string;
    rg?: string;
}, void>
  updateCustomerProfile: UseMutateFunction<CustomerEmployee, ErrorType, Partial<EditableCustomerEmployee>, void>
}

export function ProfileContainer({
  updateCustomerProfile,
  updateAvatar,
  isLoadingCustomer,
  defaultData
}: CreateCustomerContainerProps) {

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Atualizar perfil" />
        </AppBarContainer>
      </AppBar>

      <Container css={{ py: "$10", "@mxlg": { p: "$5" }, width: "80%"}}>
       <Card css={{ pb: "$5" }}>
          <UpdateProfileForm 
            defaultData={defaultData}
            isLoadingCustomer={isLoadingCustomer}
            updateAvatar={updateAvatar}
            updateCustomerProfile={updateCustomerProfile} 
          />
       </Card>
      </Container>
    </Fragment>
  );
}
