import { FC } from "react";
import { OrderStatus } from "~/application/types";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { SvgDownload } from "~/components/Icon/icons";
import { DataColItem } from "~/components/List";
import { Text } from "~/components/Text";
import { RoadTravelerBpeInfoProps } from "./types";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Alert } from "~/components/Alert";

export const RoadTravelerBpeInfo: FC<RoadTravelerBpeInfoProps> = ({
  seatNumber,
  travelName,
  ticket,
  doestNotHaveAnyBpe,
  orderIsIssued,
  enabled,
  bpe,
}) => {
  const isMobile = useMobile();

  return (
    <>
      <Flex
        gap="5"
        justify="between"
        direction={{ "@mxlg": "column" }}
        align="center"
        css={{ p: "$6", "@mxlg": { p: "0" } }}
      >
        <Col sz={{ "@initial": "2", "@mxlg": "12" }}>
          {!isMobile && (
            <DataColItem
              headerTitle="Nº do Bilhete"
              css={{ mb: "$8" }}
              boxCss={{ mt: "$2" }}
              data={<Text fw="600">{ticket}</Text>}
            />
          )}
        </Col>

        <Col sz={{ "@initial": "4", "@mxlg": "12" }}>
          <DataColItem
            headerTitle={`Trecho: ${travelName}`}
            boxCss={{ mt: "$2" }}
            data={
              <Button
                css={{ width: "100%" }}
                disabled={!bpe}
                onClick={() => {
                  if (bpe) window.open(bpe);
                }}
              >
                {bpe && <SvgDownload />}
                {!isMobile && (bpe ? "Bilhete Eletrônico" : "Não Possui Bilhete Eletrônico")}
              </Button>
            }
          />
        </Col>

        <Col
          sz={{ "@initial": "2", "@mxlg": "12" }}
          css={{ "@mxlg": { display: "flex", justifyContent: "space-between" } }}
        >
          <DataColItem
            headerTitle="Nº do assento"
            css={{ mb: "$6" }}
            boxCss={{ mt: "$2" }}
            data={<Text fw="600">{seatNumber}</Text>}
          />

          {isMobile && (
            <DataColItem
              headerTitle="Nº do Bilhete"
              css={{ mb: "$8", alignItems: "end" }}
              boxCss={{ mt: "$2" }}
              data={<Text fw="600">{ticket}</Text>}
            />
          )}
        </Col>
      </Flex>

      {doestNotHaveAnyBpe && orderIsIssued && (
        <Col>
          <Alert variant="info">
            <Text fw={500} variant="primary-dark">
              Para esta viagem, não há bilhete eletrônico disponível. Por favor, dirija-se ao guichê
              da viação para retirar sua passagem rodoviária.
            </Text>
          </Alert>
        </Col>
      )}
    </>
  );
};
