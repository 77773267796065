export enum SettingParameterTypeValue {
  INTEGER = "integer",
  BOOLEAN = "boolean",
  FLOAT = "float",
  PERCENTAGE = "percentage",
  SELECT = "select",
}

export enum SettingParameterType {
  CUSTOMER = "customer",
  AGENCY = "agency",
  BOTH = "both",
}

export enum SettingParameterSlug {
  HOTEL_PRICE_CHANGE = "hotel-price-change-parameter",
  VOUCHER_WITHOUT_VALUE = "voucher-without-value",
  SELECT_APPROVERS = "select-approvers",
  POLICY_BY_REASON_TRIP = "policy-by-reason-trip",
  MANAGE_BUDGET_BY = "manage-budget-by",
  COST_CENTER_REQUIRED_PHASE = "cost-center-required-phase",
  APPROVAL_BY = "approval-by",
  DEFAULT_CUSTOMER_EMPLOYEE_GROUP = "default-customer-employee-group",
}

export enum BudgetOption {
  COST_CENTER = "cost_center",
  PROJECT = "project",
  PHASE = "phase",
  GROUP = "customer_employee_group",
}

export enum ApprovalOption {
  COST_CENTER = "cost_center",
  PROJECT = "project",
  PHASE = "phase",
  GROUP = "customer_employee_group",
}

export type SettingParameter = {
  uuid: string;
  slug: SettingParameterSlug;
  description: string;
  type: SettingParameterType;
  typeValue: SettingParameterTypeValue;
  value: string;
};

export const getAvailableParameterOptions = (slug: string) => {
  switch (slug) {
    case SettingParameterSlug.MANAGE_BUDGET_BY:
      return [
        { label: "Centro de custo", value: BudgetOption.COST_CENTER },
        { label: "Projeto", value: BudgetOption.PROJECT },
        { label: "Fase", value: BudgetOption.PHASE },
      ];
    case SettingParameterSlug.APPROVAL_BY:
      return [
        { label: "Centro de custo", value: ApprovalOption.COST_CENTER },
        { label: "Projeto", value: ApprovalOption.PROJECT },
        { label: "Fase", value: ApprovalOption.PHASE },
        { label: "Grupo", value: ApprovalOption.GROUP },
      ];
    default:
      return undefined;
  }
};

export const getDefaultLabelParameterSelect = (data: string) => {
  switch (data) {
    case ApprovalOption.COST_CENTER: {
      return "Centro de custo";
    }
    case ApprovalOption.GROUP: {
      return "Grupo";
    }
    case ApprovalOption.PHASE: {
      return "Fase";
    }
    case ApprovalOption.PROJECT: {
      return "Projeto";
    }
    default:
  }
};
