import { ComponentType } from "react";
import { Actions, UserContext } from "~/application/types";
import { routes } from "../theme/routes";
import { NavMenuItemType } from "../utils/menu-functions";

type MenuItem = NavMenuItemType & {
  icon?: ComponentType;
};

export const searchMenuItems: MenuItem[] = [
  {
    title: "Aéreo",
    href: routes.Booking.Airway.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.CreateOrder],
  },
  {
    title: "Hotéis",
    href: routes.Booking.Hotel.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.CreateOrder],
  },
  {
    title: "Ônibus",
    href: routes.Booking.Road.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.CreateOrder],
  },
  {
    title: "Carros",
    href: routes.Booking.Vehicle.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.CreateOrder],
  },
];

export const orderMenuItems: MenuItem[] = [
  {
    title: "Meus pedidos",
    href: routes.Orders.CustomerOrders.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewOrder],
  },
  {
    title: "Ver pedidos",
    href: routes.Agencies.Orders.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Relatórios",
    href: routes.Configuration.Reports.root,
    requiredActions: [Actions.ViewPurchaseReport],
    requiredContext: UserContext.Customer,
  },
  {
    title: "Relatórios",
    href: routes.Configuration.Reports.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Ver pedidos",
    href: routes.Orders.CustomerOrders.root,
    requiredContext: UserContext.Customer,
    requiredActions: [],
  },
  // {
  //   title: "Adiantamentos",
  //   href: routes.Orders.Advances.root,
  //   requiredContext: UserContext.Customer,
  //   requiredActions: [],
  // },
  {
    title: "Pedidos pendentes",
    href: routes.Orders.PendingOrders.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
];

export const expenseMenuItems: MenuItem[] = [
  {
    title: "Despesas",
    href: routes.Expenses.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewAccountabilityExpense],
  },
];

export const settingsMenuItems: MenuItem[] = [
  {
    title: "Parâmetros",
    href: routes.Configuration.Parameters.Customers.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewSettingParameter],
  },
  {
    title: "Dashboard BI",
    href: routes.Configuration.DashboardBI.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Empresas",
    href: routes.Configuration.Customers.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Credenciais",
    href: routes.Configuration.Credentials.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Cartões de crédito",
    href: routes.Configuration.CreditCards.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Taxas",
    href: routes.Configuration.Fees.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Markups",
    href: routes.Configuration.Markups.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Markdowns",
    href: routes.Configuration.Markdowns.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Agências",
    href: routes.Configuration.Agencies.root,
    requiredContext: UserContext.Biztrip,
    requiredActions: [],
  },
  {
    title: "Categorias de despesas",
    href: routes.Configuration.ExpenseCategories.root,
    requiredContext: UserContext.Biztrip,
    requiredActions: [],
  },
  {
    title: "Filiais",
    href: routes.Configuration.Branches.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewBranch],
  },
  {
    title: "Centros de custo",
    href: routes.Configuration.CostCenters.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewCostCenter],
  },
  {
    title: "Funcionários",
    href: routes.Configuration.CustomerEmployees.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewCustomerEmployee],
  },
  {
    title: "Grupos de funcionários",
    href: routes.Configuration.CustomerEmployeesGroups.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewCustomerEmployeesGroup],
  },
  {
    title: "Modelos de aprovação",
    href: routes.Configuration.ApprovalModels.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewApprovalModel],
  },
  {
    title: "Políticas (expenses)",
    href: routes.Configuration.PoliciesExpenses.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewPolicy],
  },
  {
    title: "Motivos de viagem",
    href: routes.Configuration.ReasonTrips.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewReasonTrip],
  },
  {
    title: "Justificativas",
    href: routes.Configuration.Justifications.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewJustification],
  },
  {
    title: "Tipo de despesa",
    href: routes.Configuration.ExpenseTypes.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.GetExpenseType],
  },
  {
    title: "Projetos",
    href: routes.Configuration.Projects.root,
    requiredActions: [Actions.ViewProject],
    requiredContext: UserContext.Customer,
  },
  {
    title: "Políticas de compra",
    href: routes.Configuration.PurchasingPolicies.root,
    requiredActions: [Actions.ViewPolicyValue],
    requiredContext: UserContext.Customer,
  },
  {
    title: "Hotéis offline",
    href: routes.Configuration.OfflineHotels.root,
    requiredContext: UserContext.Agency,
    requiredActions: [],
  },
  {
    title: "Orçamentos",
    href: routes.Configuration.Budgets.root,
    requiredContext: UserContext.Customer,
    requiredActions: [Actions.ViewBudget],
  },
];
