import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { FlightQuery } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { log } from "~/application/utils/log";
import { AirwayQueryFormData } from "~/presentation/shared/components/AirwayQueryForm";

const LOG_TAG = "Booking/BookingAirway/useAirwayParams";

export function useAirwayParams(): AirwayQueryFormData | null {
  const [searchParams] = useSearchParams();

  const airwayParams = useMemo(() => {
    const obj = Object.fromEntries(searchParams);

    try {
      return {
        adultQuantity: JSON.parse(obj.adultQuantity),
        childrenQuantity: JSON.parse(obj.childrenQuantity),
        reasonTrip: obj.reasonTrip ? JSON.parse(obj.reasonTrip) : null,
        stretch: JSON.parse(obj.stretch).map((stretch: any) => {
          return {
            outboundDate: toDate(stretch.dateGoSelected),
            returnDate: !stretch.dateReturnSelected ? null : toDate(stretch.dateReturnSelected),
            origin: JSON.parse(stretch.originSelected),
            destination: JSON.parse(stretch.destinationSelected),
          };
        }),
      };
    } catch (error) {
      log.w(LOG_TAG, error);

      return null;
    }
  }, [searchParams]);

  return airwayParams;
}
