import { SvgBag, SvgBook, SvgCustomers, SvgDash, SvgDollarSign, SvgInactiveCustomer, SvgUserInactive } from "~/components/Icon/icons";
import { DashboardBIAdditionalInfoDTO } from "~/infrastructure/api/dtos/DashboardBIDTO";
import { ConvertedData, Result } from "./types";
import { DashboardBiFilter } from "~/application/types/entities/DashBoardBI.type";

export const DURANTIONS = ["12 meses", "3 meses", "30 dias", "7 dias", "24 horas"];
export const DURANTIONSMAPING: { [key: number]: keyof Result } = {
  0: "year",
  1: "threeMonths",
  2: "month",
  3: "week",
  4: "day",
};

export const getAdditionalInfoIcon = (slug: string) => {
  switch (slug) {
    case DashboardBIAdditionalInfoDTO.LOGOUT_CUSTOMERS:
      return SvgUserInactive
    case DashboardBIAdditionalInfoDTO.NEW_CLIENTS_MONTH:
      return SvgDash
    case DashboardBIAdditionalInfoDTO.NOT_BUYING_CUSTOMERS:
      return SvgInactiveCustomer
    case DashboardBIAdditionalInfoDTO.OPERATIONAL_INTERVENTIONS:
      return SvgBook
    case DashboardBIAdditionalInfoDTO.ORDERS_NOT_ISSUED_WEEK:
      return SvgBag
    case DashboardBIAdditionalInfoDTO.PENDING_FIRST_PURCHASE:
      return SvgDollarSign
  }
}

export const getDescriptionAdditionalIcon = (slug: string) => {
  switch (slug) {
    case DashboardBIAdditionalInfoDTO.LOGOUT_CUSTOMERS:
      return SvgUserInactive
    case DashboardBIAdditionalInfoDTO.NEW_CLIENTS_MONTH:
      return SvgDash
    case DashboardBIAdditionalInfoDTO.NOT_BUYING_CUSTOMERS:
      return SvgCustomers
    case DashboardBIAdditionalInfoDTO.OPERATIONAL_INTERVENTIONS:
      return SvgBook
    case DashboardBIAdditionalInfoDTO.ORDERS_NOT_ISSUED_WEEK:
      return SvgBag
    case DashboardBIAdditionalInfoDTO.PENDING_FIRST_PURCHASE:
      return SvgCustomers
  }
}

export const getAdditionalInfoLabel = (label: string) => {
  switch (label) {
    case DashboardBIAdditionalInfoDTO.NEW_CLIENTS_MONTH:
      return 'Novos clientes no mês'
    case DashboardBIAdditionalInfoDTO.PENDING_FIRST_PURCHASE:
      return 'Pendentes de primeira compra'
    case DashboardBIAdditionalInfoDTO.NOT_BUYING_CUSTOMERS:
      return 'Clientes inativos'
    case DashboardBIAdditionalInfoDTO.OPERATIONAL_INTERVENTIONS:
      return 'Intervenções operacionais'
    case DashboardBIAdditionalInfoDTO.ORDERS_NOT_ISSUED_WEEK:
     return 'Pedidos sem emissão da semana'
    case DashboardBIAdditionalInfoDTO.LOGOUT_CUSTOMERS:
    return 'Clientes sem acessar'
  }
}

export function convertData(jsonData?: DashboardBiFilter[]): Result {
  const result: Result = {
    year: [],
    month: [],
    threeMonths: [],
    week: [],
    day: []
  };

  jsonData?.forEach(entry => {
    const period = entry.filter?.type;
    const data: ConvertedData[] = entry.filter?.data?.map((item, idx) => ({
      name: period === "hours" 
        ? `${item.hour}` 
        : period === "days" && entry.filter?.value === 7
        ? `${item.day}` 
        : period === "days" && entry.filter?.value === 30
        ? `${item.month}` 
        : period === "months" && entry.filter?.value === 3
        ? `${item.month}`
        : period === "months" && entry.filter?.value === 12
        ? `${item.month}`
        : '',
      sales: item.salesAmount,
      amt: item.salesAmount
    })) || [];  

    if (entry.filter?.type === "months" && entry.filter?.value === 12) {
      result.year.push(...data);
    } else if (entry.filter?.type === "months" && entry.filter?.value === 3) {
      result.threeMonths.push(...data);
    } else if (entry.filter?.type === "days" && entry.filter?.value === 30) {
      result.month.push(...data);
    } else if (entry.filter?.type === "days" && entry.filter?.value === 7) {
      result.week.push(...data);
    } else if (entry.filter?.type === "hours") {
      result.day.push(...data);
    }
  });

  return result;
}
