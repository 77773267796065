import { BudgetBalance } from "~/application/types";
import { BudgetBalanceDTO } from "../dtos";

export function mapBudgetBalanceDTO(data: BudgetBalanceDTO): BudgetBalance {
  return {
    name: data.name,
    projectName: data.project_name,
    budgetType: data.budget_type,
    value: data.balance !== null ? Number(data.balance) : null,
  };
}
