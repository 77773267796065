import queryString from "query-string";

import {
  ICreateCustomerEmployeesGroupParams,
  IFindCustomerEmployeesGroupParams,
  ICustomerEmployeesGroupService,
} from "./ICustomerEmployeesGroupService";
import { CustomerEmployee, CustomerEmployeesGroup, PaginatedResource } from "~/application/types";
import { mapCustomerEmployeeDTO, mapCustomerEmployeesGroupDTO } from "~/infrastructure/api/mappers";
import { CustomerEmployeeDTO, CustomerEmployeesGroupDTO } from "~/infrastructure/api/dtos";
import { api } from "~/infrastructure/api";
import { GroupEmployee } from "~/core/modules/Customer/pages/CustomerPage/hooks/useCustomerEmployeesGroups";

export class CustomerEmployeesGroupService implements ICustomerEmployeesGroupService {
  async find({
    customerId,
    page,
    description,
    withApprovalModels,
    active,
  }: IFindCustomerEmployeesGroupParams): Promise<PaginatedResource<CustomerEmployeesGroup[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/groups`,
      query: { page, description, with_approval_model: withApprovalModels, active },
    });

    return await api.get<PaginatedResource<CustomerEmployeesGroupDTO[]>>(url).then(({ data }) => ({
      ...data,
      data: data.data.map(mapCustomerEmployeesGroupDTO),
    }));
  }

  async create(data: ICreateCustomerEmployeesGroupParams): Promise<CustomerEmployeesGroup> {
    return await api
      .post<CustomerEmployeesGroupDTO>(`/customers/${data.customerId}/groups`, {
        description: data.description,
      })
      .then(({ data }) => mapCustomerEmployeesGroupDTO(data));
  }

  async employees({
    groupIds,
    name,
    customerId,
  }: {
    groupIds?: string[];
    name?: string;
    customerId: string;
  }): Promise<GroupEmployee[]> {
    const url = queryString.stringifyUrl(
      {
        url: `/customers/${customerId}/groups/employees`,
        query: { group_uuids: groupIds, name },
      },
      {
        arrayFormat: "bracket",
      }
    );
    return await api
      .get<{ data: CustomerEmployeeDTO[] }>(url)
      .then(({ data }) => data.data.map(mapCustomerEmployeeDTO));
  }

  async update(data: CustomerEmployeesGroup): Promise<CustomerEmployeesGroup> {
    return await api
      .put<CustomerEmployeesGroupDTO>(`/customers/${data.customerId}/groups/${data.uuid}`, {
        description: data.description,
      })
      .then(({ data }) => mapCustomerEmployeesGroupDTO(data));
  }

  async toggleActive(data: CustomerEmployeesGroup): Promise<void> {
    await api.patch(`/customers/${data.customerId}/groups/${data.uuid}/toggle-active`);
  }
}
