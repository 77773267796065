import { UseMutateFunction } from "@tanstack/react-query";
import { CustomerCRM } from "~/application/types/entities/CustomerCrm.type";
import { CrmData } from "~/application/usecases/crm/ICrmService";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgChevronUp, SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { Collapse } from "react-collapse";
import { Flex } from "~/components/Flex";
import { decodeBase64 } from "../utils";
import { getServiceLabel } from "~/core/shared/utils/order.utils";

export interface CustomerCrmListItem extends DataListItemProps {
  data: CustomerCRM;
  checked?: boolean;
  isCollapse: boolean;
  onToggleCollapse: (id: string) => void;
  onToggle: UseMutateFunction<void, unknown, CrmData, void>;
  onEditClick: (data: CrmData) => void;
}

export function CustomerCrmListItem({
  data,
  checked,
  isCollapse,
  onToggle,
  onEditClick,
  onToggleCollapse,
  ...props
}: CustomerCrmListItem) {

  function generateTextPreview(text: string) {
    if (text.length <= 90) {
      return text;
    }
    return text.slice(0, 90) + '...';
  }
  
  const htmlText = generateTextPreview(data.obs || '').replaceAll('<p>', '').replace('</p>', '')

  return (
    <>
    <DataListItem
      data-active={data.isActive || isCollapse}
      data-list={isCollapse}
      {...props}
      onClick={() => {
        onToggleCollapse(data.uuid);
      }}
      css={{
        cursor: "pointer",
        borderBottomLeftRadius: isCollapse ? "$none" : undefined,
        borderBottomRightRadius: isCollapse ? "$none" : undefined,
        "&:hover::after": {
          boxShadow: isCollapse ? "$none" : undefined,
        },
      }}
    >
      <DataColItem headerTitle="Tipo de serviço" data={getServiceLabel(data.serviceType)} />

      {data.obs ? (
        <DataColItem
          headerTitle="Observação"
          css={{ wordBreak: "break-all" }}
          dangerouslySetInnerHTML={{ __html:  htmlText }}
          data={null} />
      ) : (
        <DataColItem headerTitle="Observação" data="" />
      )}


      <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
        <IconButton size="md" onClick={(e) => {
          e.stopPropagation()
          onEditClick(data)
        }}>
          <Icon as={SvgEdit} />
        </IconButton>
      </Tooltip>

      <Switch
        checked={checked}
        onClick={() => onToggle(data)}
      >
        <Caption>{checked ? "Ativo" : "Inativo"}</Caption>
      </Switch>

      <IconButton size="md">
        <Icon as={isCollapse ? SvgChevronUp : SvgChevronDown} />
      </IconButton>
    </DataListItem>
      <Collapse isOpened={isCollapse} style={{ padding: "10px", background: "#fff" }}>
        <Flex
          gap="5"
          css={{
            background: "#fff",
            borderWidth: "0 2px 2px 2px",
            borderStyle: "solid",
            borderColor: "$primary-base",
            borderBottomLeftRadius: "$md",
            borderBottomRightRadius: "$md",
            p: "$5",
          }}
        >

          {data.obs ? (
            <DataColItem
              headerTitle="Observação"
              css={{ wordBreak: "break-all" }}
              dangerouslySetInnerHTML={{ __html: data.obs.replaceAll('<p>', '').replace('</p>', '') }}
              data={null} />
          ) : (
            <DataColItem headerTitle="Observação" data="" />
          )}
        </Flex>
      </Collapse>
    </>
  );
}

CustomerCrmListItem.displayName = "CustomerCrmListItem";
