import { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { DeepPartial } from "~/application/types";
import { ExpenseCategory, ExpenseType } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Select, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { QueryKeys } from "~/constants/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "~/components/Spinner";

const expenseTypeSchema = yup.object().shape({
  name: yup.string().required("O nome do projeto é obrigatório"),
  expenseCategory: yup.object().shape({
    name: yup.string().required("A categoria da despesa é obrigatória"),
    uuid: yup.string().required(),
  }),
});

type ExpenseTypeSchema = yup.InferType<typeof expenseTypeSchema>;

export interface ExpenseTypeDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<ExpenseType>;
  onCloseClick?: () => void;
  onSubmit: (data: ExpenseType) => void;
  fetchExpenseCategories: () => Promise<ExpenseCategory[]>;
}

export function ExpenseTypeDialog({
  isNew,
  defaultData,
  onCloseClick,
  onSubmit,
  fetchExpenseCategories,
}: ExpenseTypeDialogProps) {
  const { control, formState, handleSubmit } = useForm<ExpenseTypeSchema>({
    defaultValues: defaultData,
    resolver: yupResolver(expenseTypeSchema),
  });

  const { data: expenseCategories, isFetching: isFetchingExpenseCategories } = useQuery(
    [QueryKeys.EXPENSE_CATEGORIES],
    fetchExpenseCategories,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  const getExpenseCategoryLabel = useCallback((item: ExpenseCategory) => item.name, []);
  const getExpenseCategoryValue = useCallback((item: ExpenseCategory) => item.uuid, []);

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit((data) => onSubmit(data as ExpenseType))}>
        <FormDialog
          title={isNew ? "Novo tipo de despesa" : "Editar tipo de despesa"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting || isFetchingExpenseCategories} type="submit">
              {formState.isSubmitting && (
                <Spinner
                  css={{
                    borderLeftColor: "$neutrals-white",
                    width: "$4",
                    height: "$4",
                    borderWidth: "2px",
                  }}
                />
              )}
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Row gap="6">
              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome da despesa</FieldLabel>
                  <TextInput placeholder="Digite o nome da despesa" />
                  {formState.errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="expenseCategory" control={control} required>
                  <FieldLabel>Categoria da despesa</FieldLabel>
                  <Select
                    options={expenseCategories}
                    isLoading={isFetchingExpenseCategories}
                    placeholder="Selecione a categoria da despesa"
                    getOptionLabel={getExpenseCategoryLabel}
                    getOptionValue={getExpenseCategoryValue}
                  />
                  {formState.errors.expenseCategory?.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.expenseCategory.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
