import { useCallback } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import { ApiError, Customer, State } from "~/application/types";
import { customerService, stateService } from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { CustomerTab } from "../../utils";
import { CustomerContainer } from "./CustomerContainer";
import { useCustomerSettingParameters } from "./hooks/useCustomerSettingParameters";
import { useApprovalModels } from "./hooks/useApprovalModels";
import { useBranches } from "./hooks/useBranches";
import { useCostCenters } from "./hooks/useCostCenters";
import { useCustomerEmployees } from "./hooks/useCustomerEmployees";
import { useCustomerTab } from "./hooks/useCustomerTab";
import { useExpensePolicies } from "./hooks/useExpensePolicies";
import { useExpensesType } from "./hooks/useExpenseTypes";
import { useFees } from "./hooks/useFees";
import { useJustifications } from "./hooks/useJustifications";
import { useMarkdowns } from "./hooks/useMarkdowns";
import { useMarkups } from "./hooks/useMarkups";
import { useReasonTrips } from "./hooks/useReasonTrips";
import { TabCustomerSettingParameterProps } from "./views/CustomerSettingParameter/TabCustomerSettingParameters";
import { useProjects } from "./hooks/useProjects";
import { useCredentials } from "./hooks/useCredentials";
import { usePaymentMethods } from "./hooks/usePaymentMethods";
import { useForm } from "react-hook-form";
import { editCustomer } from "../../utils/form";
import { logError } from "~/presentation/shared/utils/errors";
import { PATTERN_CEP, PATTERN_CNPJ } from "~/utils/mask.utils";
import { useCustomerCrm } from "./hooks/useCustomerCrm";
import { useProfiles } from "./hooks/useProfiles";
import { usePhases } from "./hooks/usePhases";
import { useBudgets } from "./hooks/useBudgets";
import { useCustomerEmployeesGroups } from "./hooks/useCustomerEmployeesGroups";
import { usePurchansingPolicies } from "~/presentation/CustomerSettings/pages/PurchasingPoliciesPage/hooks/usePurchansingPolicies";

const LOG_TAG = "Customer/CustomerPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar empresa",
  UPDATE_SUCCESS_MESSAGE: "Empresa atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar empresa",
} as const;

const customerSchema = yup.object().shape({
  cnpj: yup.string().matches(PATTERN_CNPJ, "CNPJ inválido").required("O CNPJ é obrigatório"),
  stateRegistration: yup.string().nullable(),
  companyName: yup.string().required("A razão social é obrigatória"),
  wintourCode: yup.string().nullable(),
  tradingName: yup.string().required("O nome fantasia é obrigatório"),
  dashboardLink: yup.string().url("Link inválido").nullable(),
  email: yup.string().required("O e-mail é obrigatório").email("E-mail inválido"),
  phone: yup.string().min(18, "Telefone inválido").required("O telefone é obrigatório"),
  zipCode: yup.string().matches(PATTERN_CEP, "CEP inválido").required("CEP é obrigatório"),
  address: yup.string().required("O logradouro é obrigatório"),
  number: yup.string().required("O número é obrigatório"),
  district: yup.string().required("O bairro é obrigatório"),
  state: yup.object().shape({
    name: yup.string().required("O estado é obrigatório"),
    state: yup.string().required(),
  }),
  city: yup.object().shape({
    name: yup.string().required("A cidade é obrigatória"),
  }),
  domains: yup.array().of(
    yup.object().shape({
      domain: yup.string(),
    })
  ),
});

export type CustomerSchema = yup.InferType<typeof customerSchema>;

export function CustomerPage() {
  const [searchParams] = useSearchParams();
  const { customerId } = useParams() as { customerId: string };

  const projectId = searchParams.get("id_projeto");

  function customerHookForm(defaultData?: Customer) {
    return useForm<CustomerSchema>({
      defaultValues: defaultData && editCustomer(defaultData),
      resolver: yupResolver(customerSchema),
    });
  }

  const tabValue = useCustomerTab();

  const { data, isFetching } = useQuery<Customer, ApiError>(
    [QueryKeys.CUSTOMER, customerId],
    () => customerService.findById(customerId),
    {
      staleTime: QueryTimes.LONG,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateUpdateCustomer, isLoading: isUpdatingCustomer } = useMutation(
    (item: Customer) => customerService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Customer(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Customer(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMER, customerId]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
    }
  );

  const fetchCitiesByState = useCallback(
    (state: State) =>
      stateService
        .findCities(state)
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );
  const justificationsState = useJustifications({
    customerId,
    enabled: tabValue === CustomerTab.JUSTIFICATIONS,
  });

  const reasonTripsState = useReasonTrips({
    customerId,
    enabled: tabValue === CustomerTab.REASON_TRIPS,
  });

  const costCentersState = useCostCenters({
    customerId,
    enabled: tabValue === CustomerTab.COSTS_CENTERS,
  });

  const expenseTypesState = useExpensesType({
    customerId,
    enabled: tabValue === CustomerTab.EXPENSE_TYPES,
  });

  const feesState = useFees({
    customerId,
    activeFeeId: data?.feeId,
    enabled: tabValue === CustomerTab.FEES,
  });

  const customerEmployeesState = useCustomerEmployees({
    customerId,
    enabled: tabValue === CustomerTab.EMPLOYEES,
  });

  const customerEmployeesGroupsState = useCustomerEmployeesGroups({
    customerId,
    enabled: tabValue === CustomerTab.CUSTOMER_EMPLOYEES_GROUPS,
  });

  const profilesState = useProfiles({
    customerId,
    enabled: tabValue === CustomerTab.PROFILES,
  });

  const expensePoliciesState = useExpensePolicies({
    customerId,
    enabled: tabValue === CustomerTab.POLICIES_EXPENSES,
  });

  const markupsState = useMarkups({
    customerId,
    activeMarkupId: data?.markupId,
    enabled: tabValue === CustomerTab.MARKUPS,
  });

  const markdownsState = useMarkdowns({
    customerId,
    activeMarkdownId: data?.markdownId,
    enabled: tabValue === CustomerTab.MARKDOWNS,
  });

  const branchesState = useBranches({
    customerId,
    enabled: tabValue === CustomerTab.BRANCHES,
  });

  const approvalModelsState = useApprovalModels({
    customerId,
    enabled: tabValue === CustomerTab.APPROVAL_MODELS,
  });

  const customerCrmState = useCustomerCrm({
    customerId,
    enabled: tabValue === CustomerTab.CRM,
  });

  const customerSettingParametersState = useCustomerSettingParameters({
    customerId,
    enabled: tabValue === CustomerTab.Parameters,
  });

  const projectsState = useProjects({
    customerId,
    enabled: tabValue === CustomerTab.PROJECTS,
  });

  const phasesState = usePhases({
    projectId: projectId!,
    enabled: !!projectId,
  });

  const credentialState = useCredentials({
    customerId,
    enabled: tabValue === CustomerTab.CREDENTIALS,
  });

  const paymentMethodsState = usePaymentMethods({
    customerId,
    enabled: tabValue === CustomerTab.PAYMENTS,
  });

  const budgetsState = useBudgets({
    customerId,
    enabled: tabValue === CustomerTab.BUDGETS || tabValue === CustomerTab.VIEW_BUDGETS,
  });

  const purchasingPoliciesState = usePurchansingPolicies({
    customerId,
  });

  return (
    <CustomerContainer
      isLoading={isFetching}
      isUpdatingCustomer={isUpdatingCustomer}
      activeTab={tabValue}
      customer={data}
      customerId={customerId}
      customerHookForm={customerHookForm}
      onUpdateCustomer={mutateUpdateCustomer}
      customerCrmState={customerCrmState}
      fetchCitiesByState={fetchCitiesByState}
      credentialState={credentialState}
      justificationsState={justificationsState}
      reasonTripsState={reasonTripsState}
      costCentersState={costCentersState}
      feesState={feesState}
      expenseTypesState={expenseTypesState}
      customerEmployeesState={customerEmployeesState}
      customerEmployeesGroupsState={customerEmployeesGroupsState}
      expensePoliciesState={expensePoliciesState}
      markupsState={markupsState}
      markdownsState={markdownsState}
      branchesState={branchesState}
      profilesState={profilesState}
      paymentMethodsState={paymentMethodsState}
      approvalModelsState={approvalModelsState}
      customerSettingParametersState={
        customerSettingParametersState as TabCustomerSettingParameterProps
      }
      projectsState={projectsState}
      phasesState={phasesState}
      budgetsState={budgetsState}
      purchasingPoliciesState={{ ...purchasingPoliciesState, customerId }}
    />
  );
}
