import { FC, useCallback } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { OrderItems } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Select, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { getServiceLabel } from "../../utils";
import { JustificationDialogProps } from "./types";
import { EditableJustification, createJustification, editJustification } from "./utils";

const SERVICE_TYPE_OPTIONS = Object.values(OrderItems)
  .filter((i) => i !== OrderItems.ADVANCE)
  .map((item) => ({
    value: item,
    label: getServiceLabel(item),
  }));

export const JustificationDialog: FC<JustificationDialogProps> = ({
  data,
  onCloseClick,
  onSubmit: onSubmitProp,
}) => {
  const isNew = !data;

  const { control, formState, handleSubmit } = useForm<EditableJustification>({
    defaultValues: editJustification(data),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const onSubmit = useCallback(
    (formData: EditableJustification) => {
      onSubmitProp?.(createJustification(formData));
    },
    [onSubmitProp]
  );

  const onError = useCallback<SubmitErrorHandler<EditableJustification>>((formErrors) => {
    // eslint-disable-next-line no-console
    console.log(formErrors);
  }, []);

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormDialog
          title={isNew ? "Nova justificativa" : "Editar justificativa"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Row gap="6">
              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Justificativa</FieldLabel>
                  <TextInput placeholder="Digite a justiticativa" />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="serviceType" control={control} required>
                  <FieldLabel>Tipo de serviço</FieldLabel>
                  <Select
                    options={SERVICE_TYPE_OPTIONS}
                    placeholder="Selecione o tipo de serviço"
                  />
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

JustificationDialog.displayName = "JustificationDialog";
