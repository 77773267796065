import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { ApiError, Customer, PaginatedResource } from "~/application/types";
import { customerService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys, QueryTimes } from "~/constants";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { CustomersContainer } from "./CustomersContainer";
import { CreditLimitUpdateDialog } from "./components/CreditLimitUpdateDialog";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { BlockedDialog } from "~/core/shared/components/BlockedDialog";

const LOG_TAG = "Customer/CustomersPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar empresas",
  UPDATING_MESSAGE: "Atualizando empresa",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar empresa",
  ACTIVATE_SUCCESS_MESSAGE: "Empresa ativada",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar empresa",
  INACTIVATE_SUCCESS_MESSAGE: "Empresa inativada",
  BLOCK_ERROR_MESSAGE: "Falha ao bloquear empresa",
  BLOCK_SUCCESS_MESSAGE: "Empresa bloqueada",
  UNBLOCK_ERROR_MESSAGE: "Falha ao desbloquear empresa",
  UNBLOCK_SUCCESS_MESSAGE: "Empresa desbloqueada",
  UPDATE_CREDIT_LIMIT: "Limite de crédito atualizado",
} as const;

export function CustomersPage() {
  const { user } = useAuth();
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [customerStatusSelected, setCustomerStatusSelected] = useState({ label: "", value: null })
  const [customerLowCreditSelected, setCustomerLowCreditSelected] = useState({ label: "", value: "" })
  const [search] = useDebounce<string>(searchText, 700);

  const isCustomerActive = customerStatusSelected.value
  const customerLowCredit = customerLowCreditSelected.value

  const { data, isFetching } = useQuery<
    PaginatedResource<Customer[]>,
    ApiError
  >(
    [QueryKeys.CUSTOMERS, user.agency, currentPage, { search }, isCustomerActive, customerLowCredit],
    () => customerService.find({ page: currentPage, name: search, active: isCustomerActive, max_credit_limit: customerLowCredit }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: mutateUpdateCreditLimite } = useMutation(
    async (item: Customer) =>
      await customerService.creditLimit({
        creditLimit: item.invoiceLimit,
        customerId: item.uuid,
      }),
    {
      onMutate: () => {
        dialogService.showDialog(
          <LoadingDialog message="Alterando limite de crédito " />
        );
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Customer(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMERS, user.agency]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_CREDIT_LIMIT);

        dialogService.popAll();
      },
    }
  );

  const handleUpdateCreditLimite = useCallback(
    (item: Customer) => {
      dialogService.showDialog(
        <CreditLimitUpdateDialog
          data={item}
          onSubmit={mutateUpdateCreditLimite}
        />
      );
    },
    [mutateUpdateCreditLimite]
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: Customer) => customerService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Customer(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Customer(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMERS, user.agency]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: Customer) => {
    if (!item.isActive) {
      mutateToggleState(item);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando empresa"
        onConfirm={() => mutateToggleState(item)}
      />
    );
  }, []);

  const { mutate: mutateToggleFraudBlocking } = useMutation(
    (item: Customer) => customerService.toggleFraudBlocking(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Customer(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Customer(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.CUSTOMERS, user.agency]);

        snackbarService.showSnackbar(
          item.fraudBlocking
            ? SNACKBAR_MESSAGES.UNBLOCK_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.BLOCK_SUCCESS_MESSAGE,
          "success"
        );

        if (!item.fraudBlocking) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.fraudBlocking
            ? SNACKBAR_MESSAGES.UNBLOCK_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.BLOCK_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleFraudBlocking = useCallback((item: Customer) => {
    if (item.fraudBlocking) {
      mutateToggleFraudBlocking(item);
      return;
    }

    dialogService.showDialog(
      <BlockedDialog
        loadingMessage="Bloqueando empresa"
        onConfirm={() => mutateToggleFraudBlocking(item)}
      />
    );
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
    setCurrentPage(1);
  }, []);
  
  const onCustomerStatusSelected = useCallback((data: { label: string; value: null; }) => {
    setCustomerStatusSelected(data)
  }, [])

  return (
    <CustomersContainer
      isLoading={isFetching}
      agenciesList={data?.data}
      currentPage={currentPage}
      lastPage={data?.meta.last_page || 1}
      searchText={searchText}
      setSearchText={handleChangeSearch}
      onGoToPage={setCurrentPage}
      onToggleState={handleToggleState}
      onChangeCustomerCustomerLowCreditFilter={setCustomerLowCreditSelected}
      onToggleFraudBlocking={handleToggleFraudBlocking}
      onChangeCustomerFilter={onCustomerStatusSelected}
      updateCreditLimit={handleUpdateCreditLimite}
    />
  );
}
