import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { Box } from "../Box";
import { Text } from "../Text";

export type AppBarHeadingProps = Omit<ComponentProps<typeof AppBarHeadingRoot>, "title"> & {
  title: React.ReactNode;
  description?: React.ReactNode;
};

const AppBarHeadingRoot = styled("div", {
  // Reset
  boxSizing: "border-box",
  flex: "1 0 0",
});

export const AppBarHeading = ({ title, description, ...props }: AppBarHeadingProps) => (
  <AppBarHeadingRoot {...props}>
    {typeof title === "string" ? (
      <Text
        as="h3"
        size="6"
        css={{
          fw: "700",
          "@mxlg": {
            fw: "600",
            fontSize: "$md",
          },
        }}
      >
        {title}
      </Text>
    ) : (
      title
    )}

    {!!description && (
      <Box css={{ mt: "$2" }}>
        {typeof description === "string" ? (
          <Text
            as="p"
            size={{ "@initial": "3", "@mxlg": "2" }}
            variant={{ "@initial": "white", "@lg": "dark" }}
            css={{ lineHeight: "1.5" }}
          >
            {description}
          </Text>
        ) : (
          description
        )}
      </Box>
    )}
  </AppBarHeadingRoot>
);

AppBarHeading.displayName = "AppBarHeading";
