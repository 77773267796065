import { FC, useCallback, useState } from "react";
import { Flight, FlightOption } from "~/application/types";
import { dialogService } from "~/components/DialogStack";
import { FlightListItem } from "~/presentation/shared/components/FlightListItem";
import { FlightDetailsDialog } from "~/presentation/shared/views/FlightDetailsDialog";
import { BookingFlightListItemProps } from "./types";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";

export const BookingFlightListItem: FC<BookingFlightListItemProps> = ({
  data,
  isSelected,
  selectedOption,
  flightOrigin,
  TagsElement,
  onSelectFlight,
  isInternationalCombined,
}) => {
  const isMobile = useMobile();
  const { airwayBudget, flightQuery, isInternational } = useFlightsPage();
  const [showOptions, setShowOptions] = useState(false);
  const toggleOptionsVisibility = useCallback(() => setShowOptions((old) => !old), []);

  const onOpenDetails = useCallback(() => {
    dialogService.showDialog(
      <FlightDetailsDialog
        data={data}
        flightOrigin={flightOrigin}
        isInternational={isInternational}
      />
    );
  }, [data, flightOrigin, isInternational]);

  const onSelectFlightOption = useCallback(
    (_flight: Flight, option: FlightOption) => {
      onSelectFlight(data, option);

      if (isMobile) {
        toggleOptionsVisibility();
      }
    },
    [data, onSelectFlight]
  );

  return (
    <FlightListItem
      data={data}
      isInternationalCombined={isInternationalCombined}
      isInternational={isInternational}
      isSelected={isSelected}
      selectedOption={selectedOption}
      optionsEnabled
      flightQuery={flightQuery}
      optionsVisibility={showOptions}
      onOpenDetails={onOpenDetails}
      onSelectFlight={onSelectFlightOption}
      onChangeOptionsVisibility={toggleOptionsVisibility}
      TagsElement={TagsElement}
    />
  );
};

BookingFlightListItem.displayName = "BookingFlightListItem";
