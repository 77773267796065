import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Container } from '~/components/Container';

export interface ChartDataProps {
  name: string;
  sales?: number;
  pv?: number;
  amt: number;
}

interface LineChartComponentProps {
  chartData: ChartDataProps[];
}

export function LineChartComponent({ chartData }: LineChartComponentProps) {
  return (
    <ResponsiveContainer width="100%" height={300} style={{ marginTop: "20px" }}>
      <LineChart
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis tickCount={30} interval={30} dataKey="name" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="sales" name='vendas' stroke="#0064c5" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}
