export enum CustomerTab {
  INFORMATION = "informacoes-basicas",
  PAYMENTS = "pagamentos",
  CREDENTIALS = "credenciais",
  PROFILES = "profiles",
  BRANCHES = "filiais",
  APPROVAL_MODELS = "modelos-de-aprovacao",
  COSTS_CENTERS = "centros-de-custo",
  EMPLOYEES = "funcionarios",
  POLICIES_EXPENSES = "politicas-expenses",
  PURCHASING_POLICIES = "politicas-de-compra",
  REASON_TRIPS = "motivos-de-viagem",
  JUSTIFICATIONS = "justificativas",
  EXPENSE_TYPES = "tipo-de-despesa",
  FEES = "taxas",
  MARKUPS = "markups",
  MARKDOWNS = "markdowns",
  PROJECTS = "projetos",
  PHASES = "fases",
  Parameters = "parametros",
  CRM = "CRM",
  BUDGETS = "orcamentos-criados",
  VIEW_BUDGETS = "visualizacao-dos-orcamentos",
  CUSTOMER_EMPLOYEES_GROUPS = "grupos-de-funcionarios",
}

export const CUSTOMER_MENU_TABS = [
  { title: "Informações básicas", id: CustomerTab.INFORMATION },
  { title: "Pagamentos", id: CustomerTab.PAYMENTS },
  { title: "Credenciais", id: CustomerTab.CREDENTIALS },
  { title: "Filiais", id: CustomerTab.BRANCHES },
  { title: "Modelos de aprovação", id: CustomerTab.APPROVAL_MODELS },
  { title: "Centros de custo", id: CustomerTab.COSTS_CENTERS },
  { title: "Perfis", id: CustomerTab.PROFILES },
  { title: "Funcionários", id: CustomerTab.EMPLOYEES },
  { title: "Grupos de funcionários", id: CustomerTab.CUSTOMER_EMPLOYEES_GROUPS },
  { title: "Políticas (Expenses)", id: CustomerTab.POLICIES_EXPENSES },
  { title: "Políticas de compra", id: CustomerTab.PURCHASING_POLICIES },
  { title: "Motivos de viagem", id: CustomerTab.REASON_TRIPS },
  { title: "Justificativas", id: CustomerTab.JUSTIFICATIONS },
  { title: "Tipo de despesa", id: CustomerTab.EXPENSE_TYPES },
  { title: "Taxas", id: CustomerTab.FEES },
  { title: "Markups", id: CustomerTab.MARKUPS },
  { title: "Markdowns", id: CustomerTab.MARKDOWNS },
  { title: "Parâmetros", id: CustomerTab.Parameters },
  { title: "Projetos", id: CustomerTab.PROJECTS },
  { title: "CRM", id: CustomerTab.CRM },
  { title: "Orçamentos", id: CustomerTab.BUDGETS },
];
