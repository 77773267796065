import { CredentialDTO } from "../dtos/CredentialDTO";
import { Credential } from "~/application/types/entities/Credential.type";
import { AgreementDTO } from "../dtos/AgreementDTO";
import { CredentialCustomerDTO } from "../dtos/CredentialCustomerDTO";

export function mapCredentialDTO(data: CredentialDTO): Credential {
  return {
    uuid: data.credential_uuid,
    provider: {
      uuid: data.provider.uuid,
      name: data.provider.name,
      slug: data.provider.slug,
      logo: data.provider.logo,
      serviceType: data.provider.service_type,
      active: data.provider.active,
    },
    active: data.active,
    description: data.description,
    identifier: data.identifier,
    integrationCode: data.integration_code,
    serviceType: data.service_type,
    credentialValues: data.credential_values.map((value) => ({
      uuid: value.uuid,
      name: value.name,
      value: value.value,
      parameter: {
        uuid: value.parameter.uuid,
        title: value.parameter.title,
        description: value.parameter.description,
        input_type: value.parameter.input_type,
        required: value.parameter.required,
      },
    })),
  };
}

export function mapCustomerCredentialDTO(data: CredentialCustomerDTO) {
  return {
    uuid: data.uuid,
    tradingName: data.trading_name,
    companyName: data.company_name,
    cnpj: data.cnpj,
    agreementId: data.agreement_uuid,
    active: data.active,
  };
}

export function mapBindCredentialDTO(agreement: AgreementDTO) {
  return {
    uuid: agreement.uuid,
    codeAgreement: agreement.code_agreement,
    agreementComparation: {
      uuid: agreement.agreement_comparation?.uuid,
      codeAgreement: agreement.agreement_comparation?.code_agreement,
    },
    active: agreement.active,
    credential: {
      serviceType: agreement.credential.service_type,
      uuid: agreement.credential.uuid,
      description: agreement.credential.description,
      provider: {
        name: agreement.credential.provider.name,
        logo: agreement.credential.provider.logo,
        slug: agreement.credential.provider.slug,
        active: agreement.credential.provider.active,
      },
    },
  };
}
