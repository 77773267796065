import * as yup from "yup";
import { QuoteOfflineHotelFormData } from "../../types";

const amenitySchema = yup.object().shape({
  uuid: yup.string().required("Amenity UUID is required"),
  name: yup.string().required("Amenity name is required"),
});

const roomTypeSchema = yup
  .object()
  .required()
  .shape({ name: yup.string().required("Tipo quarto é obrigatorio") });

const regimenSchema = yup
  .object()
  .required()
  .shape({ name: yup.string().required("Regime é obrigatorio") });

export const formSchema = yup.object().shape({
  options: yup.array().of(
    yup.object().shape({
      description: yup.string().required("Descrição é obrigatória"),
      address: yup.string().required("Endereço é obrigatório"),
      phone: yup.string().test("is-valid-phone", "Telefone inválido", (value) => {
        if (!value || value === "+") return true;
        return value.length >= 10;
      }),
      email: yup.string().email("Email inválido"),
      roomType: roomTypeSchema,
      regimen: regimenSchema,
      providerValue: yup.number().required("Valor do fornecedor é obrigatório"),
      customerValue: yup.number().default(0),
      hotelFee: yup.number().default(0),
      hotelMarkup: yup.number().default(0),
      obsIssuer: yup.string(),
      obsAgency: yup.string(),
      paymentPix: yup.string(),
      paymentCreditCard: yup.string(),
      amenities: yup.array().of(amenitySchema),
    })
  ),
}) as yup.ObjectSchema<QuoteOfflineHotelFormData, yup.AnyObject, any, "">;
