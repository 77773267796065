import { FC, useMemo } from "react";
import { usePDF } from "react-to-pdf";
import { AirlineCode, Customer, Flight, FlightServicesIncluded, User } from "~/application/types";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { FormDialog } from "~/components/FormDialog";
import { Icon } from "~/components/Icon";
import { SvgFavicon } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { FlightOptionBenefit } from "~/presentation/shared/components/FlightListItem";
import { createFlightBenefits } from "~/presentation/shared/components/FlightListItem/FlightOptionItem/utils";
import { formatFlightInfo, getAirlineName, getAirlineUrl } from "~/presentation/shared/utils";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";
import { AirwayBudgetState } from "../type";
import { AirwayQueryFormData } from "~/presentation/shared/components/AirwayQueryForm";
import { Grid } from "~/components/Grid";

export type AirwayBudgetDialogProps = {
  data: AirwayBudgetState;
  user?: User;
  customer?: Customer;
  airwayParams: AirwayQueryFormData | null;
};

export const AirwayBudgetDialog: FC<AirwayBudgetDialogProps> = ({
  data,
  user,
  customer,
  airwayParams,
}) => {
  const { toPDF, targetRef } = usePDF({
    filename: "Orçamento.pdf",
    overrides: {
      pdf: {
        compress: true,
      },
    },
  });

  const currentData = Object.values(data);

  return (
    <Card
      css={{
        width: "55vw",
      }}
    >
      <FormDialog
        title={"PDF"}
        css={{
          width: "100%",
          overflow: "hidden",
        }}
        positiveButton={
          <Flex justify="end">
            <Button onClick={() => toPDF()}>Baixar PDF</Button>
          </Flex>
        }
      >
        <Flex
          css={{
            width: "100%",
            overflow: "auto",
            maxHeight: "75vh",
          }}
          direction="column"
        >
          <CardBody ref={targetRef}>
            <Flex gap="2" direction="column">
              <Flex
                css={{
                  width: "100%",
                  p: "$3 $6",
                  borderRadius: "$md $md $none $none",
                  backgroundColor: "$primary-base",
                }}
                align="center"
                justify="between"
              >
                <Icon as={SvgFavicon} size="lg" />
                <Flex direction="column" gap="1">
                  <Text size="1" variant="white">
                    Empresa
                  </Text>
                  <Text size="3" variant="white" fw="600">
                    {customer?.companyName}
                  </Text>
                </Flex>
              </Flex>
              <Flex gap="2" direction="column">
                <Text fw="700">
                  Agente: <Text fw="400">{`${user?.name}`}</Text>
                </Text>
                <Text fw="700">
                  Telefone: <Text fw="400">{user?.phone}</Text>
                </Text>
                <Text fw="700">
                  E-mail: <Text fw="400">{user?.email}</Text>
                </Text>
              </Flex>
              <Divider
                css={{
                  borderColor: "$neutrals-dark",
                  width: "100%",
                }}
              />
              <Flex
                css={{
                  width: "100%",
                }}
                align="center"
                justify="between"
              >
                <Flex
                  css={{
                    p: "$3",
                    borderRadius: "$pill",
                    backgroundColor: "$primary-base",
                  }}
                >
                  <Text variant="white" fw="700" size="4">
                    Cotação Eletrônica
                  </Text>
                </Flex>
                <Flex direction="column" gap="1">
                  <Text size="1" fw="400">
                    Data da cotação
                  </Text>
                  <Text size="3" fw="600">
                    {displayDate(Date(), DateFormats.SMALL_DATE)}
                  </Text>
                </Flex>
              </Flex>
              <Divider
                css={{
                  width: "100%",
                  borderColor: "$neutrals-dark",
                }}
              />
              {currentData.map((newData, index) => {
                if (!newData.length) return;
                return (
                  <Flex direction="column" gap="6" key={`budget ${index}`}>
                    <Text fw="700" variant="primary-dark">
                      Data da {index > 0 ? "volta" : "ida"}:{" "}
                      <Text fw="500" variant="primary-dark">
                        {displayDate(
                          index > 0
                            ? (airwayParams?.stretch?.at(0)?.returnDate as Date)
                            : (airwayParams?.stretch.at(0)?.outboundDate as Date),
                          DateFormats.SMALL_COMPLETE_WEEK_DATE
                        )}
                      </Text>
                    </Text>

                    {newData.map((fli, index) => {
                      const flightInfo = useMemo(() => formatFlightInfo(fli as Flight), [fli]);

                      const scales = flightInfo.flight.segments.length - 1;

                      const connectionsAirportIatas = `Parada${
                        scales > 1 ? "s" : ""
                      }: ${flightInfo.connectionsAirportIatas
                        .map((iata) => `(${iata}) `)
                        .join("")}`;

                      return (
                        <Flex
                          key={fli?.id}
                          direction="column"
                          gap="2"
                          css={{
                            border: "1px solid $neutrals-dark",
                            p: "$1",
                          }}
                        >
                          <Flex gap="3" align="center">
                            <Text
                              fw="700"
                              size="5"
                              variant="primary"
                              css={{
                                border: "1px solid $primary-base",
                                borderRadius: "50%",
                                p: "$1",
                                px: index < 9 ? "$2" : "$1",
                              }}
                            >
                              {`${index + 1}`}
                            </Text>
                            <Text fw="700" size="4">
                              {getAirlineName(fli?.segments?.at(0)?.airline || AirlineCode.GOL)}
                            </Text>
                            <Text fw="700" size="4">
                              {"->"}
                            </Text>
                            <Text size="2" variant="darkest" fw="500">
                              Voo Nº {fli?.segments?.at(0)?.flightNumber}
                            </Text>
                          </Flex>
                          <Flex align="center" justify="center" direction="column" gap="6">
                            <Flex justify="between" align="center" css={{ width: "100%" }}>
                              <Flex
                                direction="column"
                                gap="2"
                                align="center"
                                css={{
                                  flex: "1",
                                }}
                              >
                                <Text fw="700">
                                  {flightInfo.departureFlightInfo.dateTimeHour12}
                                </Text>
                                <Text size="1">Origem</Text>
                                <Text variant="darkest" size="3" fw="600">
                                  {flightInfo.departureFlightInfo.airportIata}
                                  {" - "}
                                  {flightInfo.departureFlightInfo.airport}
                                </Text>
                              </Flex>
                              <Flex
                                direction="column"
                                gap="2"
                                align="center"
                                css={{
                                  flex: "1",
                                }}
                              >
                                <Text fw="600" variant="darkest" size="2">
                                  Duração
                                </Text>
                                <Flex
                                  css={{
                                    width: "100%",
                                  }}
                                  align="center"
                                  gap="1"
                                >
                                  <Divider
                                    css={{
                                      width: "80%",
                                      borderColor: "$neutrals-dark",
                                    }}
                                  />
                                  <Text
                                    size="2"
                                    fw="700"
                                    css={{
                                      minWidth: "max-content",
                                    }}
                                  >
                                    {flightInfo.flightDuration}
                                  </Text>
                                  <Divider
                                    css={{
                                      width: "80%",
                                      borderColor: "$neutrals-dark",
                                    }}
                                  />
                                </Flex>
                                <Text variant="darkest" size="2" fw="600">
                                  {`${scales ? scales : "Sem"} ${
                                    scales > 1 ? "conexões" : "conexão"
                                  }`}
                                </Text>
                                {scales > 0 && (
                                  <Text
                                    variant="darkest"
                                    size="2"
                                    fw="600"
                                    css={{ lineHeight: 1.25 }}
                                  >
                                    {connectionsAirportIatas}
                                  </Text>
                                )}
                              </Flex>
                              <Flex
                                direction="column"
                                gap="2"
                                align="center"
                                css={{
                                  flex: "1",
                                }}
                              >
                                <Text fw="700">{flightInfo.arrivalInfo.dateTimeHour12}</Text>
                                <Text size="1">Origem</Text>
                                <Text variant="darkest" size="3" fw="600">
                                  {flightInfo.arrivalInfo.airportIata}
                                  {" - "}
                                  {flightInfo.arrivalInfo.airport}
                                </Text>
                              </Flex>
                            </Flex>
                            <Grid
                              columns={2}
                              justify="center"
                              gap="6"
                              css={{
                                width: "100%",
                              }}
                            >
                              {fli?.options?.map((option) => {
                                const flightBenefits = useMemo(
                                  () =>
                                    createFlightBenefits(
                                      option?.servicesIncluded as FlightServicesIncluded
                                    ),
                                  [option]
                                );
                                return (
                                  <Flex
                                    key={option?.id}
                                    direction="column"
                                    gap="2"
                                    css={{
                                      p: "$2",
                                      border: "1px solid $neutrals-light",
                                    }}
                                  >
                                    <Flex justify="between" gap="3">
                                      <Text size="2" fw="700">
                                        {option?.familyFare}
                                      </Text>
                                      <Text size="2" fw="600">
                                        {asCurrency(option?.pricingInfo?.total?.amount || 0)}
                                      </Text>
                                    </Flex>
                                    <ul
                                      style={{
                                        margin: "0",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      {flightBenefits.map(
                                        (item) =>
                                          item.isExists && (
                                            <li key={item.name}>
                                              <Text size="2">{item.name}</Text>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                  </Flex>
                                );
                              })}
                            </Grid>
                          </Flex>
                        </Flex>
                      );
                    })}
                    <Divider
                      css={{
                        borderStyle: "groove",
                        borderColor: "$neutrals-dark",
                      }}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </CardBody>
        </Flex>
      </FormDialog>
    </Card>
  );
};
