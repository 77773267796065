import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { OrderAdvance, Traveler } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { NumberInput, TextAreaInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { QueryKeys } from "~/constants/queryKeys";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";

export interface AdvanceDialogProps {
  isNew?: boolean;
  defaultData?: Partial<OrderAdvance>;
  onCloseClick?: () => void;
  onSubmit: (data: OrderAdvance) => void;
  fetchTravelers: () => Promise<Traveler[]>;
}

export function AdvanceDialog({
  isNew = true,
  defaultData,
  onCloseClick,
  onSubmit,
  fetchTravelers,
}: AdvanceDialogProps) {
  const { control, formState, handleSubmit } = useForm<OrderAdvance>({
    defaultValues: defaultData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { fields, append } = useFieldArray({
    control,
    name: "travelers",
  });

  const onAddOrderAdvance = useCallback(() => {
    append({
      description: "",
    } as any);
  }, [append]);

  const { data: employees, isFetching: isFetchingTravelers } = useQuery(
    [QueryKeys.CUSTOMER_EMPLOYEES],
    fetchTravelers,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  const getTravelerLabel = useCallback((item: Traveler) => item.fullName, []);

  const getTravelerValue = useCallback((item: Traveler) => item.uuid, []);

  return (
    <Container size="10" fixed>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          title="Novo adiantamento"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>Adicionar</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            {isNew && (
              <Box css={{ mb: "$10" }}>
                <Box css={{ mb: "$8" }}>
                  <H5>Informações de pedido</H5>
                </Box>

                <FormControl name="numberOfDays" control={control} required>
                  <FieldLabel>Quantidade de dias</FieldLabel>
                  <NumberInput fixedDecimalScale={false} />
                </FormControl>
              </Box>
            )}

            <Box css={{ mb: "$8" }}>
              <H5>Funcionários</H5>
            </Box>

            <Flex direction="column" gap="6">
              {fields.map((field, index) => (
                <Card key={field.id}>
                  <CardBody>
                    <Row
                      gap="4"
                      css={{
                        "@mxlg": {
                          display: "flex",
                          flexDirection: "column",
                        },
                      }}
                    >
                      <Col
                        sz="6"
                        css={{
                          "@mxlg": {
                            width: "100%",
                          },
                        }}
                      >
                        <FormControl
                          name={`travelers.${index}.traveler`}
                          control={control}
                          required
                        >
                          <FieldLabel>Funcionário</FieldLabel>
                          <AsyncSelect
                            placeholder="Selecione um funcionário"
                            defaultOptions={employees}
                            isLoading={isFetchingTravelers}
                            disabled={!isNew}
                            getOptionValue={getTravelerValue}
                            getOptionLabel={getTravelerLabel}
                          />
                        </FormControl>
                      </Col>
                      <Col
                        sz="6"
                        css={{
                          "@mxlg": {
                            width: "100%",
                          },
                        }}
                      >
                        <FormControl name={`travelers.${index}.value`} control={control} required>
                          <FieldLabel>Valor do adiantamento</FieldLabel>
                          <NumberInput prefix="R$ " placeholder="R$" />
                        </FormControl>
                      </Col>
                      <Col sz="12">
                        <FormControl
                          name={`travelers.${index}.description`}
                          control={control}
                          required
                        >
                          <FieldLabel>Observações</FieldLabel>
                          <TextAreaInput placeholder="Digite uma observação para liberação do adiantamento" />
                        </FormControl>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
              {isNew && (
                <Button
                  title="Adicionar funcionário"
                  variant="tertiary"
                  onClick={onAddOrderAdvance}
                >
                  <Text>Adicionar funcionário</Text>
                </Button>
              )}
            </Flex>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
