import { useCallback, useState } from "react";
import { Collapse } from "react-collapse";
import {
  Order,
  OrderHotelItem,
  OrderHotelItem as OrderHotelItemType,
  OrderHotelOfflineOption,
  OrderItemStatus,
  OrderStatus,
} from "~/application/types";
import { QuoteOfflineHotelProps } from "~/application/usecases/OfflineHotel";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCheck, SvgChevronDown, SvgChevronUp, SvgInfo, SvgPlus } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { LazyList } from "~/components/LazyList";
import { DataListItem } from "~/components/List";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { QuoteOfflineHotelForm } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/QuoteOfflineHotelForm";
import { EMPTY_OPTION } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/QuoteOfflineHotelForm/constants";
import { QuoteOfflineHotelFormData } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/components/QuoteOfflineHotelForm/types";
import { TravelerListItem } from "~/core/shared/components/TravelerListItem";
import { OrderHotelCard } from "~/presentation/shared/components/OrderHotelCard";
import { OrderHotelItemSkeleton } from "~/presentation/shared/components/OrderHotelItemSkeleton";
import { ReasonRejectedItem } from "~/presentation/shared/components/ReasonRejectedItem";
import { useCopyTracker } from "~/presentation/shared/hooks/useCopyTracker";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { canMarkAsRejected } from "~/presentation/shared/utils";
import { formatNumber } from "~/utils/string.utils";
import { OrderHotelOfflineAgencyItem } from "./OrderHotelOfflineAgencyItem";
import { AdicionalTravelerInfo } from "~/presentation/shared/components/AdicionalTravelerInfo";

export interface OrderHotelItemProps {
  isLoading: boolean;
  data?: OrderHotelItemType[];
  onQuote: (data: QuoteOfflineHotelProps) => void;
  onDeleteOfflineQuote: (optionId: string) => void;
  onOpenOptionDetails: (item: OrderHotelItem, option: OrderHotelOfflineOption) => void;
}

export default function OrderHotelItemComponent({
  data,
  isLoading,
  onQuote,
  onOpenOptionDetails,
  onDeleteOfflineQuote,
}: OrderHotelItemProps) {
  const { order } = useOrder();
  const { renderCopyTracker } = useCopyTracker();
  const orderStatus = order?.status as OrderStatus;

  const onOpenMoreInfoDialog = useCallback(
    (travelerId: string) => {
      dialogService.showDialog(<AdicionalTravelerInfo travelerId={travelerId} order={order} />);
    },
    [order]
  );

  const [roomExpanded, setRoomExpanded] = useState(
    data?.map((room) => {
      return {
        id: room.uuid,
        roomExpanded: true,
        checked: room.options?.some((option) => option.status === OrderItemStatus.QUOTED),
      };
    }) as Array<{ id: string; roomExpanded: boolean; checked: boolean }>
  );

  const handleOpenQuoteOfflineHotelForm = useCallback(
    (item: OrderHotelItemType, options: OrderHotelOfflineOption[]) => {
      const onSubmit = (data: QuoteOfflineHotelFormData) => {
        onQuote({
          room: item,
          options: data.options.map((option) => ({
            uuid: option.uuid,
            description: option.description,
            address: option.address,
            phone: option.phone,
            email: option.email,
            providerValue: option.providerValue || 0,
            customerValue: option.customerValue || 0,
            hotelFee: option.hotelFee || 0,
            hotelMarkup: option.hotelMarkup || 0,
            obsIssuer: option.obsIssuer,
            obsAgency: option.obsAgency,
            paymentPix: option.paymentPix,
            paymentCreditCard: option.paymentCreditCard,
            whatsapp: option.whatsapp,
            paymentMethod: option.paymentMethod,
            regimen: option.regimen,
            roomType: option.roomType,
            amenities: option.amenities.map((amenity) => ({
              uuid: amenity.uuid,
              name: amenity.name,
            })),
            status: option.status,
            note: option.note,
            checkIn: option.checkIn,
            checkOut: option.checkOut,
          })),
        });
      };

      const defaultData = {
        options: options.length
          ? options.map((option) => ({
              uuid: option.uuid,
              description: option.description,
              address: option.address,
              phone: option.phone,
              email: option.email,
              providerValue: option.providerValue,
              customerValue: option.customerValue,
              hotelFee: option.hotelFee,
              hotelMarkup: option.hotelMarkup,
              obsIssuer: option.obsIssuer,
              obsAgency: option.obsAgency,
              paymentPix: option.paymentPix,
              whatsapp: option.whatsapp,
              paymentMethod: option.paymentMethod,
              paymentCreditCard: option.paymentCreditCard,
              regimen: { name: option.regimen },
              roomType: { name: option.roomType },
              amenities: option?.amenities?.map((amenity) => ({
                uuid: amenity.uuid,
                name: amenity.name,
              })),
              checkIn: option.checkIn || new Date(item.checkOut),
              checkOut: option.checkOut || new Date(item.checkOut),
              otherTaxes: option.otherTaxes,
            }))
          : [
              {
                ...EMPTY_OPTION,
                checkIn: new Date(item.checkOut),
                checkOut: new Date(item.checkOut),
              },
            ],
      };

      dialogService.showDialog(
        <QuoteOfflineHotelForm
          order={order as Order}
          isCopy={false}
          item={item}
          defaultData={defaultData as QuoteOfflineHotelFormData}
          onSubmit={onSubmit}
        />
      );
    },
    [order, onQuote]
  );

  const handleCopyQuoteOfflineHotel = useCallback(
    (item: OrderHotelItem, options: OrderHotelOfflineOption[]) => {
      const onSubmit = (data: QuoteOfflineHotelFormData) => {
        onQuote({
          room: data.options[0]?.replicateRoom,
          options: data.options.map((option) => ({
            description: option.description,
            address: option.address,
            phone: option.phone,
            email: option.email,
            providerValue: option.providerValue || 0,
            customerValue: option.customerValue || 0,
            hotelFee: option.hotelFee || 0,
            hotelMarkup: option.hotelMarkup || 0,
            obsIssuer: option.obsIssuer,
            obsAgency: option.obsAgency,
            paymentPix: option.paymentPix,
            paymentMethod: option.paymentMethod,
            paymentCreditCard: option.paymentCreditCard,
            regimen: option.regimen,
            roomType: option.roomType,
            amenities: option.amenities.map((amenity) => ({
              uuid: amenity.uuid,
              name: amenity.name,
            })),
            status: option.status,
            note: option.note,
            checkIn: option.checkIn,
            checkOut: option.checkOut,
          })),
        });
      };

      const defaultData = {
        options: options.length
          ? options.map((option) => ({
              uuid: option.uuid,
              description: option.description,
              address: option.address,
              phone: option.phone,
              email: option.email,
              providerValue: option.providerValue,
              customerValue: option.customerValue,
              hotelFee: option.hotelFee,
              hotelMarkup: option.hotelMarkup,
              obsIssuer: option.obsIssuer,
              obsAgency: option.obsAgency,
              paymentPix: option.paymentPix,
              paymentCreditCard: option.paymentCreditCard,
              paymentMethod: option.paymentMethod,
              regimen: { name: option.regimen },
              roomType: { name: option.roomType },
              amenities: option?.amenities?.map((amenity) => ({
                uuid: amenity.uuid,
                name: amenity.name,
              })),
              checkIn: option.checkIn || new Date(item.checkOut),
              checkOut: option.checkOut || new Date(item.checkOut),
              otherTaxes: option.otherTaxes,
            }))
          : [
              {
                ...EMPTY_OPTION,
                checkIn: new Date(item.checkOut),
                checkOut: new Date(item.checkOut),
              },
            ],
      };

      dialogService.showDialog(
        <QuoteOfflineHotelForm
          isCopy={true}
          order={order as Order}
          item={item}
          defaultData={defaultData as QuoteOfflineHotelFormData}
          onSubmit={onSubmit}
        />
      );
    },
    [order, onQuote]
  );

  const toggleRoomExpanded = useCallback(
    (room: OrderHotelItemType) => {
      setRoomExpanded((old) => {
        const index = old.findIndex((item) => item.id === room.uuid);

        const newRoom = {
          id: room.uuid,
          roomExpanded: old[index].roomExpanded ? false : true,
          checked: room.options?.some(
            (option) => option.status === OrderItemStatus.QUOTED
          ) as boolean,
        };

        return [...old.slice(0, index), newRoom, ...old.slice(index + 1)];
      });
    },
    [roomExpanded]
  );
  const listRenderer = useCallback(
    (item: OrderHotelItemType, index: number) => {
      const shouldMarkAsRejected = canMarkAsRejected({
        reasonRejected: item.reasonRejected,
        order,
      });

      const reasonRejected = item.reasonRejected;
      const isOpen = roomExpanded.find((room) => room.id === item.uuid)?.roomExpanded as boolean;

      const checked = roomExpanded.find((room) => room.id === item.uuid)?.checked as boolean;

      const cannotAddQuote =
        orderStatus !== OrderStatus.QUOTING && orderStatus !== OrderStatus.OPEN;

      return item.isOffline ? (
        <Box>
          <DataListItem css={{ p: "$4 $6" }}>
            <Col>
              <Text size="4" css={{ fw: "400" }}>
                {`Quarto ${index + 1}`}
              </Text>
            </Col>

            {shouldMarkAsRejected && (
              <Tag variant="error-light">
                <Text>Reprovado</Text>
              </Tag>
            )}

            {orderStatus !== OrderStatus.CANCELED && (
              <Tooltip
                content={<TooltipLabel>{isOpen ? "Recolher itens" : "Ver itens"}</TooltipLabel>}
              >
                <IconButton
                  size="lg"
                  onClick={() => toggleRoomExpanded(item)}
                  css={{
                    fill: "$success-base",
                  }}
                >
                  <Icon as={checked ? SvgCheck : isOpen ? SvgChevronDown : SvgChevronUp} />
                </IconButton>
              </Tooltip>
            )}
          </DataListItem>

          <Collapse isOpened={isOpen}>
            <Box css={{ mt: "$6" }}>
              <Flex gap="8" direction="column">
                <OrderHotelOfflineAgencyItem
                  item={item}
                  index={index}
                  order={order}
                  onOpenQuoteOffline={handleOpenQuoteOfflineHotelForm}
                  onCopyQuoteOffline={handleCopyQuoteOfflineHotel}
                  onDeleteQuoteOffline={onDeleteOfflineQuote}
                  onItemExpand={toggleRoomExpanded}
                  onOpenOptionDetails={onOpenOptionDetails}
                />

                {!order?.isExpired && (
                  <Col css={{ alignSelf: "center" }}>
                    <Button
                      variant="primary"
                      onClick={() => handleOpenQuoteOfflineHotelForm(item, [])}
                      css={{
                        "@mxlg": {
                          height: "$10",
                        },
                      }}
                      disabled={cannotAddQuote}
                    >
                      <SvgPlus />
                      Adicionar cotação
                    </Button>
                  </Col>
                )}
                <Text as="h4">Hóspedes</Text>

                {item.guests.map((passenger) => {
                  const traveler = order?.travelers.find((t) => passenger.uuid === t.uuid);
                  return (
                    <Card css={{ border: "none" }} key={passenger.uuid}>
                      <CardBody
                        css={{
                          p: "$8",
                          "@mxlg": {
                            p: "$5",
                          },
                        }}
                      >
                        <Flex justify="around" gap="2">
                          <Col>
                            <Flex direction="column" gap="3">
                              <Text size={{ "@mxlg": "3" }} fw="600">
                                NOME
                              </Text>
                              <Text size={{ "@mxlg": "2" }}>{`${passenger?.fullName}`}</Text>
                            </Flex>
                          </Col>

                          <Col>
                            <Flex direction="column" gap="3">
                              <Text size={{ "@mxlg": "3" }} fw="600">
                                CPF
                              </Text>
                              <Text size={{ "@mxlg": "2" }}>{`${passenger?.cpf}`}</Text>
                            </Flex>
                          </Col>

                          {traveler?.group?.description && (
                            <Col>
                              <Flex direction="column" gap="3">
                                <Text size={{ "@mxlg": "3" }} fw="600">
                                  GRUPO
                                </Text>
                                <Text size={{ "@mxlg": "2" }}>{traveler?.group?.description}</Text>
                              </Flex>
                            </Col>
                          )}

                          <Col>
                            <Flex direction="column" gap="3">
                              <Text size={{ "@mxlg": "3" }} fw="600">
                                RG
                              </Text>
                              <Text size={{ "@mxlg": "2" }}>{passenger.rg}</Text>
                            </Flex>
                          </Col>
                          <Col>
                            <Flex direction="column" gap="3">
                              <Button
                                variant="tertiary"
                                onClick={() => onOpenMoreInfoDialog(passenger.uuid)}
                              >
                                <Icon as={SvgInfo} />
                              </Button>
                            </Flex>
                          </Col>
                        </Flex>
                      </CardBody>
                    </Card>
                  );
                })}
              </Flex>
            </Box>
          </Collapse>

          <hr style={{ marginTop: "2rem", border: "1px solid  #d6cbcb" }} />
        </Box>
      ) : (
        <Card css={{ border: shouldMarkAsRejected ? "1px solid red" : "none" }}>
          <CardBody>
            <Flex direction="column" gap="6" key={item.uuid}>
              <Flex justify="between">
                <Text size="5">Quarto {formatNumber(1 + index, 2)}</Text>

                <Flex css={{ gap: "$2" }} align="center">
                  <Tag variant="info-light">{item.credentialDescription}</Tag>

                  <Text variant="darkest" css={{ fw: "600" }}>
                    <Flex align="center">{item.tracker && item.tracker}</Flex>
                  </Text>

                  {item.tracker && renderCopyTracker(item.tracker as string)}
                </Flex>
              </Flex>

              <OrderHotelCard data={item} onDeleteItem={undefined} />

              <Text size="5">Hóspedes</Text>

              <LazyList
                items={item.guests}
                render={(guest) => <TravelerListItem data={guest} key={guest.uuid} />}
                skeletonHeight={88}
                skeletonQuantity={2}
              />

              {shouldMarkAsRejected && <ReasonRejectedItem reasonRejected={reasonRejected} />}
            </Flex>
          </CardBody>
        </Card>
      );
    },
    [handleOpenQuoteOfflineHotelForm, onOpenOptionDetails, roomExpanded, order]
  );

  return (
    <Box>
      <LazyList
        isLoading={isLoading}
        items={data}
        gap="8"
        render={listRenderer}
        skeletonQuantity={1}
        SkeletonComponent={<OrderHotelItemSkeleton />}
      />
    </Box>
  );
}
