import { FC, useState } from "react";
import { useFieldArray } from "react-hook-form";

import { Avatar } from "~/components/Avatar";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col } from "~/components/Grid";
import { NumberInput, Select, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { RoadIssuanceFormProps } from "./types";

export const RoadIssuanceForm: FC<RoadIssuanceFormProps> = ({
  control,
  orderRoad,
  agreements,
  isLoading,
  connections,
  watchIssuances,
  formState,
  paymentMethods,
  isLoadingPaymentMethod,
  setValue,
  setError,
  clearErrors,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const { fields } = useFieldArray({
    control,
    name: "issuance.travelers",
  });


  const calculateTotal = (travelerIndex: number) => {
    const fare = (watchIssuances.travelers?.at(travelerIndex)?.fare) || 0;
    const providerFees = (watchIssuances.travelers?.at(travelerIndex)?.providerFees) || 0;
    const fee = (watchIssuances.travelers?.at(travelerIndex)?.fee) || 0;
    const markup = (watchIssuances.travelers.at(travelerIndex)?.markup) || 0;
    return (fare + providerFees + fee + markup);
  };


  const handleBpeChange = (travelerIndex: number, value: string) => {
    const bpeEqual = watchIssuances.travelers.some((traveler) => {
      return traveler.seats.some((seat) => seat.bpe === value);
    });

    setValue(
      `issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`,
      value
    );

    if (bpeEqual) {
      setError(`issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`, {
        type: "required",
        message: "Os links não podem ser iguais.",
      });
    } else if (value.trim() && value.match(/admin/)) {
      setError(`issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`, {
        type: "required",
        message: "Certifique-se de que o link não contenha 'admin'.",
      });
    } else if (value.trim() && !value.match(/^(http|https):\/\//)) {
      setError(`issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`, {
        type: "required",
        message: "Certifique-se de que o link comece com 'http'.",
      });
    } else {
      clearErrors(
        `issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`
      );
    }
  };

  const nextPage = () => {
    setCurrentPage(1);
  };

  const prevPage = () => {
    setCurrentPage(0);
  };

  const getConnectionText = () => {
    if (!connections) return undefined;

    const { arrival, departure, connection } = connections;

    if (!departure || !connection || !arrival) return undefined;

    const [departureCity] = departure.split(", ");
    const [connectionCity] = connection.split(", ");
    const [arrivalCity] = arrival.split(", ");

    return {
      firstSection: [departureCity, connectionCity],
      secondSection: [connectionCity, arrivalCity],
    };
  };

  const connectionText = getConnectionText();

  const agreementsOptions = agreements?.map((agreement) => ({
    name: agreement.codeAgreement,
    uuid: agreement.uuid,
  }));

  return (
    <Flex
      align="center"
      direction="column"
      css={{
        py: "$10",
        backgroundColor: "$neutrals-lightest",
      }}
      gap="4"
    >
      {connectionText && (
        <Flex gap="3">
          <Button
            onClick={prevPage}
            variant="secondary"
            css={{ opacity: currentPage ? 0.6 : 1 }}
          >
            {connectionText.firstSection[0]}
            {" -> "}
            {connectionText.firstSection[1]}
          </Button>

          <Button
            onClick={nextPage}
            variant="secondary"
            css={{ opacity: currentPage ? 1 : 0.6 }}
          >
            {connectionText.secondSection[0]}
            {" -> "}
            {connectionText.secondSection[1]}
          </Button>
        </Flex>
      )}

      <Flex as={Card} direction="column">
        {orderRoad.travelers.map((traveler, travelerIndex) => (
          <Flex
            as={CardBody}
            direction="column"
            gap="4"
            key={`${traveler.uuid}-${currentPage}`}
          >
            <Col>
              <FieldLabel>Passageiro</FieldLabel>
              <Flex
                as={Card}
                gap="4"
                align="center"
                css={{
                  p: "0 $3",
                  height: "$13",
                  border: "2px solid $primary-base",
                }}
              >
                <Avatar src={traveler.avatarUrl} />
                <Text>{traveler.fullName}</Text>
              </Flex>
            </Col>

            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.tracker`}
              >
                <FieldLabel>Código da reserva</FieldLabel>
                <TextInput placeholder="Digite o código da reserva" />
              </FormControl>
            </Col>

            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.seats.${currentPage}.ticket`}
                required
              >
                <FieldLabel>Número do bilhete</FieldLabel>
                <TextInput placeholder="Digite o número do bilhete" />
              </FormControl>
            </Col>

            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.seats.${currentPage}.bpe`}
              >
                <FieldLabel>Link do bilhete</FieldLabel>
                <Flex direction="column" gap="3">
                  <TextInput
                    placeholder="Digite o link do bilhete"
                    onChange={(ev) =>
                      handleBpeChange(travelerIndex, ev.target.value)
                    }
                  />

                  {formState?.errors?.issuance?.travelers?.[travelerIndex]?.seats?.[currentPage]?.bpe && (
                    <Text as={"p"} css={{ color: "$error-base" }}>
                      {formState?.errors?.issuance?.travelers?.[travelerIndex]
                        ?.seats?.[currentPage]?.bpe?.message ?? ""}
                    </Text>
                  )}
                </Flex>
              </FormControl>
            </Col>

            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.paymentMethod`}
              >
                <FieldLabel>Método de pagamento</FieldLabel>
                <Select
                  isLoading={isLoadingPaymentMethod}
                  placeholder="Selecione o método de pagamento"
                  options={paymentMethods}
                  getOptionValue={(provider) => provider.uuid}
                  getOptionLabel={(provider) => provider.description}
                />
              </FormControl>
            </Col>

            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.provider`}
              >
                <FieldLabel>Fornecedor</FieldLabel>
                <Select
                  isLoading={isLoading}
                  placeholder="Selecione o fornecedor"
                  options={agreementsOptions}
                  getOptionValue={(agreement) => agreement.uuid}
                  getOptionLabel={(agreement) => agreement.name}
                />
              </FormControl>
            </Col>

            <Col>
              <FormControl
                control={control}
                name={`issuance.travelers.${travelerIndex}.seats.${currentPage}.seatNumber`}
              >
                <FieldLabel>Número do Assento</FieldLabel>
                <TextInput
                  maxLength={45}
                  placeholder="Digite o número do assento"
                />
              </FormControl>
            </Col>

            <Col>
              <Flex gap="2">
                <Col>
                  <FormControl
                    control={control}
                    name={`issuance.travelers.${travelerIndex}.fare`}
                    required
                  >
                    <FieldLabel>Tarifa</FieldLabel>
                    <NumberInput prefix="R$ " maxLength={45} />
                  </FormControl>
                </Col>
                <Col>
                  <FormControl
                    control={control}
                    name={`issuance.travelers.${travelerIndex}.providerFees`}
                    required
                  >
                    <FieldLabel>Taxas e encargos</FieldLabel>
                    <NumberInput prefix="R$ " maxLength={45} />
                  </FormControl>
                </Col>
              </Flex>

              <Flex gap="2" css={{ py: "$2" }}>
                <Col>
                  <FormControl
                    control={control}
                    name={`issuance.travelers.${travelerIndex}.fee`}
                    required
                  >
                    <FieldLabel>Fee</FieldLabel>
                    <NumberInput prefix="R$ " maxLength={45} />
                  </FormControl>
                </Col>
                <Col>
                  <FormControl
                    control={control}
                    name={`issuance.travelers.${travelerIndex}.markup`}
                    required
                  >
                    <FieldLabel>Markup</FieldLabel>
                    <NumberInput prefix="R$ " maxLength={45} />
                  </FormControl>
                </Col>
              </Flex>
              <Col>
                  <FormControl
                    control={control}
                    disabled
                    name={`issuance.travelers.${travelerIndex}.total`}
                  >
                    <FieldLabel>Total</FieldLabel>
                    <NumberInput prefix="R$ " maxLength={45} value={calculateTotal(travelerIndex)} />
                  </FormControl>
                </Col>
            </Col>

            {travelerIndex < orderRoad.travelers.length - 1 && (
              <hr style={{ width: "100%" }} />
            )}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

RoadIssuanceForm.displayName = "RoadIssuanceForm";
