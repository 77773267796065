import { Flight } from "~/application/types";
import { dayTimeOptions } from "~/presentation/Booking/constants";
import { IFilterGenerator, IFilterGroup, getAirlineName } from "~/presentation/shared/utils";

export const STATIC_FLIGHT_FILTERS: IFilterGroup<Flight>[] = [
  {
    key: "scales",
    label: "Escalas",
    isValid: (item, optionValue) => item.scales <= optionValue,
    options: [
      {
        key: "scales0",
        label: "Vôo direto",
        value: 0,
      },
      {
        key: "scales1",
        label: "Até 1 parada",
        value: 1,
      },
      {
        key: "scales2",
        label: "Até 2 paradas",
        value: 2,
      },
      {
        key: "scales3",
        label: "Até 3 paradas",
        value: 3,
      },
    ],
  },
  {
    key: "departureTime",
    label: "Período de partida",
    isValid: (item, optionValue) => {
      const departureTime = item.segments[0].departureFlightInfo.dateTime.getHours();

      return optionValue[0] <= departureTime && departureTime < optionValue[1];
    },
    options: dayTimeOptions,
  },
  {
    key: "arrivalTime",
    label: "Período de chegada",
    isValid: (item, optionValue) => {
      const arrivalTime =
        item.segments[item.segments.length - 1].arrivalFlightInfo.dateTime.getHours();

      return optionValue[0] <= arrivalTime && arrivalTime < optionValue[1];
    },
    options: dayTimeOptions,
  },
  {
    key: "baggages",
    label: "Bagagens",
    isValid: (item, optionValue) => {
      return optionValue ? !!item.baggages.length : !item.baggages.length;
    },
    options: [
      {
        key: "withLuggage",
        label: "Com bagagem",
        value: true,
      },
      {
        key: "withoutLuggage",
        label: "Sem bagagem",
        value: false,
      },
    ],
  },
];

export const DYNAMIC_FLIGHT_FILTERS: IFilterGenerator<Flight>[] = [
  {
    key: "airline",
    label: "Companhia aérea",
    generator: (item) => ({
      key: item.airline,
      value: item.airline,
      label: getAirlineName(item.airline),
    }),
  },
  {
    key: "goAirport",
    label: "Aeroporto de saída",
    generator: (item) => item.segments[0].departureFlightInfo.airport,
  },
  {
    key: "returnAirport",
    label: "Aeroporto de chegada",
    generator: (item) => item.segments[item.segments.length - 1].arrivalFlightInfo.airport,
  },
];
