import { useContext } from "react";
import { FormControlContext } from "../../FormControl/FormControlContext";
import { SelectBase, SelectBaseProps } from "../base/SelectBase";

export type SelectProps<TOption = unknown> = SelectBaseProps<TOption> & {
  style?: React.CSSProperties;
  formatOptionLabel?: (option: TOption) => React.ReactNode; // Adicionando a prop aqui
};

export const Select = <TOption,>({
  name: nameProps,
  disabled: disabledProps,
  onBlur: onBlurProps,
  onChange: onChangeProps,
  value: valueProps,
  style,
  formatOptionLabel,
  ...props
}: SelectProps<TOption>) => {
  const formControl = useContext(FormControlContext);
  const name = nameProps ?? formControl.name;
  const disabled = disabledProps ?? formControl.disabled;
  const onBlur = onBlurProps ?? formControl.onBlur;
  const onChange = onChangeProps ?? formControl.onChange;
  const value = valueProps ?? formControl.value;
  const isDirty = !!formControl.error;
  const isFieldError = !!formControl.error;

  return (
    <SelectBase
      style={style}
      {...props}
      name={name}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      isFieldError={isFieldError}
      isDirty={isDirty}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

Select.displayName = "Select";
