import { useForm } from "react-hook-form";

import { 
  InputTypeRender
 } from "~/presentation/CustomerSettings/pages/PurchasingPoliciesPage/components/InputTypeRender/InputTypeRender";
import { 
  CardSelectTypePolicies
 } from "~/presentation/CustomerSettings/pages/PurchasingPoliciesPage/components/CardSelectPoliciesType";
import { 
  PolicyFilter
} from "~/presentation/CustomerSettings/pages/PurchasingPoliciesPage/type";
import { Actions, ReasonTrip } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { Icon } from "~/components/Icon";
import { SvgCheck } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { FieldLabel } from "~/components/FormControl";
import { NumberInput } from "~/components/Input";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { useState } from "react";
import { useReasonTrips } from "../../hooks/useReasonTrips";
import { PurchansingPolicyType } from "~/presentation/CustomerSettings/pages/PurchasingPoliciesPage/hooks/type";
import { FormPolicyData } from "~/application/usecases/Policy/IPolicyService";

export type TabPurchasingPoliciesProps = {
  data?: (PolicyFilter | undefined)[];
  isLoading: boolean;
  disableSubmit: boolean;
  isReasonTripPolicy: boolean;
  customerId: string;
  policySelect: PurchansingPolicyType;
  onSubmitPolicies: () => void;
  setPolicySelect: (type: PurchansingPolicyType) => void;
  onChancePolicies: ({ policyParameterId, policyId, value }: FormPolicyData) => void;
};

export function TabPurchasingPolicies({
  data,
  policySelect,
  onChancePolicies,
  onSubmitPolicies,
  setPolicySelect,
  isLoading,
  disableSubmit,
  isReasonTripPolicy,
  customerId
}: TabPurchasingPoliciesProps) {
  const { handleSubmit } = useForm({ defaultValues: data });
  const { contexts, profile } = useUser();

  const [reasonSelected, setReason] = useState<ReasonTrip>();

  const {
    data: reasonTrip,
    isLoading: isLoadingReasonTrip,
    fetchReasonTrip,
  } = useReasonTrips({
    customerId,
    enabled: isReasonTripPolicy,
  });

  const canChangePolicyValue = useVerifyActions({
    actions: [Actions.CreatePolicyValue],
    contexts,
    profile,
  });

  const reasonTripPolicySelected = data?.find((policy) => {
    return (
      policy?.value.reasonTrip?.uuid === reasonSelected?.uuid && policy?.value.reasonTrip?.uuid
    );
  });

  const policies = data?.filter((policy) => policy?.value && !isNaN(policy?.value.value));

  return (
    <>
      <Card css={{ p: "$6", minHeight: "6.25rem" }}>
        <Flex align="center" css={{ flex: 1 }}>
          <H4>Políticas de compra</H4>
        </Flex>
      </Card>

      <Box css={{ my: "$6" }}>
        <Flex direction={{ "@initial": "column", "@lg": "row" }} gap="8">
          <CardSelectTypePolicies
            onSelectPolicieSelect={setPolicySelect}
            policieSelect={policySelect}
          />
          <Form onSubmit={handleSubmit(onSubmitPolicies)} css={{ flex: 1 }}>
            <Flex
              direction="column"
              gap="8"
            >
              <Text size="6" fw="500">
                Políticas de {policySelect.name.toLowerCase()}
              </Text>

              {isReasonTripPolicy && (
                <AsyncSelect
                  placeholder="Selecione o motivo de viagem"
                  defaultOptions={reasonTrip}
                  defaultValue={reasonSelected}
                  isLoading={isLoadingReasonTrip}
                  fetchOptions={fetchReasonTrip}
                  getOptionLabel={(reason) => reason.reason}
                  getOptionValue={(reason) => reason.uuid}
                  onChange={(policies) => setReason(policies)}
                />
              )}

              {reasonTripPolicySelected && (
                <Flex direction="column" gap="2">
                  <FieldLabel>{reasonTripPolicySelected?.name}</FieldLabel>
                  <NumberInput
                    value={Number(reasonTripPolicySelected?.value.value)}
                    key={reasonTripPolicySelected?.value.parameter?.uuid}
                    onChange={(e) =>
                      onChancePolicies({
                        policyId: reasonTripPolicySelected?.uuid || "",
                        policyParameterId: reasonTripPolicySelected?.value.parameter?.uuid || "",
                        value: e.target.value.toString(),
                        policyValueId: reasonTripPolicySelected?.value.uuid || "",
                      })
                    }
                  />
                </Flex>
              )}

              {!isReasonTripPolicy && (
                <LazyList
                  isLoading={isLoading}
                  items={policies as PolicyFilter[]}
                  render={(policy) => {
                    return <InputTypeRender data={policy} onChancePolicies={onChancePolicies} />;
                  }}
                />
              )}

              {canChangePolicyValue && (
                <Flex justify="end">
                  <Button type="submit" disabled={!disableSubmit}>
                    <Icon as={SvgCheck} />
                    <Text>Salvar</Text>
                  </Button>
                </Flex>
              )}
            </Flex>
          </Form>
        </Flex>
      </Box>
    </>
  );
}
