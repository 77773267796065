import queryString from "query-string";
import type { Fee, PaginatedResource } from "~/application/types";
import { api } from "~/infrastructure/api";
import { FeeDTO } from "~/infrastructure/api/dtos";
import { mapFeeDTO } from "~/infrastructure/api/mappers";
import type { IFeeService, IFindFeeByCustomerParams, IFindFeeParams } from "./IFeeService";

export class FeeService implements IFeeService {
  async findById(id: string): Promise<Fee> {
    return await api.get<FeeDTO>(`/agencies/fees/${id}`).then(({ data }) => mapFeeDTO(data));
  }

  async find({ page = 1, name, agencyId }: IFindFeeParams): Promise<PaginatedResource<Fee[]>> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/fees`,
      query: { page, name },
    });

    return await api.get<PaginatedResource<FeeDTO[]>>(url).then(({ data }) => ({
      links: data.links,
      meta: data.meta,
      data: data.data.map(mapFeeDTO),
    }));
  }

  async findByCustomer({ agencyId, customerId }: IFindFeeByCustomerParams): Promise<Fee | null> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/fees`,
      query: { customer_uuid: customerId },
    });

    return await api.get<PaginatedResource<FeeDTO[]>>(url).then(({ data }) => {
      const hasData = Boolean(data.data[0]);

      if (hasData) {
        return mapFeeDTO(data.data[0]);
      }

      return null;
    });
  }

  async create(data: Omit<Fee, "uuid">): Promise<Fee> {
    return await api
      .post<FeeDTO>(`/agencies/${data.agencyId}/fees`, {
        name: data.name,
        agency_uuid: data.agencyId,
        items: data.items.map((item) => ({
          service_type: item.serviceType,
          type: item.type,
          value: item.value,
          offline_value: item.offlineValue,
          international_value: item.internationalValue,
        })),
      })
      .then(({ data }) => mapFeeDTO(data));
  }

  async updateById(data: Fee): Promise<void> {
    return await api
      .put<undefined>(`/agencies/fees/${data.uuid}`, {
        name: data.name,
        items: data.items.map((item) => ({
          uuid: item.uuid,
          service_type: item.serviceType,
          type: item.type,
          value: item.value,
          fee_uuid: data.uuid,
          offline_value: item.offlineValue,
          international_value: item.internationalValue,
        })),
      })
      .then(({ data }) => data);
  }

  async toggleActive(data: Fee): Promise<void> {
    if (data.isActive) {
      return await api.patch<void>(`/agencies/fees/${data.uuid}/inactive`).then(({ data }) => data);
    }

    return await api.patch<void>(`/agencies/fees/${data.uuid}/active`).then(({ data }) => data);
  }
}
