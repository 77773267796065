import { useQuery } from "@tanstack/react-query";
import { FC, useCallback, useEffect } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import {
  ExpenseCategory,
  ExpensePolicy,
  ExpenseType,
  Policy,
  PolicyParameterExpense,
} from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { ContainedRadio, NumberInput, Select, Switch } from "~/components/Input";
import { Text } from "~/components/Text";
import { Caption, H4 } from "~/components/Typography";
import { QueryKeys } from "~/constants/queryKeys";
import { EXPENSE_POLICY_RULE_TYPES } from "../../utils";
import { ExpensePolicyDialogProps } from "./types";
import { EditableExpensePolicy, createExpensePolicy, editExpensePolicy } from "./utils";

export const ExpensePolicyDialog: FC<ExpensePolicyDialogProps> = ({
  data,
  onCloseClick,
  fetchExpenseCategories,
  fetchPolicies,
  fetchExpenseTypes,
  fetchPolicyParameterExpenses,
  onSubmit: onSubmitProp,
}) => {
  const isNew = !data;

  const { control, formState, handleSubmit, watch, resetField, setValue } = useForm<ExpensePolicy>({
    defaultValues: editExpensePolicy(data),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { expenseCategory, policyParameterExpense } = watch();

  const { data: expenseCategories, isFetching: isFetchingExpenseCategories } = useQuery(
    [QueryKeys.EXPENSE_CATEGORIES],
    fetchExpenseCategories,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  const { data: expenseTypes, isFetching: isFetchingExpenseTypes } = useQuery(
    [QueryKeys.CUSTOMER_EXPENSE_TYPES],
    fetchExpenseTypes,
    {
      cacheTime: 0,
      retry: 2,
      enabled: !!expenseCategory,
    }
  );

  const { data: policyParameterExpenses, isFetching: isFetchingPolicyParameterExpenses } = useQuery(
    [QueryKeys.POLICY_PARAMETER_EXPENSES],
    fetchPolicyParameterExpenses,
    {
      cacheTime: 0,
      retry: 2,
      onSuccess: (data) => {
        if (!policyParameterExpense) {
          setValue("policyParameterExpense", data[0]);
        }
      },
    }
  );

  const { data: policies, isFetching: isFetchingPolicies } = useQuery(
    [QueryKeys.CUSTOMER_POLICIES],
    fetchPolicies,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  useEffect(() => {
    resetField("typeValue");
  }, [policyParameterExpense]);

  const getExpenseCategoryLabel = useCallback((item: ExpenseCategory) => item.name, []);
  const getExpenseCategoryValue = useCallback((item: ExpenseCategory) => item.uuid, []);

  const getPolicyLabel = useCallback((item: Policy) => item.name, []);
  const getPolicyValue = useCallback((item: Policy) => item.uuid, []);

  const getExpenseTypeLabel = useCallback((item: ExpenseType) => item.name, []);
  const getExpenseTypeValue = useCallback((item: ExpenseType) => item.uuid, []);

  const getPolicyParameterExpenseLabel = useCallback(
    (item: PolicyParameterExpense) => item.description,
    []
  );
  const getPolicyParameterExpenseValue = useCallback(
    (item: PolicyParameterExpense) => item.uuid,
    []
  );

  const onSubmit = useCallback(
    (formData: EditableExpensePolicy) => {
      onSubmitProp?.(createExpensePolicy(formData));
    },
    [onSubmitProp]
  );

  const onError = useCallback<SubmitErrorHandler<EditableExpensePolicy>>((formErrors) => {
    // eslint-disable-next-line no-console
    console.log(formErrors);
  }, []);

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormDialog
          title={isNew ? "Nova regra" : "Editar regra"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "60vh" }}>
            <Box css={{ mb: "$6" }}>
              <H4>Detalhes da regra</H4>
            </Box>

            <Row gap="6">
              <Col sz="12">
                <FormControl name="policy" control={control} required>
                  <FieldLabel>Politica afetada</FieldLabel>
                  <Select
                    options={policies}
                    isLoading={isFetchingPolicies}
                    placeholder="Selecione a política afetada"
                    getOptionLabel={getPolicyLabel}
                    getOptionValue={getPolicyValue}
                  />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="expenseCategory" control={control} required>
                  <FieldLabel>Categoria de despesa</FieldLabel>
                  <Select
                    options={expenseCategories}
                    isLoading={isFetchingExpenseCategories}
                    placeholder="Selecione a categoria de despesa"
                    getOptionLabel={getExpenseCategoryLabel}
                    getOptionValue={getExpenseCategoryValue}
                  />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="expenseType" control={control} required>
                  <FieldLabel>Tipo de despesa</FieldLabel>
                  <Select
                    options={expenseTypes}
                    isLoading={isFetchingExpenseTypes}
                    placeholder="Selecione o tipo de despesa"
                    getOptionLabel={getExpenseTypeLabel}
                    getOptionValue={getExpenseTypeValue}
                  />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="voucherRequired" control={control}>
                  <Switch>
                    <Caption>Comprovante obrigatório</Caption>
                  </Switch>
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="policyParameterExpense" control={control} required>
                  <FieldLabel>Tipo de regra</FieldLabel>
                  <Select
                    options={policyParameterExpenses}
                    isLoading={isFetchingPolicyParameterExpenses}
                    placeholder="Selecione o tipo de regra"
                    getOptionLabel={getPolicyParameterExpenseLabel}
                    getOptionValue={getPolicyParameterExpenseValue}
                  />
                </FormControl>
              </Col>
            </Row>

            <Box css={{ mb: "$6", mt: "$10" }}>
              <H4>Controle da regra</H4>
            </Box>

            <FormControl name="typeValue" control={control} required>
              <FieldLabel>Tipo de controle</FieldLabel>
              <Flex gap="2" direction="column">
                {EXPENSE_POLICY_RULE_TYPES.map((rule) => (
                  <ContainedRadio
                    value={rule.value}
                    disabled={rule.ruleType !== policyParameterExpense?.type}
                    key={rule.value}
                  >
                    <Flex direction="column" gap="2">
                      <Text size="3" css={{ fw: "700" }}>
                        {rule.name}
                      </Text>
                      <Text size="2" css={{ color: "$neutrals-dark" }}>
                        {rule.description}
                      </Text>
                    </Flex>
                  </ContainedRadio>
                ))}
              </Flex>
            </FormControl>

            <Box css={{ mb: "$6", mt: "$10" }}>
              <H4>Valores da regra</H4>
            </Box>

            <Row>
              <Col sz="12">
                {policyParameterExpense?.type === "amount" ? (
                  <FormControl name="value" control={control} required>
                    <FieldLabel>Quantidade limite diária</FieldLabel>
                    <NumberInput placeholder="Selecione o tipo de despesa" decimalScale={0} />
                  </FormControl>
                ) : (
                  <FormControl name="value" control={control} required>
                    <FieldLabel>Valor</FieldLabel>
                    <NumberInput placeholder="R$ 200,00" prefix="R$ " />
                  </FormControl>
                )}
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

ExpensePolicyDialog.displayName = "ExpensePolicyDialog";
