import { useQueries } from "@tanstack/react-query";
import { useMemo } from "react";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { Flight } from "~/application/types";
import { flightQueryService } from "~/application/usecases";
import { UseQueryGoFlightsOptions, UseQueryGoFlightsReturn } from "./types";

export function useQueryGoFlights({
  flightQueryLinks,
  enabled,
  retryCount,
  isInternationalCombined,
}: UseQueryGoFlightsOptions): UseQueryGoFlightsReturn {
  const flightQueries = useQueries({
    queries:
      flightQueryLinks?.links.map((link) => ({
        enabled,
        retry: retryCount,
        retryDelay: flightQueryLinks.waitTime,
        staleTime: QueryTimes.LONG,
        queryKey: [QueryKeys.QUERY_FLIGHTS_GO, link],
        queryFn: () =>
          flightQueryService.find(isInternationalCombined ? link.departureArrival : link.departure),
      })) ?? [],
  });

  const data = useMemo(
    () => flightQueries.reduce<Flight[]>((p, c) => [...p, ...(c.data ?? [])], []),
    [flightQueries]
  );

  const isLoading = useMemo(() => flightQueries.some((q) => q.isFetching), [flightQueries]);

  const isAvailable = useMemo(() => flightQueries.some((q) => !q.isFetching), [flightQueries]);

  return {
    data,
    isLoading,
    isAvailable,
  };
}
