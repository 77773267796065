import { FC, useMemo } from "react";
import { AirwaySeatsForm, OrderAirwaySegment, Passenger } from "~/application/types";
import { Card, CardBody } from "~/components/Card";
import { AirwaySeatsContainer } from "./components/AirwaySeatsContainer.tsx/AirwaySeatsContainer";
import { useAirwaySeats } from "../useAirwaySeats";
import { LoadingDialog } from "~/presentation/shared/views/LoadingDialog";
import { AirwaySeat } from "./components/AirwaySeat/AirwaySeat";
import { Text } from "~/components/Text";
import { Flex } from "~/components/Flex";
import { Box } from "~/components/Box";
import { Form } from "~/components/Form";
import { FormDialog } from "~/components/FormDialog";
import { DialogBody } from "~/components/Dialog";
import { Button } from "~/components/Button";
import { Stepper, StepperItem } from "~/components/Stepper";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { CompanyLogo } from "~/components/CompanyLogo";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";
import { Alert } from "~/components/Alert";
import { SvgAlert, SvgClose, SvgInfo } from "~/components/Icon/icons";
import { dialogService } from "~/components/DialogStack";
import { IconButton } from "~/components/IconButton";

export interface AirwaySeatsSelectDialogProps {
  orderItemId: string;
  onSubmit: ({ orderItemId, seats }: { orderItemId: string; seats: AirwaySeatsForm }) => void;
  onCloseClick: () => void;
  passengers: Passenger[];
  item: OrderAirwaySegment;
}

export const AirwaySeatsSelectDialog: FC<AirwaySeatsSelectDialogProps> = ({
  orderItemId,
  onSubmit,
  onCloseClick,
  passengers,
  item,
}) => {
  const airline = item?.segments[0].airline;
  const {
    isLoading: isLoadingAirwaySeats,
    data: airwaySeatsData,
    selectSeat,
    stepAirwaySeats,
    setStepAirwaySeats,
    isSeatSelected,
    canSelectSeat,
    seatsFormData,
    totalValue,
    onPassengerSelected,
    passengerSelected,
  } = useAirwaySeats({
    orderItemId,
    passengers,
  });

  const canSubmit = (airwaySeatsData?.data?.length || 0) - 1 !== stepAirwaySeats;

  const stretch = useMemo(() => {
    const { seats } = seatsFormData;
    const newArray = [] as {
      stretch: string;
      seats: AirwaySeatsForm["seats"];
    }[];
    let stretchIds = seats.map((seat) => seat.stretch_id);

    seats.forEach((seat) => {
      const newArray2 = [] as AirwaySeatsForm["seats"];

      if (stretchIds.includes(seat.stretch_id)) {
        seats.forEach((seat2) => {
          if (seat.stretch_id === seat2.stretch_id) {
            newArray2.push(seat2);
          }
        });
      }
      stretchIds = stretchIds.filter((stretch) => seat.stretch_id !== stretch);
      if (newArray2.length)
        newArray.push({
          stretch: seat.stretch_id,
          seats: newArray2,
        });
    });

    return newArray;
  }, [seatsFormData]);

  if (isLoadingAirwaySeats || !airwaySeatsData) {
    return <LoadingDialog message="Carregando assentos..." />;
  }

  if (!airwaySeatsData.data?.length) {
    return (
      <Card
        css={{
          position: "relative",
          width: "400px",
        }}
      >
        <CardBody>
          <Flex direction="column" align="center" gap="1">
            <Icon as={SvgAlert} size="3xl" variant="error" />
            <IconButton
              onClick={() => dialogService.popDialog()}
              css={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <Icon as={SvgClose} size="3xl" variant="error" />
            </IconButton>

            <Text
              size="3"
              css={{
                ta: "center",
              }}
            >
              Não é possivel marcar os assentos,
            </Text>
            <Text size="3">pois todos os trechos já foram marcados.</Text>
          </Flex>
        </CardBody>
      </Card>
    );
  }

  return (
    <Box
      css={{
        width: "fit-content",
      }}
    >
      <Form>
        <FormDialog
          title="Selecione os assentos dos trechos"
          negativeButton={
            <Button
              variant="secondary"
              onClick={() => setStepAirwaySeats(stepAirwaySeats < 1 ? 0 : stepAirwaySeats - 1)}
            >
              {"<"}
              <Text>Voltar</Text>
            </Button>
          }
          positiveButton={
            <Button
              variant={canSubmit ? "secondary" : "primary"}
              onClick={() =>
                canSubmit
                  ? setStepAirwaySeats(stepAirwaySeats + 1)
                  : onSubmit({
                      seats: seatsFormData,
                      orderItemId,
                    })
              }
              disabled={canSubmit ? false : !(seatsFormData.seats.length > 0)}
            >
              <Text>
                {canSubmit ? "Avançar" : "Confirmar"}{" "}
                {airwaySeatsData.data?.length - 1 !== stepAirwaySeats ? ">" : ""}
              </Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody
            css={{
              backgroundColor: "$neutrals-lightest",
              width: "fit-content",
              maxHeight: "600px",
            }}
          >
            <Flex direction="column" align="center">
              <Grid css={{ gap: "$6", mb: "$10", width: "100%" }}>
                <Stepper activeValue={stepAirwaySeats} setActiveValue={setStepAirwaySeats}>
                  {airwaySeatsData.data.map((stretch, index) => (
                    <StepperItem
                      key={index}
                      value={index}
                      css={{
                        width: "max-content",
                      }}
                    >
                      <Text>{`${stretch.departure} -> ${stretch.arrival}`}</Text>
                    </StepperItem>
                  ))}
                </Stepper>
              </Grid>
              <Flex
                css={{
                  pl: "200px",
                  pr: "100px",
                }}
                gap="8"
              >
                <AirwaySeatsContainer
                  currentConection={stepAirwaySeats}
                  isSeatSelected={isSeatSelected}
                  seatData={airwaySeatsData}
                  selectSeat={selectSeat}
                  canSelectSeat={canSelectSeat}
                  passengers={passengers}
                  passengerSelected={passengerSelected}
                  stretch={stretch}
                />
                <Flex
                  css={{
                    width: "fit-content",
                    height: "fit-content",
                    transform: "translatey(10%)",
                  }}
                  direction="column"
                  gap="3"
                  align="center"
                >
                  {!!totalValue && (
                    <Alert
                      variant="info"
                      css={{
                        p: "$3",
                      }}
                    >
                      <Flex gap="2" align="center">
                        <Icon as={SvgInfo} size="lg" />
                        <Flex direction="column">
                          <Text size="2">Selecionar o assento irá gerar uma taxa adicional</Text>
                          <Text size="2">
                            no valor de <Text fw="700">{asCurrency(totalValue)}</Text>, podendo ser
                            revertida.
                          </Text>
                        </Flex>
                      </Flex>
                    </Alert>
                  )}
                  <Flex css={{ width: "100%" }}>
                    <Text fw="600">
                      Trechos{" "}
                      <Text fw="400" size="3">
                        ({stepAirwaySeats + 1} de {airwaySeatsData.data.length})
                      </Text>
                    </Text>
                  </Flex>
                  <Card
                    css={{
                      p: "$5",
                      border: "none",
                      width: "100%",
                      boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <Flex gap="4" direction="column">
                      <Flex>
                        <CompanyLogo
                          src={`/images/airlines/${airline?.toLowerCase()}.png`}
                          alt={airline}
                        />
                      </Flex>
                      <Flex direction="column" gap="3">
                        <Flex>
                          <Text fw="500">
                            {airwaySeatsData.data[stepAirwaySeats]?.departure} {"->"}{" "}
                            {airwaySeatsData.data[stepAirwaySeats]?.arrival}
                          </Text>
                        </Flex>
                        <Flex direction="column" gap="2">
                          <Text fw="500" size="3">
                            <Text fw="400" size="3">
                              Data:{" "}
                            </Text>
                            {displayDate(
                              airwaySeatsData.data[stepAirwaySeats]?.arrivalDate,
                              DateFormats.LONG_DATE
                            )}
                          </Text>
                          <Text fw="500" size="3">
                            <Text fw="400" size="3">
                              Horário:{" "}
                            </Text>
                            {displayDate(
                              airwaySeatsData.data[stepAirwaySeats]?.arrivalDate,
                              DateFormats.HOURS
                            )}
                          </Text>
                        </Flex>
                        <Flex direction="column" gap="2">
                          {passengers.map((passenger) => {
                            const seat = stretch
                              .find(
                                (stre) => stre.stretch === airwaySeatsData.data[stepAirwaySeats].id
                              )
                              ?.seats.find(
                                (seat) => seat.customer_employee_uuid === passenger.uuid
                              );
                            return (
                              <Flex align="center">
                                <Flex
                                  css={{
                                    borderRadius: "$md $none $none $md",
                                    backgroundColor: "$primary-light",
                                    p: "$2",
                                  }}
                                >
                                  <AirwaySeat
                                    data-selected={seat ? true : false}
                                    size="md"
                                    seatNumber={seat ? `${seat?.row}${seat?.column}` : ""}
                                  />
                                </Flex>
                                <Flex
                                  key={passenger.uuid}
                                  css={{
                                    width: "100%",
                                    borderRadius: "$none $md $md $none",
                                    backgroundColor:
                                      passengerSelected?.uuid === passenger.uuid
                                        ? "$primary-base"
                                        : "$primary-light",

                                    p: "$4",
                                    [`${Text}`]: {
                                      color:
                                        passengerSelected?.uuid === passenger.uuid
                                          ? "$primary-light"
                                          : "$primary-base",
                                    },
                                    ["&:hover"]: {
                                      backgroundColor: "$primary-base",

                                      [`${Text}`]: {
                                        color: "$primary-light",
                                      },
                                    },
                                    cursor: "pointer",
                                  }}
                                  align="center"
                                  justify="between"
                                  onClick={() => onPassengerSelected(passenger)}
                                >
                                  <Text variant="primary" size="4">
                                    {passenger.fullName}
                                  </Text>
                                  {!!seat?.value && (
                                    <Text variant="primary" size="2">
                                      {asCurrency(seat?.value || 0)}
                                    </Text>
                                  )}
                                </Flex>
                              </Flex>
                            );
                          })}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Card>
                  <SeatStates />
                </Flex>
              </Flex>
            </Flex>
          </DialogBody>
        </FormDialog>
      </Form>
    </Box>
  );
};

const SeatStates = () => {
  return (
    <Card
      css={{
        width: "fit-content",
        height: "fit-content",
        p: "$4",
        border: "none",
        boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Flex align="center" gap="4">
        <Flex gap="2" align="center">
          <Flex
            css={{
              transform: "rotate(270deg)",
            }}
          >
            <AirwaySeat size="sm" />
          </Flex>
          <Text size="1">Disponível</Text>
        </Flex>

        <Flex gap="2" align="center">
          <Flex
            css={{
              transform: "rotate(270deg)",
            }}
          >
            <AirwaySeat data-selected="true" size="sm" />
          </Flex>
          <Text size="1">Selecionado</Text>
        </Flex>

        <Flex gap="2" align="center">
          <Flex
            css={{
              transform: "rotate(270deg)",
            }}
          >
            <AirwaySeat data-available="false" size="sm" />
          </Flex>
          <Text size="1">Indisponível</Text>
        </Flex>
      </Flex>
    </Card>
  );
};
