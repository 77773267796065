import type { CostCenter } from "~/application/types";

export enum FindCostCenterState {
  ALL = "all",
  ACTIVE = "active",
  INACTIVE = "inactive",
}
export interface IFindCostCenterParams {
  customerId: string;
  name?: string;
  page?: number;
  state?: FindCostCenterState;
  phase?: string | null;
  filterByCostCenter?: boolean | null;
  withApprovalModels?: boolean;
}

export interface IFindCostCenterWithoutApprovalModelLinkParams {
  name?: string;
  customerId: string;
  approvalModelId?: string;
}
export interface ICostCenterService {
  findById(id: string): Promise<CostCenter>;
  find(data: IFindCostCenterParams): Promise<CostCenter[]>;
  findWithoutApprovalModelLink(
    _: IFindCostCenterWithoutApprovalModelLinkParams
  ): Promise<CostCenter[]>;
  create(data: Omit<CostCenter, "uuid">): Promise<CostCenter>;
  updateById(data: CostCenter): Promise<CostCenter>;
  toggleActive(data: CostCenter): Promise<void>;
  deleteById(data: CostCenter): Promise<void>;
}
