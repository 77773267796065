import { Dispatch, SetStateAction } from "react";
import { CustomerEmployee, Traveler } from "~/application/types";
import { StepItem } from "~/presentation/shared/utils";
import { BookingAirwaySteps } from "../constants";
import { DeleteOrderAirwayParams } from "~/presentation/shared/hooks/useDeleteOrderAirway/types";
import { AirwayQueryFormData } from "~/presentation/shared/components/AirwayQueryForm";

export type FlightsPageUIProps = {
  canSelectIssuer?: boolean;
  airwayParams: AirwayQueryFormData | null;
  bookingSteps: StepItem<BookingAirwaySteps | string>[];
  bookingStep: BookingAirwaySteps | string;
  currentStep: number;
  issuerId: string;
  isShowingCart: boolean;
  isManyStretch: boolean;
  setIsShowingCart: Dispatch<SetStateAction<boolean>>;
  fetchTravelers: (name: string) => Promise<Traveler[]>;
  setCurrentStep: (step: number) => void;
  onSelectIssuer: (issuer: CustomerEmployee) => void;
  deleteOrderAirway: (data: DeleteOrderAirwayParams) => Promise<void>;
};

export enum FlightOrderType {
  PRICE = "price",
  DURATION = "duration",
}
