import { useQuery } from "@tanstack/react-query";
import { FC, useCallback } from "react";
import { SubmitErrorHandler, useForm } from "react-hook-form";
import { ExpenseCategory, ExpenseType } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Select, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { QueryKeys } from "~/constants/queryKeys";
import { ExpenseTypeDialogProps } from "./types";
import { EditableExpenseType, createExpenseType, editExpenseType } from "./utils";

export const ExpenseTypeDialog: FC<ExpenseTypeDialogProps> = ({
  data,
  onCloseClick,
  fetchExpenseCategories,
  onSubmit: onSubmitProp,
}) => {
  const isNew = !data;

  const { control, formState, handleSubmit } = useForm<ExpenseType>({
    defaultValues: editExpenseType(data),
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { data: expenseCategories, isFetching: isFetchingExpenseCategories } = useQuery(
    [QueryKeys.EXPENSE_CATEGORIES],
    fetchExpenseCategories,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  const getExpenseCategoryLabel = useCallback((item: ExpenseCategory) => item.name, []);
  const getExpenseCategoryValue = useCallback((item: ExpenseCategory) => item.uuid, []);

  const onSubmit = useCallback(
    (formData: EditableExpenseType) => {
      onSubmitProp?.(createExpenseType(formData));
    },
    [onSubmitProp]
  );

  const onError = useCallback<SubmitErrorHandler<EditableExpenseType>>((formErrors) => {
    // eslint-disable-next-line no-console
    console.log(formErrors);
  }, []);

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormDialog
          title={isNew ? "Novo tipo de despesa" : "Editar tipo de despesa"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Row gap="6">
              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome da despesa</FieldLabel>
                  <TextInput placeholder="Digite o nome da despesa" />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="expenseCategory" control={control} required>
                  <FieldLabel>Categoria da despesa</FieldLabel>
                  <Select
                    options={expenseCategories}
                    isLoading={isFetchingExpenseCategories}
                    placeholder="Selecione a categoria da despesa"
                    getOptionLabel={getExpenseCategoryLabel}
                    getOptionValue={getExpenseCategoryValue}
                  />
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

ExpenseTypeDialog.displayName = "ExpenseTypeDialog";
