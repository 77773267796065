import { Markup } from "~/application/types";
import { MarkupDTO } from "../dtos";

export function mapMarkupDTO(data: MarkupDTO): Markup {
  return {
    uuid: data.uuid,
    name: data.name,
    items: data.items.map((item) => ({
      uuid: item.uuid,
      markupId: item.markup_uuid,
      serviceType: item.service_type,
      value: item.percent_value,
      range: item.range,
      offlineValue: Number(item.offline_percent_value),
      internationalValue: Number(item.international_percent_value),
    })),
    agencyId: data.agency_uuid,
    isActive: Boolean(data.active),
  };
}
