import { CustomerSettingParameter } from "~/application/types";
import {
  getDefaultLabelParameterSelect,
  SettingParameterSlug,
  SettingParameterTypeValue,
} from "~/application/types/entities/SettingParameter.type";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";
import { EditSettingParameter } from "~/core/shared/components/EditSettingParameter";
import { useCustomerEmployeesGroups } from "../../CustomerPage/hooks/useCustomerEmployeesGroups";

export interface SettingParameterListItemProps extends DataListItemProps {
  data: CustomerSettingParameter;
  onEditClick: (item: CustomerSettingParameter) => void;
  isUpdating: boolean;
}

export function SettingParameterListItem({
  data,
  isUpdating,
  onEditClick,
  ...props
}: SettingParameterListItemProps) {
  const isDefaultCustomerEmployeeGroup =
    data?.slug === SettingParameterSlug.DEFAULT_CUSTOMER_EMPLOYEE_GROUP;

  const { data: groups } = useCustomerEmployeesGroups({
    customerId: data?.customerId || "",
    enabled: isDefaultCustomerEmployeeGroup,
  });

  const foundGroup = groups?.find((group) => group.uuid === data?.value);
  const getDefaultData = () => {
    if (data.value === null) {
      return "Não definido";
    }

    if (data.typeValue === SettingParameterTypeValue.BOOLEAN) {
      return (
        <Switch checked={!!data.value} onClick={() => onEditClick(data)}>
          <Caption>{data.value ? "Ativo" : "Inativo"}</Caption>
        </Switch>
      );
    }

    if (foundGroup) {
      return foundGroup.description;
    }

    if (data.typeValue === SettingParameterTypeValue.PERCENTAGE) {
      return `${data.value}%`;
    }

    return data.slug === SettingParameterSlug.APPROVAL_BY ||
      data.slug === SettingParameterSlug.MANAGE_BUDGET_BY
      ? getDefaultLabelParameterSelect(data.value as string)
      : data.value;
  };

  if (isUpdating) {
    return (
      <LoadingModal css={{ width: "100%" }} isOpen={isUpdating} message={"Editando parâmetro"} />
    );
  }

  return (
    <DataListItem {...props}>
      <DataColItem headerTitle="Descrição" data={data.description} />

      {data.typeValue === SettingParameterTypeValue.BOOLEAN ? (
        getDefaultData()
      ) : (
        <Tag variant="info-light">
          <Text size="2">{getDefaultData()}</Text>
        </Tag>
      )}

      {data.typeValue !== SettingParameterTypeValue.BOOLEAN && (
        <EditSettingParameter data={data} onEditClick={onEditClick} />
      )}
    </DataListItem>
  );
}

SettingParameterListItem.displayName = "SettingParameterListItem";
