import { AirlineCode, Flight } from "~/application/types";
import { DateFormats, format, timestampInterval } from "~/application/utils/date-functions";
import { FlightSegmentInfo } from "./types";
import { AirwayFlightQuery } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/hooks/useAirwayReducer/types";

const AIRLINE_NAMES: Record<AirlineCode, string> = {
  [AirlineCode.AZUL]: "Azul",
  [AirlineCode.AMERICAN_AIRLINES]: "Azul",
  [AirlineCode.GOL]: "Gol",
  [AirlineCode.LATAM]: "Latam",
  [AirlineCode.VOEPASS]: "Voepass",
  [AirlineCode.XL_AIRWAYS_FRANCE]: "XL Airways France",
};

export function formatFlightInfo(
  flight: Flight,
  isInternationalCombined?: boolean,
  flightQuery?: AirwayFlightQuery
): FlightSegmentInfo {
  const departureInfo = flight.segments[0].departureFlightInfo;
  const arrivalInfo = flight.segments[flight.segments.length - 1].arrivalFlightInfo;

  const connectionsAirportIatas = flight.segments
    .slice(0, -1)
    .map((segment) => segment.arrivalFlightInfo.airportIata);

  return {
    flight,
    flightDuration: timestampInterval(departureInfo.dateTime, arrivalInfo.dateTime),
    departureFlightInfo: {
      airportIata: departureInfo.airportIata,
      airport: departureInfo.airport,
      dateTimeHour12: format(departureInfo.dateTime, DateFormats.SMALL_TIME_AM_PM),
      date: format(departureInfo.dateTime, DateFormats.LONG_DATE),
    },
    arrivalInfo: {
      airportIata: arrivalInfo.airportIata,
      airport: arrivalInfo.airport,
      dateTimeHour12: format(arrivalInfo.dateTime, DateFormats.SMALL_TIME_AM_PM),
      date: format(arrivalInfo.dateTime, DateFormats.LONG_DATE),
    },
    connectionsAirportIatas,
  };
}

export function getAirlineUrl(airline: AirlineCode | string) {
  return airline && `/images/airlines/${airline.toLowerCase()}.png`;
}

export function getAirlineName(airline: AirlineCode): string {
  return AIRLINE_NAMES[airline] ?? airline;
}

export const flightDuration = (item: Flight) => {
  const goFlight = item?.segments?.[0]?.departureFlightInfo;
  const returnFlight = item?.segments?.[item.segments.length - 1]?.arrivalFlightInfo;

  if (goFlight?.dateTime && returnFlight?.dateTime) {
    return Math.abs(goFlight.dateTime.getTime() - returnFlight.dateTime.getTime());
  }

  return 0;
};

export const flightsDuration = (data: Flight[]) => {
  const flightsDurationData = data.map(flightDuration);

  const maxDuration = flightsDurationData.reduce((acc, value) => {
    return value >= acc ? value : acc;
  }, 0);

  const minDuration = flightsDurationData.reduce((acc, value, index) => {
    if (index === 0) return value;
    return value < acc && value > 0 ? value : acc;
  }, 0);

  return {
    max: maxDuration,
    min: minDuration,
  };
};

export function millisecondsToHours(milliseconds: number): string {
  const millisecondsPerHours = 3600000;
  const millisecondsPerMinute = 60000;

  const hour = Math.floor(milliseconds / millisecondsPerHours);
  const minutesRemaining = milliseconds % millisecondsPerHours;
  const minutes = Math.floor(minutesRemaining / millisecondsPerMinute);

  return `${hour}h ${minutes}m`;
}
