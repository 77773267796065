import React, { useCallback, useMemo } from "react";
import useMobile from "../../hooks/useMobile";
import { Flex } from "~/components/Flex";
import { H5, Label } from "~/components/Typography";
import { Text } from "~/components/Text";
import { Box } from "~/components/Box";
import { TrackDots } from "../Track";
import { DateFormats, timestampInterval } from "~/application/utils/date-functions";
import { Divider } from "~/components/Divider";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgLuggage } from "~/components/Icon/icons";
import { displayDate } from "~/utils/date.utils";
import { StringUtils } from "~/application/utils";
import { AirwayFlightQuery } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/hooks/useAirwayReducer/types";
import { formatFlightInfo } from "../../utils";
import { Flight, SegmentType } from "~/application/types";

type InternationalCombinedProps = {
  flightQuery: AirwayFlightQuery;
  data: Flight;
};

const InternationalCombined: React.FC<InternationalCombinedProps> = ({ data }) => {
  const flightInfo = useMemo(() => data && formatFlightInfo(data), [data]);
  const scales = flightInfo.flight.segments.length - 1;

  const lastIndex = data.segments.findLastIndex((ar) => ar.type === SegmentType.DEPARTURE) || 1;

  const firstIndex = data.segments.findIndex((ar) => ar.type === SegmentType.ARRIVAL) || 1;

  const flightDurationDeparture = timestampInterval(
    data.segments.at(0)?.departureFlightInfo.dateTime as Date,
    data.segments.at(lastIndex)?.arrivalFlightInfo.dateTime as Date
  );

  const flightDurationArrival = timestampInterval(
    data.segments.at(firstIndex)?.departureFlightInfo.dateTime as Date,
    data.segments.at(data.segments.length - 1)?.arrivalFlightInfo.dateTime as Date
  );

  const isMobile = useMobile();
  const displayText = useCallback(
    (scale?: number) => {
      if (!scale) return StringUtils.formatSentence([scales, "conexão", "conexões"]);
      return StringUtils.formatSentence([scale, "conexão", "conexões"]);
    },
    [data]
  );
  return (
    <Flex
      css={{
        p: "$6",
      }}
      justify="center"
      direction="column"
      gap="3"
    >
      <Flex
        direction="column"
        css={{
          width: "100%",
        }}
        gap="5"
      >
        {/* Primeira seção */}
        <Flex>
          <Flex direction="column" justify="between" gap="2" css={{ flex: "1 0 0%" }}>
            <Label css={{ fw: 400 }}>Saída</Label>
            <H5>{flightInfo.departureFlightInfo.airportIata}</H5>
            <Text size={{ "@mxxxl": "1" }}>{flightInfo.departureFlightInfo.airport}</Text>
            <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
              {flightInfo.departureFlightInfo.date}
            </Text>
            <Label css={{ fw: 400 }}>{flightInfo.departureFlightInfo.dateTimeHour12}</Label>
          </Flex>

          <Flex
            direction="column"
            align="center"
            gap="4"
            css={{
              flex: "1 1 0%",
              width: "$40",
              "@xl": {
                ml: "$4",
              },
            }}
          >
            <Label css={{ fw: 400 }}>Duração</Label>
            <Text size="3">{flightDurationDeparture}</Text>
            {scales > 0 &&
              (isMobile ? (
                <Box>
                  <Text variant="primary" size="2" css={{ lineHeight: 1.6, fw: 600 }}>
                    {displayText(firstIndex - 1)}
                  </Text>
                  <TrackDots numberOfDots={firstIndex - 1} />
                  <Text size="3" css={{ lineHeight: 1.25 }}>
                    Parada{flightInfo.connectionsAirportIatas.length > 1 && "s"}:{" "}
                    {flightInfo.connectionsAirportIatas.map((iata) => `(${iata}) `)}
                  </Text>
                </Box>
              ) : (
                <>
                  <Box css={{ width: "70px" }}>
                    <TrackDots numberOfDots={firstIndex - 1} />
                    <Text variant="primary" size="2" css={{ lineHeight: "1.6", fw: 600 }}>
                      {displayText(firstIndex - 1)}
                    </Text>
                  </Box>

                  <Text size="3" css={{ lineHeight: 1.25 }}>
                    Parada{flightInfo.connectionsAirportIatas.length > 1 && "s"}:{" "}
                    {flightInfo.connectionsAirportIatas
                      .map((iata) => `(${iata}) `)
                      .slice(0, firstIndex - 1)}
                  </Text>
                </>
              ))}
            <Flex direction="column">
              <Label css={{ fw: 400 }}>Voo {data.segments[0].flightNumber}</Label>
            </Flex>
          </Flex>

          <Flex align="end" justify="between" direction="column" css={{ flex: "1 0 0%" }}>
            <Label css={{ fw: 400 }}>Chegada</Label>
            <H5>{data.segments.at(lastIndex)?.arrivalFlightInfo.airportIata}</H5>
            <Text size={{ "@mxxxl": "1" }}>
              {data.segments.at(lastIndex)?.arrivalFlightInfo.airport}
            </Text>
            <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
              {displayDate(data.segments.at(lastIndex)?.arrivalFlightInfo.dateTime as Date)}
            </Text>
            <Label css={{ fw: 400 }}>
              {displayDate(
                data.segments.at(lastIndex)?.arrivalFlightInfo.dateTime as Date,
                DateFormats.SMALL_TIME_AM_PM
              )}
            </Label>
          </Flex>
        </Flex>

        <Divider />

        <Flex>
          <Flex
            align={{ "@mxxxl": "start" }}
            justify={"between"}
            direction="column"
            css={{ flex: "1 0 0%" }}
          >
            <Label css={{ fw: 400 }}>Chegada</Label>
            <H5>{data.segments.at(firstIndex)?.departureFlightInfo.airportIata}</H5>
            <Text size={{ "@mxxxl": "1" }}>
              {data.segments.at(firstIndex)?.departureFlightInfo.airport}
            </Text>
            <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
              {displayDate(data.segments.at(firstIndex)?.departureFlightInfo.dateTime as Date)}
            </Text>
            <Label css={{ fw: 400 }}>
              {displayDate(
                data.segments.at(firstIndex)?.departureFlightInfo.dateTime as Date,
                DateFormats.SMALL_TIME_AM_PM
              )}
            </Label>
          </Flex>
          <Flex
            direction="column"
            align={"center"}
            gap="4"
            css={{
              flex: "1 1 0%",
              width: "$40",
              "@xl": {
                ml: "$4",
              },
            }}
          >
            <Label css={{ fw: 400 }}>Duração</Label>
            <Text size="3">{flightDurationArrival}</Text>

            {scales > 0 &&
              (isMobile ? (
                <Box>
                  <Text variant="primary" size="2" css={{ lineHeight: 1.6, fw: 600 }}>
                    {displayText(flightInfo.connectionsAirportIatas.length - firstIndex)}
                  </Text>
                  <TrackDots
                    numberOfDots={flightInfo.connectionsAirportIatas.length - firstIndex}
                  />
                  <Text size="3" css={{ lineHeight: 1.25 }}>
                    Parada{flightInfo.connectionsAirportIatas.length > 1 && "s"}:{" "}
                    {flightInfo.connectionsAirportIatas
                      .map((iata) => `(${iata})`)
                      .slice(firstIndex, flightInfo.connectionsAirportIatas.length)}
                  </Text>
                </Box>
              ) : (
                <>
                  <Box css={{ width: "70px" }}>
                    <TrackDots
                      numberOfDots={flightInfo.connectionsAirportIatas.length - firstIndex}
                    />
                    <Text variant="primary" size="2" css={{ lineHeight: "1.6", fw: 600 }}>
                      {displayText(flightInfo.connectionsAirportIatas.length - firstIndex)}
                    </Text>
                  </Box>
                  <Text size="3" css={{ lineHeight: 1.25 }}>
                    Parada{flightInfo.connectionsAirportIatas.length > 1 && "s"}:{" "}
                    {flightInfo.connectionsAirportIatas
                      .map((iata) => `${iata}`)
                      .slice(firstIndex, flightInfo.connectionsAirportIatas.length)}
                  </Text>
                </>
              ))}

            <Flex direction="column">
              <Label css={{ fw: 400 }}>Voo {data.segments[0].flightNumber}</Label>
            </Flex>
          </Flex>
          <Flex align={"end"} justify={"between"} direction="column" css={{ flex: "1 0 0%" }}>
            <Label css={{ fw: 400 }}>Chegada</Label>
            <H5>{flightInfo.arrivalInfo.airportIata}</H5>
            <Text size={{ "@mxxxl": "1" }}>{flightInfo.arrivalInfo.airport}</Text>
            <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
              {flightInfo.arrivalInfo.date}
            </Text>
            <Label css={{ fw: 400 }}>{flightInfo.arrivalInfo.dateTimeHour12}</Label>
          </Flex>
        </Flex>
      </Flex>

      {/* Detalhes de bagagem */}
      <Flex
        css={{
          p: "$2",
          width: "100%",
          cursor: "pointer",
        }}
        gap="3"
        direction="column"
      >
        <Divider
          css={{
            width: "100%",
            height: "1px",
            backgroundColor: "$neutrals-base",
            alignSelf: "stretch",
          }}
        />
        <Flex justify="between">
          <Flex align="center" gap="2">
            <Icon as={SvgLuggage} size="md" />
            <Text fw="600">Bagagem</Text>
          </Flex>
          <Flex direction="column" align="end" gap="1">
            {data.baggages.length ? (
              data.baggages.map(({ amount, included, weight }, index) => (
                <Text
                  key={index}
                  variant={!index ? "black" : "darkest"}
                  fw={!index ? "500" : "400"}
                  size={!index ? "3" : "2"}
                >
                  {amount}º bagagem: {weight}kg {included ? "(inclusa)" : ""}
                </Text>
              ))
            ) : (
              <Icon as={SvgClose} variant="error" />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default InternationalCombined;
