import ReactSelect, { StylesConfig } from "react-select";
import { Spinner } from "../../../Spinner";
import { SelectControl } from "./SelectControl";
import { SelectDropdownIndicator } from "./SelectDropdownIndicator";
import { SelectOption } from "./SelectOption";
import { SelectValueContainer } from "./SelectValueContainer";

export interface SelectBaseProps<TOption = unknown> {
  value?: any;
  defaultValue?: any;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  isFieldError?: boolean;
  size?: "xs" | "sm" | "md";
  options?: TOption[];
  style?: React.CSSProperties;
  isLoading?: boolean;
  isDirty?: boolean;
  isMulti?: boolean;
  components?: any;
  portalled?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  onChange?: (value: any) => void;
  onInputChange?: (value: any) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  loadingMessage?: (obj: { inputValue: string }) => React.ReactNode;
  getOptionLabel?: (option: TOption) => string;
  getOptionValue?: (option: TOption) => string;
}

const SelectLoadingIndicator = () => <Spinner size="sm" />;

export const DEFAULT_COMPONENTS = {
  IndicatorSeparator: null,
  LoadingIndicator: SelectLoadingIndicator,
  DropdownIndicator: SelectDropdownIndicator,
  Control: SelectControl,
  Option: SelectOption,
  ValueContainer: SelectValueContainer,
};

export const SelectBase = <TOption,>({
  disabled,
  isDirty,
  portalled = false,
  components = DEFAULT_COMPONENTS,
  style,
  formatOptionLabel, // Adicione aqui
  ...props
}: SelectBaseProps<TOption> & { formatOptionLabel?: (option: TOption) => React.ReactNode }) => {
  const stylesConfig: StylesConfig<any> = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 99999,
      pointerEvents: "auto",
    }),
    container: (base) => ({
      ...base,
      ...style,
    }),
    menuList: () => ({
      maxHeight: "220px",
      overflow: "auto",
    }),
    dropdownIndicator: () => ({}),
    control: () => ({}),
    option: () => ({
      whiteSpace: "pre-line",
    }),
    multiValue: () => ({}),
    singleValue: () => ({
      whiteSpace: "pre-line",
    }),
    multiValueRemove: () => ({}),
  };

  return (
    <ReactSelect
      {...props}
      isDisabled={disabled}
      data-dirty={isDirty}
      styles={stylesConfig}
      components={components}
      menuPosition="absolute"
      menuPortalTarget={portalled ? document.body : undefined}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

SelectBase.displayName = "SelectBase";
