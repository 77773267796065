import { useReducer, useState } from "react";
import {
  AirwayBudgetDispatchAction,
  AirwayBudgetDispatchType,
  AirwayBudgetState,
  useAirwayBudgetResult,
} from "./type";
import { Flight, FlightOption } from "~/application/types";

export const useAirwayBudget = (): useAirwayBudgetResult => {
  const [activeBudget, setActiveBudget] = useState(false);

  function airwayBudgetReducer(state: AirwayBudgetState, action: AirwayBudgetDispatchAction) {
    switch (action.type) {
      case AirwayBudgetDispatchType.DEPARTURE: {
        return { ...state, departure: action.paylod };
      }
      case AirwayBudgetDispatchType.ARRIVAL: {
        return { ...state, arrival: action.paylod };
      }
      case AirwayBudgetDispatchType.CLEAR: {
        return { departure: [], arrival: [] } as AirwayBudgetState;
      }
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(airwayBudgetReducer, {
    departure: [],
    arrival: [],
  } as AirwayBudgetState);

  const handleairwayBudgetOption = (
    data: FlightOption,
    flight?: Flight,
    type?: "arrival" | "departure"
  ) => {
    const currentType =
      type === "arrival" ? AirwayBudgetDispatchType.ARRIVAL : AirwayBudgetDispatchType.DEPARTURE;

    const findFlight = state[type!]?.find((fli) => fli?.id === flight?.id);

    if (findFlight) {
      const findFlightOption = findFlight?.options?.find((option) => option?.id === data.id);
      const flightFiltered = state[type!].filter((fli) => fli?.id !== flight?.id);

      if (findFlightOption) {
        const flightOptionFiltered = findFlight?.options?.filter(
          (op) => op?.id !== findFlightOption?.id
        );

        if (!flightOptionFiltered?.length) {
          dispatch({
            type: currentType,
            paylod: [...flightFiltered],
          });
          return;
        }

        dispatch({
          type: currentType,
          paylod: [...flightFiltered, { ...flight, options: flightOptionFiltered }],
        });
        return;
      }

      dispatch({
        type: currentType,
        paylod: [
          ...flightFiltered,
          {
            ...flight,
            options: [...(findFlight.options as Flight["options"]), data].sort(
              (a, b) => a.pricingInfo.total.amount - b.pricingInfo.total.amount
            ),
          },
        ],
      });
      return;
    }

    dispatch({
      type: currentType,
      paylod: [...state[type!], { ...flight, options: [data] }],
    });
  };

  return {
    activeBudget,
    onActiveBudget: setActiveBudget,
    dispatch,
    state,
    onSelectBudget: handleairwayBudgetOption,
  };
};
