import { FC } from "react";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgArrowBack, SvgSearch, SvgSwap } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { displayDate } from "~/utils/date.utils";
import { AirwayBookingBarUIProps } from "./types";
import { Box } from "~/components/Box";
import { useNavigate } from "react-router-dom";
import { AirwayQueryContainer } from "~/presentation/shared/components/AirwayQueryContainer/AirwayQueryContainer";
import { BookingAirwaySteps } from "../../../constants";

export const AirwayBookingBarUI: FC<AirwayBookingBarUIProps> = ({
  flightQuery,
  currentStepSection,
  currentSteppter,
  isEditable,
  setStep,
  onBarClick,
  onQuery,
  order,
}) => {
  const navigate = useNavigate();
  let title;

  switch (currentStepSection) {
    case BookingAirwaySteps.SELECT_GO_TICKET:
    case BookingAirwaySteps.SELECT_RETURN_TICKET:
      title = "Reserva Aéreo";
      break;
    case BookingAirwaySteps.CONFIRMATION:
      title = "Confirmação";
      break;
    default:
      title = "Passageiros";
      break;
  }

  const prevStepSection = () => {
    if (currentSteppter === 0) {
      return navigate("/");
    }
    return setStep(currentSteppter - 1);
  };

  const isManyStretch = (flightQuery?.stretch.length || 0) > 1;
  const currentStretch = isManyStretch
    ? flightQuery?.stretch?.at(currentSteppter)
    : flightQuery?.stretch.at(0);

  return (
    <>
      <Container
        css={{
          "@mxlg": {
            display: "none",
          },
        }}
      >
        {isEditable ? (
          <AirwayQueryContainer
            onSubmit={onQuery}
            defaultData={{
              adultQuantity: flightQuery?.adultQuantity,
              childrenQuantity: flightQuery?.childrenQuantity,
              reasonTrip: flightQuery?.reasonTrip,
              stretch: flightQuery?.stretch,
            }}
            rules={{
              maxAdultQuantity: 6,
            }}
            order={order}
          />
        ) : (
          <Flex align="center" gap="6" onClick={onBarClick} css={{ py: "$4" }}>
            <Grid gap="4" css={{ flexGrow: 1 }}>
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Origem
              </Text>
              <Text>
                {currentStretch?.origin.city}, <strong>{currentStretch?.origin.countryCode}</strong>
              </Text>
            </Grid>

            <Icon as={SvgSwap} size="lg" variant="primary" />

            <Grid gap="4" css={{ flexGrow: 1 }}>
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Destino
              </Text>
              <Text>
                {currentStretch?.destination.city},{" "}
                <strong>{currentStretch?.destination.countryCode}</strong>
              </Text>
            </Grid>
            <Grid gap="4" css={{ flexGrow: 1 }}>
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Data da ida
              </Text>
              <Text>{displayDate(currentStretch?.outboundDate as Date)}</Text>
            </Grid>

            {flightQuery?.stretch.at(0)?.returnDate && (
              <Grid gap="4" css={{ flexGrow: 1 }}>
                <Text variant="dark" size="2" css={{ fw: "700" }}>
                  Data da volta
                </Text>
                <Text>{displayDate(flightQuery?.stretch.at(0)?.returnDate as Date)}</Text>
              </Grid>
            )}

            <Grid gap="4" css={{ flexGrow: 1 }}>
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Filtros
              </Text>
              <Text>{currentStretch?.destination.city}</Text>
            </Grid>

            <Button variant="tertiary">
              <Icon as={SvgSearch} />
            </Button>
          </Flex>
        )}
      </Container>
      <Flex
        justify="between"
        css={{
          padding: "$6",
          backgroundColor: "$primary-base",
          color: "white",
          "@lg": {
            display: "none",
          },
        }}
      >
        <Box>
          <Icon as={SvgArrowBack} fill="white" onClick={prevStepSection} />
        </Box>
        <Text>{title}</Text>
        <Box></Box>
      </Flex>
    </>
  );
};

AirwayBookingBarUI.displayName = "AirwayBookingBarUI";
