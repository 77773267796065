import { FlightSegment } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { FlightSegmentDTO } from "../dtos";

export function mapFlightSegmentDTO(data: FlightSegmentDTO): FlightSegment {
  return {
    type: data.type,
    airline: data.airline,
    flightNumber: data.flightNumber,
    departureFlightInfo: {
      shortAirport: data.departureInfo.short_airport,
      airportIata: data.departureInfo.airport_iata,
      airport: data.departureInfo.airport,
      dateTime: toDate(data.departureInfo.dateTime),
    },
    arrivalFlightInfo: {
      shortAirport: data.arrivalInfo.short_airport,
      airportIata: data.arrivalInfo.airport_iata,
      airport: data.arrivalInfo.airport,
      dateTime: toDate(data.arrivalInfo.dateTime),
    },
  };
}
