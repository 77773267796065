import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { PolicyFilter, PurchasingPoliciesContainerProps } from "./type";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { CardSelectTypePolicies } from "./components/CardSelectPoliciesType";
import { Text } from "~/components/Text";
import { Form } from "~/components/Form";
import { Button } from "~/components/Button";
import { useForm } from "react-hook-form";
import { Icon } from "~/components/Icon";
import { SvgCheck } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { InputTypeRender } from "./components/InputTypeRender/InputTypeRender";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { Actions } from "~/application/types";
import { NumberInput } from "~/components/Input";
import { FieldLabel } from "~/components/FormControl";
import { useVerifyActions } from "~/presentation/shared/hooks/useVerifyActions";
import { useUser } from "~/presentation/core/contexts/UserContext";

export function PurchasingPoliciesContainer({
  data,
  policySelect,
  onChancePolicies,
  onSubmitPolicies,
  setPolicySelect,
  isLoading,
  disableSubmit,
  reasonTrip,
  isReasonTripPolicy,
  isLoadingReasonTrip,
  reasonSelected,
  fetchReasonTrip,
  onReason,
}: PurchasingPoliciesContainerProps) {
  const { handleSubmit } = useForm({ defaultValues: data });
  const { contexts, profile } = useUser();

  const canChangePolicyValue = useVerifyActions({
    actions: [Actions.CreatePolicyValue],
    contexts,
    profile,
  });

  const reasonTripPolicySelected = data?.find((policy) => {
    return (
      policy?.value.reasonTrip?.uuid === reasonSelected?.uuid && policy?.value.reasonTrip?.uuid
    );
  });

  const policies = data?.filter((policy) => policy?.value && !isNaN(policy?.value.value));

  return (
    <Flex direction="column">
      <AppBar>
        <AppBarContainer>
          <AppBarHeading
            title="Políticas de compra"
            description="Gerencie as políticas de compra"
          />
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$18" }}>
        <Flex direction={{ "@initial": "column", "@lg": "row" }} gap="8">
          <CardSelectTypePolicies
            onSelectPolicieSelect={setPolicySelect}
            policieSelect={policySelect}
          />
          <Form onSubmit={handleSubmit(onSubmitPolicies)} css={{ flex: 1 }}>
            <Flex direction="column" gap="8">
              <Text size="6" fw="500">
                Políticas de {policySelect.name.toLowerCase()}
              </Text>

              {isReasonTripPolicy && (
                <AsyncSelect
                  placeholder="Selecione o motivo de viagem"
                  defaultOptions={reasonTrip}
                  defaultValue={reasonSelected}
                  isLoading={isLoadingReasonTrip}
                  fetchOptions={fetchReasonTrip}
                  getOptionLabel={(reason) => reason.reason}
                  getOptionValue={(reason) => reason.uuid}
                  onChange={(policies) => onReason(policies)}
                />
              )}

              {reasonTripPolicySelected && (
                <Flex direction="column" gap="2">
                  <FieldLabel>{reasonTripPolicySelected?.name}</FieldLabel>
                  <NumberInput
                    value={Number(reasonTripPolicySelected?.value.value)}
                    key={reasonTripPolicySelected?.value.parameter?.uuid}
                    onChange={(e) =>
                      onChancePolicies({
                        policyId: reasonTripPolicySelected?.uuid || "",
                        policyParameterId: reasonTripPolicySelected?.value.parameter?.uuid || "",
                        value: e.target.value.toString(),
                        policyValueId: reasonTripPolicySelected?.value.uuid || "",
                      })
                    }
                  />
                </Flex>
              )}

              {!isReasonTripPolicy && (
                <LazyList
                  isLoading={isLoading}
                  items={policies as PolicyFilter[]}
                  render={(policy) => {
                    return <InputTypeRender data={policy} onChancePolicies={onChancePolicies} />;
                  }}
                />
              )}

              {canChangePolicyValue && (
                <Flex justify="end">
                  <Button type="submit" disabled={!disableSubmit}>
                    <Icon as={SvgCheck} />
                    <Text>Salvar</Text>
                  </Button>
                </Flex>
              )}
            </Flex>
          </Form>
        </Flex>
      </Container>
    </Flex>
  );
}
