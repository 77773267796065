export enum QueryKeys {
  USER_PROFILE = "user/profile",
  APPROVERS = "approvers",
  AIRLINES = "airlines",
  CUSTOMERS = "agency/customers",
  AGENCIES = "agencies",
  AMENITIES = "amenities",
  AGENCY = "agency",
  ACTION = "action",
  CUSTOMER = "customer",
  ISSUERS = "issuers",
  TRAVELERS = "travelers",
  EXPENSE_CATEGORIES = "expense-categories",
  POLICY_PARAMETER_EXPENSES = "policy-parameter-expenses",
  AGENCY_EMPLOYEES = "agency/agency-employees",
  AGENCY_FEES = "agency/agency-fees",
  AGENCY_MARKDOWNS = "agency/markdowns",
  AGENCY_MARKUPS = "agency/markups",
  CUSTOMER_JUSTIFICATIONS = "customer/customer-justifications",
  CUSTOMER_REASON_TRIPS = "customer/customer-reason-trips",
  CUSTOMER_PHASES = "customer/customer-phases",
  CUSTOMER_COST_CENTERS = "customer/customer-cost-centers",
  CUSTOMER_EXPENSE_TYPES = "customer/customer-expense-types",
  CUSTOMER_EMPLOYEES = "customer/customer-employees",
  CUSTOMER_POLICIES = "customer/policies",
  CUSTOMER_EXPENSE_POLICIES = "customer/expense-policies",
  CUSTOMER_BRANCHES = "customer/branches",
  CUSTOMER_APPROVAL_MODELS = "customer/approval-models",
  CUSTOMER_APPROVAL_MODEL = "customer/approval-model",
  STATE_CITIES = "state/cities",
  CUSTOMER_ORDERS = "customer/orders",
  AGENCY_PENDING_ORDERS = "agency/pending-orders",
  ORDERS = "orders",
  ORDER_TAX_CANCELLATION = "order/tax-cancellation",
  ORDER_MESSAGES = "order/messages",
  ORDER_ACCOUNTABILITY_EXPENSES = "order/accountability-expense",
  ORDER_HOTEL = "order/hotel",
  ORDER_AIRWAY = "order/airway",
  ORDER_VEHICLE = "order/vehicle",
  ORDER_ROAD = "order/road",
  ORDER_ROAD_REBOOK = "order/road/rebook",
  ORDER_ADVANCE = "order/advance",
  ORDER_EXPENSE = "order/expense",
  ORDER_HISTORY = "order/history",
  QUERY_ROAD = "query/road",
  QUERY_HOTEL = "query/hotel",
  HOTEL = "booking/hotels",
  QUERY_HOTEL_DETAILS = "query/hotel-details",
  HOTEL_DETAILS = "booking/hotel/details",
  QUERY_HOTEL_ROOM_RULES = "query/hotel-rules",
  HOTEL_ROOM_RULES = "booking/hotel/rules",
  FLIGHT_DETAILS = "booking/flight/details",
  ROADS_GO = "booking/roads/go",
  ROADS_RETURN = "booking/roads/return",
  ROAD_SEATS = "booking/road/seats",
  QUERY_HOTELS = "query/hotels",
  QUERY_FLIGHTS = "query/flights",
  QUERY_FLIGHTS_LINKS = "query/flights/links",
  QUERY_VEHICLES = "query/vehicles",
  QUERY_LINKS_FLIGHTS = "query-links/flights",
  QUERY_FLIGHTS_GO = "query/flights/departure",
  QUERY_FLIGHTS_RETURN = "query/flights/arrival",
  QUERY_FLIGHTS_COMBINED = "query/flights/combined",
  QUERY_LINKS_ROADS = "query-links/road",
  QUERY_LINK_REPORT = "query-link/report",
  QUERY_ROADS_GO = "query/roads/go",
  QUERY_ROADS_RETURN = "query/roads/return",
  QUERY_LINKS_ROAD_SEATS = "query-links/roads/seats",
  CURRENT_TIME = "current-time",
  REPORTS = "reports",
  AIRWAYSEATS = "airway-seats",
  MARKUP_PER_CUSTOMER = "markup-per-customer",
  FEE_PER_CUSTOMER = "fee-per-customer",
  PROFILES = "profiles",
  PROFILE_ACTIONS = "profile-actions",
  OFFLINE_HOTEL = "offline-hotel",
  OFFLINE_HOTELS = "offline-hotels",
  OFFLINE_HOTEL_ROOM = "offline-hotel-room",
  OFFLINE_HOTEL_ROOMS = "offline-hotel-rooms",
  UNFLOWNS_AIRWAYS = "unflowns-airways",
  BUDGETS = "customer/budgets",
  BUDGETS_VIEW = "customer/budgets/view",
  ORDER_BUDGET_BALANCE = "order/budget-balance",
  PAYMENT_METHOD = "offline-payment",
}
