import { AirlineCode } from "./AirlineCode.enum";
import { FlightFeeCode } from "./FlightFeeCode.enum";
import { BaggageAirway, ViolatedPolicy } from "./Order.type";

export enum SegmentType {
  DEPARTURE = "departure",
  ARRIVAL = "arrival",
}

export type FlightSegment = {
  airline: AirlineCode;
  flightNumber: string;
  value?: number;
  departureFlightInfo: {
    shortAirport: string;
    airportIata: string;
    airport: string;
    dateTime: Date;
  };
  arrivalFlightInfo: {
    shortAirport: string;
    airportIata: string;
    airport: string;
    dateTime: Date;
  };
  type?: SegmentType;
};

export type FlightServicesIncluded = {
  baggage: boolean;
  checkedBaggage: number;
  comfortSeat: boolean;
  refund: boolean;
  refundDescription: string;
  rescheduling: string;
  seatAssignment: boolean;
};

export type FlightOption = {
  id: number;
  familyFare: string;
  code: string;
  servicesIncluded: FlightServicesIncluded;
  violatedPolicies: ViolatedPolicy[];
  pricingInfo: {
    tariffWithoutAgreement: number;
    tariffNet: number;
    markdown: number;
    du: number;
    baseFare: {
      amount: number;
      currency: string;
    };
    taxes: {
      amount: number;
      currency: string;
      code: FlightFeeCode;
    }[];
    total: {
      amount: number;
      currency: string;
    };
  };
};

export type CheapestFlight = {
  flightId: number | string;
  optionId?: number;
  airline: AirlineCode;
  segments: FlightSegment[];
  value: number;
  scales: number;
  fare: number;
  boardingTax: number;
  serviceTax: number;
  familyFare: string;
  servicesIncluded?: FlightServicesIncluded;
};

export type FlightOriginType = "departure" | "arrival" | "combined";

export type Flight = {
  id: number;
  searchKey: string;
  airline: AirlineCode;
  flightOrigin: FlightOriginType;
  segments: FlightSegment[];
  baggages: BaggageAirway[];
  minimumPrice: {
    amount: number;
    currency: string;
  };
  discount:
    | boolean
    | {
        code: string;
        amount: number;
      };
  options: FlightOption[];
  scales: number;
  hash: string;
  violatedPolicies?: ViolatedPolicy[];
};
