import { FC } from "react";
import { useForm } from "react-hook-form";
import { MASK_MOBILE_PHONE_NUMBER } from "~/application/utils/mask-functions";
import { DialogBody } from "~/components/Dialog";
import { dialogService } from "~/components/DialogStack";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { MaskedInput } from "~/components/Input";
import { SimpleDialog } from "../../components/SimpleDialog";
import { SendVoucherInWhatsappDialogProps } from "./types";

export const SendVoucherInWhatsappDialog: FC<SendVoucherInWhatsappDialogProps> = ({
  children,
  order,
  onCloseClick,
  defaultData,
}) => {
  const { control, handleSubmit } = useForm({
    defaultValues: defaultData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  return (
    <SimpleDialog
      size="8"
      title="Voucher via whatsapp"
      negativeTitle="Cancelar"
      positiveTitle="Enviar"
      onPositiveClick={handleSubmit(({ phone }) => {
        const voucherLink = order?.linkVoucher;
        const fullPhone = phone.replace(/\D/g, "");
        const redirectUrl = `https://wa.me//${fullPhone}?text=Olá seu voucher está no link a seguir: ${voucherLink}`;

        window.open(redirectUrl);
        dialogService.popDialog();
      })}
      onCloseClick={onCloseClick}
    >
      {children || (
        <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
          <Row gap="6">
            <Col sz="12">
              <FormControl name="phone" control={control} required>
                <FieldLabel>Número do whatsapp</FieldLabel>
                <MaskedInput mask={MASK_MOBILE_PHONE_NUMBER} placeholder="+55 (99)9 1234-5678" />
              </FormControl>
            </Col>
          </Row>
        </DialogBody>
      )}
    </SimpleDialog>
  );
};

SendVoucherInWhatsappDialog.displayName = "SendVoucherInWhatsappDialog";
