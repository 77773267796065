import { DeepPartial } from "~/application/types";
import { ExpenseCategory } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Grid, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { Radio, TextInput } from "~/components/Input";
import { Text } from "~/components/Text";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { EXPENSE_CATEGORY_ICONS } from "../utils";

export interface ExpenseCategoryDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<ExpenseCategory>;
  onCloseClick?: () => void;
  onSubmit: (data: ExpenseCategory) => void;
}

export function ExpenseCategoryDialog({
  isNew,
  defaultData,
  onCloseClick,
  onSubmit: onSubmitProp,
}: ExpenseCategoryDialogProps) {
  const { control, formState, handleSubmit } = useForm<ExpenseCategory>({
    defaultValues: defaultData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const onSubmit = useCallback((data: ExpenseCategory) => onSubmitProp(data), [onSubmitProp]);

  return (
    <Container size="8" fixed>
      <Form
        onSubmit={handleSubmit(onSubmit, (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        })}
      >
        <FormDialog
          title={isNew ? "Nova categoria de depesa" : "Editar categoria de depesa"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Row gap={4}>
              <Col sz="12">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome da categoria</FieldLabel>
                  <TextInput placeholder="Digite o nome da categoria de despesa" />
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="icon" control={control} required>
                  <FieldLabel>Ícone</FieldLabel>
                  <Grid gap="4" columns={4}>
                    {EXPENSE_CATEGORY_ICONS.map(({ icon, slug }) => (
                      <Box key={slug}>
                        <Radio value={slug}>
                          <Icon as={icon} size="lg" />
                        </Radio>
                      </Box>
                    ))}
                  </Grid>
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
