import { useCallback } from "react";

import { CustomerEmployeesGroupListItem } from "./components/CustomerEmployeesGroupListItem";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { CustomerEmployeesGroup } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { SearchBar } from "~/components/Input";
import { GroupEmployee } from "../../hooks/useCustomerEmployeesGroups";
import { UseQueryResult } from "@tanstack/react-query";

export interface TabCustomerEmployeesGroupsProps {
  data?: CustomerEmployeesGroup[];
  currentPage: number;
  lastPage: number;
  isLoading: boolean;
  search: string;
  onCreateCustomerEmployeesGroup: () => void;
  onToggleCustomerEmployeesGroupState: (item: CustomerEmployeesGroup) => void;
  onUpdateCustomerEmployeesGroup: (item: CustomerEmployeesGroup) => void;
  onGoToPage: (value: number) => void;
  onSearch: (value: string) => void;
  fetchEmployees: ({
    name,
    groupIds,
  }: {
    name: string;
    groupIds: string[];
  }) => UseQueryResult<GroupEmployee[], unknown>;
}

export function TabCustomerEmployeesGroups({
  data,
  currentPage,
  lastPage,
  isLoading,
  onCreateCustomerEmployeesGroup,
  search,
  onSearch,
  onGoToPage,
  onToggleCustomerEmployeesGroupState,
  onUpdateCustomerEmployeesGroup,
  fetchEmployees,
}: TabCustomerEmployeesGroupsProps) {
  const listRenderer = useCallback(
    (item: CustomerEmployeesGroup) => (
      <CustomerEmployeesGroupListItem
        data={item}
        onEditClick={onUpdateCustomerEmployeesGroup}
        onToggleState={onToggleCustomerEmployeesGroupState}
        fetchEmployees={fetchEmployees}
        key={item.uuid}
      />
    ),
    [onUpdateCustomerEmployeesGroup, onToggleCustomerEmployeesGroupState, fetchEmployees]
  );
  const isMobile = useMobile();

  return (
    <>
      <Card css={{ p: "$6" }}>
        <Row align="center">
          <Col>
            <H4>Grupos de funcionárioss</H4>
          </Col>

          <Col sz="auto" css={{ "@mxlg": { margin: "$5 auto", width: "100%" } }}>
            <SearchBar
              search={search}
              onSearchChange={onSearch}
              placeholder="Encontrar"
              style={isMobile ? { width: "100%" } : undefined}
            />
          </Col>

          <Col sz={{ "@lg": "auto" }}>
            <Button
              title="Novo motivo"
              color="primary"
              onClick={onCreateCustomerEmployeesGroup}
              css={{
                "@mxlg": {
                  width: "100%",
                },
              }}
            >
              <Icon as={SvgPlus} />
              <Text>Novo grupo</Text>
            </Button>
          </Col>
        </Row>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          isLoading={isLoading}
          EmptyComponent={
            <EmptyState>
              {search.length > 0 ? (
                <Text>Nenhum grupo corresponde aos dados informados</Text>
              ) : (
                <Text>Você ainda não possui grupos cadastrados</Text>
              )}
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </>
  );
}
