import { useCallback, useState } from "react";
import { useMutation, useQuery, UseQueryResult } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";

import { ICreateCustomerEmployeesGroupParams } from "~/application/usecases/CustomerEmployeesGroup/ICustomerEmployeesGroupService";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { CustomerEmployeesGroupDialog } from "../views/CustomerEmployeesGroups/components/CustomerEmployeesGroupDialog";
import { logError } from "~/presentation/shared/utils/errors";
import { customerEmployeesGroupService } from "~/application/usecases/CustomerEmployeesGroup";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { queryClient } from "~/services/queryClient";
import { CustomerEmployee, CustomerEmployeesGroup } from "~/application/types";
import { QueryKeys, QueryTimes } from "~/constants";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";

const LOG_TAG = "Customer/CustomerPage/useCustomerEmployeesGroups";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar grupos",
  CREATE_SUCCESS_MESSAGE: "Novo grupo adicionado",
  CREATE_ERROR_MESSAGE: "Falha ao criar grupo",
  UPDATE_SUCCESS_MESSAGE: "Grupo atualizado",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar grupo",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar grupo",
  ACTIVATE_SUCCESS_MESSAGE: "Grupo ativado",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar grupo",
  INACTIVATE_SUCCESS_MESSAGE: "Grupo inativado",
} as const;

export type UseCustomerEmployeesGroupsOptions = {
  customerId: string;
  enabled: boolean;
  groupIds?: string[];
  withApprovalModels?: boolean;
  active?: boolean;
};

export type GroupEmployee = Pick<
  CustomerEmployee,
  "uuid" | "lastName" | "name" | "isActive" | "email" | "profile"
>;

export type UseCustomerEmployeesGroupsResult = {
  data?: CustomerEmployeesGroup[];
  groupEmployees?: GroupEmployee[];
  isLoadingGroupEmployees: boolean;
  currentPage: number;
  lastPage: number;
  isLoading: boolean;
  search: string;
  onCreateCustomerEmployeesGroup: () => void;
  onToggleCustomerEmployeesGroupState: (item: CustomerEmployeesGroup) => void;
  onUpdateCustomerEmployeesGroup: (item: CustomerEmployeesGroup) => void;
  onGoToPage: (value: number) => void;
  fetchCustomerEmployeesGroups: (
    name: string,
    active?: boolean
  ) => Promise<CustomerEmployeesGroup[]>;
  onSearch: (value: string) => void;
  fetchGroupEmployees: (name: string) => Promise<GroupEmployee[]>;
  onSearchEmployee: (name: string) => void;
  fetchEmployees: ({
    name,
    groupIds,
  }: {
    name: string;
    groupIds: string[];
  }) => UseQueryResult<GroupEmployee[], unknown>;
};

export const useCustomerEmployeesGroups = ({
  customerId,
  enabled,
  groupIds,
  withApprovalModels,
  active,
}: UseCustomerEmployeesGroupsOptions): UseCustomerEmployeesGroupsResult => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState("");
  const [searchEmployeeName, setSearchEmployeeName] = useState("");

  const [searchDebounced] = useDebounce(search, 700);
  const [searchEmployeeNameDebounced] = useDebounce(searchEmployeeName, 700);

  const { data, isLoading } = useQuery(
    [
      QueryKeys.CUSTOMER_EMPLOYEES_GROUPS,
      customerId,
      enabled,
      currentPage,
      searchDebounced,
      active,
    ],
    () =>
      customerEmployeesGroupService.find({
        customerId,
        page: currentPage,
        description: searchDebounced,
        withApprovalModels,
        active,
      }),
    {
      enabled,
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: groupEmployees, isLoading: isLoadingGroupEmployees } = useQuery(
    [QueryKeys.GROUP_EMPLOYEES, customerId, enabled, groupIds, searchEmployeeNameDebounced],
    () =>
      customerEmployeesGroupService.employees({
        groupIds,
        customerId,
        name: searchEmployeeNameDebounced,
      }),
    {
      enabled: !!groupIds?.length && enabled,
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const fetchGroupEmployees = useCallback(
    async (name: string) => {
      return await customerEmployeesGroupService.employees({ groupIds, name, customerId });
    },
    [customerId, groupIds, customerId]
  );

  const fetchEmployees = useCallback(
    ({ name, groupIds }: { name: string; groupIds: string[] }) =>
      useQuery(
        [groupIds, customerId, name],
        async () => await customerEmployeesGroupService.employees({ groupIds, name, customerId }),
        { enabled: false }
      ),
    [customerId, enabled]
  );

  const { mutate: mutateCreateGroup } = useMutation(
    [QueryKeys.CUSTOMER_EMPLOYEES_GROUPS, customerId],
    async ({ description }: ICreateCustomerEmployeesGroupParams) =>
      await customerEmployeesGroupService.create({ customerId, description }),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Criando novo grupo" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES_GROUPS, customerId]);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: mutateUpdateGroup } = useMutation(
    async (data: CustomerEmployeesGroup) => await customerEmployeesGroupService.update(data),
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Atualizando grupo" />);
      },
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES_GROUPS, customerId]);
        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");
      },
      onError: (error) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
        });
      },
      onSettled: () => dialogService.popAll(),
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    [QueryKeys.CUSTOMER_EMPLOYEES_GROUPS, customerId],
    async (data: CustomerEmployeesGroup) => await customerEmployeesGroupService.toggleActive(data),
    {
      onSuccess: (_, data) => {
        queryClient.invalidateQueries([QueryKeys.CUSTOMER_EMPLOYEES_GROUPS, customerId]);
        snackbarService.showSnackbar(
          data.active
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (data.active) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: item.active
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
        });
      },
    }
  );

  const handleToggleState = useCallback((data: CustomerEmployeesGroup) => {
    if (!data.active) {
      mutateToggleState(data);
      return;
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando grupo"
        onConfirm={() => mutateToggleState(data)}
      />
    );
  }, []);

  const fetchCustomerEmployeesGroups = useCallback(
    async (description: string, active?: boolean) => {
      const response = await customerEmployeesGroupService.find({
        customerId,
        description,
        withApprovalModels,
        active,
      });
      return response.data;
    },
    [customerId]
  );

  const onOpenCreateCustomerEmployeesGroupModal = useCallback(
    () =>
      dialogService.showDialog(<CustomerEmployeesGroupDialog onSubmit={mutateCreateGroup} isNew />),
    [mutateCreateGroup]
  );

  const onOpenUpdateCustomerEmployeesGroupModal = useCallback(
    (customerEmployeesGroup: CustomerEmployeesGroup) =>
      dialogService.showDialog(
        <CustomerEmployeesGroupDialog
          defaultData={customerEmployeesGroup}
          onSubmit={mutateUpdateGroup}
        />
      ),
    [mutateUpdateGroup]
  );

  return {
    data: data?.data,
    fetchGroupEmployees,
    isLoadingGroupEmployees,
    groupEmployees,
    currentPage,
    lastPage: data?.meta?.last_page || 1,
    isLoading,
    search: search,
    onCreateCustomerEmployeesGroup: onOpenCreateCustomerEmployeesGroupModal,
    onToggleCustomerEmployeesGroupState: handleToggleState,
    onGoToPage: setCurrentPage,
    onUpdateCustomerEmployeesGroup: onOpenUpdateCustomerEmployeesGroupModal,
    fetchCustomerEmployeesGroups,
    onSearch: setSearch,
    onSearchEmployee: setSearchEmployeeName,
    fetchEmployees,
  };
};
