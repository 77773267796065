import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { DeepPartial, Phase } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { SvgKey } from "~/components/Icon/icons";
import { MaskedInput, TextInput } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { DateFormats, displayDate, validateDate } from "~/utils/date.utils";
import { MaskUtils } from "~/application/utils";

const phaseSchema = yup.object().shape({
  name: yup.string().required("O nome da fase é obrigatório"),
  code: yup.string().required("O código de integração externa é obrigatório"),
  startAt: yup
    .string()
    .required("A data de início é obrigatória")
    .test("is-valid-date", "Data inválida", (value) => validateDate(value!)),
  endAt: yup
    .string()
    .nullable()
    .test("is-valid-date", "Data inválida", (value) => (value ? validateDate(value) : true)),
});

type PhaseSchema = yup.InferType<typeof phaseSchema>;

export interface PhaseDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<Phase>;
  onCloseClick?: () => void;
  onSubmit: (data: Phase) => void;
}

export function PhaseDialog({
  isNew,
  defaultData,
  onCloseClick,
  onSubmit: onSubmitProp,
}: PhaseDialogProps) {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<PhaseSchema>({
    defaultValues: {
      ...defaultData,
      name: defaultData?.originalName,
      startAt: defaultData?.startAt
        ? displayDate(defaultData.startAt as Date, DateFormats.SMALL_DATE)
        : undefined,
      endAt: defaultData?.endAt
        ? displayDate(defaultData.endAt as Date, DateFormats.SMALL_DATE)
        : undefined,
    },
    resolver: yupResolver(phaseSchema),
  });

  const onSubmit = (data: PhaseSchema) => {
    const startAt = data.startAt.split("/").map(Number);
    const endAt = data.endAt?.split("/").map(Number);

    onSubmitProp({
      ...data,
      startAt: new Date(`${startAt[2]}-${startAt[1]}-${startAt[0]}`),
      endAt: data.endAt ? new Date(`${endAt![2]}-${endAt![1]}-${endAt![0]}`) : null,
    } as Phase);
  };

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          title={isNew ? "Nova fase" : "Editar fase"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={isSubmitting} type="submit">
              {isSubmitting && (
                <Spinner
                  css={{
                    borderLeftColor: "$neutrals-white",
                    width: "$4",
                    height: "$4",
                    borderWidth: "2px",
                  }}
                />
              )}
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Box css={{ mb: "$6" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6">
              <Col sz="6">
                <FormControl name="code" control={control} required>
                  <FieldLabel>Integração externa</FieldLabel>
                  <TextInput leftIcon={SvgKey} placeholder="Digite o número do ID" />
                  {errors.code && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.code.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="6">
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome da fase</FieldLabel>
                  <TextInput placeholder="Digite o nome do fase" />
                  {errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="6">
                <FormControl name="startAt" control={control} required>
                  <FieldLabel>Data de início</FieldLabel>
                  <MaskedInput placeholder="Data de início" mask={MaskUtils.MASK_DATE} />
                  {errors.startAt && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.startAt.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="6">
                <FormControl name="endAt" control={control}>
                  <FieldLabel>Data de fim</FieldLabel>
                  <MaskedInput placeholder="Data de fim" mask={MaskUtils.MASK_DATE} />
                  {errors.endAt && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.endAt.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
