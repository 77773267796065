import { ApprovalModel, ShortApprovalModel } from "~/application/types";
import { ApprovalModelDTO, ShortApprovalModelDTO } from "../dtos";
import { mapApproverDTO } from "./mapApproverDTO";

export function mapApprovalModelDTO(data: ApprovalModelDTO): ApprovalModel {
  return {
    uuid: data.uuid,
    name: data.name,
    approvalType: data.approval_type,
    branch: data.branch,
    customerEmployeeId: data.customer_employee_uuid,
    isAllBranches: data.all_branches,
    agencyEmployeeId: data.agency_employee_uuid,
    groupApprovers: data.approver_groups ?? [],
    projects: data.projects ?? [],
    phases: data.phases ?? [],
    groups: data.groups ?? [],
    costCenters: data.cost_centers ?? [],
    isActive: data.active,
    applyExpense: data.apply_expense,
    approvers: (data.approvers ?? []).map(mapApproverDTO),
    approverExpense: {
      uuid: data.approver_expense?.uuid,
      name: data.approver_expense?.name,
    },
    outPolicyApprovalModel: data.out_policy_approval_model
      ? mapApprovalModelDTO(data.out_policy_approval_model)
      : undefined,
  };
}

export function mapShortApprovalModelDTO(data: ShortApprovalModelDTO): ShortApprovalModel {
  return {
    approvers: (data.approvers ?? []).map(mapApproverDTO),
    isActive: data.active,
    name: data.name,
    uuid: data.uuid,
    approvalType: data.approval_type,
  };
}
