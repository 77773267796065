import { FC, useMemo } from "react";
import { asCurrency } from "~/application/utils/mask-functions";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Caption } from "~/components/Typography";
import { displayDate, displayTimestamp, toHour12 } from "~/utils/date.utils";
import { formatRoadInfo } from "../../utils";
import { DefaultCompanyLogo } from "../DefaultCompanyLogo/DefaultCompanyLogo";
import { RoadReadListItemProps } from "./types";
import { ViolatedPoliciesButton } from "../ViolatedPoliciesButton/ViolatedPoliciesButton";
import useMobile from "../../hooks/useMobile";

export const RoadReadListItem: FC<RoadReadListItemProps> = ({
  data,
  seats,
  seatsConnection,
  onOpenDetails,
}) => {
  const roadInfo = useMemo(() => data && formatRoadInfo(data), [data]);
  const isMobile = useMobile();

  const handleOpenDetails = () => {
    return onOpenDetails({ road: data, seats, seatsConnection });
  };

  return (
    <Card>
      {isMobile && (
        <Flex css={{ p: "$5" }}>
          {!!data.violatedPolicies?.length && (
            <ViolatedPoliciesButton data={data.violatedPolicies} />
          )}
        </Flex>
      )}
      <Flex
        align="center"
        gap="4"
        css={{
          p: "$6",
          "@mxmd": {
            p: "$3",
          },
        }}
      >
        <Flex justify={"between"} css={{ flexGrow: "1" }}>
          {data.companyImage ? (
            <CompanyLogo src={data.companyImage} alt={data.company} size="lg" />
          ) : (
            <DefaultCompanyLogo companyName={data.company} />
          )}

          {data.withBPE && (
            <Tag
              variant="info-light"
              css={{
                "@mxmd": {
                  display: "none",
                },
              }}
            >
              Bilhete eletrônico
            </Tag>
          )}
        </Flex>
        <Flex align="center" direction={{ "@mxlg": "column" }} gap="2">
          {!!data.violatedPolicies?.length && !isMobile && (
            <ViolatedPoliciesButton data={data.violatedPolicies} />
          )}
          <Button
            variant="tertiary"
            onClick={handleOpenDetails}
            css={{
              "@mxlg": {
                height: "$3",
                p: "$3",
              },
            }}
          >
            Ver detalhes
          </Button>
        </Flex>
      </Flex>

      {roadInfo && (
        <Grid columns={{ "@initial": "4", "@mxmd": "1" }} gap="6" css={{ p: "$3" }}>
          <Flex direction="column" gap="4">
            <Caption>{toHour12(data.departureDate)}</Caption>
            <Text
              size="5"
              css={{
                fw: "600",
                "@mxlg": {
                  fontSize: "$md",
                },
              }}
            >
              {data.from}
            </Text>
            <Text
              css={{
                "@mxmd": {
                  display: "none",
                },
              }}
              size="3"
            >
              {displayDate(data.departureDate)}
            </Text>
          </Flex>

          <Flex direction="column" gap="4">
            <Caption>{toHour12(data.arrivalDate)}</Caption>
            <Text
              size="5"
              css={{
                fw: "600",
                "@mxlg": {
                  fontSize: "$md",
                },
              }}
            >
              {data.to}
            </Text>
            <Text
              css={{
                "@mxmd": {
                  display: "none",
                },
              }}
              size="3"
            >
              {displayDate(data.arrivalDate)}
            </Text>
          </Flex>

          <Flex
            direction="column"
            gap="4"
            align="start"
            css={{
              "@mxmd": {
                display: "none",
              },
            }}
          >
            {data.travelDuration && (
              <>
                <Caption>Duração</Caption>
                <Caption>{displayTimestamp(data.travelDuration)}</Caption>
              </>
            )}

            <Tag variant="info-light">{data.seatClass}</Tag>
          </Flex>

          {data.price && (
            <Flex
              direction="column"
              gap="4"
              css={{
                alignItems: "flex-end",
                flex: "1 0 0%",
                "@mxmd": {
                  display: "none",
                },
              }}
            >
              <Text size="6" css={{ fw: "600", ta: "end" }}>
                {asCurrency(data.price)}
              </Text>
            </Flex>
          )}
        </Grid>
      )}
    </Card>
  );
};

RoadReadListItem.displayName = "RoadReadListItem";
