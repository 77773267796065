import * as yup from "yup";

export function isValidImageType(fileName?: string) {
  return fileName
    ? ["jpg", "png", "jpeg", "webp"].indexOf(fileName.split('.').pop()!) > -1
    : false;
}

export const updateCustomerProfileSchema = yup.object().shape({
  name: yup.string().required("O nome é obrigatório"),
  lastName: yup.string().required("O sobrenome é obrigatório"),
  birthDate: yup.string().required("A data de nascimento é obrigatória"),
  cpf: yup
    .string()
    .required("O CPF é obrigatório"),
  phone: yup
    .string()
    .required("O Telefone é obrigatório")
    .test("is-valid-phone", "Telefone inválido", (value) => {
      if (!value || value === "+") return true;
      return value.length >= 10;
    }),
  rg: yup.string().required("O RG é obrigatório"),
  image: yup
    .mixed<File>()
    .nullable()
    .test("is-valid-type", "Selecione uma imagem válida", (value: any) => {
      if (!value) {
        return true;
      } else { if (!value.length) return true }
      return isValidImageType(value[0].name.toLowerCase());
    }),
});


export type UpdateCustomerProfile = yup.InferType<typeof updateCustomerProfileSchema>;
