import type { HotelDetails, HotelRoom } from "~/application/types";
import { Card } from "~/components/Card";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { FormDialog } from "~/components/FormDialog";
import { Grid } from "~/components/Grid";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { HotelImage } from "../HotelImage";

export type AccommodationDetailsDialogProps = {
  data: HotelRoom;
  hotelDetails: HotelDetails;
  onCloseClick?: () => void;
};

export function AccommodationDetailsDialog({
  data,
  hotelDetails,
  onCloseClick,
}: AccommodationDetailsDialogProps) {
  return (
    <Container size="12" fixed css={{ overflow: "auto" }}>
      <Card>
        <FormDialog
          title="Detalhes do quarto"
          onClickDismissButton={onCloseClick}
          css={{
            "@mxlg": {
              width: "90%",
              margin: "0 auto",
              height: "78vh",
            },
          }}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Flex direction="column" css={{ gap: "$10" }}>
              <Flex direction="column" gap="8">
                <H5>Sobre o {data.description}</H5>

                <Text as="p" size="3" css={{ lineHeight: "$5" }} variant="darkest">
                  {data.description}
                </Text>
              </Flex>

              <Flex direction="column" gap="8">
                <H5>Oferece</H5>
                <Text>Obs: Amenidades sujeito a cobrança por parte do hotel.</Text>
                <Flex wrap="wrap" gap="2">
                  {hotelDetails.amenities.map((tag, tagIndex) => (
                    <Tag variant="info-light" key={tagIndex}>
                      <Text>{tag}</Text>
                    </Tag>
                  ))}
                </Flex>
              </Flex>

              <Flex direction="column" gap="8">
                <H5>Galeria de fotos</H5>

                <Grid
                  columns={{ "@initial": "3", "@mxlg": "1" }}
                  gap="6"
                  style={{ gridAutoRows: "216px" }}
                >
                  {hotelDetails.images.map((image, imageIndex) => (
                    <HotelImage src={image} key={imageIndex} />
                  ))}
                </Grid>
              </Flex>
            </Flex>
          </DialogBody>
        </FormDialog>
      </Card>
    </Container>
  );
}

AccommodationDetailsDialog.displayName = "AccommodationDetailsDialog";
