import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { OrderAirwaySegment } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col } from "~/components/Grid";
import { TextInput } from "~/components/Input";
import { Text } from "~/components/Text";

const addTrackerSchema = yup.object().shape({
  tracker: yup.string().required("O localizador é obrigatório"),
});

type AddTrackerSchema = yup.InferType<typeof addTrackerSchema>;

export type AddTrackerDialogProps = {
  data: OrderAirwaySegment;
  onCloseClick?: () => void;
  onSubmit: (data: OrderAirwaySegment) => void;
};

export const AddTrackerDialog = ({ data, onSubmit, onCloseClick }: AddTrackerDialogProps) => {
  const { control, formState, handleSubmit } = useForm<AddTrackerSchema>({
    resolver: yupResolver(addTrackerSchema),
  });

  const handleAddTracker = ({ tracker }: AddTrackerSchema) => {
    onSubmit({ ...data, tracker });
  };

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(handleAddTracker)}>
        <FormDialog
          title="Adicionar localizador"
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>Adicionar</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Col sz="12">
              <FormControl name="tracker" control={control} required>
                <FieldLabel>Localizador</FieldLabel>
                <TextInput placeholder="Digite o localizador" />
                {formState.errors.tracker && (
                  <Text variant="error-base" size="2" fw="700" css={{ mt: "$2" }}>
                    {formState.errors.tracker.message}
                  </Text>
                )}
              </FormControl>
            </Col>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

AddTrackerDialog.displayName = "AddTrackerDialog";
