import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { QueryKeys } from "~/constants";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col } from "~/components/Grid";
import { Select } from "~/components/Input";
import { Text } from "~/components/Text";
import { Provider } from "~/application/types";
import { CredentialForm } from "./CredentialForm";
import { Parameter } from "~/application/types/entities/CredentialParameter.type";
import { SubmissionData } from "~/application/usecases/Credentials/ICredentialService";

export type CredentialDialogForm = {
  title: string;
  provider: Provider;
  value: string;
  description: string;
  identifier: string;
  integrationCode: string;
  serviceType: {
    value: string;
  };
  credentialValues?: {
    uuid: string;
    parameterId: string;
    serviceType: string;
    title: string;
    value: string;
    name: string;
    parameter: Parameter;
  }[];
};

type CredentialResponse = {
  data: {
    parameters: Parameter[];
  };
};

interface CredentialDialogProps {
  isNew?: boolean;
  onCloseClick?: () => void;
  defaultFormData: CredentialDialogForm;
  fetchProviders: () => Promise<Provider[]>;
  fetchCredentials: (provider: Provider) => Promise<CredentialResponse>;
  onSubmitProp: (data: SubmissionData) => void;
}

export function CredentialDialog({
  isNew = false,
  onCloseClick,
  defaultFormData,
  fetchProviders,
  fetchCredentials,
  onSubmitProp,
}: CredentialDialogProps) {
  const { control, handleSubmit, watch, setValue, formState } = useForm<CredentialDialogForm>({
    defaultValues: defaultFormData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { provider, ...formData } = watch();
  const { isDirty } = formState;

  const { data: providers, isLoading: isLoadingProviders } = useQuery<Provider[]>(
    [QueryKeys.PROVIDERS, provider?.uuid],
    () => fetchProviders(),
    { enabled: !!isNew }
  );

  const { data: credentialsData } = useQuery<CredentialResponse>(
    [QueryKeys.CREDENTIALS, provider?.uuid],
    () => fetchCredentials(provider),
    { enabled: !!provider?.uuid }
  );

  const toSnakeCase = (text: string): string => text.replace(/[\s:.]/g, "_").toLowerCase();

  const transformCredentialValues = useCallback(
    (values: CredentialDialogForm["credentialValues"]) =>
      values?.map((item, index) => {
        const { uuid, title, serviceType, ...rest } = item.parameter;
        return {
          uuid: item.uuid,
          value: item.value,
          name: title ? toSnakeCase(title) : "",
          serviceType: {
            id: index.toString(),
            name: defaultFormData.serviceType,
          },
          title,
          ...rest,
        };
      }) ?? [],
    [defaultFormData]
  );

  const transformedCredentialValues = transformCredentialValues(defaultFormData.credentialValues);

  const formatCredentialParameterValues = useCallback(
    (parameters: Parameter[] | undefined) =>
      parameters?.map((parameter) => ({
        ...parameter,
        name: toSnakeCase(parameter.title ?? ""),
      })) ?? [],
    []
  );

  const credentialParameterValues = formatCredentialParameterValues(
    credentialsData?.data?.parameters
  );

  const parameters = isNew ? credentialParameterValues : transformedCredentialValues;

  const getKey = (isNew: boolean) => (!isNew ? "uuid" : "credentialParameterId");

  const payload = parameters.map((param) => ({
    [getKey(isNew)]: param.uuid,
    value: Object.entries(isDirty ? formData : defaultFormData)
      .filter(([key]) => key === param.name)
      .at(0)
      ?.at(1),
  }));

  const renderFormFields = useCallback(
    () => (
      <CredentialForm
        control={control}
        parameter={parameters as Parameter[]}
        setValue={setValue}
        isNew={isNew}
      />
    ),
    [parameters, payload]
  );

  const onSubmit = () => {
    onSubmitProp({
      providerId: provider?.uuid,
      description: formData.description,
      identifier: formData.identifier,
      integrationCode: formData.integrationCode,
      serviceType: formData.serviceType.value,
      payload,
    });
  };

  return (
    <Container
      fixed
      size={{ "@initial": "8", "@mxlg": "12" }}
      css={{
        height: "85vh",
        "@mxlg": {
          width: "95vw",
        },
      }}
    >
      <Form
        css={{ height: "90vh" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormDialog
          css={{ height: "85vh" }}
          title={isNew ? "Nova credencial" : "Editar credencial"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button type="submit">
              <Text>{isNew ? "Adicionar" : "Salvar"}</Text>
            </Button>
          }
        >
          <DialogBody css={{ p: "$6", height: "80vh", maxHeight: "600px" }}>
            {isNew && (
              <Col>
                <FormControl control={control} name="provider" required>
                  <FieldLabel>Fornecedor</FieldLabel>
                  <Select
                    isLoading={isLoadingProviders}
                    placeholder="Selecione o fornecedor"
                    options={providers}
                    getOptionValue={(provider) => provider.uuid}
                    getOptionLabel={(provider) => provider.name}
                    size="md"
                  />
                </FormControl>
              </Col>
            )}

            {renderFormFields()}
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
