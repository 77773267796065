
import { Order } from "~/application/types";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { H6 } from "~/components/Typography";

export interface OrderAdditionalInfoItemProps {
  order?: Order;
}

export function OrderAdditionalInfoItem({
  order,
}: OrderAdditionalInfoItemProps) {
  if (!order) {
    return <></>;
  }

  return (
    <Card>
      <Flex direction="column" justify="between" gap="4" css={{ p: "$6" }}>
        <CardBody
          css={{
            border: "1px solid $neutrals-base",
            borderRadius: "$rounded",
          }}
        >
          <Flex direction="column">
            <Text
              variant="dark"
              css={{
                mb: "$$data-list-gap",
                fw: "500",
                display: "block",
                fontSize: "$sm",
              }}
            >
              Centro de custo
            </Text>

            <H6 css={{ mt: "$4" }}>{order.costCenter.name}</H6>
          </Flex>
        </CardBody>

        <CardBody
          css={{
            border: "1px solid $neutrals-base",
            borderRadius: "$rounded",
          }}
        >
          <Text
            variant="dark"
            css={{
              mb: "$$data-list-gap",
              fw: "500",
              display: "block",
              fontSize: "$sm",
            }}
          >
            Motivo
          </Text>

          <H6 css={{ mt: "$4" }}>{order.reasonTrip?.reason}</H6>
        </CardBody>

        <CardBody
          css={{
            border: "1px solid $neutrals-base",
            borderRadius: "$rounded",
          }}
        >
          <Flex direction="column">
            <Text
              variant="dark"
              css={{
                mb: "$$data-list-gap",
                fw: "500",
                display: "block",
                fontSize: "$sm",
              }}
            >
              Projeto
            </Text>

            <H6 css={{ mt: "$4" }}>{order.project?.name}</H6>
          </Flex>
        </CardBody>

        <CardBody
          css={{
            border: "1px solid $neutrals-base",
            borderRadius: "$rounded",
          }}
        >
          <Flex direction="column">
            <Text
              variant="dark"
              css={{
                mb: "$$data-list-gap",
                fw: "500",
                display: "block",
                fontSize: "$sm",
              }}
            >
              Fase
            </Text>

            <H6 css={{ mt: "$4" }}>{order.phase?.name}</H6>
          </Flex>
        </CardBody>

        <CardBody
          css={{
            border: "1px solid $neutrals-base",
            borderRadius: "$rounded",
          }}
        >
          <Text
            variant="dark"
            css={{
              mb: "$$data-list-gap",
              fw: "500",
              display: "block",
              fontSize: "$sm",
            }}
          >
            Observações
          </Text>

          <H6 css={{ mt: "$4" }}>{order.observation}</H6>
        </CardBody>
      </Flex>
    </Card>
  );
}

OrderAdditionalInfoItem.displayName = "OrderAdditionalInfoItem";
