import { useCustomerEmployeesGroups } from "~/core/modules/Customer/pages/CustomerPage/hooks/useCustomerEmployeesGroups";
import { CustomerEmployeesGroupsContainer } from "./CustomerEmployeesGroupsContainer";
import { useUser } from "~/presentation/core/contexts/UserContext";

export function CustomerEmployeesGroupsPage() {
  const { contexts } = useUser();

  const customerEmployeesGroupState = useCustomerEmployeesGroups({
    customerId: contexts.customer.uuid,
    enabled: true,
  });

  return <CustomerEmployeesGroupsContainer {...customerEmployeesGroupState} />;
}
