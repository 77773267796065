import { createContext, useContext } from "react";
import type {
  AirwayBookingStep,
  AirwayFlightQuery,
  AirwayReducer,
} from "../hooks/useAirwayReducer/types";
import { FlightQuery, Order } from "~/application/types";
import { useAirwayBudgetResult } from "../hooks/useAirwayBudget/type";
import { AirwayQueryFormData } from "~/presentation/shared/components/AirwayQueryForm";
import { FlightOrderType } from "../ui/types";

export interface FlightsPageContextType {
  isOneWay: boolean;
  airwayReducer: AirwayReducer;
  flightQuery: AirwayFlightQuery;
  bookingStep: AirwayBookingStep;
  order?: Order;
  airwayBudget: useAirwayBudgetResult;
  airwayParams: AirwayQueryFormData | null;
  isManyStretch: boolean;
  isInternational?: boolean;
  isInternationalCombined?: boolean;
  orderType: FlightOrderType;
  setOrderType: (data: FlightOrderType) => void;
}

export const FlightsPageContext = createContext({} as FlightsPageContextType);

export const useFlightsPage = () => useContext(FlightsPageContext);

export const FlightsPageProvider = FlightsPageContext.Provider;
