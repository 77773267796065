import { AirwayEmission, HotelEmission, OrderItems, VehicleEmission } from "~/application/types";
import { api } from "~/infrastructure/api";
import type { IEmissionService } from "./IEmissionService";
import { RoadEmissionSubmit } from "~/presentation/shared/views/RoadIssuance";

export class EmissionService implements IEmissionService {
  async airway(orderId: string, data: AirwayEmission[]): Promise<void> {
    const body = data.map((airway) => ({
      tracker: airway.tracker,
      travelers: airway.travelers,
      uuid: airway.uuid,
      value: airway.value,
      change_tax: airway.changeTax,
    }));
    return await api.post(`/orders/${orderId}/emissions/airway`, body).then(({ data }) => data);
  }

  async road(orderId: string, data: RoadEmissionSubmit): Promise<void> {
    const body = {
      ...data,
      travelers: data.travelers.map((traveler) => ({
        agreement_uuid: traveler.providerId,
        offline_payment_method_uuid: traveler.paymentMethod.uuid,
        uuid: traveler.uuid,
        tracker: traveler.tracker,
        fare: traveler.fare,
        fee: traveler.fee,
        markup: traveler.markup,
        provider_fees: traveler.providerFees,
        seats: traveler.seats.map((seat) => ({
          is_connection: seat.isConnection,
          ticket: seat.ticket,
          seat_number: seat.seatNumber,
          bpe: seat.bpe,
          seat_type: seat.seatType,
        })),
      })),
    };

    return await api.post(`/orders/${orderId}/emissions/road`, [body]).then(({ data }) => data);
  }

  async cancelItem({
    orderItemType,
    orderItemId,
  }: {
    orderItemType: OrderItems;
    orderItemId: string;
  }): Promise<void> {
    return await api
      .post(`/orders/${orderItemType}/${orderItemId}/cancel`)
      .then(({ data }) => data);
  }

  async hotel(orderId: string, data: HotelEmission): Promise<void> {
    const body = {
      uuid: data.uuid,
      tracker: data.tracker,
      agreement_uuid: data.provider?.uuid,
      offline_payment_method_uuid: data.paymentMethod?.uuid,
      fare_value: data.fareValue,
      total_value: data.value,
    };

    return await api.post(`/orders/${orderId}/emissions/hotel`, [body]).then(({ data }) => data);
  }

  async vehicle(orderId: string, data: VehicleEmission): Promise<void> {
    return await api.post(`/orders/${orderId}/emissions/vehicle`, [data]).then(({ data }) => data);
  }
}
