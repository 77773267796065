import { FC, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { CustomerSettingParameter } from "~/application/types";
import {
  SettingParameterSlug,
  SettingParameterTypeValue,
} from "~/application/types/entities/SettingParameter.type";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { NumberInput, Select } from "~/components/Input";
import { Text } from "~/components/Text";
import { H5 } from "~/components/Typography";
import { getSettingParametersDefaultValues } from "../../../utils/form";
import { useCustomerEmployeesGroups } from "../../CustomerPage/hooks/useCustomerEmployeesGroups";

const customerSettingParameterSchema = yup.object().shape({
  value: yup
    .mixed()
    .required("O valor é obrigatório")
    .test({
      name: "is-value-not-empty",
      test: (value: any) => value !== "",
      message: "O valor é obrigatório",
      exclusive: true,
    }),
});

type CustomerSettingParameterSchema = yup.InferType<typeof customerSettingParameterSchema>;

export type CustomerSettingParameterDialogProps = {
  data?: CustomerSettingParameter;
  onCloseClick?: () => void;
  onSubmit: (data: CustomerSettingParameter) => void;
  options?: { label: string; value: string }[];
};

export const CustomerSettingParameterDialog: FC<CustomerSettingParameterDialogProps> = ({
  data,
  onCloseClick,
  onSubmit: onSubmitProp,
  options,
}) => {
  const isNew = !data;

  const { control, formState, handleSubmit, setValue } = useForm<CustomerSettingParameterSchema>({
    defaultValues: data && getSettingParametersDefaultValues(data),
    resolver: yupResolver(customerSettingParameterSchema),
  });
  const isDefaultCustomerEmployeeGroup =
    data?.slug === SettingParameterSlug.DEFAULT_CUSTOMER_EMPLOYEE_GROUP;

  const {
    data: groups,
    onSearch,
    isLoading: isLoadingGroups,
  } = useCustomerEmployeesGroups({
    customerId: data?.customerId || "",
    enabled: isDefaultCustomerEmployeeGroup,
    active: true,
  });

  const foundGroup = groups?.find((group) => group.uuid === data?.value);

  const formatGroups = groups?.map((group) => ({
    label: group.description,
    value: group.uuid,
  }));

  useEffect(() => {
    if (foundGroup) {
      setValue("value", { value: foundGroup.uuid, label: foundGroup.description });
    }
  }, [foundGroup]);

  const values: Record<SettingParameterTypeValue, JSX.Element> = {
    [SettingParameterTypeValue.INTEGER]: (
      <>
        <FieldLabel>Valor</FieldLabel>
        <NumberInput size="sm" thousandSeparator="" decimalScale={0} placeholder="Digite o valor" />
      </>
    ),

    [SettingParameterTypeValue.FLOAT]: (
      <>
        <FieldLabel>Valor</FieldLabel>
        <NumberInput placeholder="Digite o valor" size="sm" />
      </>
    ),

    [SettingParameterTypeValue.PERCENTAGE]: (
      <>
        <FieldLabel>Valor</FieldLabel>
        <NumberInput
          size="sm"
          suffix="%"
          placeholder="Digite o percentual"
          fixedDecimalScale={false}
        />
      </>
    ),

    [SettingParameterTypeValue.SELECT]: (
      <>
        <FieldLabel>Valor</FieldLabel>
        <Select
          options={!isDefaultCustomerEmployeeGroup ? options : formatGroups}
          placeholder="Selecione uma opção"
          portalled
          onInputChange={onSearch}
          isLoading={isLoadingGroups}
        />
      </>
    ),
  } as Record<SettingParameterTypeValue, JSX.Element>;

  const onSubmit = (data: CustomerSettingParameterSchema) => {
    onSubmitProp({
      ...data,
      value:
        typeof data.value === "object" && "value" in data.value ? data.value.value : data.value,
    } as CustomerSettingParameter);
  };

  return (
    <Container size="8" fixed>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormDialog
          css={{ overflow: "visible" }}
          title={"Editar parâmetro de configuração"}
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", overflow: "visible", maxHeight: "70vh" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6">
              <Col>
                <FormControl name="value" control={control}>
                  {data?.typeValue && data.typeValue in values && values[data.typeValue]}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

CustomerSettingParameterDialog.displayName = "CustomerSettingParameterDialog";
