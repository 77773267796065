import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { ApprovalModel } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { SearchBar, Select } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { ApprovalModelListItem } from "./components/ApprovalModelListItem";
import { Col, Row } from "~/components/Grid";
import { H4 } from "~/components/Typography";
import { IApproverFilters, ISelectOption } from "../../hooks/useApprovalModels";
import { CheckMarkMultiSelect } from "~/components/Input/CheckMarkMultiSelect";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { approvalModelService } from "~/application/usecases";
import { logError } from "~/presentation/shared/utils/errors";
import { dialogService } from "~/components/DialogStack";
import { filterByStatusOptions } from "../../constants";
import { Flex } from "~/components/Flex";

export const LOG_TAG = "Customer/CustomerPage/ApprovalModel/TabApprovalModels";

export const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar modelo de aprovação",
} as const;

export type TabApprovalModelProps = {
  currentPage: number;
  lastPage: number;
  data: ApprovalModel[];
  searchText: string;
  isLoading: boolean;
  approverOptions?: IApproverFilters;
  isLoadingApprovers: boolean;
  selectedApprover: IApproverFilters;
  selectedStatus: ISelectOption;
  setSearchText: (search: string) => void;
  handleChangeSearchApprover: (text: string) => void;
  onCreateApprovalModel: () => void;
  onChangeFilterApprover: (data: ISelectOption[]) => void;
  onChangeStatusFilter: (data: ISelectOption) => void;
  onClearFilters: () => void;
  onEditApprovalModel: (ApprovalModel: ApprovalModel) => void;
  onToggleState: (ApprovalModel: ApprovalModel) => void;
  onGoToPage: (value: number) => void;
};

export function TabApprovalModels({
  data,
  isLoading,
  searchText,
  approverOptions,
  isLoadingApprovers,
  selectedApprover,
  selectedStatus,
  onChangeFilterApprover,
  onChangeStatusFilter,
  handleChangeSearchApprover,
  setSearchText,
  onGoToPage,
  onEditApprovalModel,
  onToggleState,
  onCreateApprovalModel,
  currentPage,
  lastPage,
  onClearFilters,
}: TabApprovalModelProps) {
  const [searchParams] = useSearchParams();
  const approvalModelId = searchParams.get("modelo-de-aprovacao");

  useQuery(
    [QueryKeys.CUSTOMER_APPROVAL_MODEL, approvalModelId],
    () => approvalModelService.findById(approvalModelId!),
    {
      enabled: !!approvalModelId,
      staleTime: QueryTimes.NONE,
      onSuccess: (data) => {
        searchParams.delete("modelo-de-aprovacao");

        dialogService.popDialog();

        onEditApprovalModel({
          ...data,
          approvers: data.approvers.filter((approver) => approver.isActive),
        });
      },
      onError: (error) => {
        dialogService.popDialog();

        logError({
          error,
          logTag: LOG_TAG,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const listRenderer = useCallback(
    (item: ApprovalModel) => (
      <ApprovalModelListItem
        data={item}
        onEditClick={onEditApprovalModel}
        onToggleState={onToggleState}
        key={item.uuid}
      />
    ),
    [onEditApprovalModel, onToggleState]
  );

  return (
    <>
      <Card css={{ overflow: "visible" }}>
        <CardBody css={{ borderBottom: "1px solid $colors$neutrals-light" }}>
          <Row align="center">
            <Col>
              <H4>Modelos de aprovação</H4>
            </Col>

            <Col sz="auto">
              <Button
                title="Novo modelo de aprovação"
                color="primary"
                onClick={onCreateApprovalModel}
              >
                <Icon as={SvgPlus} />
                <Text>Novo modelo de aprovação</Text>
              </Button>
            </Col>
          </Row>
        </CardBody>

        <CardBody>
          <Flex direction={{ "@initial": "column", "@lg": "row" }} align="center" gap="4">
            <Row
              gap="4"
              align="center"
              wrap="noWrap"
              css={{
                flex: 1,
                width: "100%",
                "@mxlg": { display: "flex", flexDirection: "column" },
              }}
            >
              <Col sz={{ "@initial": "12", "@lg": "4" }}>
                <SearchBar
                  css={{ width: "100%" }}
                  search={searchText}
                  onSearchChange={setSearchText}
                  placeholder="Encontrar"
                />
              </Col>

              <Col sz={{ "@initial": "12", "@lg": "4" }}>
                <CheckMarkMultiSelect
                  value={selectedApprover.approvers}
                  options={approverOptions?.approvers}
                  isLoading={isLoadingApprovers}
                  onInputChange={handleChangeSearchApprover}
                  onChange={onChangeFilterApprover}
                  placeholder="Aprovadores"
                />
              </Col>

              <Col sz={{ "@initial": "12", "@lg": "4" }}>
                <Select
                  options={filterByStatusOptions}
                  onChange={onChangeStatusFilter}
                  value={selectedStatus}
                  placeholder="Status"
                  portalled
                />
              </Col>
            </Row>

            <Button title="Limpar filtros" variant="tertiary" onClick={onClearFilters}>
              <Text>Limpar filtros</Text>
            </Button>
          </Flex>
        </CardBody>
      </Card>

      <Box css={{ my: "$6" }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui modelos de aprovação cadastradas</Text>
            </EmptyState>
          }
        />
      </Box>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </>
  );
}
