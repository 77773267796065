import queryString from "query-string";

import { CreateBudgetProps, FindBudgetsProps, UpdateBudgetProps, ViewBudgetsProps, type IBudgetService } from "./IBudgetService";
import type { Budget, BudgetBalance, BudgetView, PaginatedResource } from "~/application/types";
import { mapBudgetBalanceDTO, mapBudgetDTO, mapBudgetViewDTO } from "~/infrastructure/api/mappers";
import { BudgetBalanceDTO, BudgetDTO, BudgetViewDTO } from "~/infrastructure/api/dtos";
import { api } from "~/infrastructure/api";
import { dayjs } from "~/utils/date.utils";

export class BudgetService implements IBudgetService {
  async create({ customerId, data }: CreateBudgetProps): Promise<Budget> {
    return await api
      .post<BudgetDTO>(`/customers/${customerId}/budgets`, {
        cost_center_uuid: data.costCenter?.uuid ?? null,
        project_uuid: data.project?.uuid ?? null,
        phase_uuid: data.phase?.uuid ?? null,
        type: data.periodicity,
        blocking: data.blocking,
        items: data.items.map((item) => ({
          value: item.value,
          start_at: dayjs(item.startAt).format('YYYY-MM-DD HH:mm:ss'),
          end_at: item.endAt ? dayjs(item.endAt).format('YYYY-MM-DD HH:mm:ss') : null,
        }))
      })
      .then(({ data }) => mapBudgetDTO(data));
  }

  async update({ customerId, data }: UpdateBudgetProps): Promise<Budget> {
    return await api
      .put<BudgetDTO>(`/customers/${customerId}/budgets/${data.uuid}`, {
        cost_center_uuid: data.costCenter?.uuid ?? null,
        project_uuid: data.project?.uuid ?? null,
        phase_uuid: data.phase?.uuid ?? null,
        type: data.periodicity,
        blocking: data.blocking,
        items: data.items.map((item) => ({
          value: item.value,
          start_at: dayjs(item.startAt).format('YYYY-MM-DD HH:mm:ss'),
          end_at: item.endAt ? dayjs(item.endAt).format('YYYY-MM-DD HH:mm:ss') : null,
        }))
      })
      .then(({ data }) => mapBudgetDTO(data));
  }

  async find({ customerId, page = 1, name }: FindBudgetsProps): Promise<PaginatedResource<Budget[]>> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/budgets`,
      query: { page, name },
    });

    return await api.get<PaginatedResource<BudgetDTO[]>>(url).then(({ data }) => ({
      links: data.links,
      meta: data.meta,
      data: data.data.map(mapBudgetDTO),
    }));
  }

  async view({ customerId, page = 1, year, name }: ViewBudgetsProps): Promise<PaginatedResource<BudgetView[]> & { years: number[] }> {
    const url = queryString.stringifyUrl({
      url: `/customers/${customerId}/budgets/dashboard`,
      query: { page, year, name },
    });

    return await api.get<PaginatedResource<BudgetViewDTO[]> & { years: number[] }>(url).then(({ data }) => ({
      links: data.links,
      meta: data.meta,
      data: data.data.map(mapBudgetViewDTO),
      years: data.years,
    }));
  }

  async getBalance(orderId: string): Promise<BudgetBalance> {
    return await api.get<BudgetBalanceDTO>(`/orders/${orderId}/budget-balance`)
      .then(({ data }) => mapBudgetBalanceDTO(data));
  }
}
