import { FC } from "react";
import { GenderEnum, Order } from "~/application/types";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { FormDialog } from "~/components/FormDialog";
import { Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCall, SvgFile, SvgMail, SvgPerson } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import useMobile from "../../hooks/useMobile";
import { DateFormats, displayDate } from "~/utils/date.utils";

type AdicionalTravelerInfoProps = {
  order?: Order;
  travelerId: string;
};

export const AdicionalTravelerInfo: FC<AdicionalTravelerInfoProps> = ({ order, travelerId }) => {
  const findTraveler = order?.travelers.find((t) => t.uuid === travelerId);
  const isMobile = useMobile();

  return (
    <FormDialog
      title="Informações adicionais"
      css={{
        width: "100%",
        maxWidth: "700px",
      }}
      headerCss={{
        backgroundColor: "$neutrals-lightest",
      }}
    >
      <Grid
        columns={isMobile ? 1 : 2}
        gap="6"
        css={{
          width: "100%",
          p: "$4",
        }}
      >
        <Flex
          direction="column"
          gap="3"
          css={{
            width: "100%",
            maxWidth: "700px",
            height: "100%",
          }}
        >
          <Flex align="center" gap="4">
            <Icon as={SvgPerson} variant="primary" />
            <Text fw="600">Informações Pessoais</Text>
          </Flex>
          <Divider />
          <Flex justify="between" direction="column" gap="1">
            <Text fw="400" variant="darkest" size="2">
              Nome
            </Text>
            <Text fw="600" variant="black" size="3">
              {findTraveler?.fullName}
            </Text>
          </Flex>
          <Flex justify="between" direction="column" gap="1">
            <Text fw="400" variant="darkest" size="2">
              Data de Nascimento
            </Text>
            <Text fw="600" variant="black" size="3">
              {displayDate(findTraveler?.dateBirth as Date, DateFormats.SMALL_DATE)}
            </Text>
          </Flex>
          <Flex justify="between" direction="column" gap="1">
            <Text fw="400" variant="darkest" size="2">
              Cargo
            </Text>
            <Text fw="600" variant="black" size="3">
              {findTraveler?.position}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          gap="3"
          css={{
            width: "100%",
            maxWidth: "700px",
            height: "100%",
          }}
        >
          <Flex align="center" gap="4">
            <Icon as={SvgCall} variant="black" />
            <Text fw="600">Contato</Text>
          </Flex>
          <Divider />
          <Flex justify="between" direction="column" gap="1">
            <Text fw="400" variant="darkest" size="2">
              Celular
            </Text>
            <Text fw="600" variant="black" size="3">
              {findTraveler?.phone}
            </Text>
          </Flex>
          {findTraveler?.email && (
            <Flex justify="between" direction="column" gap="1">
              <Text fw="400" variant="darkest" size="2">
                E-mail
              </Text>
              <Text fw="600" variant="black" size="3">
                {findTraveler?.email}
              </Text>
            </Flex>
          )}
          <Flex justify="between" direction="column" gap="1">
            <Text fw="400" variant="darkest" size="2">
              Gênero
            </Text>
            <Text fw="600" variant="black" size="3">
              {findTraveler?.gender === GenderEnum.MASCULINE ? "Masculino" : "Feminino"}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          gap="3"
          css={{
            width: "100%",
            maxWidth: "700px",
            height: "100%",
          }}
        >
          <Flex align="center" gap="4">
            <Icon as={SvgMail} variant="primary" />
            <Text fw="600">Documentos</Text>
          </Flex>
          <Divider />
          <Flex justify="between" direction="column" gap="1">
            <Text fw="400" variant="darkest" size="2">
              CPF
            </Text>
            <Text fw="600" variant="black" size="3">
              {findTraveler?.cpf}
            </Text>
          </Flex>
          <Flex justify="between" direction="column" gap="1">
            <Text fw="400" variant="darkest" size="2">
              RG
            </Text>
            <Text fw="600" variant="black" size="3">
              {findTraveler?.rg}
            </Text>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          gap="3"
          css={{
            width: "100%",
            maxWidth: "700px",
            height: "100%",
          }}
        >
          <Flex align="center" gap="4">
            <Icon as={SvgFile} variant="primary" />
            <Text fw="600">Informações Adicionais</Text>
          </Flex>
          <Divider />
          {findTraveler?.loyaltyAzul && (
            <Flex justify="between" direction="column" gap="1">
              <Text fw="400" variant="darkest" size="2">
                Fidelidade azul
              </Text>
              <Text fw="600" variant="black" size="3">
                {findTraveler?.loyaltyAzul}
              </Text>
            </Flex>
          )}
          {findTraveler?.loyaltyGol && (
            <Flex justify="between" direction="column" gap="1">
              <Text fw="400" variant="darkest" size="2">
                Fidelidade gol
              </Text>
              <Text fw="600" variant="black" size="3">
                {findTraveler?.loyaltyGol}
              </Text>
            </Flex>
          )}
          {findTraveler?.loyaltyLatam && (
            <Flex justify="between" direction="column" gap="1">
              <Text fw="400" variant="darkest" size="2">
                Fidelidade latam
              </Text>
              <Text fw="600" variant="black" size="3">
                {findTraveler?.loyaltyLatam}
              </Text>
            </Flex>
          )}
        </Flex>
      </Grid>
    </FormDialog>
  );
};
