import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Airline, DeepPartial, Markdown } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { NumberInput, TextInput } from "~/components/Input";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { H4, H5 } from "~/components/Typography";
import { QueryKeys } from "~/constants/queryKeys";
import { LoadingModal } from "~/core/modules/DeprecatedBooking/components/LoadingModal";

export interface MarkdownDialogProps {
  isNew?: boolean;
  defaultData?: DeepPartial<Markdown>;
  onCloseClick?: () => void;
  onSubmit: (data: Markdown) => void;
  fetchAirlines: () => Promise<Airline[]>;
}

export function MarkdownDialog({
  isNew = false,
  defaultData,
  onCloseClick,
  onSubmit,
  fetchAirlines,
}: MarkdownDialogProps) {
  const { control, formState, watch, reset, handleSubmit } = useForm<Markdown>({
    defaultValues: defaultData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const { isFetching: isFetchingAirlines } = useQuery([QueryKeys.AIRLINES], fetchAirlines, {
    cacheTime: 0,
    retry: 2,
    enabled: !!isNew,
    onSuccess: (airlines) => reset({ items: airlines.map((airline) => ({ airline })) }),
  });

  const { items: markdownItems } = watch();

  if (formState.isSubmitting) {
    return (
      <LoadingModal
        isOpen={formState.isSubmitting}
        message={isNew ? "Criando markdown" : "Editando markdown"}
      />
    );
  }

  return (
    <Container size="8" fixed>
      <Form
        onSubmit={handleSubmit(onSubmit, (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        })}
      >
        <FormDialog
          title={isNew ? "Novo markdown" : "Editar markdown"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Box css={{ mb: "$6" }}>
              <H4>Informações básicas</H4>
            </Box>

            <FormControl name="name" control={control} required>
              <FieldLabel>Nome do Markdown</FieldLabel>
              <TextInput placeholder="Digite um nome para o markdown" />
            </FormControl>

            <Box css={{ mb: "$6", mt: "$10" }}>
              <H4>Serviços</H4>
            </Box>

            <LazyList
              isLoading={isFetchingAirlines}
              items={markdownItems}
              skeletonHeight={180}
              render={(item, index) => (
                <Card key={item.airline.uuid}>
                  <CardBody>
                    <Box css={{ mb: "$6" }}>
                      <H5>{item.airline.name}</H5>
                    </Box>

                    <FormControl name={`items.${index}.value`} control={control} required>
                      <FieldLabel>Valor percentual</FieldLabel>
                      <NumberInput
                        suffix="%"
                        placeholder="Digite o valor para este serviço"
                        fixedDecimalScale={false}
                      />
                    </FormControl>
                  </CardBody>
                </Card>
              )}
            />
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
