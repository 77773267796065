import { FC, useCallback, useEffect, useReducer, useState } from "react";
import { StorageKeys } from "~/application/constants";
import { authService } from "~/application/usecases";
import { snackbarService } from "~/components/SnackbarStack";
import { LoginFormData } from "~/core/modules/Auth/pages/LoginPage";
import { api } from "~/infrastructure/api";
import { queryClient } from "~/services/queryClient";
import { AuthContext, AuthSignInData } from "../../contexts/AuthContext";
import { AuthDispatchAction, AuthProviderProps, AuthState } from "./types";

export function authReducer(state: AuthState, action: AuthDispatchAction): AuthState {
  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        user: action.payload.user,
        authToken: action.payload.authToken,
        isLoading: false,
      };
    case "SIGN_OUT":
      return {
        ...state,
        user: null!,
        authToken: undefined,
        isLoading: false,
      };
    default:
      return state;
  }
}

const initialState: AuthState = {
  user: null,
  authToken: undefined,
  isLoading: true,
};

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [{ user, authToken, isLoading }, dispatch] = useReducer(authReducer, initialState);
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null | undefined>();

  const saveAuthToken = useCallback(
    ({ authToken, user, storeTokenLocally }: AuthSignInData) => {
      api.headers.common["Authorization"] = `Bearer ${authToken.value}`;

      if (!authToken.createdAt) {
        return;
      }

      const dateToken = authToken.createdAt.getTime();

      if (storeTokenLocally) {
        localStorage.setItem(StorageKeys.AUTH_TOKEN, authToken.value as string);
        localStorage.setItem(StorageKeys.DATE_TOKEN, dateToken.toString());
      } else {
        sessionStorage.setItem(StorageKeys.AUTH_TOKEN, authToken.value as string);
      }

      dispatch({
        type: "SIGN_IN",
        payload: {
          authToken,
          user: user,
        },
      });
    },
    [dispatch]
  );

  const signIn = useCallback(
    async ({ email, password, keepSigned = false, securityKey }: LoginFormData) => {
      try {
        const { authToken, user } = await authService.signIn({
          email,
          password,
          secret: securityKey.replace(/\s+/g, ""),
        });

        saveAuthToken({ authToken, user, storeTokenLocally: keepSigned });
        setQrCodeUrl(undefined);
      } catch (error: any) {
        if (error.data?.errors) {
          (Object.values(error.data.errors) as string[]).forEach((error) =>
            snackbarService.showSnackbar(error, "error")
          );
        } else if (error?.code == "MANDATORY_SECRET_KEY") {
          const { qrCodeUrl } = await authService.generateSecretKey({
            email,
            password,
          });

          setQrCodeUrl(qrCodeUrl);
        } else if (error?.message) {
          snackbarService.showSnackbar(error.message, "error");
        } else {
          snackbarService.showSnackbar("Erro desconhecido", "error");
        }
      }
    },
    [saveAuthToken]
  );

  const signOut = useCallback(() => {
    delete api.headers.common["Authorization"];

    sessionStorage.removeItem(StorageKeys.AUTH_TOKEN);
    localStorage.removeItem(StorageKeys.AUTH_TOKEN);
    localStorage.removeItem(StorageKeys.DATE_TOKEN);

    localStorage.removeItem(StorageKeys.AGENCY_CUSTOMER_ID);

    queryClient.clear();

    dispatch({ type: "SIGN_OUT" });
  }, [dispatch]);

  useEffect(() => {
    async function loadDataAsync() {
      const authToken =
        localStorage.getItem(StorageKeys.AUTH_TOKEN) ||
        sessionStorage.getItem(StorageKeys.AUTH_TOKEN);

      const dateToken = localStorage.getItem(StorageKeys.DATE_TOKEN);

      if (authToken) {
        
        const { user } = await authService.verifyToken({
          value: authToken,
          createdAt: new Date(parseInt(dateToken as string)),
        });

        saveAuthToken({
          user,
          authToken: {
            value: authToken,
            createdAt: new Date(parseInt(dateToken as string)),
          },
        });
      } else {
        signOut();
      }
    }

    loadDataAsync();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: user!, //! [DANGER] "user" CAN be null
        authToken: authToken!, //! [DANGER] "authToken" CAN be null
        isSigned: !!user,
        isLoading,
        saveAuthToken,
        signIn,
        signOut,
        qrCodeUrl,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
